export const collegeActionTypes = {
  COLLEGE_LIST_INITIATE: "COLLEGE_LIST_INITIATE",
  COLLEGE_LIST_SUCCESS: "COLLEGE_LIST_SUCCESS",
  COLLEGE_LIST_FAILURE: "COLLEGE_LIST_FAILURE",

  COLLEGE_DETAILS_INITIATE: "COLLEGE_DETAILS_INITIATE",
  COLLEGE_DETAILS_SUCCESS: "COLLEGE_DETAILS_SUCCESS",
  COLLEGE_DETAILS_FAILURE: "COLLEGE_DETAILS_FAILURE",

  COLLEGE_FILTER_INITIATE: "COLLEGE_FILTER_INITIATE",
  COLLEGE_FILTER_SUCCESS: "COLLEGE_FILTER_SUCCESS",
  COLLEGE_FILTER_FAILURE: "COLLEGE_FILTER_FAILURE",
};

export const universityActionTypes = {
  UNIVERSITY_LIST_INITIATE: "UNIVERSITY_LIST_INITIATE",
  UNIVERSITY_LIST_SUCCESS: "UNIVERSITY_LIST_SUCCESS",
  UNIVERSITY_LIST_FAILURE: "UNIVERSITY_LIST_FAILURE",

  UNIVERSITY_FILTER_INITIATE: "UNIVERSITY_FILTER_INITIATE",
  UNIVERSITY_FILTER_SUCCESS: "UNIVERSITY_FILTER_SUCCESS",
  UNIVERSITY_FILTER_FAILURE: "UNIVERSITY_FILTER_FAILURE",

  UNIVERSITY_DETAILS_INITIATE: "UNIVERSITY_DETAILS_INITIATE",
  UNIVERSITY_DETAILS_SUCCESS: "UNIVERSITY_DETAILS_SUCCESS",
  UNIVERSITY_DETAILS_FAILURE: "UNIVERSITY_DETAILS_FAILURE",

  STREAM_DROPDOWN_INITIATE: "STREAM_DROPDOWN_INITIATE",
  STREAM_DROPDOWN_SUCCESS: "STREAM_DROPDOWN_SUCCESS",
  STREAM_DROPDOWN_FAILURE: "STREAM_DROPDOWN_FAILURE",

  SUB_STREAM_DROPDOWN_INITIATE: "SUB_STREAM_DROPDOWN_INITIATE",
  SUB_STREAM_DROPDOWN_SUCCESS: "SUB_STREAM_DROPDOWN_SUCCESS",
  SUB_STREAM_DROPDOWN_FAILURE: "SUB_STREAM_DROPDOWN_FAILURE",
};

export const schoolActionTypes = {
  SCHOOL_LIST_INITIATE: "SCHOOL_LIST_INITIATE",
  SCHOOL_LIST_SUCCESS: "SCHOOL_LIST_SUCCESS",
  SCHOOL_LIST_FAILURE: "SCHOOL_LIST_FAILURE",

  SCHOOL_DETAILS_INITIATE: "SCHOOL_DETAILS_INITIATE",
  SCHOOL_DETAILS_SUCCESS: "SCHOOL_DETAILS_SUCCESS",
  SCHOOL_DETAILS_FAILURE: "SCHOOL_DETAILS_FAILURE",

  SCHOOL_FILTER_INITIATE: "SCHOOL_FILTER_INITIATE",
  SCHOOL_FILTER_SUCCESS: "SCHOOL_FILTER_SUCCESS",
  SCHOOL_FILTER_FAILURE: "SCHOOL_FILTER_FAILURE",

  PRE_SCHOOL_LIST_INITIATE: "PRE_SCHOOL_LIST_INITIATE",
  PRE_SCHOOL_LIST_SUCCESS: "PRE_SCHOOL_LIST_SUCCESS",
  PRE_SCHOOL_LIST_FAILURE: "PRE_SCHOOL_LIST_FAILURE",

  PRE_SCHOOL_DETAILS_INITIATE: "PRE_SCHOOL_DETAILS_INITIATE",
  PRE_SCHOOL_DETAILS_SUCCESS: "PRE_SCHOOL_DETAILS_SUCCESS",
  PRE_SCHOOL_DETAILS_FAILURE: "PRE_SCHOOL_DETAILS_FAILURE",

  PRE_SCHOOL_FILTER_INITIATE: "PRE_SCHOOL_FILTER_INITIATE",
  PRE_SCHOOL_FILTER_SUCCESS: "PRE_SCHOOL_FILTER_SUCCESS",
  PRE_SCHOOL_FILTER_FAILURE: "PRE_SCHOOL_FILTER_FAILURE",
};

export const coursesAndCertificates = {
  COURSES_LIST_INITIATE: "COURSES_LIST_INITIATE",
  COURSES_LIST_SUCCESS: "COURSES_LIST_SUCCESS",
  COURSES_LIST_FAILURE: "COURSES_LIST_FAILURE",

  COURSES_DETAILS_INITIATE: "COURSES_DETAILS_INITIATE",
  COURSES_DETAILS_SUCCESS: "COURSES_DETAILS_SUCCESS",
  COURSES_DETAILS_FAILURE: "COURSES_DETAILS_FAILURE",

  COURSES_FILTER_INITIATE: "COURSES_FILTER_INITIATE",
  COURSES_FILTER_SUCCESS: "COURSES_FILTER_SUCCESS",
  COURSES_FILTER_FAILURE: "COURSES_FILTER_FAILURE",

  COURSE_DETAILS_INITIATE: "COURSE_DETAILS_INITIATE",
  COURSE_DETAILS_SUCCESS: "COURSE_DETAILS_SUCCESS",
  COURSE_DETAILS_FAILURE: "COURSE_DETAILS_FAILURE",

  COURSE_DROPDOWN_INITIATE: "COURSE_DROPDOWN_INITIATE",
  COURSE_DROPDOWN_SUCCESS: "COURSE_DROPDOWN_SUCCESS",
  COURSE_DROPDOWNN_FAILURE: "COURSE_DROPDOWNN_FAILURE",

  COURSES_WEBSITE_LIST_INITIATE:"COURSES_WEBSITE_LIST_INITIATE",
  COURSES_WEBSITE_LIST_SUCCESS:"COURSES_WEBSITE_LIST_SUCCESS",
  COURSES_WEBSITE_LIST_FAILURE:"COURSES_WEBSITE_LIST_FAILURE"
};

export const courseActionTypes = {
  COURSE_ADD_INITIATE: "COURSE_ADD_INITIATE",
  COURSE_ADD_SUCCESS: "COURSE_ADD_SUCCESS",
  COURSE_ADD_FAILURE: "COURSE_ADD_FAILURE",

  COURSE_LIST_INITIATE: "COURSE_LIST_INITIATE",
  COURSE_LIST_SUCCESS: "COURSE_LIST_SUCCESS",
  COURSE_LIST_FAILURE: "COURSE_LIST_FAILURE",

  UPDATE_COURSE_INITIATE: "UPDATE_COURSE_INITIATE",
  UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",
  UPDATE_COURSE_FAILURE: "UPDATE_COURSE_FAILURE",

  COURSE_DETAILS_INITIATE: "COURSE_DETAILS_INITIATE",
  COURSE_DETAILS_SUCCESS: "COURSE_DETAILS_SUCCESS",
  COURSE_DETAILS_FAILURE: "COURSE_DETAILS_FAILURE",
};

export const loginConstant = {
  LOGIN_INITIATE: "LOGIN_INITIATE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_INITIATE",

  OTP_LOGIN_INITIATE: "OTP_LOGIN_INITIATE",
  OTP_LOGIN_SUCCESS: "OTP_LOGIN_SUCCESS",
  OTP_LOGIN_FAILURE: "OTP_LOGIN_INITIATE",
};

export const adminDetailsConstant = {
  ADMIN_DETAILS_INITIATE: "ADMIN_DETAILS_INITIATE",
  ADMIN_DETAILS_SUCCESS: "ADMIN_DETAILS_SUCCESS",
  ADMIN_DETAILS_FAILURE: "ADMIN_DETAILS_INITIATE",
};

export const signUPConstant = {
  SIGN_UP_INITIATE: "SIGN_UP_INITIATE",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_INITIATE",

  GOOGLE_SIGN_UP_INITIATE: "GOOGLE_SIGN_UP_INITIATE",
  GOOGLE_SIGN_UP_SUCCESS:  "GOOGLE_SIGN_UP_SUCCESS",
  GOOGLE_SIGN_UP_FAILURE:  "GOOGLE_SIGN_UP_INITIATE",

 


};

export const sendOtpConstant = {
  LOGIN_OTP_INITIATE: "LOGIN_OTP_INITIATE",
  LOGIN_OTP_SUCCESS: "LOGIN_OTP_SUCCESS",
  LOGIN_OTP_FAILURE: "LOGIN_OTP_FAILURE",
};

export const verifyOtpConstant = {
  VERIFY_OTP_INITIATE: "VERIFY_OTP_INITIATE",
  VERIFY_OTP_SUCCESS: " VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
};

export const reSendOtpConstant = {
  RESEND_OTP_INITIATE: "RESEND_OTP_INITIATE",
  RESEND_OTP_SUCCESS: " RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",
};

export const confirmPasswordConstant = {
  CONFIRM_PASSWORD_INITIATE: "CONFIRM_PASSWORD_INITIATE",
  CONFIRM_PASSWORD_SUCCESS: "CONFIRM_PASSWORD_SUCCESS",
  CONFIRM_PASSWORD_FAILURE: "CONFIRM_PASSWORD_FAILURE",
};

export const logOutConstant = {
  LOGOUT_INITIATE: "LOGOUT_INITIATE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const updatePasswordConstant = {
  UPDATE_PASSWORD_INITIATE: "UPDATE_PASSWORD_INITIATE",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
};

export const trendingConstant = {
  TRENDING_COLLEGE_UNIVERSITY_LIST_INITIATE:
    "TRENDING_COLLEGE_UNIVERSITY_LIST_INITIATE",
  TRENDING_COLLEGE_UNIVERSITY_LIST_SUCCESS:
    "TRENDING_COLLEGE_UNIVERSITY_LIST_SUCCESS",
  TRENDING_COLLEGE_UNIVERSITY_LIST_FAILURE:
    "TRENDING_COLLEGE_UNIVERSITY_LIST_FAILURE",

  TRENDING_SCHOOL_PRESCHOOL_LIST_INITIATE:
    "TRENDING_SCHOOL_PRESCHOOL_LIST_INITIATE",
  TRENDING_SCHOOL_PRESCHOOL_LIST_SUCCESS:
    "TRENDING_SCHOOL_PRESCHOOL_LIST_SUCCESS",
  TRENDING_SCHOOL_PRESCHOOL_LIST_FAILURE:
    "TRENDING_SCHOOL_PRESCHOOL_LIST_FAILURE",

  TRENDING_STREAM_LIST_INITIATE: "TRENDING_STREAM_LIST_INITIATE",
  TRENDING_STREAM_LIST_SUCCESS: "TRENDING_STREAM_LIST_SUCCESS",
  TRENDING_STREAM_LIST_FAILURE: "TRENDING_STREAM_LIST_FAILURE",

  TRENDING_CITY_LIST_INITIATE: "TRENDING_CITY_LIST_INITIATE",
  TRENDING_CITY_LIST_SUCCESS: "TRENDING_CITY_LIST_SUCCESS",
  TRENDING_CITY_LIST_FAILURE: "TRENDING_CITY_LIST_FAILURE",
};

export const topConstant = {
  TOP_COLLEGE_UNIVERSITY_LIST_INITIATE: "TOP_COLLEGE_UNIVERSITY_LIST_INITIATE",
  TOP_COLLEGE_UNIVERSITY_LIST_SUCCESS: "TOP_COLLEGE_UNIVERSITY_LIST_SUCCESS",
  TOP_COLLEGE_UNIVERSITY_LIST_FAILURE: "TOP_COLLEGE_UNIVERSITY_LIST_FAILURE",

  TOP_SCHOOL_PRESCHOOL_LIST_INITIATE: "TOP_SCHOOL_PRESCHOOL_LIST_INITIATE",
  TOP_SCHOOL_PRESCHOOL_LIST_SUCCESS: "TOP_SCHOOL_PRESCHOOL_LIST_SUCCESS",
  TOP_SCHOOL_PRESCHOOL_LIST_FAILURE: "TOP_SCHOOL_PRESCHOOL_LIST_FAILURE",
};

export const homePageConstant = {
  HOME_PAGE_DATA_INITIATE: "HOME_PAGE_DATA_INITIATE",
  HOME_PAGE_DATA_SUCCESS: "HOME_PAGE_DATA_SUCCESS",
  HOME_PAGE_DATA_FAILURE: "HOME_PAGE_DATA_FAILURE",

  GET_HOME_PAGE_DATA_INITIATE: "GET_HOME_PAGE_DATA_INITIATE",
  GET_HOME_PAGE_DATA_SUCCESS: "GET_HOME_PAGE_DATA_SUCCESS",
  GET_HOME_PAGE_DATA_FAILURE: "GET_HOME_PAGE_DATA_FAILURE",

  COURSE_DETAILS_INITIATE: "COURSE_DETAILS_INITIATE",
  COURSE_DETAILS_SUCCESS: "COURSE_DETAILS_SUCCESS",
  COURSE_DETAILS_FAILURE: "COURSE_DETAILS_FAILURE",
};

export const faqListConstant = {
  FAQ_LIST_INITIATE: "FAQ_LIST_INITIATE",
  FAQ_LIST_SUCCESS: "FAQ_LIST_SUCCESS",
  FAQ_LIST_FAILURE: "FAQ_LIST_FAILURE",
};

export const staticManagementConstant = {
  GET_STATIC_PRIVACY_POLICY_INITIATE: "GET_STATIC_PRIVACY_POLICY_INITIATE",
  GET_STATIC_PRIVACY_POLICY_SUCCESS: "GET_STATIC_PRIVACY_POLICY_SUCCESS",
  GET_STATIC_PRIVACY_POLICY_FAILURE: "GET_STATIC_PRIVACY_POLICY_FAILURE",

  GET_STATIC_EDUCATION_LOAN_INITIATE: "GET_STATIC_EDUCATION_LOAN_INITIATE",
  GET_STATIC_EDUCATION_LOAN_SUCCESS: "GET_STATIC_EDUCATION_LOAN_SUCCESS",
  GET_STATIC_EDUCATION_LOAN_FAILURE: "GET_STATIC_EDUCATION_LOAN_FAILURE",

  GET_STATIC_ABOUT_US_INITIATE: "GET_STATIC_ABOUT_US_INITIATE",
  GET_STATIC_ABOUT_US_SUCCESS: "GET_STATIC_ABOUT_US_SUCCESS",
  GET_STATIC_ABOUT_US_FAILURE: "GET_STATIC_ABOUT_US_FAILURE",

  GET_STATIC_CONTACT_US_INITIATE: "GET_STATIC_CONTACT_US_INITIATE",
  GET_STATIC_CONTACT_US_SUCCESS: "GET_STATIC_CONTACT_US_SUCCESS",
  GET_STATIC_CONTACT_US_FAILURE: "GET_STATIC_CONTACT_US_FAILURE",

  UPDATE_STATIC_CONTACT_US_INITIATE: "UPDATE_STATIC_CONTACT_US_INITIATE",
  UPDATE_STATIC_CONTACT_US_SUCCESS: "UPDATE_STATIC_CONTACT_US_SUCCESS",
  UPDATE_STATIC_CONTACT_US_FAILURE: "UPDATE_STATIC_CONTACT_US_FAILURE",

  GET_STATIC_TERM_CONDITION_INITIATE: "GET_STATIC_TERM_CONDITION_INITIATE",
  GET_STATIC_TERM_CONDITION_SUCCESS: "GET_STATIC_TERM_CONDITION_SUCCESS",
  GET_STATIC_TERM_CONDITION_FAILURE: "GET_STATIC_TERM_CONDITION_FAILURE",

  ADD_LOAN_ENQUIRY_INITIATE: "ADD_LOAN_ENQUIRY_INITIATE",
  ADD_LOAN_ENQUIRY_SUCCESS: "ADD_LOAN_ENQUIRY_SUCCESS",
  ADD_LOAN_ENQUIRY_FAILURE: "ADD_LOAN_ENQUIRY_FAILURE",

  GET_RATING_STATIC_INITIATE:"GET_RATING_STATIC_INITIATE",
  GET_RATING_STATIC_SUCCESS:"GET_RATING_STATIC_SUCCESS",
  GET_RATING_STATIC_FAILURE:"GET_RATING_STATIC_FAILURE,"
};

export const blogListConstant = {
  BLOG_LIST_INITIATE: "BLOG_LIST_INITIATE",
  BLOG_LIST_SUCCESS: "BLOG_LIST_SUCCESS",
  BLOG_LIST_FAILURE: "BLOG_LIST_FAILURE",

  BLOG_DETAILS_INITIATE:"BLOG_DETAILS_INITIATE",
  BLOG_DETAILS_SUCCESS:"BLOG_DETAILS_SUCCESS",
  BLOG_DETAILS_FAILURE:"BLOG_DETAILS_FAILURE"
};

export const newsListConstant = {
  NEWS_LIST_INITIATE: "NEWS_LIST_INITIATE",
  NEWS_LIST_SUCCESS: "NEWS_LIST_SUCCESS",
  NEWS_LIST_FAILURE: "NEWS_LIST_FAILURE",

  NEWS_DETAILS_INITIATE:"NEWS_DETAILS_INITIATE",
  NEWS_DETAILS_SUCCESS:"NEWS_DETAILS_SUCCESS",
  NEWS_DETAILS_FAILURE:"NEWS_DETAILS_FAILURE"
};

export const newsLetterListConstant = {
  NEWS_LETTER_LIST_INITIATE: "NEWS_LETTER_LIST_INITIATE",
  NEWS_LETTER_LIST_SUCCESS: "NEWS_LETTER_LIST_SUCCESS",
  NEWS_LETTER_LIST_FAILURE: "NEWS_LETTER_LIST_FAILURE",
};

export const studentListConstant = {
  STUDENT_LIST_INITIATE: "STUDENT_LIST_INITIATE",
  STUDENT_LIST_SUCCESS: "STUDENT_LIST_SUCCESS",
  STUDENT_LIST_FAILURE: "STUDENT_LIST_FAILURE",
};

export const studentUpdateConstant = {
  STUDENT_UPDATE_INITIATE: "STUDENT_UDATE_INITIATE",
  STUDENT_UPDATE_SUCCESS: "STUDENT_UDATE_SUCCESS",
  STUDENT_UPDATE_FAILURE: "STUDENT_UDATE_FAILURE",
};

export const studentDetailsConstant = {
  STUDENT_DETAILS_INITIATE: "STUDENT_DETAILS_INITIATE",
  STUDENT_DETAILS_SUCCESS: "STUDENT_DETAILS_SUCCESS",
  STUDENT_DETAILS_FAILURE: "STUDENT_DETAILS_FAILURE",
};

export const enquiryManagement = {
  ADVERTISE_ENQUIRY_INITIATE: "ADVERTISE_ENQUIRY_INITIATE",
  ADVERTISE_ENQUIRY_SUCCESS: "ADVERTISE_ENQUIRY_SUCCESS",
  ADVERTISE_ENQUIRY_FAILURE: "ADVERTISE_ENQUIRY_FAILURE",

  CAREER_ENQUIRY_INITIATE: "CAREER_ENQUIRY_INITIATE",
  CAREER_ENQUIRY_SUCCESS: "CAREER_ENQUIRY_SUCCESS",
  CAREER_ENQUIRY_FAILURE: "CAREER_ENQUIRY_FAILURE",

  CREATE_ENTITIES_INITIATE: "CREATE_ENTITIES_INITIATE",
  CREATE_ENTITIES_SUCCESS: "CREATE_ENTITIES_SUCCESS",
  CREATE_ENTITIES_FAILURE: "CREATE_ENTITIES_FAILURE",

  CREATE_LOAN_ENQUIRY_INITIATE: "CREATE_LOAN_ENQUIRY_INITIATE",
  CREATE_LOAN_ENQUIRY_SUCCESS: "CREATE_LOAN_ENQUIRY_SUCCESS",
  CREATE_LOAN_ENQUIRY_FAILURE: "CREATE_LOAN_ENQUIRY_FAILURE",

  CREATE_EXAM_ENQUIRY_INITIATE: "CREATE_EXAM_ENQUIRY_INITIATE",
  CREATE_EXAM_ENQUIRY_SUCCESS: "CREATE_EXAM_ENQUIRY_SUCCESS",
  CREATE_EXAM_ENQUIRY_FAILURE: "CREATE_EXAM_ENQUIRY_FAILURE",

  CREATE_INSTITUTE_ENQUIRY_INITIATE: "CREATE_INSTITUTE_ENQUIRY_INITIATE",
  CREATE_INSTITUTE_ENQUIRY_SUCCESS:  "CREATE_INSTITUTE_ENQUIRY_SUCCESS",
  CREATE_INSTITUTE_ENQUIRY_FAILURE:  "CREATE_INSTITUTE_ENQUIRY_FAILURE",

  ADMISSION_ENQUIRY_INITIATE:"ADMISSION_ENQUIRY_INITIATE",
  ADMISSION_ENQUIRY_SUCCESS:"ADMISSION_ENQUIRY_SUCCESS",
  ADMISSION_ENQUIRY_FAIURE:"ADMISSION_ENQUIRY_FAILURE",
};

export const jobManagement = {
  INTERNAL_JOB_INITATE: "INTERNAL_JOB_INITATE",
  INTERNAL_JOB_SUCCESS: "INTERNAL_JOB_SUCCESS",
  INTERNAL_JOB_FAILURE: "INTERNAL_JOB_FAILURE",

  EXTERNAL_JOB_INITIATE: "EXTERNAL_JOB_INITIATE",
  EXTERNAL_JOB_SUCCESS: "EXTERNAL_JOB_SUCCESS",
  EXTERNAL_JOB_FAILURE: "EXTERNAL_JOB_FAILURE",

  JOB_APPLY_INITIATE: "JOB_APPLY_INITIATE",
  JOB_APPLY_SUCCESS: "JOB_APPLY_SUCCESS",
  JOB_APPLY_FAILURE: "JOB_APPLY_FAILURE",

  VERIFY_CDUID_INITIATE: "VERIFY_CDUID_INITIATE",
  VERIFY_CDUID_SUCCESS: "VERIFY_CDUID_SUCCESS",
  VERIFY_CDUID_FAILURE: "VERIFY_CDUID_FAILURE",

  GET_STATE_LIST_INITIATE: "GET_STATE_LIST_INITIATE",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",

  GET_CITY_LIST_INITIATE: "GET_CITY_LIST_INITIATE",
  GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",
};

export const ratingManagement = {
  COLLEGE_RATING_INITIATE: "COLLEGE_RATING_INITIATE",
  COLLEGE_RATING_SUCCESS: "COLLEGE_RATING_SUCCESS",
  COLLEGE_RATING_FAILURE: "COLLEGE_RATING_FAILURE",

  UNIVERSITY_RATING_INITIATE: "UNIVERSITY_RATING_INITIATE",
  UNIVERSITY_RATING_SUCCESS: "UNIVERSITY_RATING_SUCCESS",
  UNIVERSITY_RATING_FAILURE: "UNIVERSITY_RATING_FAILURE",

  SCHOOL_RATING_INITIATE: "SCHOOL_RATING_INITIATE",
  SCHOOL_RATING_SUCCESS: "SCHOOL_RATING_SUCCESS",
  SCHOOL_RATING_FAILURE: "SCHOOL_RATING_FAILURE",

  PRE_SCHOOL_RATING_INITIATE: "PRE_SCHOOL_RATING_INITIATE",
  PRE_SCHOOL_RATING_SUCCESS: "PRE_SCHOOL_RATING_SUCCESS",
  PRE_SCHOOL_RATING_FAILURE: "PRE_SCHOOL_RATING_FAILURE",

  COACHING_RATING_FAILURE: "COACHING_RATING_FAILURE",
  COACHING_RATING_SUCCESS: "COACHING_RATING_SUCCESS",
  COACHING_RATING_INITIATE: "COACHING_RATING_INITIATE",
};
export const subscribeNewsLetter = {
  SUBSCRIBE_NEWSLETTER_INITIATE: "SUBSCRIBE_NEWSLETTER_INITIATE",
  SUBSCRIBE_NEWSLETTER_SUCCESS: "SUBSCRIBE_NEWSLETTER_SUCCESS",
  SUBSCRIBE_NEWSLETTER_FAILURE: "SUBSCRIBE_NEWSLETTER_FAILURE",
};

export const favouriteConstant = {
  FAVOURITE_LIST_INITIATE: "FAVOURITE_LIST_INITIATE",
  FAVOURITE_LIST_SUCCESS: "FAVOURITE_LIST_SUCCESS",
  FAVOURITE_LIST_FAILURE: "FAVOURITE_LIST_FAILURE",

  ADD_FAVOURITE_INITIATE: "ADD_FAVOURITE_INITIATE",
  ADD_FAVOURITE_SUCCESS: "ADD_FAVOURITE_SUCCESS",
  ADD_FAVOURITE_FAILURE: "ADD_FAVOURITE_FAILURE",

  REMOVE_FAVOURITE_INITIATE: "REMOVE_FAVOURITE_INITIATE",
  REMOVE_FAVOURITE_SUCCESS: "REMOVE_FAVOURITE_SUCCESS",
  REMOVE_FAVOURITE_FAILURE: "REMOVE_FAVOURITE_FAILURE",
};

export const homepageSearch = {
  HOMEPAGE_SEARCH_INITIATE: "HOMEPAGE_SEARCH_INITIATE",
  HOMEPAGE_SEARCH_SUCCESS: "HOMEPAGE_SEARCH_SUCCESS",
  HOMEPAGE_SEARCH_FAILURE: "HOMEPAGE_SEARCH_FAILURE",


  SEARCH_SUGGESTION_INITIATE: "SEARCH_SUGGESTION_INITIATE",
  SEARCH_SUGGESTION_SUCCESS:  "SEARCH_SUGGESTION_SUCCESS",
  SEARCH_SUGGESTION_FAILURE:  "SEARCH_SUGGESTION_FAILURE",


};

export const examCareerConstant = {
  EXAM_LIST_INITIATE: "EXAM_LIST_INITIATE",
  EXAM_LIST_SUCCESS: "EXAM_LIST_SUCCESS",
  EXAM_LIST_FAILURE: "EXAM_LIST_FAILURE",

  CAREER_LIST_INITIATE: "CAREER_LIST_INITIATE",
  CAREER_LIST_SUCCESS: "CAREER_LIST_SUCCESS",
  CAREER_LIST_FAILURE: "CAREER_LIST_FAILURE",

  ADD_EXAM_ENQUIRY_INITIATE: "ADD_EXAM_ENQUIRY_INITIATE",
  ADD_EXAM_ENQUIRY_SUCCESS: "ADD_EXAM_ENQUIRY_SUCCESS",
  ADD_EXAM_ENQUIRY_FAILURE: "ADD_EXAM_ENQUIRY_FAILURE",

  ADD_CAREER_ENQUIRY_INITIATE: "ADD_CAREER_ENQUIRY_INITIATE",
  ADD_CAREER_ENQUIRY_SUCCESS: "ADD_CAREER_ENQUIRY_SUCCESS",
  ADD_CAREER_ENQUIRY_FAILURE: "ADD_CAREER_ENQUIRY_FAILURE",
};

export const getStateCityConstant = {
  GET_STATE_LIST_INITIATE: "GET_STATE_LIST_INITIATE",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",

  GET_CITY_LIST_INITIATE: "GET_CITY_LIST_INITIATE",
  GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",
};

export const ratingConstant = {
  ADD_RATING_INITIATE: "ADD_RATING_INITIATE",
  ADD_RATING_SUCCESS: "ADD_RATING_SUCCESS",
  ADD_RATING_FAILURE: "ADD_RATING_FAILURE",

  RATING_LIST_INITIATE: "RATING_LIST_INITIATE",
  RATING_LIST_SUCCESS: "RATING_LIST_SUCCESS",
  RATING_LIST_FAILURE: "RATING_LIST_FAILURE",
};

export const studentApplication = {
  APPLICATION_LIST_INITIATE: "APPLICATION_LIST_INITIATE",
  APPLICATION_LIST_SUCCESS: "APPLICATION_LIST_SUCCESS",
  APPLICATION_LIST_FAILURE: "APPLICATION_LIST_FAILURE",
};

export const getAddsActionTypes = {
  GET_ADDS_INITIATE: "GET_ADDS_INITIATE",
  GET_ADDS_SUCCESS: "GET_ADDS_SUCCESS",
  GET_ADDS_FAILURE: "GET_ADDS_FAILURE",
};

export const notification={
  NOTIFICATION_INITIATE: 'NOTIFICATION_INITIATE',
  NOTIFICATION_SUCCESS:  'NOTIFICATION_SUCCESS',
  NOTIFICATION_FAILURE:  'NOTIFICATION_FAILURE',


}