import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import Logo from "../assets/images/logo.png"
import {
  getEducationLoanAction,
  AddLoanEnquiryAction,
} from "../redux/actions/staticManagementAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddsListAction } from "../redux/actions/getAddsActions";

const initialState = {
  email: "",
  mobile: "",
  name: "",
  loanData: "",
  emailError: "",
  mobileError: "",
  nameError: "",
  thankuModal: false,
};

const EducationLoan = () => {
  const [loanModal, setLoanModal] = useState(false);
  const [ShowSignInModal, setShowSignInModal] = useState(false)
  const [iState, updateState] = useState(initialState);
  const[addsMappingData, setAddsMappingData] = useState([])

  const { email, emailError, mobile, mobileError, name, nameError, loanData,thankuModal, } =
    iState;

  const handleLoanShow = (item) => {
    const userData = JSON.parse(localStorage.getItem("websiteData"))?._id;
    if(userData){
      setLoanModal(true);
      updateState({...iState, loanData:item})
    } else{
      // toast.info("Please login to perform this action !");
      setShowSignInModal(true)
    }
    
  };

  const handleCloseThankuModal = () => {
    updateState({ ...iState, thankuModal: false, nameError: "", emailError: "", mobileError: "",name:"", mobile:"", email:""});
  };
  const handleShowThankuModal = () => {
    updateState({ ...iState, thankuModal: true });
  };
  const handleLoanClose = () => {
    setLoanModal(false);
    updateState({ ...iState, nameError: "", emailError: "", mobileError: "",name:"", mobile:"", email:"" });
  };

  const dispatch = useDispatch();
  const { educationLoanData } = useSelector(
    (state) => state.staticManagementReducer
  );
  const {getAddsListData} = useSelector((state) => state.listReducer);
  console.log("getAddsListData===============>",getAddsListData)

  let handleValidation = () => {
    let emailError = "";
    let mobileError = "";
    let nameError = "";
    let formIsValid = true;
    if (!email) {
      emailError = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailError = "* Email format is not valid";
      formIsValid = false;
    }
    if (!mobile) {
      mobileError = "* Please enter mobile number.";
      formIsValid = false;
    } else if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(mobile)) {
      mobileError = "* Please enter valid phone number.";
      formIsValid = false;
    }
    if (!name) {
      nameError = "* Please enter name.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      emailError,
      mobileError,
      nameError,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      const userdata = JSON.parse(localStorage.getItem("websiteData"));

      var data = { studentId: userdata._id,loanData:loanData, email, phoneNumber: mobile, name };
      // console.log(formIsValid);

      dispatch(AddLoanEnquiryAction(data))
        .then((res) => {
          if (res.status === 200) {
            // toast.success("You have successfully applied for loan")
            updateState({ ...iState,thankuModal:true, email: "", mobile: "", name: "", loanData:'' });
            setLoanModal(false)

          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              nameError: "",
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(getAddsListAction({category:"Education Loan Page"}))
    dispatch(getEducationLoanAction());
  }, []);

  useEffect(() =>{
    if(getAddsListData?.[0]?.image?.length > 0){
      console.log("insde")
      if(getAddsListData?.[0]?.googleAddShow == "Inactive"){
        // console.log("insde",getAddsListData?.data?.[0]?.image)
        setAddsMappingData(getAddsListData?.[0]?.image)
        // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
      } else{
        setAddsMappingData(["https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"])
        // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
      }
    }
    
  },[getAddsListData])

  
  const handleCloseModal=()=>{
    setShowSignInModal(false)

  }


  return (
    <div>
    <Header
                  ShowSignInModal={ShowSignInModal}
                  handleCloseModal={handleCloseModal}

      />
      <section>
        <div class="SearchArea NewsBreadcumb">
          <div class="container">
            <h3>Education Loan</h3>
            <p>Home / Education Loan</p>
          </div>
        </div>
      </section>

      <section>
      <div class="VerticalBannerBox VerticalBannerLeft">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Listing left"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              
              )
            }
      })}
         </> : <></> }                               
                            </div>
                <div class="VerticalBannerBox VerticalBannerRight">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Page description right"){
              // console.log(ele)
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              
              )
            }
      })}
         </> : <></> }                               
                            </div>
        <div class="NewsDetailsArea">
          <div class="container">
          <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Top left"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt="faq top"/></a>
              
              )
            }
      })}
         </> : <></> }                               
                            </div>
            <div class="NewsDetailsRigth">
              <figcaption>
              {/*   <blockquote>
                  <p>
                  CareerDekhoo education loan facility is introduced to extend financial assistance to students to fulfill their dream of studying at prominent Indian institutions and to reduce the financial strain on their families.
An Education loan in India should not be treated merely as a loan but as an investment that lets aspiring students choose and study the course of their choice from the top-ranking institutes.
However, getting an education loan is also a complicated process for an individual. So here, CareerDekhoo appears to assist you to get the loan as per your requirements through various Indian banks (Government, Private or Co-operative) and NBFCs offering low-interest rates along with government-driven subsidy schemes listed on our website.

Simply click on "Apply" and submit your details! Our experts will reach out to you directly to assist and guide you further.{" "}
                  </p>
                  <h6>RT dbdio </h6>
                </blockquote> */}

                <ul>
                  <li>
                    <strong>
                    CareerDekhoo education loan facility is introduced to extend financial assistance to students to fulfill their dream of studying at prominent Indian institutions and to reduce the financial strain on their families.
                    </strong>
                  </li>
                  <li>
                    <strong>
                    An Education loan in India should not be treated merely as a loan but as an investment that lets aspiring students choose and study the course of their choice from the top-ranking institutes.
                    </strong>
                  </li>
                  <li>
                    <strong>
                    However, getting an education loan is also a complicated process for an individual. So here, CareerDekhoo appears to assist you to get the loan as per your requirements through various Indian banks (Government, Private or Co-operative) and NBFCs offering low-interest rates along with government-driven subsidy schemes listed on our website.
                    </strong>
                  </li>
                  <li>
                    <strong>
                    Simply click on "Apply" and submit your details! Our experts will reach out to you directly to assist and guide you further.{" "}
                    </strong>
                  </li>
                </ul>
              </figcaption>
            </div>

            <div class="LoanArea">
              {educationLoanData?.bankList?.map((item) => (
                <div class="LoanBox">
                  <div class="LoanBoxHead">
                    <b>{item?.bankName}</b>
                    <a onClick={()=>handleLoanShow(item)}>Apply</a>
                  </div>
                  <article>
                    <aside>
                      <h6>Bank Type</h6>
                      <p>{item?.bankType}</p>
                    </aside>
                    <aside>
                      <h6>Loan Term (Max)</h6>
                      <p>{item?.loanTerm} Years</p>
                    </aside>
                    <aside>
                      <span class="Interest">
                        Rate of Interest <strong>{item?.interestRate}%</strong>
                      </span>
                    </aside>
                  </article>
                  {/* <h6>Loan Scheme</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra just</p> */}
                </div>
              ))}
                <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Footer top"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt="faq top"/></a>
              
              )
            }
      })}
         </> : <></> }                               
                            </div>
            </div>
          </div>
          <Modal show={loanModal}>
            <div class="LoginArea">
              <a class="CloseModal" onClick={handleLoanClose}>
                &times;
              </a>
              <div class="LoginLeft">
                <span class="Icon">
                {/* <img src={Logo} /> */}
                  {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
                </span>
                <div>
                  <figure>
                  <img src={Logo} />
                    {/* <img src={require("../assets/images/Modal-1.png")} /> */}
                  </figure>
                  <figcaption>
                  <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
                  </figcaption>
                </div>
              </div>
              <div class="LoginRight">
                <div class="LoginBox">
                  <h3>
                    <strong>Thanks for Applying</strong>, Please
                    fill below details to Apply.
                  </h3>
                  {/* <h3>Write a Review</h3> */}
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Please enter your name..."
                        onChange={handleChange}
                        value={name}
                        name="name"
                      />
                      {nameError && (
                        <p style={{ color: "red" }}> {nameError}</p>
                      )}
                    </div>

                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Please enter email..."
                        onChange={handleChange}
                        value={email}
                        name="email"
                      />
                      {emailError && (
                        <p style={{ color: "red" }}> {emailError}</p>
                      )}
                    </div>

                    <div class="form-group">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Please enter your phone number..."
                        onChange={handleChange}
                        value={mobile}
                        name="mobile"
                      />
                      {mobileError && (
                        <p style={{ color: "red" }}> {mobileError}</p>
                      )}
                    </div>

                    <div class="ContinueButton">
                      <button onClick={handleSubmit}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={thankuModal}>
        <div class="LoginArea">
          <a
            onClick={handleCloseThankuModal}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="ThankBox">
            <div>
              <figure>
                <img src={require("../assets/images/Apply-Thank.png")} />
              </figure>
              <h3>Thank you</h3>
              <p>
                {
                   //  Thank You for applying with ${(name)}!
                `
Your application has been submitted successfully. 
We will be in touch and contact you soon.`}
                
              </p>
              <a
                href="javascript:void(0);"
                onClick={handleCloseThankuModal}
                data-dismiss="modal"
              >
                Great
              </a>
            </div>
          </div>
        </div>
      </Modal>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default EducationLoan;
