import { newsLetterListConstant } from "../actionType";

const initialState ={
    newsLetterList:[],
    loader:false,
    error:{}
}



export default function newsLetterListReducer(state=initialState,{type,payload}){
    switch(type){
        case newsLetterListConstant.NEWS_LETTER_LIST_INITIATE:
            return{...state,loader:true,  newsLetterList:[]}
        case newsLetterListConstant.NEWS_LETTER_LIST_SUCCESS:
            return{...state,loader:false,  newsLetterList :payload.data}

        case  newsLetterListConstant.NEWS_LETTER_LIST_FAILURE:
            return{...state,  error:payload}

            default : return state;
    }

    }

    