import { trendingConstant } from "../actionType";




import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";




export const trendingCollegeUniversityListInitiate=() =>({
    type: trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_INITIATE,

})

export const trendingCollegeUniversityListSuccess=(data) =>({
    type: trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_SUCCESS,
    payload:data

})

export const trendingCollegeUniversityListFailure=(data) =>({
    type: trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_FAILURE,

})


export const  trendingCollegeUniversityListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(trendingCollegeUniversityListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/trendingCollegesAndUniversities', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(trendingCollegeUniversityListSuccess(data))

                    }
                    else {
                        dispatch(trendingCollegeUniversityListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(trendingCollegeUniversityListFailure(err))
                    reject(err);
                })

        );
    }

}

// **********************************







export const trendingSchoolPreSchoolListInitiate=() =>({

    type: trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_INITIATE,

})

export const trendingSchoolPreSchoolListSuccess=(data) =>({
    type: trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_SUCCESS,
    payload:data

})

export const trendingSchoolPreSchoolListFailure=(data) =>({
    type: trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_FAILURE,

})


export const  trendingSchoolPreSchoolListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(trendingSchoolPreSchoolListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/trendingSchoolsAndPreSchools', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(trendingSchoolPreSchoolListSuccess(data))

                    }
                    else {
                        dispatch(trendingSchoolPreSchoolListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(trendingSchoolPreSchoolListFailure(err))
                    reject(err);
                })

        );
    }

}

// **********************************



export const trendingStreamListInitiate=() =>({
    type: trendingConstant.TRENDING_STREAM_LIST_INITIATE,

})

export const trendingStreamListSuccess=(data) =>({
    type: trendingConstant.TRENDING_STREAM_LIST_SUCCESS,
    payload:data

})

export const trendingStreamListFailure=(data) =>({
    type: trendingConstant.TRENDING_STREAM_LIST_FAILURE,

})


export const  trendingStreamListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(trendingStreamListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/trendingStreams', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(trendingStreamListSuccess(data))

                    }
                    else {
                        dispatch(trendingStreamListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(trendingStreamListFailure(err))
                    reject(err);
                })

        );
    }

}










//*************************************** */


export const trendingCityListInitiate=() =>({
    type: trendingConstant.TRENDING_CITY_LIST_INITIATE,

})

export const trendingCityListSuccess=(data) =>({
    type: trendingConstant.TRENDING_CITY_LIST_SUCCESS,
    payload:data

})

export const trendingCityListFailure=(data) =>({
    type: trendingConstant.TRENDING_CITY_LIST_FAILURE,

})


export const  trendingCityListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(trendingCityListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getTrendingCitiy', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(trendingCityListSuccess(data))

                    }
                    else {
                        dispatch(trendingCityListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(trendingCityListFailure(err))
                    reject(err);
                })

        );
    }

}

// **********************************

