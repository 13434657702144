import React, { useEffect } from "react";
 

import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import { getTermsConditionAction } from "../redux/actions/staticManagementAction";

const TermsAndConditon = () => {
    const dispatch= useDispatch()
    const {termsData} = useSelector((state)=> state.staticManagementReducer)
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTermsConditionAction())
  }, []);
  return (
    <div>
        <Header />
        <section>
            <div class="SearchArea NewsBreadcumb">
                <div class="container">
                    <h3>Terms & Conditions</h3>
                    <p>Home / Terms & Conditions</p>
                </div>
            </div>
        </section>
        <section>
            <div class="AboutArea">
                <div className="container">
                     
                    <h1>Terms & Conditions</h1>

                    <div
                                          dangerouslySetInnerHTML={{
                                            __html: termsData?.data,
                                          }}
                                          className="editor"
                                        ></div>
                    {/* <h3>1. Introduction</h3>

                    <p>
                    Welcome to CareerDekhoo Edtech Services (“Company”, “we”, “our”,
                    “us”)! <br />
                    The website and company is collectively termed as "Platform". <br />
                    “CareerDekhoo Edtech Services” can also be referred as CDES. <br />
                    These Terms of Service (“Terms”, “Terms of Service”) govern your use
                    of our website located at www.careerdekhoo.com (together or
                    individually “Service”) operated by CareerDekhoo Edtech Services.{" "}
                    <br />
                    Our Privacy Policy also governs your use of our Service and explains
                    how we collect, safeguard and disclose information that results from
                    your use of our web pages. <br />
                    Your agreement with us includes these Terms and our Privacy Policy
                    (“Agreements”). You acknowledge that you have read and understood
                    Agreements, and agree to be bound of them. <br />
                    If you do not agree with (or cannot comply with) Agreements, then you
                    may not use the Service, but please let us know by emailing at
                    grievance@careerdekhoo.com so we can try to find a solution. These
                    Terms apply to all visitors, users and others who wish to access or
                    use Service. <br />
                    Access to any part of the website or any content downloaded from it is
                    contingent on Your acceptance of and compliance with these Terms and
                    Privacy Policy. If You do not understand and do not agree to be
                    bounded by all Terms and the Privacy Policy, do not use this Platform.
                    </p>

                    <h3>2. Eligibility</h3>
                    <p>
                    By accessing or using the Platform You represent and warrant that You
                    fulfil the requirement of minimum age and have the legal capacity and
                    authority to enter into a contract as per the applicable law <br />
                    You are eligible to conduct the purpose “hereinafter defined” lawfully
                    </p>

                    <h3>3. Registration / Login</h3>

                    <p>
                    Our Platform may require you to be registered or become a registered
                    user or will ask you to provide some of your personal details in order
                    to serve you better. <br />
                    On registration and upon providing the required information, you agree
                    to the following points:
                    </p>

                    <ul>
                    <li>
                        Make Your contact details available to partners/business
                        associates/institutes of the Platform, You may be contacted by
                        Platform or its partners for additional information and the
                        registration process through email, telephone and SMS.
                    </li>
                    <li>
                        When you create an account with us, you guarantee that you are
                        fulfilling the minimum age requirement norms as per law and as
                        mentioned under our Privacy Policy, and that the information you
                        provide us is accurate, complete, and current at all times.{" "}
                    </li>
                    <li>
                        Inaccurate, incomplete, or obsolete information may result in the
                        immediate termination of your account on Service.
                    </li>
                    <li>
                        You agree to subscribe to newsletters, marketing or promotional
                        materials and other information we may send. However, you may opt
                        out of receiving any, or all, of these communications from us by
                        following the unsubscribe link or by emailing at
                        grievance@careerdekhoo.com.
                    </li>
                    <li>
                        Receive promotional mails/special offers from CDES or any of its
                        associate/partner websites.
                    </li>
                    <li>
                        Be contacted by CareerDekhoo Edtech Services in accordance with the
                        information provided and settings done by you.{" "}
                    </li>
                    </ul>

                    <h3>4. Purpose</h3>
                    <ul>
                    <li>
                        CareerDekhoo Edtech Services has curated the CareerDekhoo.com
                        website for parents, professionals and educational institutions to
                        compare schools and colleges to accurately cater to the skill
                        enhancement of their wards.{" "}
                    </li>
                    <li>
                        CareerDekhoo.com has an engaging and user-friendly interface which
                        easily directs the users to their dream careers. We intend to build
                        a comprehensive repository of elementary schools, high schools,
                        coaching institutions, junior colleges and universities to spread
                        awareness of the opportunities available for aspiring students.{" "}
                    </li>
                    <li>
                        The website is made available for use to help students to get
                        details, discover & research on colleges, courses and exams of their
                        interest and for other related services.{" "}
                    </li>
                    <li>
                        Our platform also helps connect colleges/educational
                        institutes/coaching centres etc. with prospective students who may
                        be of interest to them.{" "}
                    </li>
                    <li>
                        We reserves the right to sell this information and also the entire
                        site to a third party if a third party is desirous of purchasing
                        this information.
                    </li>
                    </ul>

                    <h3>5. Content</h3>

                    <ul>
                    <li>
                        Content found on or through this Platform are the property of
                        CareerDekhoo Edtech Services or used with permission. You may not
                        distribute, modify, transmit, reuse, download, repost, copy, or use
                        said Content, whether in whole or in part, for commercial purposes
                        or for personal gain, without express advance written permission
                        from us.
                    </li>
                    <li>
                        Platform shall not be responsible for any errors or an omission
                        contained on www.careerdekhoo.com, and reserves the right to make
                        changes anytime without notice.
                    </li>
                    </ul>

                    <h3>6. Prohibited Uses</h3>
                    <p>
                    You may use Service only for lawful purposes and in accordance with
                    Terms. You agree not to use Service:
                    </p>
                    
                    <ol>
                        <li> In any way that violates any applicable national or international
                    law or regulation.</li>
                        <li>For the purpose of exploiting, harming, or attempting to exploit
                    or harm minors in any way by exposing them to inappropriate content or
                    otherwise.</li>
                        <li>To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain letter,”
                    “spam,” or any other similar solicitation.</li>
                        <li>To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.</li>
                        <li>In any way that infringes upon the rights of others, or in any
                    way is illegal, threatening, fraudulent, or harmful, or in connection
                    with any unlawful, illegal, fraudulent, or harmful purpose or
                    activity.</li>
                        <li>To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as determined by us,
                    may harm or offend Company or users of Service or expose them to
                    liability.</li> 
                    </ol>
                    <p><strong>Additionally, you agree not to:</strong></p>
                    
                    <ol>
                        <li>Use Service in any manner that could disable, overburden, damage,
                    or impair Service or interfere with any other party’s use of Service,
                    including their ability to engage in real time activities through
                    Service.</li>
                        <li>Use any robot, spider, or other automatic device, process, or
                    means to access Service for any purpose, including monitoring or
                    copying any of the material on Service.</li>
                        <li>Use any manual process to monitor or copy any of the material on
                    Service or for any other unauthorized purpose without our prior
                    written consent.</li>
                        <li>Use any device, software, or routine that interferes with the
                    proper working of Service.</li>
                        <li>Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material which is malicious or technologically harmful.</li>
                        <li>Attempt to gain unauthorized access to, interfere with, damage,
                    or disrupt any parts of Service, the server on which Service is
                    stored, or any server, computer, or database connected to Service.</li>
                        <li>Attack Service via a denial-of-service attack or a distributed
                    denial-of-service attack.</li>
                        <li>Take any action that may damage or falsify Company rating.</li>
                        <li>Otherwise attempt to interfere with the proper working of
                    Service.</li>
                    </ol>
 

                    <h3>7. Analytics</h3>
                    <p>
                    We may use third-party Service Providers to monitor and analyze the
                    use of our Service.
                    </p>

                    <h3>8. Intellectual Property</h3>
                    <ul>
                    <li>
                        Service and its original content (excluding Content provided by
                        users), features and functionality are and will remain the exclusive
                        property of CareerDekhoo Edtech Services and its licensors.{" "}
                    </li>
                    <li>
                        Service is protected by copyright, trademark, and other laws of and
                        foreign countries. Our trademarks may not be used in connection with
                        any product or service without the prior written consent of
                        CareerDekhoo Edtech Services.
                    </li>
                    </ul>

                    <h3>9. Copyright Policyy</h3>
                    <p>
                    We respect the intellectual property rights of others. It is our
                    policy to respond to any claim that Content posted on Service
                    infringes on the copyright or other intellectual property rights
                    (“Infringement”) of any person or entity. <br />
                    If you are a copyright owner, or authorized on behalf of one, and you
                    believe that the copyrighted work has been copied in a way that
                    constitutes copyright infringement, please submit your claim via email
                    to grievance@careerdekhoo.com, with the subject line: “Copyright
                    Infringement” and include in your claim a detailed description of the
                    alleged Infringement as detailed below, under the proper provisions of
                    law. <br />
                    You may be held accountable for damages (including costs and
                    attorneys’ fees) for misrepresentation or bad-faith claims on the
                    infringement of any Content found on and/or through Service on your
                    copyright.
                    </p>

                    <h3>10. Links To Other Web Sites</h3>
                    <p>
                    Our Service may contain links to third party web sites or services
                    that are not owned or controlled by CareerDekhoo Edtech Services.{" "}
                    <br />
                    CareerDekhoo Edtech Services has no control over, and assumes no
                    responsibility for the content, privacy policies, or practices of any
                    third party web sites or services. We do not warrant the offerings of
                    any of these entities/individuals or their websites. <br />
                    YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
                    LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
                    ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
                    ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
                    THIRD PARTY WEB SITES OR SERVICES. <br />
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
                    POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
                    </p>

                    <h3>11. Termination</h3>
                    <p>
                    We may terminate or suspend your account and bar access to Service
                    immediately, without prior notice or liability, under our sole
                    discretion, for any reason whatsoever and without limitation,
                    including but not limited to a breach of Terms. <br />
                    If you wish to terminate your account, you may simply discontinue
                    using Service. <br />
                    All provisions of Terms which by their nature should survive
                    termination shall survive termination, including, without limitation,
                    ownership provisions, warranty disclaimers, indemnity and limitations
                    of liability.
                    </p>

                    <h3>12. Disclaimer Of Warranty</h3>

                    <p>
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
                    ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
                    OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. <br />
                    Notwithstanding anything contained in any other agreement or
                    arrangement, CDES receives incentives/commissions from its partner
                    colleges/ universities/institutions against its admission-related
                    services and also compensation for third-party / partners for
                    promotional advertisement activities. <br />
                    CDES does not take responsibility for the genuineness and authenticity
                    of the content(information of schools/pre-schools/colleges,
                    universities, institutions, exams, events etc) and materials so
                    provided. Users are advised to check the original (source) websites in
                    case of any doubts. We always try to keep the content/material updated
                    on our Platform to ensure high quality. <br />
                    CDES also promotes its partnered institutes on the platform as
                    “Featured” institutes; this is purely on the basis of CDES internal
                    agreement with listed institutes not based on any government or any
                    other approved ranking. <br />
                    CDES gives/sets internal ranking for its listed institutes and this
                    ranking is purely based on CDES internal ranking criteria. CDES does
                    not take responsibility or guarantee CD ranking if you consider
                    ranking as one of the key factors while deciding for taking admission.
                    CDES advises users to do research about the institute at their own
                    level on other platforms or directly contact the listed institute for
                    more clarification. <br />
                    YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT,
                    AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
                    </p>

                    <h3>13. Error Reporting and Feedback</h3>
                    <p>
                    You may provide us either directly at grievance@careerdekhoo.com or
                    via third party sites and tools with information and feedback
                    concerning errors, suggestions for improvements, ideas, problems,
                    complaints, and other matters related to our Service (“Feedback”). You
                    acknowledge and agree that: (i) you shall not retain, acquire or
                    assert any intellectual property right or other right, title or
                    interest in or to the Feedback; (ii) Company may have development
                    ideas similar to the Feedback; (iii) Feedback does not contain
                    confidential information or proprietary information from you or any
                    third party; and (iv) Company is not under any obligation of
                    confidentiality with respect to the Feedback. In the event the
                    transfer of the ownership to the Feedback is not possible due to
                    applicable mandatory laws, you grant Company and its affiliates an
                    exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
                    unlimited and perpetual right to use (including copy, modify, create
                    derivative works, publish, distribute and commercialize) Feedback in
                    any manner and for any purpose.
                    </p>

                    <h3>14. Intellectual Property</h3>

                    <p>
                    Service and its original content (excluding Content provided by
                    users), features and functionality are and will remain the exclusive
                    property of CareerDekhoo Edtech Services and its licensors. Service is
                    protected by copyright, trademark, and other laws of and foreign
                    countries. Our trademarks may not be used in connection with any
                    product or service without the prior written consent of CareerDekhoo
                    Edtech Services.
                    </p>

                    <h3>15. Governing Law</h3>

                    <p>
                    These Terms shall be governed and construed in accordance with the
                    laws of India, which governing law applies to an agreement without
                    regard to its conflict of law provisions. <br />
                    Our failure to enforce any right or provision of these Terms will not
                    be considered a waiver of those rights. If any provision of these
                    Terms is held to be invalid or unenforceable by a court, the remaining
                    provisions of these Terms will remain in effect. These Terms
                    constitute the entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had between
                    us regarding Service.
                    </p>

                    <h3>16. Scholarships</h3>

                    <p>
                    Our Platform only assists students/parents or people willing to get
                    admissions through our Platform in getting scholarships provided by
                    respective colleges/schools/Institutes and by the government. <br />
                    The eligibility for scholarships depends on the scholarship scheme and
                    eligibility criteria set by the concerned authority. CDES does not
                    commit or guarantee of providing any type of scholarship from our
                    Platform.
                    </p>

                    <h3>17. Find Jobs T&C</h3>

                    <p>
                    CDES has exclusively designed the “Find Jobs” service and feature on
                    the Platform to cater to our premium users who gets enrolled/admitted
                    in any academic course to any of the listed institutes on our website
                    using our “Get Admission” services. This service is only available for
                    students who are pursuing higher education in Colleges or
                    Universities. <br />
                    We have tie-ups with different industry companies and we list their
                    available/current job vacancies on our platform. CDES doesn’t
                    guarantee any job but only provides a bridge between the company and
                    the job seeker.
                    </p>

                    <h3>18. Amendments To Terms</h3>

                    <p>
                    We may amend the Terms at any time by posting the amended terms on
                    this site. It is your responsibility to review these Terms
                    periodically. <br />
                    Your continued use of the Platform following the posting of revised
                    Terms means that you accept and agree to the changes. You are expected
                    to check this page frequently so you are aware of any changes, as they
                    are binding on you. <br />
                    By continuing to access or use our Service after any revisions become
                    effective, you agree to be bound by the revised terms. If you do not
                    agree to the new terms, you are no longer authorized to use the
                    Service.
                    </p>

                    <h3>19. Acknowledgement</h3>

                    <p>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
                    THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
                    THEM.
                    </p>

                    <h3>20. Contact Us</h3>

                    <p>
                    Please send your feedback, comments, and requests for technical
                    support by email at grievance@careerdekhoo.com
                    </p> */}
                </div> 
                </div>
        </section>
        <Footer />
    </div>
  );
};
export default TermsAndConditon;
