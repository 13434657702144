import { topConstant } from "../actionType";


const initialState = {
  
  topCollegeUniversityList: [],
  topCollegeUniversityData: {},


  topSchoolPreSchoolList: [],
  topSchoolPreSchoolData: {},

  topStreamList: [],
  topStreamData: {},

  topCityList: [],
  topCityData: {},

  loader: false,
  error: {},
};

export default function universityReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
   

      case topConstant.TOP_COLLEGE_UNIVERSITY_LIST_INITIATE:
        return { ...state, loader: true, topCollegeUniversityList: [] };
      case topConstant.TOP_COLLEGE_UNIVERSITY_LIST_SUCCESS:
        return { ...state, loader: false, topCollegeUniversityList: payload.data };
  
      case topConstant.TOP_COLLEGE_UNIVERSITY_LIST_FAILURE:
        return { ...state, error: payload };
  
    

        case topConstant.TOP_SCHOOL_PRESCHOOL_LIST_INITIATE:
            return { ...state, loader: true, topSchoolPreSchoolList: [] };
          case topConstant.TOP_SCHOOL_PRESCHOOL_LIST_SUCCESS:
            return { ...state, loader: false, topSchoolPreSchoolList: payload.data };
      
          case topConstant.TOP_SCHOOL_PRESCHOOL_LIST_FAILURE:
            return { ...state, error: payload };
      


            
    

       

    default:
      return state;
  }
}
