import axios from "axios";
import { schoolActionTypes } from "../actionType";
import { isLoggedIn } from "../../utils";
import {baseUrl} from "../../config/Config"


export const schoolListInitate = () => ({
    type: schoolActionTypes.SCHOOL_LIST_INITIATE,
  });
  
  export const schoolListSuccess = (data) => ({
    type: schoolActionTypes.SCHOOL_LIST_SUCCESS,
    payload: data,
  });
  
  export const schoolListFailure = (data) => ({
    type: schoolActionTypes.SCHOOL_LIST_FAILURE,
    payload: data,
  });
  
  export function schoolListAction(payload) {
    const token = isLoggedIn("institueData");
    return (dispatch) => {
      dispatch(schoolListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/schoolList`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(schoolListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(schoolListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(schoolListFailure(err))
            reject(err);
          });
      });
    };
  }

  export const schoolDetailsInitate = () => ({
    type: schoolActionTypes.SCHOOL_DETAILS_INITIATE,
  });
  
  export const schoolDetailsSuccess = (data) => ({
    type: schoolActionTypes.SCHOOL_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const schoolDetailsFailure = (data) => ({
    type: schoolActionTypes.SCHOOL_DETAILS_SUCCESS,
    payload: data,
  });
  
  export function schoolDetailsAction(payload) {
    const token = isLoggedIn("institueData");
    return (dispatch) => {
      dispatch(schoolDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/schoolDetails`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                schoolDetailsSuccess(data);
              //// console.log("storeDetails", data);
              resolve(data);
            } else {
                schoolDetailsFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            schoolDetailsFailure(err);
            reject(err);
          });
      });
    };
  }




  
  export const schoolFilterInitate = () => ({
    type: schoolActionTypes.SCHOOL_FILTER_INITIATE,
  });
  
  export const schoolFilterSuccess = (data) => ({
    type: schoolActionTypes.SCHOOL_FILTER_SUCCESS,
    payload: data,
  });
  
  export const schoolFilterFailure = (data) => ({
    type: schoolActionTypes.SCHOOL_FILTER_SUCCESS,
    payload: data,
  });

  export function schoolFilterAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(schoolFilterInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/schoolFilters`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(schoolFilterSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(schoolFilterFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(schoolFilterFailure(err))
            reject(err);
          });
      });
    };
  }



  

export const preSchoolListInitate = () => ({
  type: schoolActionTypes.PRE_SCHOOL_LIST_INITIATE,
});

export const preSchoolListSuccess = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_LIST_SUCCESS,
  payload: data,
});

export const preSchoolListFailure = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_LIST_FAILURE,
  payload: data,
});

export function preSchoolListAction(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(preSchoolListInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/website/preSchoolList`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          // //// console.log("data", response)
          if (data && data.status == 200) {
             dispatch(preSchoolListSuccess(data));
            //// console.log("storeList", data);
            resolve(data);
          }
         
           else {
            dispatch(preSchoolListFailure(data))
            reject(data)
          }
        })
        .catch((err) => {
          dispatch(preSchoolListFailure(err))
          reject(err);
        });
    });
  };
}

export const preSchoolDetailsInitate = () => ({
  type: schoolActionTypes.PRE_SCHOOL_DETAILS_INITIATE,
});

export const preSchoolDetailsSuccess = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_DETAILS_SUCCESS,
  payload: data,
});

export const preSchoolDetailsFailure = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_DETAILS_FAILURE,
  payload: data,
});

export function preSchoolDetailsAction(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(preSchoolDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/website/preSchoolDetails`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status == 200) {
            preSchoolDetailsSuccess(data);
            //// console.log("storeDetails", data);
            resolve(data);
          } else {
            preSchoolDetailsFailure(data);
            reject(data);
          }
        })
        .catch((err) => {
          preSchoolDetailsFailure(err);
          reject(err);
        });
    });
  };
}



  
export const preSchoolFilterInitate = () => ({
  type: schoolActionTypes.PRE_SCHOOL_FILTER_INITIATE,
});

export const preSchoolFilterSuccess = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_FILTER_SUCCESS,
  payload: data,
});

export const preSchoolFilterFailure = (data) => ({
  type: schoolActionTypes.PRE_SCHOOL_FILTER_FAILURE,
  payload: data,
});

export function preSchoolFilterAction(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(preSchoolFilterInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/website/preSchoolFilters`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          // //// console.log("data", response)
          if (data && data.status == 200) {
             dispatch(preSchoolFilterSuccess(data));
            //// console.log("storeList", data);
            resolve(data);
          }
         
           else {
            dispatch(preSchoolFilterFailure(data))
            reject(data)
          }
        })
        .catch((err) => {
          dispatch(preSchoolFilterFailure(err))
          reject(err);
        });
    });
  };
}
