import { confirmPasswordConstant } from "../actionType";
import { baseUrl } from "../../config/Config";

import axios from "axios";


export const confirmPasswordInitiate=() =>({
    type: confirmPasswordConstant.CONFIRM_PASSWORD_INITIATE

})

export const confirmPasswordSuccess=(data) =>({
    type:confirmPasswordConstant.CONFIRM_PASSWORD_SUCCESS,
    payload:data

})

export const confirmPasswordFailure=(data) =>({
    type:confirmPasswordConstant.CONFIRM_PASSWORD_FAILURE,
    payload : data

})




export const  ConfirmPasswordAction=(payload)=>{
    
        
    return dispatch => {
        dispatch(confirmPasswordInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/forgetPassword', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(confirmPasswordSuccess(data))

                    }
                    else {
                        dispatch(confirmPasswordFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(confirmPasswordFailure(err))
                    reject(err);
                })

        );
    }

}
