import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { onAuthStateChanged } from "firebase/auth";
import FacebookLogin from "react-facebook-login";

import { getAuth, signInWithPopup,signOut,getRedirectResult, FacebookAuthProvider,signInWithRedirect,GoogleAuthProvider } from "firebase/auth";


import Logo from "../assets/images/logo.png";
import Search from "../assets/images/Search.png";
import Institute from "../assets/images/institute.png";
import demoProfile from "../assets/images/demo-profile.png";


import {
  AdminLoginAction,
  OtpLoginAction,
} from "../redux/actions/AdminLoginAction";
import { signUpAction, GoogleSignUpAction } from "../redux/actions/signupAction";
import { veriFyOtpAction } from "../redux/actions/verifyOtpAction";
import { LoginOtpAction, SendNewOtpAction } from "../redux/actions/sendOtpAction";
import { ConfirmPasswordAction } from "../redux/actions/confirmPasswordAction";
import { toast } from "react-toastify";


import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { streamDropdownAction, subStreamDropdownAction } from "../redux/actions/universityAction";
import { courseDropdownAction } from "../redux/actions/coachingAndCertificatesAction";
import { listClasses } from "@mui/material";
import { studentDetailsAction } from "../redux/actions/studentAction";
import { trendingCityListAction, trendingCollegeUniversityListAction, trendingSchoolPreSchoolListAction, trendingStreamListAction } from "../redux/actions/trendingAction";
import { topCollegeUniversityListAction, topSchoolPreSchoolListAction } from "../redux/actions/topAction";
import { getHomePageDataAction } from "../redux/actions/home-pageAction";
import ReactFacebookLogin from "react-facebook-login";

const initialState = {
  email: "",
  type: "email",
  password: "",
  emailError: "",
  passwordError: "",
  errorMsg: "",
  stream:"",
  subStream:""
};

const emailState = {
  forgetConfirmPassword: "",
  forgetPassword: "",
  forgetConfirmPasswordError: "",
  forgetPasswordError: "",
};

const secondInitialState = {
  terms:false,
  secondEmail: "",
  secondPassword: "",
  secondEmailError: "",
  secondName: "",
  secondLast:"",
  secondNameError: "",
  secondPasswordError: "",
  secondConfirm: "",
  secondConfirmError: "",
  secondPhone: "",
  secondPhoneError: "",
  secondLastError:"",
  termsError:""
  
};

const Header = ({ShowSignInModal,handleCloseModal}) => {
  const Profie = JSON?.parse(localStorage?.getItem("websiteData"));
  const[forgetOTPEmail, setForgetOTPEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);

  const [studentData1, setStudentData] = useState('')
  const [isValid, setIsvalid] = useState(false)

  const [secondState, secondUpdateState] = useState(secondInitialState);
  const [otpState, setOtpState] = useState({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  });

  const [forgetState, forgetUpdateState] = useState(emailState);
  const [googleData, setGoogleData] = useState("");

  
  const [password1Shown, setPassword1Shown] = useState(false);
  const [password2Shown, setPassword2Shown] = useState(false);
  const { studentData } = useSelector((state) => state.studentReducer);
  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };

  const toggle2PasswordVisiblity = () => {
    setPassword2Shown(password2Shown ? false : true);
  };
  const {
    trendingCityList,
    trendingStreamList,
    trendingCollegeUniversityList,
    trendingSchoolPreSchoolList,
  } = useSelector((state) => state?.trendingReducer)

  // #############

  // Modals States

  const [signUpModal, setSignUp] = useState(false);
  const [sendOtpModal, updateHandleSendOTPModal] = useState(false)
  const [OtpModal, setOtp] = useState(false);
  const [signInModal, setSignIn] = useState(false);
  const [signOtpModal, setSignOtp] = useState(false);
  const [VerifyForgetModal, setVerifyForget] = useState(false);
  const [forgetModal, setForget] = useState(false);
  const[errors, setErrors] = useState({})
  const [signupOtpModal, setSignupOtp] = useState(false);

  const deviceToken = JSON.parse(localStorage.getItem("deviceToken"))


  const handleSendOTPModalClose = () =>{
    setForgetOTPEmail("")
    setIsvalid(false)
    updateHandleSendOTPModal(false)
    setErrors({})
  }

  const handleSendOTPModalOpen = () =>{
    updateHandleSendOTPModal(true)
    setIsvalid(false)
  }
  
    //##############################
    const handleSetSignupOtpClose = () => {
      secondUpdateState({...secondState, secondName:'',secondEmail:'',secondPhone:'', secondPassword:'',secondLast:'', secondConfirm:'',terms:false,
      secondNameError:'', secondEmailError:'', secondPasswordError:'',secondConfirmError:'', secondPhoneError:'', termsError:'',secondLastError:""
    })
    setIsvalid(false)
      setSignupOtp(false)
      setOtpState({    dig1: "",
      dig2: "",
      dig3: "",
      dig4: "",
      dig5: "",
      dig6: "",
  })
    };
    const handleSetSignupOtpShow = () => {
      setSignupOtp(true);
    };
  
  

  //##############################
  const handleSetSignUpClose = () => {
    setSignUp(false)
    secondUpdateState({...secondState, secondEmail:"", secondName:"",secondPassword:"" ,secondConfirm:"",secondPhone:"", terms:false, secondLast:"",
  secondNameError:"",secondEmailError:"",secondPasswordError:"",secondConfirmError:"", secondPhoneError:"",termsError:'', secondLastError:''})
  };
  const handleSetSignUpShow = () => {
    setSignUp(true);
    secondUpdateState({...secondState, secondEmail:"", secondName:"",secondPassword:"" ,secondConfirm:"",secondPhone:"", terms:false, secondLast:"",
    secondNameError:"",secondEmailError:"",secondPasswordError:"",secondConfirmError:"", secondPhoneError:"",termsError:'', secondLastError:''})
    };

  //##############################

  const handleSetOtpClose = () =>{
    setOtp(false);
    setIsvalid(false)
  }
  const handleSetOtpShow = () => {
    setOtp(true);
  };

  //##############################

  const handleSignInClose = () => {
    // setShowSignInModal(false)
    updateState({...iState, emailError:"", passwordError:"", email:"", password:""})

    setSignIn(false);
  };
  const handleSignInShow = () => {
  //  alert("fjkdfkgfj")
    setSignIn(true);
    setForget(false);
    setSignUp(false);
    handleSendOTPModalClose()
    updateState({...iState, emailError:"", passwordError:"", email:"", password:""})
  };




  //##############################


  const handleSignOtpClose = () => {
    setSignOtp(true);

    setSignIn(false);
  };
  const handleSignOtpShow = () => {
    setSignOtp(true);
  };

  //##############################

  const handleSetForgetClose = () => {
    setForget(false);
    forgetUpdateState({...forgetState, forgetConfirmPasswordError:"",forgetPasswordError:"", forgetConfirmPassword:"",forgetPassword:""})

  };
  const handleSetForgetShow = () => {
    setForget(true);
    setSignIn(false);
  };



  
  const { email, type, password, emailError, passwordError, errorMsg , stream,subStream} = iState;


  const { streamData, subStreamData, courseData
  ,courseDropdownData } = useSelector(
    (state) => state?.listReducer
  );

  const [advertisementImage, updateAdvertisementImage] = useState("")
  const [advertisementImageLink, updateAdvertisementImageLink] = useState("")


  useEffect(() => {  
    dispatch(studentDetailsAction()).then((res) =>{
      if(res.status === 503){
        toast?.error("User is already login somewhere, Please login again")
        localStorage.clear();
        handleSignInShow();
        navigate("/")
        window.location.reload();
       
      
      }
    })
    dispatch(streamDropdownAction());
    dispatch(courseDropdownAction());
    dispatch(trendingCityListAction());
    dispatch(trendingStreamListAction());
    dispatch(trendingCollegeUniversityListAction());

    dispatch(trendingSchoolPreSchoolListAction());
    dispatch(topCollegeUniversityListAction());
    dispatch(topSchoolPreSchoolListAction());
    dispatch(getHomePageDataAction()).then((res)=>{
            updateAdvertisementImage(res?.data?.advertisementImage)
            updateAdvertisementImageLink(res?.data?.link)

          })
  }, []);

  // useEffect(() => {
  //   dispatch(subStreamDropdownAction({ streamId: stream }));
  // }, [stream]);


  //##############################

  const handleSetVerifyForgetClose = () => {
    setVerifyForget(false);
  };
  const handleSetVefifyForgetShow = () => {
    setVerifyForget(true);
  };

 

  let handleValidation = () => {
    let emailError = "";
    let passwordError = "";
    let formIsValid = true;
    if (type === "email") {
      if (!email) {
        emailError = "* Please enter email.";
        formIsValid = false;
      } else if (!/^.+?@.+?\..+$/.test(email)) {
        emailError = "* Email format is not valid";
        formIsValid = false;
      }
      if (!password) {
        passwordError = "* Please enter password.";
        formIsValid = false;
      }
    }

    updateState({
      ...iState,
      emailError,
      passwordError,
      errorMsg,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = { email :email.toLowerCase(), password, deviceToken };
    

      dispatch(AdminLoginAction(data))
        .then((res) => {
          if (res.status === 200) {
            window.localStorage.setItem(
              "websiteData",
              JSON.stringify(res.data)
            );
            setSignIn(false);
            dispatch(studentDetailsAction());
          } else {
           toast .error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              emailError: "",
              passwordError: "",
              email:"", password:""
            });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  const {
    secondEmail,
    terms,
    secondName,
    secondLast,
    secondLastError,
    secondEmailError,
    secondNameError,
    secondPassword,
    secondPasswordError,
    secondConfirm,
    secondConfirmError,
    secondPhone,
    secondPhoneError,
    termsError,
  } = secondState;


  let secondHandleValidation = () => {
    let secondEmailError = "";
    let termsError="";
    let secondPasswordError = "";
    let secondNameError = "";
    let secondConfirmError = "";
    let secondPhoneError = "";
    let secondLastError ="";


    let secondFormIsValid = true;
    if(!secondLast){
      secondLastError="* Please enter last name"
      secondFormIsValid = false
    }
    if(terms == false){
      termsError="* Please check term  and privacy"
      secondFormIsValid = false
    }
    if (!secondEmail) {
      secondEmailError = "* Please enter email.";
      secondFormIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(secondEmail)) {
      secondEmailError = "* Email format is not valid";
      secondFormIsValid = false;
    }
    if (!secondName) {
      secondNameError = "Please enter name";
      secondFormIsValid = false;
    }
    if (!secondPassword) {
      secondPasswordError = "* Please enter password.";
      secondFormIsValid = false;
    }
    else if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(secondPassword))
    {
      secondPasswordError = "* Ensure that password contains both upper , lowercase includes symbol @ # * a. and numbers";
      // secondFormIsValid = false;
    }

    if (!secondPhone) {
      secondPhoneError = "* Please enter phone number.";
      secondFormIsValid = false;
    }
    else if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(secondPhone)) {
        secondPhoneError = "* Please enter valid number";
        secondFormIsValid = false;
      }
    if (!secondConfirm) {
      secondConfirmError = "* Please enter confirm password.";
      secondFormIsValid = false;
    }
    if (secondPassword !== secondConfirm) {
      secondConfirmError = "* Password not match!.";
      secondFormIsValid = false;
    }
    

    secondUpdateState({
      ...secondState,
      termsError,
      secondEmailError,
      secondLastError,
      secondNameError,
      secondPasswordError,
      secondConfirmError,
      secondPhoneError,
    });
    return secondFormIsValid;
  };

  const secondHandleChange = (e) => {
    const { name, value } = e.target;
    secondUpdateState({
      ...secondState,
      [name]: value,
    });
  };

  const secondHandleSubmit = (e) => {
    e.preventDefault();

    let formIsValid2 = secondHandleValidation();

    if (formIsValid2) {
      var data = {
        
        email: secondEmail?.toLowerCase(),
        password: secondPassword,
        fullName: `${secondName} ${secondLast}`,
        phoneNumber: secondPhone,

      };
      setStudentData(data)


      
      dispatch(SendNewOtpAction({ email: secondEmail, phoneNumber:secondPhone }))
        .then((res) => {
          if (res.status === 200) {
             setSignUp(false);
             setSignupOtp(true);
             toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
             dispatch(studentDetailsAction());

          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
        });
    }
  };


  const handleSignupVerify = (e) => {
    e.preventDefault();
    setIsvalid(false)

    let data = {
      email:  secondEmail?.toLowerCase(),
      otp:
        otpState.dig1 +
        otpState.dig2 +
        otpState.dig3 +
        otpState.dig4 +
        otpState.dig5 +
        otpState.dig6,
    };



  if(otpState?.dig6){
    dispatch(veriFyOtpAction(data))
      .then((res) => {
        if (res.status === 200) {  
          setSignupOtp(false);

          var data = {
        
            email: secondEmail?.toLowerCase(),
            password: secondPassword,
            fullName: `${secondName} ${secondLast} ` ,
            phoneNumber: secondPhone,
    
          };
    
     
          dispatch(signUpAction(data))
          .then((res) => {
            if (res.status === 200) { 
              window.localStorage.setItem(
                "websiteData",
                JSON.stringify(res?.data)
              );
                dispatch(studentDetailsAction());
       
            }
            else{
              
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
            }
          })
          setOtpState({
            dig1: "",
            dig2: "",
            dig3: "",
            dig4: "",
            dig5: "",
            dig6: "",
          });
          // toast.success(res.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else {
          // toast.error(res.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          //   updateState({
          //     ...iState,
          //     errorMsg: res.message,
          //     emailError: "",
          //     passwordError: "",
          //   });
        }
      })
      .catch((err) => {
      });}
  };


 

  const otpHandleChange = (e) => {
    const { name, value } = e.target;
    setOtpState({
      ...otpState,
      [name]: value,
    });
  };

  const handleLoginOtp = (e) => {
    e.preventDefault();
    const userdata = JSON.parse(localStorage.getItem("websiteData"));

    let data = {
      email: userdata.email?.toLowerCase(),
      otp:
        otpState.dig1 +
        otpState.dig2 +
        otpState.dig3 +
        otpState.dig4 +
        otpState.dig5 +
        otpState.dig6,
    };

    dispatch(OtpLoginAction(data))
      .then((res) => {
        if (res.status === 200) {
          setSignOtp(false);
          // toast.success(res.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOtpState({
            dig1: "",
            dig2: "",
            dig3: "",
            dig4: "",
            dig5: "",
            dig6: "",
          });
        }
      })
      .catch((err) => {
        // alert(err);
      });
  };

  const handleResendOtp = (e, redir) => {
    e.preventDefault();
    const userdata = JSON.parse(localStorage.getItem("websiteData"));

    dispatch(LoginOtpAction({ email: userdata? userdata.email?.toLowerCase(): studentData1?.email?.toLowerCase() }))
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          if (redir) {
            
            setSignIn(false);
            setSignOtp(true);

            
            // // alert(res?.data?.otp)
            // alert(res?.data?.otp)
          }

        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        // alert(err);
      });
  };


  const handleSignUpResendOtp = (e, redir) => {
    e.preventDefault();
    const userdata = JSON.parse(localStorage.getItem("websiteData"));

    dispatch(SendNewOtpAction({ email: userdata? userdata.email?.toLowerCase(): studentData1?.email?.toLowerCase() }))
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        
        } else {  
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        // alert(err);
      });
  };


  const { forgetConfirmPassword, forgetPassword, forgetPasswordError, forgetConfirmPasswordError } =
    forgetState;

  let forgetHandleValidation = () => {

    let forgetFormIsValid = true;
    let forgetConfirmPasswordError = "";
    let forgetPasswordError = "";

    
    if (!forgetPassword) {
      forgetPasswordError = "* Please enter password.";
      forgetFormIsValid = false;
    }
    else if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(forgetPassword))
    {
      forgetPasswordError = "* Ensure that password contains both upper , lowercase includes symbol @ # * a. and numbers";
      forgetFormIsValid = false;
    }

    if(!forgetConfirmPassword){
      forgetConfirmPasswordError = "* Please enter confirm password.";
      forgetFormIsValid = false;

    }
    else if(forgetPassword !== forgetConfirmPassword){
      forgetConfirmPasswordError="*  Password not match !"
      forgetFormIsValid = false
    }


    forgetUpdateState({
      ...forgetState,
      forgetConfirmPasswordError,
      forgetPasswordError,
    });
    return forgetFormIsValid;
  };

  const forgetHandleChange = (e) => {
    const { name, value } = e.target;
    forgetUpdateState({
      ...forgetState,
      [name]: value,
    });
  };

  const handleForgetPasswordValidation = () =>{
    let error ={};
    let  formIsValidForget = true;

    if(!forgetOTPEmail){
     error.forgetEmailError = "Please enter a email";
     formIsValidForget = false
    }
    if(forgetOTPEmail){
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(forgetOTPEmail)) {
          error.forgetEmailError = "Please enter a valid email";
          formIsValidForget = false;
        }
  } 

   setErrors(error)
   return formIsValidForget

  }


  const sendEmailForOTP = (e) =>{
    e.preventDefault()
    let formIsValidForget = handleForgetPasswordValidation() ;
    if(formIsValidForget){
      dispatch(LoginOtpAction({email:forgetOTPEmail?.toLowerCase()})).then((res) =>{
        if(res.status == 200){

          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setIsvalid(false)
          localStorage.setItem("forgetOTPEmail", JSON.stringify(forgetOTPEmail))
          setForgetOTPEmail("")
          setErrors({})
  
          setOtp(true)
          updateHandleSendOTPModal(false)
          
          
  
  
        }
        else {
                toast.error(res.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }    
  
      })
    }
  
  }

  const handleForgetOTPVerify = (e) => {
    e.preventDefault();
    setIsvalid(true)
    // const userdata = JSON.parse(localStorage.getItem("websiteData"));
    // const emailData = JSON.parse(localStorage.getItem("studenEmail"))
    const forgetOTPEmail = JSON.parse(localStorage.getItem("forgetOTPEmail")) 


    let data = {
      email: forgetOTPEmail?.toLowerCase(),
      otp:
        otpState.dig1 +
        otpState.dig2 +
        otpState.dig3 +
        otpState.dig4 +
        otpState.dig5 +
        otpState.dig6,
    };
    if(otpState.dig6){
      
    setIsvalid(false)
    dispatch(veriFyOtpAction(data))
    .then((res) => {
      if (res.status === 200) {   
        handleSetOtpClose()     
          window.localStorage.setItem(
        "websiteData",
        JSON.stringify(studentData1)
      );
     

        setOtp(false);
        setOtpState({
          dig1: "",
          dig2: "",
          dig3: "",
          dig4: "",
          dig5: "",
          dig6: "",
        });
        setForget(true)
        // toast.success(res.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        //   updateState({
        //     ...iState,
        //     errorMsg: res.message,
        //     emailError: "",
        //     passwordError: "",
        //   });
      }
    })
    .catch((err) => {
      // alert(err);
    });
    }

  };

  const handleForgetSubmit = (e) => {
    e.preventDefault();

    let formIsValid = forgetHandleValidation();
    let forgetEmailOTP = JSON.parse(localStorage.getItem("forgetOTPEmail"));




    if (formIsValid) {
      dispatch(
        ConfirmPasswordAction({
          email: forgetEmailOTP?.toLowerCase(),
          newPassword: forgetPassword,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            forgetUpdateState({...forgetState, forgetPassword:"", forgetConfirmPassword:"", forgetPasswordError:"", forgetConfirmPasswordError:""})
            
            
                  toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setForget(false)
                  setSignIn(true)
                  
                  
            


          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  const resendForgetOtp = (e) => {
    e.preventDefault();
    const student = JSON.parse(localStorage.getItem("studentEmail"));
    const forgetOTPEmail = JSON.parse(localStorage.getItem("forgetOTPEmail"));

    dispatch(LoginOtpAction({ email: forgetOTPEmail?.toLowerCase() ? forgetOTPEmail?.toLowerCase() : student.email?.toLowerCase() }))
    // ? student.email  : forgetOTPEmail 
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        // alert(err);
      });
  };

  useEffect(()=>{
    setSignIn(ShowSignInModal)
    

  },[ShowSignInModal])


  const handleVerifyForgetSumbmit = () => {
    const student = JSON.parse(localStorage.getItem("studentEmail"));

    dispatch(
      veriFyOtpAction({
        email: student.email?.toLowerCase(),
        otp:
          otpState.dig1 +
          otpState.dig2 +
          otpState.dig3 +
          otpState.dig4 +
          otpState.dig5 +
          otpState.dig6,
      })
    )
      .then((res) => {
        if (res.status === 200) {
          setVerifyForget(false);
          updateState({})

          // toast.success(res.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setOtpState({
          dig1: "",
          dig2: "",
          dig3: "",
          dig4: "",
          dig5: "",
          dig6: "",
        });
      })
      .catch((err) => {
        // alert(err);
      });
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    setSignUp(true);
    setSignIn(false);
    secondUpdateState({...secondState, secondEmail:"", secondName:"",secondPassword:"" ,secondConfirm:"",secondPhone:"", terms:false, secondLast:"",
    secondNameError:"",secondEmailError:"",secondPasswordError:"",secondConfirmError:"", secondPhoneError:"",termsError:'', secondLastError:''})
    };
  

const naviagte = useNavigate()
  const goToSchools = (type,stateNameee) => {
        // navigate("/list-preschool")
        naviagte("/list-school", {
          state:{
            type,stateNameee         
          }
        })
  }


  const goToPreSchools = (type,stateNameee) => {
    navigate("/list-preschool", {
      state:{
        type,stateNameee  , flag:true       
      }
    })
  }


  const goToSubStream = (id) => {
    dispatch(subStreamDropdownAction({streamId: id}))
  }
   const goToInstitute =( ) => {
    navigate("http://13.59.90.211/career_dekhoo_institute/")
   }

   const goToCollege =(filterID,filterName) => {
    naviagte("/list-college", {state : {filterName,filterID}})
   }



  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;

      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
     
     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }

  let path = window.location.href.split('/');

 

  let pathLength = parseInt(path.length) - 1;
    let pathMatch = path[pathLength];
  
    const handleForgetPasswordVerify = (e) => {
      e.preventDefault();
      const userdata = JSON.parse(localStorage.getItem("websiteData"));
      const emailData = JSON.parse(localStorage.getItem("studenEmail"))
  
      let data = {
        email: emailData? emailData?.email?.toLowerCase(): (userdata) ? (userdata?.email?.toLowerCase()) : studentData1?.email?.toLowerCase(),
        otp:
          otpState.dig1 +
          otpState.dig2 +
          otpState.dig3 +
          otpState.dig4 +
          otpState.dig5 +
          otpState.dig6,
      };
  
  
      dispatch(veriFyOtpAction(data))
        .then((res) => {
          if (res.status === 200) {          
              window.localStorage.setItem(
            "websiteData",
            JSON.stringify(studentData1)
          );
            setOtp(false);
            setOtpState({
              dig1: "",
              dig2: "",
              dig3: "",
              dig4: "",
              dig5: "",
              dig6: "",
            });
            // toast.success(res.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            //   updateState({
            //     ...iState,
            //     errorMsg: res.message,
            //     emailError: "",
            //     passwordError: "",
            //   });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    };
  

    const handleGoogleSignup=()=>{

      const provider = new GoogleAuthProvider();
      const auth = getAuth()
    
      signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        if(user){

          dispatch(GoogleSignUpAction({email: user?.email, fullName:user?.displayName, platform:"google", deviceToken}))
          .then((res) => {
            
            if (res.status === 200) { 
              window.localStorage.setItem(
                "websiteData",
                JSON.stringify(res?.data)
              );          
                dispatch(studentDetailsAction());
                setSignUp(false)
                setSignIn(false)
            }
            else{
              
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
            }})

        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    
    }

    const handleFacebookSignup=()=>{

      const provider = new FacebookAuthProvider();
      const auth = getAuth()
    
      signInWithPopup(auth, provider)
      .then((result) => {
        console.log("result", result)
        
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("##################",user )
        if(user){

          dispatch(GoogleSignUpAction({email: user?.email, fullName:user?.displayName, platform:"facebook",deviceToken}))
          .then((res) => {
            if (res.status === 200) { 
              window.localStorage.setItem(
                "websiteData",
                JSON.stringify(res?.data)
              );
              setSignUp(false)
              setSignIn(false)
                dispatch(studentDetailsAction());
            }
            else{
              
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
            }})

        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
     
      getRedirectResult(auth)
        .then((result) => {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
      
          const user = result?.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error?.code;
          const errorMessage = error?.message;
          // The email of the user's account used.
          const email = error?.customData?.email;
          // AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);
          // ...
        });
    }

    const handleRefreshAgain = (e) => {
      naviagte("/")
     window.location.reload();
   
    }

  //   function checkLoginState() {
  //     FB.getLoginStatus(function(response) {
  //       statusChangeCallback(response);
  //     });
  //   }
  //   FB.getLoginStatus(function(response) {
  //     statusChangeCallback(response);
  // });

  const handleFacebookRegister = () => {
    const provider = new FacebookAuthProvider();
    const auth = getAuth()

    
  
    signInWithPopup(auth, provider)
    .then((result) => {
      console.log("result", result)
    //  FB.login(function(response) {
    //     console.log(response);
    //  }, {scope: 'email'});
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("##################",user )
      if(user){

        dispatch(GoogleSignUpAction({email: user?.email, fullName:user?.displayName, platform:"facebook"}))
        .then((res) => {
          if (res.status === 200) { 
            window.localStorage.setItem(
              "websiteData",
              JSON.stringify(res?.data)
            );
            setSignUp(false)
            setSignIn(false)
              dispatch(studentDetailsAction());
          }
          else{
            
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
          }})

      }
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error?.code;
      const errorMessage = error?.message;
      // The email of the user's account used.
      const email = error?.customData?.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);
      // ...
    });
   
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
    
        const user = result?.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
  }
  
  function onResponse(data){
   console.log("data", data)
  }

  const responseFacebook = (response) => {
    console.log("response", response);
    // Login failed
    // if (response.status === "unknown") {
    //   alert("Login failed!");
    //   setLogin(false);
    //   return false;
    // }
    // setData(response);
    // setPicture(response.picture.data.url);
    // if (response.accessToken) {
    //   setLogin(true);
    // } else {
    //   setLogin(false);
    // }
  };
  return (
    <header>
      <div class="container">
        <div class="UpderHeader">
          <div class="MainLogo" onClick={handleRefreshAgain} >
            <img src={Logo} />
          </div>

          {/* {Object?.keys(studentData)?.length > 0 ? ( */}
          {Profie ? (
            <ul>
              <li className={path[4] === "my-profile" || path[4] === "my-profile-edit" ? "active" :''}>
                <Link className="ProfileLink" to="/my-profile">
                  {studentData?.result?.fullName}
                  {studentData?.result?.profilePic ?<span><img src={studentData?.result?.profilePic} alt="profilePic" /></span>:<span><img src={demoProfile} alt="profilePic" /></span>}
                </Link>
              </li>
              <li>
              <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a onClick={handleSignInShow}>
                  <span>
                    {" "}
                    <img src={Institute} />
                  </span>
                  <div class="TextMain">
                    <p>Register/Login as </p>
                    <h6>Student</h6>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://careerdekhoo.com/institute/" target="_blank">
                  <span>
                    {" "}
                    <img src={Institute} />
                  </span>
                  <div class="TextMain"> 
                  <p>Register/Login as </p>
                    <h6>Institute</h6>
                   </div>
                </a>
              </li>
              <li>
              <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
              </li>
            </ul>
          )}
        </div>
        <div class="Header">
          <nav class="navbar navbar-expand-lg navbar-light">
            

            <div class="collapse navbar-collapse" id="menu">
              <ul class="navbar-nav">
              <li className={`nav-item dropdown UniversitiesMenu ${path[pathLength] === "list-university" ? "active" :""}`}>
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  >
                    Universities
                  </a>
                  <div class="dropdown-menu">
                    <ul>
                    {streamData?.data?.result && streamData?.data?.result?.length > 0 ? <>
                    {streamData?.data?.result?.map((ele,i) => {
                      return(
                        <>
                         <li  class="dropdown-submenu">
                        <a onMouseOver={() =>goToSubStream(ele?._id)} href="javascript:void(0);">{ele?.streamName}</a>
                        <ul>
                          { subStreamData?.data?.result &&  subStreamData?.data?.result?.length > 0 ? <>
                          {subStreamData?.data?.result?.map((ele,i) => {
                            return(
                              <>
                               <li>
                                <Link state={ele?._id} to="/list-university">
                                {ele?.subStreamName}
                                </Link>
                              </li>
                              </>
                            )
                          })}
                          </> : <></>}
                         
                        </ul>
                      </li>
                        </>
                      )
                    })}

                      </> : <></>}
                     
                     
                     
                    
                    </ul>
                  </div>
                </li>
                <li className={`nav-item dropdown UniversitiesMenu ${path[pathLength] === "list-college" ? "active" :""}`}>
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  >
                    Colleges{" "}
                  </a>
                  <div class="dropdown-menu">
                    <ul>
                    {streamData?.data?.result && streamData?.data?.result?.length > 0 ? <>
                    {streamData?.data?.result?.map((ele,i) => {
                      return(
                        <>
                         <li class="dropdown-submenu">
                        <a onMouseMove={() =>goToSubStream(ele?._id)} href="javascript:void(0);">{ele?.streamName}</a>
                        <ul>
                          { subStreamData?.data?.result &&  subStreamData?.data?.result?.length > 0 ? <>
                          {subStreamData?.data?.result?.map((ele,i) => {
                            return(
                              <>
                               <li>
                            {/* <Link state={ele?._id} to="/list-college">
                             {ele?.subStreamName}
                            </Link> */}
                              <a onClick={() =>goToCollege(ele?._id, "SUBSTREAM")} href="javascript:void(0);">{ele?.subStreamName}</a>
                          </li>
                              </>
                            )
                          })}
                          </> : <></>}
                         
                        </ul>
                      </li>
                        </>
                      )
                    })}

                      </> : <></>}
                     
                     
                     
                    
                    </ul>
                  </div>
                </li>
              
                <li className={`nav-item dropdown ${path[pathLength] === "list-school" ? "active" :""}`}>
                 
                    <a class="nav-link" href="javascript:void(0);" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Schools                                   
                                </a>
                                
                    <div class="dropdown-menu MegaMenu">
                    <div class="MegaMenuBox">
                                        <h3>CBSE School</h3>
                                        <ol> 
                                            <li><a onClick={() => goToSchools("CBSE","")}>CBSE Schools in India</a></li>                                         
                                            <li><a onClick={() => goToSchools("CBSE","Delhi")}>CBSE Schools in Delhi</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Gurgaon")}>CBSE Schools in Gurgoan</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Noida")}>CBSE Schools in Noida</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Jammu")}>CBSE Schools in Jammu</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Chandigarh")}>CBSE Schools in Chandigarh</a></li>                                
                                            {/* <li><a onClick={() => goToSchools("CBSE","Jammu")}>CBSE Schools in Jammu</a></li>                                            */}
                                            <li><a onClick={() => goToSchools("CBSE","Himanchal Pradesh")}>CBSE Schools in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Uttar Pradesh")}>CBSE Schools in Uttar Pradesh</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Haryana")}>CBSE Schools in Haryana</a></li>
                                            <li><a onClick={() => goToSchools("CBSE","Punjab")}>CBSE Schools in Punjab</a></li>
                                        </ol>
                                    </div>
                                    <div class="MegaMenuBox">
                                        <h3>ICSE Schools</h3>
                                        <ol> 
                                            <li><a onClick={() => goToSchools("ICSE","")}>ICSE Schools in India</a></li>                                         
                                            <li><a onClick={() => goToSchools("ICSE","Delhi")}>ICSE Schools in Delhi</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Gurgaon")}>ICSE Schools in Gurgoan</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Noida")}>ICSE Schools in Noida</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Jammu")}>ICSE Schools in Jammu</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Chandigarh")}>ICSE Schools in Chandigarh</a></li>                                
                                            <li><a onClick={() => goToSchools("ICSE","Uttar Pradesh")}>ICSE Schools in Uttar Pradesh</a></li>  
                                            <li><a onClick={() => goToSchools("ICSE","Himanchal Pradesh")}>ICSE Schools in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Haryana")}>ICSE Schools in Haryana</a></li>
                                            <li><a onClick={() => goToSchools("ICSE","Punjab")}>ICSE Schools in Punjab</a></li>
                                        </ol>
                                    </div>
                                    <div class="MegaMenuBox">
                                        <h3>Day-cum-Boarding Schools</h3>
                                        <ol> 
                                            <li><a onClick={() => goToSchools("Board","")}>Day-cum-Boarding Schools in India</a></li>                                         
                                            <li><a onClick={() => goToSchools("Board","Delhi")}>Day-cum-Boarding Schools in Delhi</a></li>
                                            <li><a onClick={() => goToSchools("Board","Gurgaon")}>Day-cum-Boarding Schools in Gurgoan</a></li>
                                            <li><a onClick={() => goToSchools("Board","Noida")}>Day-cum-Boarding Schools in Noida</a></li>
                                            <li><a onClick={() => goToSchools("Board","Jammu")}>Day-cum-Boarding Schools in Jammu</a></li>
                                            <li><a onClick={() => goToSchools("Board","Chandigarh")}>Day-cum-Boarding Schools in Chandigarh</a></li>
                                            <li><a onClick={() => goToSchools("Board","Uttar Pradesh")}>Day-cum-Boarding Schools in Uttar Pradesh</a></li>                                                                           
                                            <li><a onClick={() => goToSchools("Board","Himanchal Pradesh")}>Day-cum-Boarding Schools in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToSchools("Board","Haryana")}>Day-cum-Boarding Schools in Haryana</a></li>
                                            <li><a onClick={() => goToSchools("Board","Punjab")}>Day-cum-Boarding Schools in Punjab</a></li>
                                        </ol>
                                    </div>
                    </div>
                    
                   
                    
                 
                  
                </li>

                <li className={`nav-item dropdown ${path[pathLength] === "list-preschool" ? "active" :""}`}>
                  {/* <a class="nav-link">
                    Pre-schools{" "} */}
                    
                    <a class="nav-link" href="javascript:void(0);" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Pre-Schools
                                    {/* <span class="PromoIcon">
                      <img src={require("../assets/images/New.png")} />
                    </span> */}
                                </a>
                                
                                <div class="dropdown-menu MegaMenu">
                                <div class="MegaMenuBox">
                                        <h3>Day Care Pre-Schools </h3>
                                        <ol> 
                                            <li><a onClick={() => goToPreSchools("Day-Care","")}>Day care Pre-School in India</a></li>                                         
                                            <li><a onClick={() => goToPreSchools("Day-Care","Delhi")}>Day care Pre-School in Delhi</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Gurgaon")}>Day care Pre-School in Gurgoan</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Noida")}>Day care Pre-School in Noida</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Jammu")}>Day care Pre-School in Jammu</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Chandigarh")}>Day care Pre-School in Chandigarh</a></li>                                
                                            <li><a onClick={() => goToPreSchools("Day-Care","Jammu")}>Day care Pre-School in Jammu</a></li>                                           
                                            <li><a onClick={() => goToPreSchools("Day-Care","Himanchal Pradesh")}>Day care Pre-School in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Haryana")}>Day care Pre-School in Haryana</a></li>
                                            <li><a onClick={() => goToPreSchools("Day-Care","Punjab")}>Day care Pre-School in Punjab</a></li>
                                        </ol>
                                    </div>



                    <div class="MegaMenuBox">
                                        <h3>Playway Pre-School </h3>
                                        <ol> 
                                            <li><a onClick={() => goToPreSchools("playway","")}>Playway Pre-Schools in India</a></li>                                         
                                            <li><a onClick={() => goToPreSchools("playway","Delhi")}>Playway Pre-Schools in Delhi</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Gurgaon")}>Playway Pre-Schools in Gurgoan</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Noida")}>Playway Pre-Schools in Noida</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Jammu")}>Playway Pre-Schools in Jammu</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Chandigarh")}>Playway Pre-Schools in Chandigarh</a></li>                                
                                            <li><a onClick={() => goToPreSchools("playway","Jammu")}>Playway Pre-Schools in Jammu</a></li>                                           
                                            <li><a onClick={() => goToPreSchools("playway","Himanchal Pradesh")}>Playway Pre-Schools in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Haryana")}>Playway Pre-Schools in Haryana</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Punjab")}>Playway Pre-Schools in Punjab</a></li>
                                        </ol>
                                    </div>
                                    <div class="MegaMenuBox">
                                        <h3>Top Rated Playway Pre-Schools</h3>
                                        <ol> 
                                            <li><a onClick={() => goToPreSchools("playway","")}>Top Rated Playway Pre-Schools in India</a></li>                                         
                                            <li><a onClick={() => goToPreSchools("playway","Delhi")}>Top Rated Playway Pre-Schools in Delhi</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Gurgaon")}>Top Rated Playway Pre-Schools in Gurgoan</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Noida")}>Top Rated Playway Pre-Schools in Noida</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Jammu")}>Top Rated Playway Pre-Schools in Jammu</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Chandigarh")}>Top Rated Playway Pre-Schools in Chandigarh</a></li>                                
                                            <li><a onClick={() => goToPreSchools("playway","Jammu")}>Top Rated Playway Pre-Schools in Jammu</a></li>                                           
                                            <li><a onClick={() => goToPreSchools("playway","Himanchal Pradesh")}>Top Rated Playway Pre-Schools in Himanchal Pradesh</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Haryana")}>Top Rated Playway Pre-Schools in Haryana</a></li>
                                            <li><a onClick={() => goToPreSchools("playway","Punjab")}>Top Rated Playway Pre-Schools in Punjab</a></li>
                                        </ol>
                                    </div>
                                  
                    </div>
                    
                    
                   
                    
                  {/* </a> */}
                  
                </li>
                <li className={`nav-item ${path[pathLength] == "list-certificate" ? "active" :""}`}>
                  <Link to="/list-certificate" class="nav-link">
                    Certificates & Coaching
                  </Link>
                </li>
                {/* <li className={`nav-item dropdown CourseMenu ${path[pathLength] === "list-college" ? "active" :""}`}>  */}
                <li className="nav-item dropdown CourseMenu"> 
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  >
                    Courses{" "}
                  </a>
                  <div class="dropdown-menu">
                    <ul>
                    {courseDropdownData?.data?.result && courseDropdownData?.data?.result?.length > 0 ? <>
                    {courseDropdownData?.data?.result?.map((ele,i) => {
                      return(
                        <>
                         <li class="dropdown-submenu">
                         {/* <Link state={ele?.streamId} to="/list-college">
                             {ele?.courseName}
                            </Link> */}
                        <a onClick={() =>goToCollege(ele?.streamId, "STREAM")} href="javascript:void(0);">{ele?.courseName}</a>
                        {/* <ul>
                          { subStreamData?.data?.result &&  subStreamData?.data?.result?.length > 0 ? <>
                          {subStreamData?.data?.result?.map((ele,i) => {
                            return(
                              <>
                               <li>
                            <Link state={ele?._id} to="/list-college">
                             {ele?.subStreamName}
                            </Link>
                          </li>
                              </>
                            )
                          })}
                          </> : <></>}
                         
                        </ul> */}
                      </li>
                        </>
                      )
                    })}

                      </> : <></>}
                     
                     
                     
                    
                    </ul>
                  </div>
                </li>
              
                <li className={`nav-item ${path[pathLength] === "choose-career" ? "active" :""}`}>
                  <Link to="/choose-career" class="nav-link">
                    Choose Career{" "}
                  </Link>
                </li>
                <li className={`nav-item ${path[pathLength] === "lastest-news" ? "active" :""}`}>
                  <Link to="/lastest-news" class="nav-link">
                    Latest News
                  </Link>
                </li>
                <li className={`nav-item ${path[pathLength] === "exam" ? "active" :""}`}>
                  <Link to="/exam" class="nav-link">
                    Exams
                  </Link>
                </li>
                <li  className={`nav-item dropdown ${path[pathLength] === "education-loan" || path[pathLength] === "faq" || path[pathLength] === "about-us" || path[pathLength] === "join-us" || path[pathLength] === "contact-us" || path[pathLength] === "blog" ? "active" :''}`}>
               
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    More
                  </a>
                  <ol class="dropdown-menu">
                    <li>
                      <Link to="/education-loan">Education Loan</Link>
                    </li>
                    <li>
                      <Link to="/faq">Q & A </Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us </Link>
                    </li>
                    <li>
                      <Link to="/join-us">Join Our Team </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us </Link>
                    </li>
                    <li>
                      <Link to="/blog">Blogs </Link>
                    </li>
                  </ol>
                </li>
              </ul>
              <div class="menuright">
                <ul>
                <li className={`${path[pathLength] === "/exam" ? "active" :""}`}>
                    <Link to="/find-job">
                      <p>Find Jobs</p>
                      {/* <span id="Search">
                         
                          {" "}
                          <img src={Search} />
                        
                      </span> */}
                      </Link>
                    <div class="SearchBar">
                      <input type="search" placeholder="Search here" />
                      <button>
                        {" "}
                        <img src={Search} />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* SIGN IN MODAL */}
      <Modal show={signInModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={ShowSignInModal?handleCloseModal:handleSignInClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal

                </p>
                <ul>
                  <li>Free access to the huge database of Educational Institutes</li>
                  <li>Free Career Counselling by industry experts</li>
                  <li>Simply shortlist and apply</li>
                  <li>Find latest news and information about admissions</li>
                  <li>Latest Exams information and access to the “Find Jobs” portal</li>
                  {/* <li>Free access to the huge database of Educational Institutes</li>
                  <li>Free access to the huge database of Educational Institutes</li>
                  <li>Free access to the huge database of Educational Institutes</li>
                  <li>Free access to the huge database of Educational Institutes</li> */}
                </ul>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>
                Sign in to <strong>CareerDekhoo</strong>
              </h3>
              <form>
                <div class="form-group">
                  <div class="PhoneCode">
                    <input
                      type="text"
                      class="form-control"
                      valid={email}
                      name="email"
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </div>
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>
                <div className="form-group">
                  <div class="PhoneCode">
                    <input
                    type={password1Shown?"text":"password"}
                    value={password}
                      name="password"
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Password"
                    />
                                      <span onClick={toggle1PasswordVisiblity} className={password1Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                  </div>
                  {passwordError && (
                    <p style={{ color: "red" }}>{passwordError}</p>
                  )}
                </div>

                <div class="form-group">
                  <div class="FormButton">
                    <p>
                      <a onClick={handleSendOTPModalOpen}>Forgot password?</a>
                    </p>
                    <button type="button" onClick={handleSubmit}>
                      Sign in with Password
                    </button>
                  </div>
                </div>
              </form>

              {/* <div class="OrBox">
                <p>
                  <span>or</span>
                </p>
                <button type="submit" onClick={(e) => handleResendOtp(e, true)}>
                  Sign in with OTP
                </button>
              </div> */}

<div class="Already">
                  <aside>
                    <a class="Google" onClick={handleGoogleSignup}>
                      <i class="fa fa-google"></i>
                      Sign in with Google
                    </a>
                    <a class="Facebook" onClick={handleFacebookSignup}>
                      <i class="fa fa-facebook"></i>
                    </a>
                   
    {/* <FacebookLogin
          appId="2390820564426674"
          autoLoad={false}
          fields="name,email"
          scope="email"
          callback={responseFacebook}
          icon="fa-facebook"
        /> */}
                  </aside>

                </div>

              <div class="Already">
                <p>
                  Don’t have a account?{" "}
                  <a onClick={handleCreateAccount}>Create an account</a>
                </p>
                <hr />
                <h6>
                  {/* /termsAndConditon */}
                  By proceeding you agree to{" "}
                  <a href="#/termsAndConditon" target="_blank" >Terms & conditions</a> and{" "}
                  <a  href="#/privacyDetails" target="_blank" >Privacy Policy</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* SIGN Up MODAL */}
      <Modal show={signUpModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleSetSignUpClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}{" "}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>
                Sign up to <strong>CareerDekhoo</strong>
              </h3>
              <form>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    value={secondEmail}
                    name="secondEmail"
                    onChange={secondHandleChange}
                    placeholder="Email address"
                  />
                  <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                  {secondEmailError && (
                    <p style={{ color: "red" }}>{secondEmailError}</p>
                  )}
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        type="text"
                        value={secondName}
                        name="secondName"
                        onChange={secondHandleChange}
                        class="form-control"
                        placeholder="First name"
                      />
                      <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                      {secondNameError && (
                        <p style={{ color: "red" }}>{secondNameError}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        type="text"
                        onChange={secondHandleChange}
                        value={secondLast}
                        name="secondLast"
                        class="form-control"
                        placeholder="Last name"
                      />
                      <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                                        {secondLastError && (
                    <p style={{ color: "red" }}>{secondLastError}</p>
                  )}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="PhoneCode">
                    <span>
                      <img src={require("../assets/images/Flag.png")} />{" "}
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      value={secondPhone}
                      name="secondPhone"
                      onChange={secondHandleChange}
                      placeholder="Phone number"
                    />
                    <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                  </div>
                  {secondPhoneError && (
                    <p style={{ color: "red" }}>{secondPhoneError}</p>
                  )}
                </div>
                <div class="form-group">
                  <input
                    type={password1Shown?"text":"password"}
                    value={secondPassword}
                    name="secondPassword"
                    onChange={secondHandleChange}
                    class="form-control"
                    placeholder="Password"
                  />
                  <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                  {/* <span class="Icon">
                    <i class="fa fa-eye"></i>
                  </span> */}
                  <span onClick={toggle1PasswordVisiblity} className={password1Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                  {secondPasswordError && (
                    <p style={{ color: "red" }}>{secondPasswordError}</p>
                  )}

                  <div class="PasswordVerifiy">
                    {/* <article>

                      <span style={{ backgroundColor: "#009512" }}></span>
                    </article>
                    <aside>
                      <span>Your password is great. Nice work!</span>
                      <span>Strong</span>
                    </aside> */}
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type={password2Shown?"text":"password"}
                    class="form-control"
                    value={secondConfirm}
                    name="secondConfirm"
                    onChange={secondHandleChange}
                    placeholder="Confirm Password"
                  />
                  <span className="starIcon"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                  <span onClick={toggle2PasswordVisiblity} className={password2Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                  {secondConfirmError && (
                    <p style={{ color: "red" }}>{secondConfirmError}</p>
                  )}
                </div>

                <div class="ContinueButton">
                  <button  type="button" onClick={secondHandleSubmit}>
                    Continue
                  </button>

                  <label class="Checkbox">
                    By clicking Create account, I agree that I have read and
                    accepted the 
                    {/* <a href="javascript:void(0)">Terms of Use</a>{" "}
                    and <a href="javascript:void(0)">Privacy Policy</a>. */}
                     <a href="#/termsAndConditon" target="_blank" >Terms & conditions</a> and{" "}
                  <a  href="#/termsAndConditon" target="_blank" >Privacy Policy</a>
                    <input value={terms} name="terms" onChange={(e)=> secondUpdateState({...secondState, terms: e.target.checked})} type="checkbox" />
                    {termsError && (
                    <p style={{ color: "red" }}>{termsError}</p>
                  )}
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="OrBox">
                  <p>
                    <span>or</span>
                  </p>
                </div>

                <div class="Already">
                  <aside>
                    <a class="Google" onClick={handleGoogleSignup}>
                      <i class="fa fa-google"></i>
                      Sign up with Google
                    </a>
                    <a class="Facebook" onClick={handleFacebookRegister}>
                      <i class="fa fa-facebook"></i>
                    </a>
                  </aside>
                  <p>
                    Already have a CareerDekho account?{" "}
                    <a onClick={handleSignInShow}>Sign in</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>


   {/* SIGN UP OTP MODAL */}
      <Modal show={signOtpModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleSignOtpClose}>&times;</a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>OTP Verification</h3>
              <p>
                Please enter 6 digit OTP  Verification code recieved on your
                email address.
              </p>
              <div class="form-group">
                <div class="OTPBox">
                <input
                      value={otpState.dig1}
                      name="dig1"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="1" maxLength="1" onKeyUp={e =>inputfocus(e)}

 
                    />
                    <input
                      value={otpState.dig2}
                      name="dig2"
                      onChange={otpHandleChange}
                      type="text"
                      tabIndex="2" maxLength="1" onKeyUp={e =>inputfocus(e)}
 
                      class="form-control"
                    />
                    <input
                      value={otpState.dig3}
                      name="dig3"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="3" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    />
                    <input
                      value={otpState.dig4}
                      name="dig4"
                      tabIndex="4" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                    />
                    <input
                      value={otpState.dig5}
                      name="dig5"
                      onChange={otpHandleChange}
                      type="text"

                      tabIndex="5" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      class="form-control"
                    />
                    <input
                      value={otpState.dig6}
                      name="dig6"
                      tabIndex="6" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                    />
                </div>
              </div>

              <div class="form-group">
                <div class="Resend">
                  {/* <p>1:00</p> */}
                  <h6>
                    <a onClick={handleResendOtp}>Resend</a>
                  </h6>
                </div>
              </div>

              <div class="OrBox">
                <button onClick={handleLoginOtp}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* FORGET MODAL */}
      <Modal show={forgetModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleSetForgetClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>Forgot password?</h3>
              {/* <p>
                No worriest! Just enter your mobile number and we’ll send you a
                OTP to confirm your account.
              </p> */}
              <form>
                
                <div class="form-group">
                  <div class="PhoneCode">
                    <input
                    type={password1Shown?"text":"password"}
                    value={forgetPassword}
                      name="forgetPassword"
                      onChange={forgetHandleChange}
                      class="form-control"
                      placeholder="New password"
                    />
                                                         <span onClick={toggle1PasswordVisiblity} className={password1Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                  </div>
                  {forgetPasswordError && (
                      <p style={{ color: "red" }}>{forgetPasswordError}</p>
                    )}
                </div>
                <div class="form-group">
                  <div class="PhoneCode">
                    <input
                    type={password2Shown?"text":"password"}
                    value={forgetConfirmPassword}
                      name="forgetConfirmPassword"
                      onChange={forgetHandleChange}
                      class="form-control"
                      placeholder="Confirm Password"
                    />
                                     <span onClick={toggle2PasswordVisiblity} className={password2Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                  </div>
                  {forgetConfirmPasswordError && (
                      <p style={{ color: "red" }}>{forgetConfirmPasswordError}</p>
                    )}
                </div>
                
               

                <div class="form-group">
                  <div class="FormButton">
                    <button class="w-100" onClick={handleForgetSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>

              <div class="Already text-center">
                <br />
                <br />
                <br />
                <br />
                <p>
                  Just remember? <a onClick={handleSignInShow}>Sign in</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>



      {/* VERIFY  MODAL */}
      <Modal show={VerifyForgetModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleSetVerifyForgetClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                {/* <img src={require("../assets/images/Modal-3.png")} /> */}
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>

          <div class="LoginRight">
            <div class="LoginBox">
              <h3>OTP Verification</h3>
              <p>
                Please enter 6 digit OTP Verification code recieved on your
                email address.
              </p>
              <form>
                <div class="form-group">
                  <div class="OTPBox">
                  <input
                      value={otpState.dig1}
                      name="dig1"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      ref={(input) => {input && !otpState.dig1 && input.focus() }} 

 
                      maxlength="1"
                    />
                    <input
                      value={otpState.dig2}
                      name="dig2"
                      onChange={otpHandleChange}
                      type="text"
                      ref={(input) => {input && otpState && !otpState.dig2&& input.focus() }} 
 
                      class="form-control"
                      maxlength="1"
                    />
                    <input
                      value={otpState.dig3}
                      name="dig3"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      maxlength="1"
                      ref={(input) => {input && otpState && otpState.dig2&& !otpState.dig3 && input.focus() }} 

                    />
                    <input
                      value={otpState.dig4}
                      name="dig4"
                      ref={(input) => {input && otpState && otpState.dig2&& otpState.dig3 && !otpState.dig4 && input.focus() }} 

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      maxlength="1"
                    />
                    <input
                      value={otpState.dig5}
                      name="dig5"
                      onChange={otpHandleChange}
                      type="text"

                      ref={(input) => {input && otpState && otpState.dig2&& otpState.dig3 && otpState.dig4 && !otpState.dig5 && input.focus() }} 

                      class="form-control"
                      maxlength="1"
                    />
                    <input
                      value={otpState.dig6}
                      name="dig6"
                      ref={(input) => {input && otpState && otpState.dig2&& otpState.dig3 && otpState.dig4  && otpState.dig5  && !otpState.dig6 && input.focus() }} 

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      maxlength="1"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="Resend">
                    {/* <p>1:00</p> */}
                    <h6>
                      <a onClick={resendForgetOtp}>Resend</a>
                    </h6>
                  </div>
                </div>

                <div class="OrBox">
                  <button type="submit" disabled={!otpState.dig6? true:false} onClick={handleVerifyForgetSumbmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>







{/* FORGET PASSWORD SEND EMAL ON OTP */}

      <Modal show={sendOtpModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleSendOTPModalClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>Forgot password?</h3>
              <p>
                No worries! Just enter your email and we’ll send you a
                OTP to confirm your account.
              </p>
              <form>
                <div class="form-group">
                  <div class="PhoneCode">
                    <input
                      type="email"
                      value={forgetOTPEmail}
                      onChange={(e)=> setForgetOTPEmail(e.target.value)}
                      class="form-control"
                      placeholder="Email"
                    />
                   
                  </div>
                  {/* {!forgetOTPEmail && isValid && ( */}
                      <span style={{ color: "red" }}>{errors?.forgetEmailError}</span>
                    {/* )} */}
                </div>
                {/* <div class="form-group">
                  <div class="PhoneCode">
                    <input
                      type="password"
                      value={forgetPassword}
                      name="forgetPassword"
                      onChange={forgetHandleChange}
                      class="form-control"
                      placeholder="new password"
                    />
                    {forgetPasswordError && (
                      <p style={{ color: "red" }}>{forgetPasswordError}</p>
                    )}
                  </div>
                </div> */}

                <div class="form-group">
                  <div class="FormButton">
                    <button  class="w-100" onClick={(e)=>sendEmailForOTP(e)}>
                      Send OTP
                    </button>
                  </div>
                </div>
              </form>

              <div class="Already text-center">
                <br />
                <br />
                <br />
                <br />
                <p>
                  Just remember? <a onClick={handleSignInShow}>Sign in</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

{/* FORGET PASSWORD OTP  */}
      <Modal show={OtpModal}>
        <div class="LoginArea">
          <a class="CloseModal"  onClick={handleSetOtpClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’sssssssss most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>OTP Verification</h3>
              <p>
                Please enter 6 digit OTP Verification code recieved on your
                email address.
              </p>
              <form>
                <div class="form-group">
                  <div class="OTPBox">
                  

                    <input
                      value={otpState.dig1}
                      name="dig1"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="1" maxLength="1" onKeyUp={e =>inputfocus(e)}

 
                    />
                    <input
                      value={otpState.dig2}
                      name="dig2"
                      onChange={otpHandleChange}
                      type="text"
                      tabIndex="2" maxLength="1" onKeyUp={e =>inputfocus(e)}
 
                      class="form-control"
                    />
                    <input
                      value={otpState.dig3}
                      name="dig3"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="3" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    />
                    <input
                      value={otpState.dig4}
                      name="dig4"
                      tabIndex="4" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                    />
                    <input
                      value={otpState.dig5}
                      name="dig5"
                      onChange={otpHandleChange}
                      type="text"

                      tabIndex="5" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      class="form-control"
                    />
                    <input
                      value={otpState.dig6}
                      name="dig6"

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="6" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    />
                  </div>
                </div>
                {isValid && <p style={{color :"red"}}>*Please enter otp</p>}


                <div class="form-group">
                  <div class="Resend">
                    {/* <p>1:00</p> */}
                    <h6>
                      <a onClick={resendForgetOtp}>Resend</a>
                    </h6>
                  </div>
                </div>
              </form>

              <div class="OrBox">
                <button onClick={handleForgetOTPVerify}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={signupOtpModal}>
        <div class="LoginArea">
          <a class="CloseModal"  onClick={handleSetSignupOtpClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              <img src={require("../assets/images/LogoModal.png")} />{" "}
            </span>
            <div>
            <figure>
                <img src={Logo} />
              </figure>
              <figcaption>
              <h3>Career Dekho</h3>
                <p>
                Welcome to India’s most trusted and largest career guidance portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>OTP Verification</h3>
              <p>
                Please enter 6 digit OTP Verification code recieved on your
                email address.
              </p>
              <form>
                <div class="form-group">
                  <div class="OTPBox">
                  

                    <input
                      value={otpState.dig1}
                      name="dig1"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="1" maxLength="1" onKeyUp={e =>inputfocus(e)}

 
                    />
                    <input
                      value={otpState.dig2}
                      name="dig2"
                      onChange={otpHandleChange}
                      type="text"
                      tabIndex="2" maxLength="1" onKeyUp={e =>inputfocus(e)}
 
                      class="form-control"
                    />
                    <input
                      value={otpState.dig3}
                      name="dig3"
                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="3" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    />
                    <input
                      value={otpState.dig4}
                      name="dig4"
                      tabIndex="4" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                    />
                    <input
                      value={otpState.dig5}
                      name="dig5"
                      onChange={otpHandleChange}
                      type="text"

                      tabIndex="5" maxLength="1" onKeyUp={e =>inputfocus(e)}

                      class="form-control"
                    />
                    <input
                      value={otpState.dig6}
                      name="dig6"

                      onChange={otpHandleChange}
                      type="text"
                      class="form-control"
                      tabIndex="6" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    />
                  </div>
                  {isValid && !otpState?.dig6  && <p style={{color:"red"}}>Please enter OTP</p> }

                </div>

                <div class="form-group">
                  <div class="Resend">
                    {/* <p>1:00</p> */}
                    <h6>
                      <a onClick={handleSignUpResendOtp}>Resend</a>
                    </h6>
                  </div>
                </div>
              </form>

              <div class="OrBox">
                <button onClick={handleSignupVerify}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <div className="ModalBox">
      <div class="modal fade" id="AdvertisementModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="LoginArea">
                            <a class="CloseModal" data-dismiss="modal">&times;</a>
                            <a href={advertisementImageLink} target="_blank"><img src={advertisementImage} /></a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> 
      </div>

    </header>
  );
};

export default Header;






