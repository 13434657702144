import { staticManagementConstant } from "../actionType";

const initialState = {
  contactusData: {},
  educationLoanData: {},
  termsData: {},
  privacyData: {},
  aboutusData: {},
  addLoanData:{},
  loader: false,
  error: {},
  ratingStaticData:{},
};



export default function staticManagementReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case staticManagementConstant.GET_STATIC_CONTACT_US_INITIATE:
      return { ...state, loader: true, contactusData: {} };
    case staticManagementConstant.GET_STATIC_CONTACT_US_SUCCESS:
      return { ...state, loader: false, contactusData: payload.data };
    case staticManagementConstant.GET_STATIC_CONTACT_US_FAILURE:
      return { ...state, error: payload };

   
    case staticManagementConstant.GET_STATIC_EDUCATION_LOAN_INITIATE:
      return { ...state, loader: true, educationLoanData:{} };
    case staticManagementConstant.GET_STATIC_EDUCATION_LOAN_SUCCESS:
      return { ...state, loader: false, educationLoanData: payload.data };

    case staticManagementConstant.GET_STATIC_EDUCATION_LOAN_FAILURE:
      return { ...state, error: payload };

   
    case staticManagementConstant.GET_STATIC_TERM_CONDITION_INITIATE:
      return { ...state, loader: true, termsData: {} };
    case staticManagementConstant.GET_STATIC_TERM_CONDITION_SUCCESS:
      return { ...state, loader: false, termsData: payload.data };

    case staticManagementConstant.GET_STATIC_TERM_CONDITION_FAILURE:
      return { ...state, error: payload };

   
    case staticManagementConstant.GET_STATIC_PRIVACY_POLICY_INITIATE:
      return { ...state, loader: true, privacyData: {} };
    case staticManagementConstant.GET_STATIC_PRIVACY_POLICY_SUCCESS:
      return { ...state, loader: false, privacyData: payload.data };

    case staticManagementConstant.GET_STATIC_PRIVACY_POLICY_FAILURE:
      return { ...state, error: payload };


    case staticManagementConstant.GET_STATIC_ABOUT_US_INITIATE:
      return { ...state, loader: true, aboutusData: {} };
    case staticManagementConstant.GET_STATIC_ABOUT_US_SUCCESS:
      return { ...state, loader: false, aboutusData: payload.data };

    case staticManagementConstant.GET_STATIC_ABOUT_US_FAILURE:
      return { ...state, error: payload };


      case staticManagementConstant.ADD_LOAN_ENQUIRY_INITIATE:
        return { ...state, loader: true, addLoanData: {} };
      case staticManagementConstant.ADD_LOAN_ENQUIRY_SUCCESS:
        return { ...state, loader: false, addLoanData: payload.data };
  
      case staticManagementConstant.ADD_LOAN_ENQUIRY_FAILURE:
        return { ...state, error: payload };

        case staticManagementConstant.GET_RATING_STATIC_INITIATE:
        return { ...state, loader: true, ratingStaticData: {} };
      case staticManagementConstant.GET_RATING_STATIC_SUCCESS:
        return { ...state, loader: false, ratingStaticData: payload.data };
  
      case staticManagementConstant.GET_RATING_STATIC_FAILURE:
        return { ...state, error: payload, ratingStaticData:payload };


    default:
      return state;
  }
}
