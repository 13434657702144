import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";


const CompareSelection = () => {

    return (
        <div>
            <Header />
            <section>
                <div class="SearchArea">
                    <div class="SearchBox">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="State, City" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Category" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Stream" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Sub Stream" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Courses / Degree" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Exam Accepted" />
                        </div>
                        <button>Search</button>
                    </div>
                </div>
            </section>

            <section>
                <div class="UniversityArea">
                    <div class="container">
                        <div class="UniversitySort">
                            <aside>
                                <span>Sort -</span>
                                <select>
                                    <option>Top Rated First</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                            </aside>
                        </div>

                        <div class="row">
                            <div class="col-sm-3 padding_none">
                                <div class="FilterArea">
                                    <div class="FilterHead">
                                        <h3>Filters</h3>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Study Mode</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        Distance Learning (4 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Regular (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Online (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Fees Range</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        1 lakh to 2 lakhs
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        2 to 3 Lakhs
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        3 to 4 Lakhs
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        4 to 5 Lakhs
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        5 to 6 Lakhs
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Courses</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <div class="Search">
                                                        <span><i class="fa fa-search"></i></span>
                                                        <input type="text" placeholder="Search" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        BTech (40 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        BE (40 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        BCA (40 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        MCA (40 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        MTech (40 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <p><a href="javascript:void(0);">+ View more</a></p>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Facilities</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        Fully AC (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Play ground (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Scholarships Available ( 0 )
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Swimming Pool (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Meal Services (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <p><a href="javascript:void(0);">+ View more</a></p>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Stream</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 1 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 2 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 3 (0 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 4 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 5 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <p><a href="javascript:void(0);">+ View more</a></p>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Courses / Degree</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 1 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 2 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 3 (0 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 4 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 5 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <p><a href="javascript:void(0);">+ View more</a></p>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                    <div class="FilterBox">
                                        <article>
                                            <h3>Exam Accepted</h3>
                                            <a href="javascript:void(0);">Clear all</a>
                                        </article>
                                        <aside>
                                            <ul>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 1 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 2 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 3 (0 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 4 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label class="Checkbox">
                                                        Category 5 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <p><a href="javascript:void(0);">+ View more</a></p>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-9">
                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-1.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-2.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-3.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-4.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-5.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-1.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-2.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-3.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-4.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="UniversityBox">
                                    <div class="UniversityLeft">
                                        <img src={require('../assets/images/University-5.png')} />
                                    </div>
                                    <div class="UniversityRight">
                                        <div class="UniversityName">
                                            <aside>
                                                <h3>ABC International</h3>
                                                <ul>
                                                    <li><img src={require('../assets/images/Location.png')} /> Dwarka, Sec-4, Delhi</li>
                                                    <li>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <span>(12k)</span>
                                                    </li>
                                                </ul>
                                            </aside>
                                            <aside>
                                                <a class="WishIcon" href="javascript:void(0);"><i class="fa fa-heart-o"></i> Add to Wishlist</a>
                                            </aside>
                                        </div>
                                        <div class="UniversityRank">
                                            <h6>Ranking :</h6>
                                            <ul>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                    <label>CD : <strong>8/10</strong></label>
                                                </li>
                                                <li>
                                                    <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                    <label>Outlook : <strong>8/10</strong></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="UniversityType">
                                            <Link to="/compare" class="Select">Select</Link>
                                            <h6>Type :</h6>
                                            <p>Private</p>
                                        </div>
                                        <div class="UniversityFee">
                                            <p>
                                                <span><img src={require('../assets/images/Book.png')} /></span> Regular | Online
                                            </p>
                                            <p><strong>Fees Range : </strong> 1 lakh to 2 Lakhs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
}

export default CompareSelection