import React from 'react'

const NoDataFound = () => {
  return (
    <div className='NoDataFound'>
        <h2>No Data found !</h2>
    </div>
  )
}

export default NoDataFound