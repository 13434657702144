import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";

import {BlogListAction} from "../redux/actions/blogAction";



const Blog = () => {
    
    const dispatch= useDispatch();
    const {blogList} = useSelector((state)=> state.blogReducer)


    useEffect(()=>
    {
        dispatch(BlogListAction())
 
    },[])

    console.log("#############",blogList)


    return (
        <div>
            <Header />
            <section>
                <div class="SearchArea NewsBreadcumb">
                    <div class="container">
                        <h3>Blog</h3>
                        <p>Home / Blog</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="NewsArea">
                    <div class="container">
                        <div class="MainHeading">
                            <figure><img src={require('../assets/images/blogIcon.png')} /></figure>
                            <p>What's Blog</p>
                            <h2>Get Update From Our Blogs</h2>
                        </div>

                        <div class="row">
                            {blogList?.result?.map((item)=>
                             <div class="col-sm-4">
                             <div class="NewsBox">
                                 <figure>
                                     <img src={item?.image} />
                                 </figure>
                                 <figcaption>
                                     <h6><span>{moment(item?.createdAt).format('ll')}</span></h6>
                                     <h3><Link to="/blog-details" state={item}>{item?.title}</Link></h3>
                                     <p> <div dangerouslySetInnerHTML={{__html: item?.description}} className='editor'></div> </p>
                                     <p class="readMore"><Link to="/blog-details" state={item}>Read more</Link></p>
                                 </figcaption>
                             </div>
                         </div>
                             )}
                           
                            
                        </div>

                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
}

export default Blog