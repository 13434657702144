import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { applyJobAction } from "../redux/actions/jobAction";
import S3FileUpload from "react-s3/lib/ReactS3";
import { toast } from "react-toastify";

const init = {
  studentId: "",
  jobId: "",
  applicantName: "",
  mobileNumber: "",
  email: "",
  address: "",
  availableFaceToFace: "",
  readyToRelocate: "",
  highestQualification: "",
  instituteName: "",
  completionDate: "",
  remarks: "",
  resume: "",
  companyName: "",
  position: "",
  lastWorkingDay: "",
  leavingReason: "",
  errors:{}
};
const JoinApply = () => {
  const [iState, updateState] = useState(init);
  const studentId = JSON.parse(localStorage.getItem("websiteData"))?._id
  const[mobileNumber,setMobileNumber] = useState()
  const {
    // studentId,
    jobId,
    applicantName,
    // mobileNumber,
    email,
    address,
    availableFaceToFace,
    readyToRelocate,
    completionDate,
    highestQualification,
    instituteName,
    remarks,
    resume,
    companyName,
    position,
    lastWorkingDay,
    leavingReason,
    errors,
  } = iState;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();
  // console.log("++++++++>" , state?.ele?._id)

  useEffect(() => {

  },[])


  
const handleInputPhone = (event) => {
    const result = event.target.value.replace(/\D/s, "");
    setMobileNumber(result);
  };

  const handleInput = (e) =>{
    const {name,value} = e.target;
    updateState({...iState, [name]:value})
  }

  const config = {
    bucketName: "careerdekhoo",
    dirName: "website",
    region: "ap-south-1",
    accessKeyId: "AKIAVOWC6CG6JOZ2Q32M",
    secretAccessKey: "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",
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleInputResume = (e) => {
    const file = e.target.files[0];
    // console.log("fileee", file.type);
    if (file) {
      if (file.type === "application/pdf") {
        S3FileUpload.uploadFile(file, config)
          .then((data) => {
            console.log("data===============================>", data.location);

            updateState({ ...iState, resume: data.location });

            // setImages(oldArray => [...oldArray,{image: data.location} ])
          })

          .catch((err) => console.log(err));
      } else {
        toast.error("Only pdf file are allowed ", {
          university: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleInputChecked = (e, type) => {
    const {name,value,checked} = e.target
    updateState({ ...iState, [name]:checked });

  }

//   console.log(availableFaceToFace, readyToRelocate)

  const handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if(!applicantName){
        error.applicantNameError = "Applicant name can't be empty";
        formIsValid = false
    }
    if(!address){
        error.addressError = "Address can't be empty";
        formIsValid = false
    }
    if(!mobileNumber){
        error.mobileNumberError = "Moblie number can't be empty";
        formIsValid = false
    }

    if (mobileNumber) {
        if (!/^[1-9][0-9]{9}$/.test(mobileNumber)) {
          error.mobileNumberError = "Please enter a valid phone number";
          formIsValid = false;
        }
      }
    if(!email){
        error.emailError = "Email can't be empty";
        formIsValid = false
    }
    if (email) {
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
          )
        ) {
          error.emailError = "Please enter a valid email";
          formIsValid = false;
        }
      }

    if(!highestQualification){
        error.highestQualificationError = "Highest qualification can't be empty";
        formIsValid = false
    }
    if(!instituteName){
        error.instituteNameError = "Institute name can't be empty";
        formIsValid = false
    }
    if(!remarks){
        error.remarksError = "Remarks can't be empty";
        formIsValid = false
    }



    if(!resume){
        error.resumeError = "Resume can't be empty";
        formIsValid = false
    }
    if(!companyName){
        error.companyNameError = "Company name can't be empty";
        formIsValid = false
    }
    if(!position){
        error.positionError = "Position can't be empty";
        formIsValid = false
    }
    if(!lastWorkingDay){
        error.lastWorkingDayError = "Last working day can't be empty";
        formIsValid = false
    }
    if(!leavingReason){
        error.leavingReasonError = "Leaving reason can't be empty";
        formIsValid = false
    }
    if(!completionDate){
        error.completionDateError = "Completion date be can't be empty";
        formIsValid = false
    }
    
    updateState({...iState, errors:error});
    return formIsValid
   
  }


  const applyCourseFun = () => {
    let formIsValid = handleValidation();
    if(studentId){
        if(formIsValid){
            let data = {
                studentId,
                jobId : state?.ele?._id,
                applicantName,
                mobileNumber,
                email,
                address,
                availableFaceToFace,  
                readyToRelocate,
                highestQualification,
                instituteName,
                remarks,
                resume,
                companyName,
                position,
                lastWorkingDay,
                leavingReason,  
                completionDate
            }
            console.log("data", data)
            dispatch(applyJobAction(data)).then((res) => {
              if(res?.status === 200){
                toast.success(res.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate('/join-us')
      
              }
                console.log("res=============>", res)
            }).catch((err) => {
                console.log("err==========>", err)
            })
        }
    }else {
        // toast.info("Please login first")
        updateState({...iState, errors:{}})
    }
   
   
  }
  console.log(state,"@@@@@@@@@@@@@@@@")

  return (
    <div>
      <Header />

      <section>
        <div
          class="SearchArea NewsBreadcumb"
          style={{
            backgroundImage:
              "url(https://mobulous.co.in/CareerDekhoo/Website/images/JobBanner.png)",
          }}
        >
          <div class="container">
            <h3>{state.ele.type}</h3>
            <p>Job Id: {state.ele.jobId}</p>
          </div>
        </div>
      </section>

      <section>
        <div class="OpeningArea">
          <div class="container">
            <div class="OpeningBody">
              <h3>Apply Now</h3>
              <div class="OpeningFilter">
                {/* <!-- <form> --> */}
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Applicant Name</label>
                      <input type="text" class="form-control" placeholder="Please enter your name..."name="applicantName" value={applicantName} onChange ={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.applicantNameError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Mobile Number</label>
                      <input type="text" class="form-control" placeholder="Mobile Number..." name="mobileNumber" value={mobileNumber} onChange={handleInputPhone} maxLength={13} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.mobileNumberError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email Address</label>
                      <input type="text" class="form-control" placeholder="Email..." name="email" value={email} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.emailError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Current Address</label>
                      <input type="text" class="form-control" placeholder="Address..." name="address" value={address} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.addressError}</span>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="Checkbox">
                            Avaiable for Face to Face
                            <input type="checkbox" name="availableFaceToFace"  onChange={(e) => handleInputChecked(e,"availableFaceToFace")} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="Checkbox">
                            Ready to Relocate
                            <input type="checkbox" name="readyToRelocate"  onChange={(e) => handleInputChecked(e,"readyToRelocate")} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <h4>Qualification</h4>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Highest Qualification</label>
                      <input type="text" class="form-control" placeholder="Highest qualification..." name="highestQualification" value={highestQualification} onChange={handleInput}/>
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.highestQualificationError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Name of Institute</label>
                      <input type="text" class="form-control" placeholder="Institute name..." name="instituteName" value={instituteName} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.instituteNameError}</span>
                    </div> 
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Date of Completion</label>
                      <input type="date" class="form-control" placeholder="" name="completionDate" value={completionDate} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.completionDateError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Remarks</label>
                      <input type="text" class="form-control" placeholder="Remarks..." name="remarks" value={remarks} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.remarksError}</span>
                    </div>
                  </div>
                  {/* {state?.openingFor =="" ? <></> : <></>} */}
                  <div class="col-sm-12">
                    <div class="form-group">
                      <h4>Most Recent Employment</h4>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input type="text" class="form-control" placeholder="CompanyName..." name="companyName" value={companyName} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.companyNameError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Position Holding</label>
                      <input type="text" class="form-control" placeholder="Position..." name="position" value={position} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.positionError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Last Working Day</label>
                      <input type="text" class="form-control" placeholder="Last working day..." name="lastWorkingDay" value={lastWorkingDay} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.lastWorkingDayError}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Reason of Leaving</label>
                      <input type="text" class="form-control" placeholder="Leaving reason..." name="leavingReason" value={leavingReason} onChange={handleInput} />
                      <span style={{fontSize:"14px", color:"red"}}>{errors?.leavingReasonError}</span>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <div class="Upload">
                        <h6>+ Upload Resume</h6>
                        <p>{resume?<>{(resume?.split("/")[4]).split(".")[0]}</> : <></> }</p>
                        <input type="file"name="resume"  onChange={handleInputResume}  />
                        <span style={{fontSize:"14px", color:"red"}}>{errors?.resumeError}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 offset-sm-6">
                    <div class="form-group">
                      <button onClick={applyCourseFun}
                    //    data-toggle="modal" data-target="#ThankYouModal"
                       >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- </form> --> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default JoinApply;
