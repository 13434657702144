import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import {
  courseDetailsAction,
  courseDropdownAction,
  courseListAction,
} from "../redux/actions/coachingAndCertificatesAction";
import { createEntitiesAction } from "../redux/actions/enquiryAction";
import { toast } from "react-toastify";
import Logo from "../assets/images/logo.png";
import { Carousel, Modal } from "react-bootstrap";
import { getAddsListAction } from "../redux/actions/getAddsActions";
import StarRatings from "react-star-ratings";
const init = {
  collegeId: "",
  firstName: "",
  lastName: "",
  email: "",
  streamName: "",
  courses: "",
  allRemark: "",
  errors: {},
  courseModal: false,
  thankuModal: false,
  allPhoneNumber: "",
  courseDetailsData: [],
};

const CourseDetails = () => {
  const [iState, updateState] = useState(init);
  const { courseDropdownData, streamData, courseListData, getAddsListData } =
    useSelector((state) => state.listReducer);
  const [ShowSignInModal, setShowSignInModal] = useState(false);
  const [addsMappingData, setAddsMappingData] = useState([]);
  const [allPhoneNumber, setAllPhoneNumber] = useState();
  const studentId = JSON.parse(localStorage.getItem("websiteData"))?._id;

  const {
    collegeId,
    firstName,
    errors,
    // allPhoneNumber,
    lastName,
    email,
    allRemark,
    streamName,
    courses,
    courseModal,
    thankuModal,
    courseDetailsData,
  } = iState;
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { state } = useLocation();

  console.log("state",state)

  useEffect(() => {
    dispatch(getAddsListAction({ category: "Course Details Page" }));
    dispatch(courseDropdownAction());
    dispatch(courseListAction());
    dispatch(courseDetailsAction({ _id: state?.ele?._id }))
      .then((res) => {
        console.log("res------->", res);
        updateState({ ...iState, courseDetailsData: res?.data });
      })

      .catch((err) => {
        console.log("err=====>", err);
      });
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    updateState({
      ...iState,
      courseModal: false,
      name: "",
      email: "",
      streamName: "",
      courses: "",
      allPhoneNumber: "",
      allRemark: "",
      errors: {},
    });
  };

  const handleShowModal = () => {
    const studentId = JSON.parse(localStorage.getItem("websiteData"))?._id;
    if (studentId) {
      updateState({ ...iState, courseModal: true });
    } else {
      setShowSignInModal(true);
    }
  };
  const handleCloseThankuModal = () => {
    updateState({
      ...iState,
      thankuModal: false,
      name: "",
      email: "",
      streamName: "",
      courses: "",
      allPhoneNumber: "",
      allRemark: "",
      errors: {},
    });
  };
  const handleShowThankuModal = () => {
    updateState({ ...iState, thankuModal: true });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleChangePersonalPhone = (event) => {
    const result = event.target.value.replace(/\D/s, "");
    setAllPhoneNumber(result);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!firstName) {
      error.firstNameError = "First name can't be empty";
      formIsValid = false;
    }
    if (!lastName) {
      error.lastNameError = "Last name can't be empty";
      formIsValid = false;
    }
    if (!streamName) {
      error.streamNameError = "Stream can't be empty";
      formIsValid = false;
    }
    if (!courses) {
      error.coursesError = "Courses can't be empty";
      formIsValid = false;
    }

    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    if (!allPhoneNumber) {
      error.allPhoneNumberError = "*  Mobile number can't be empty";
      formIsValid = false;
    }
    if (allPhoneNumber) {
      if (!/^[1-9][0-9]{9}$/.test(allPhoneNumber)) {
        error.allPhoneNumberError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }
    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const applyCourse = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      let data = {
        firstName,
        lastName,
        email,
        stream: streamName,
        courses,
        allPhoneNumber,
        allRemark,
        collegeId,
        studentId,
      };
      dispatch(createEntitiesAction(data))
        .then((res) => {
          console.log("res", res);
          updateState({
            ...iState,
            courseModal: false,
            thankuModal: true,
            firstName: "",
            lastName: "",
            streamName: "",
            courses: "",
            email: "",
            allRemark: "",
            allPhoneNumber: "",
            errors: {},
          });
          setAllPhoneNumber("");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const getCourseDetails = (id) => {
    dispatch(courseDetailsAction({ _id: id }))
      .then((res) => {
        console.log("res------->", res);
        updateState({ ...iState, courseDetailsData: res?.data });
        window.scrollTo(0, 0);
      })

      .catch((err) => {
        console.log("err=====>", err);
      });
  };

  useEffect(() => {
    if (getAddsListData?.length > 0) {
      if (getAddsListData?.[0]?.image?.length > 0) {
        console.log("insde");
        if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
          // console.log("insde",getAddsListData?.data?.[0]?.image)
          setAddsMappingData(getAddsListData?.[0]?.image);
          // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
        } else {
          setAddsMappingData([
            "https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg",
          ]);
          // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
        }
      }
    }

    console.log(getAddsListData);
  }, [getAddsListData]);
  console.log("state",state)
  return (
    <div>
      <Header
        ShowSignInModal={ShowSignInModal}
        setShowSignInModal={setShowSignInModal}
      />

      <section>
        <div class="CollegeSlider">
          <div id="demo" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={require("../assets/images/SearchBanner.png")}
                  alt=""
                />
              </div>
              <div class="carousel-item">
                <img
                  src={require("../assets/images/SearchBanner.png")}
                  alt=""
                />
              </div>
              <div class="carousel-item">
                <img
                  src={require("../assets/images/SearchBanner.png")}
                  alt=""
                />
              </div>
            </div>
            <ul class="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" class="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
            </ul>
          </div>
          <div class="CollegeContent">
            <div class="container">
              <div class="row">
                <div class="col-sm-9">
                  <div class="CollegeContentLeft">
                    <h3>{courseDetailsData?.name}</h3>
                    <p>
                      {/* <span><img src={require('../assets/images/Location.png')} /></span> {courseDetailsData?.city}, {courseDetailsData?.address} */}
                    </p>
                  </div>
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="VerticalBannerBox VerticalBannerLeft">
        {addsMappingData && addsMappingData?.length > 0 ? (
          <>
            {addsMappingData?.map((ele, i) => {
              if (ele?.position == "Top left") {
                return (
                  <a href={ele?.link} target="_blank">
                    {" "}
                    <img src={ele?.image} alt="Top left" />
                  </a>
                );
              }
            })}
          </>
        ) : (
          <></>
        )}
      </div>
      <section>
        <div class="OverviewArea">
          <div class="container">
            <div class="row">
              <div class="col-sm-8">
                <div class="OverviewLeft">
                

                  <div class="OverviewBox">
                                <div class="FeeRange">
                                    <aside>
                                        <p>Course : <span>{courseDetailsData?.name}</span></p>
                                    </aside>
                                    {/* <aside>
                                        <p>Associated with : <span><a href="javascript:void(0);">B&amp;C Institution Groups</a></span></p>
                                    </aside> */}
                                    <aside>
                                        <p>Reviews : </p>
                                        <h5>
                                        <StarRatings
                            rating={state?.comingdata?.ratingCount}
                            starRatedColor="yellow"
                            // changeRating={this.changeRating}
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="2px"
                            name="rating"
                          />
                          {state?.comingdata?.rating ? <>
                            <span>({state?.comingdata?.rating})</span>
                          </> : <></>}
                        
                                        </h5>
                                    </aside>
                                    <aside>
                                        <h6>Fee Range : <strong> {courseDetailsData?.feesMin} to{" "}
                            {courseDetailsData?.feesMax}</strong></h6>
                                    </aside>
                                </div>
                            </div>

                  <div class="OverviewBox">
                    <h3>Description</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: courseDetailsData?.description,
                      }}
                      className="editor"
                    ></div>
                  </div>

                  <div class="RankingTable">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th>Eligiblity</th>
                          <td>{courseDetailsData?.eligibility}</td>
                        </tr>
                        <tr>
                          <th>Study Mode</th>
                          <td>{courseDetailsData?.studyMode}</td>
                        </tr>
                        <tr>
                          <th>Course Type</th>
                          <td>{courseDetailsData?.courseType}</td>
                        </tr>
                        <tr>
                          <th>Tution Fees</th>
                          <td>{courseDetailsData?.tutionFees}</td>
                        </tr>

                        {/* {state?.pageName === "College" ? (
                          <></>
                        ) : (
                          <>
                            <tr>
                              <th>How To Prepare</th>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: courseDetailsData?.howToPrepare,
                                  }}
                                  className="editor"
                                ></div>
                              </td>
                            </tr>
                            <tr>
                              <th>Carrer Options</th>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: courseDetailsData?.careerOptions,
                                  }}
                                  className="editor"
                                ></div>
                              </td>
                            </tr>
                          </>
                        )} */}
                        {/* <tr>
                                                    <th>Accepted Exams</th>
                                                    <td>{courseDetailsData?.description}</td>
                                                </tr> */}
                      </tbody>
                    </table>
                  </div>
                  {state?.pageName === "Coaching" ? <>
                  <div class="OverviewBox">
                    <h3>How To Prepare</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: courseDetailsData?.howToPrepare,
                      }}
                      className="editor"
                    ></div>
                  </div>
                  </> : <></>
}
{state?.pageName === "Coaching" ? <>
                  <div class="OverviewBox">
                    <h3>Career Options</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: courseDetailsData?.careerOptions,
                      }}
                      className="editor"
                    ></div>
                  </div>
                  </> : <></>
}
                
                  {state?.pageName === "College" ? (
                    <>
                      {courseDetailsData?.feeStructure &&
                      courseDetailsData?.feeStructure?.length > 0 ? (
                        <>
                          {courseDetailsData?.feeStructure?.map((ele, i) => {
                            return (
                              <>
                                <div class="OverviewBox">
                                  <div class="CoursesTable FeesTable">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th>
                                            Semester - {ele?.semester}{" "}
                                            <strong>
                                              Tution Fee: {ele?.fees} INR
                                            </strong>
                                          </th>
                                          <th>Credits</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ele?.subjects?.map((ele, i) => (
                                          <tr>
                                            <td>{ele?.name}</td>
                                            <td>{ele?.credits}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="col-sm-4">
                <div class="OverviewRight">
                  <div class="BrochureBox">
                    {/* <button class="Apply" onClick={handleShowModal}>Apply</button> */}
                  </div>

                  <div class="FacultiesBox CoursesBox">
                    <h3>Similar Courses</h3>
                    <ul>
                      {courseListData?.data &&
                      courseListData?.data?.length > 0 ? (
                        <>
                          {courseListData?.data?.map((ele, i) => {
                            return (
                              <>
                                <li>
                                  {/* <figure>
                                    <img
                                      src={require("../assets/images/Courses-1.png")}
                                    />
                                  </figure> */}
                                  <figcaption>
                                    <h5
                                      onClick={() => getCourseDetails(ele?._id)}
                                    >
                                      {ele?.name}
                                    </h5>
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            courseListData?.data?.[i]
                                              ?.description,
                                        }}
                                        className="editor"
                                      ></div>
                                    </p>
                                  </figcaption>
                                </li>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>

                  <div class="VideoBox">
                    {addsMappingData && addsMappingData?.length > 0 ? (
                      <>
                        {addsMappingData?.map((ele, i) => {
                          if (ele?.position == "Similar courses bottom") {
                            return (
                              <a href={ele?.link} target="_blank">
                                {" "}
                                <img
                                  src={ele?.image}
                                  alt="Similar courses bottom"
                                />
                              </a>
                            );
                          }
                          if (ele?.position == "Courses fees right") {
                            return (
                              <a href={ele?.link} target="_blank">
                                {" "}
                                <img
                                  src={ele?.image}
                                  alt="Courses fees right"
                                />
                              </a>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
 



                </div>
              </div>
            </div>

            <div class="AdvertisementBox">
              {addsMappingData && addsMappingData?.length > 0 ? (
                <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Footer top") {
                      return (
                        <a href={ele?.link} target="_blank">
                          {" "}
                          <img src={ele?.image} alt=" faq top" />
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Modal show={thankuModal}>
        <div class="LoginArea">
          <a
            onClick={handleCloseThankuModal}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="ThankBox">
            <div>
              <figure>
                <img src={require("../assets/images/Apply-Thank.png")} />
              </figure>
              <h3>Thank you</h3>
              {/*  <p>
                 {`Thank You for applying
                //  with 
                // ${(name)}!
Your application has been submitted successfully. 
We will be in touch and contact you soon.`}
                
              </p> */}
              <a
                href="javascript:void(0);"
                onClick={handleCloseThankuModal}
                data-dismiss="modal"
              >
                Great
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={courseModal}>
        <div class="LoginArea">
          <a onClick={handleCloseModal} class="CloseModal" data-dismiss="modal">
            ×
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <h2>
                {`Thanks for contacting , Please fill below
                        details to Apply.`}
              </h2>

              <figure>
                <img src={Logo} />
                {/* <img src={require("../assets/images/ApplyCourse.png")} /> */}
              </figure>
              <figcaption>
                <h3>Career Dekho</h3>
                <p>
                  Welcome to India’s most trusted and largest career guidance
                  portal
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <h3>Apply for a Course</h3>
              <form onSubmit={applyCourse}>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="First name"
                        name="firstName"
                        value={firstName}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.firstNameError}
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Last name"
                        name="lastName"
                        value={lastName}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.lastNameError}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email address (Optional)"
                    name="email"
                    value={email}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.emailError}
                  </span>
                </div>

                <div class="form-group">
                  <div class="PhoneCode">
                    <span>
                      <img src={require("../assets/images/Flag.png")} />{" "}
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone number"
                      name="allPhoneNumber"
                      value={allPhoneNumber}
                      onChange={handleChangePersonalPhone}
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.allPhoneNumberError}
                  </span>
                </div>

                <div class="form-group">
                  <select
                    name="streamName"
                    value={streamName}
                    onChange={handleInput}
                    class="form-control"
                  >
                    <option>Select Stream</option>
                    {streamData?.data?.result &&
                    streamData?.data?.result?.length > 0 ? (
                      <>
                        {streamData?.data?.result?.map((ele, i) => {
                          return (
                            <option value={ele?.streamName}>
                              {ele?.streamName}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.streamNameError}
                  </span>
                </div>

                <div class="form-group">
                  <select
                    name="courses"
                    value={courses}
                    onChange={handleInput}
                    class="form-control"
                  >
                    <option>Select Courses</option>
                    {courseDropdownData?.data?.result &&
                    courseDropdownData?.data?.result?.length > 0 ? (
                      <>
                        {courseDropdownData?.data?.result?.map((ele, i) => {
                          return (
                            <option value={ele?.courseName}>
                              {ele?.courseName}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.coursesError}
                  </span>
                </div>

                <div class="form-group">
                  <textarea
                    rows="5"
                    class="form-control"
                    placeholder="Remark (If any)"
                    name="allRemark"
                    value={allRemark}
                    onChange={handleInput}
                  ></textarea>
                </div>

                <div class="ContinueButton">
                  <button>Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CourseDetails;
