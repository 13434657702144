import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { toast } from "react-toastify";

import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from "react-s3";

import {
  studentUpdateAction,
  studentDetailsAction,
} from "../../redux/actions/studentAction";
import { getCityList, getStateList } from "../../redux/actions/jobAction";
import { courseDropdownAction } from "../../redux/actions/coachingAndCertificatesAction";

const initialState = {
  interestedCategories: "",
  interestedCourses: "",
  fullName: "",
  phoneNumber: "",
  email: "",
  gender: "",
  profilePic: "",
  cdUid: "",
  interestedCourses: "",
  highestEducation: "",
  boardOrUniversity: "",
  yearOfCompletion: "",
  cgpaOrPercentile: "",
  subjectOrStream: "",
  otherHighestEducation:"",
  errorCgpaOrPercentileType:"",

  emailError: "",
  errorFullName: "",
  errorPhoneNumber: "",
  errorEmail: "",
  errorGender: "",
  errorCity: "",
  errorProfilePic: "",
  errorCdUid: "",
  errorInterestedCategories: "",
  errorInterestedCourses: "",
  errorHighestEducation: "",
  errorBoardOrUniversity: "",
  errorYearOfCompletion: "",
  errorCgpaOrPercentile: "",
  errorSubjectOrStream: "",
  genderError: "",
  state: "",
  city: "",
  stateIsoCodeName: "",
  errorMsg: "",
  cgpaOrPercentileType:"",
  errorCgpaOrPercentileType:"",
  yearArray:[]
};

const MyProfileEdit = () => {
  const Profie = JSON.parse(localStorage.getItem("websiteData"));
  const id = Profie._id;

  const [iState, updateState] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stateData, cityData } = useSelector((state) => state.listReducer);

  const { courseDropdownData } = useSelector((state) => state?.listReducer);

  const [stateList, setStateList] = useState({ name: "", isoCode: "" });
  const [city, setCity] = useState("");

  // const [interestedCategories, setIntersetedCategories] = useState([]);
  // const [interestedCourses, setIntersetedCourses] = useState([]);

  const {
    state,
    // city,
    genderError,
    interestedCategories,
    interestedCourses,
    errorCgpaOrPercentileType,
    email,
    fullName,
    phoneNumber,
    gender,
    profilePic,
    cdUid,
    stateIsoCodeName,
    highestEducation,
    boardOrUniversity,
    yearOfCompletion,
    cgpaOrPercentile,
    subjectOrStream,
    otherHighestEducation,

    emailError,
    stateError,
    errorFullName,
    errorPhoneNumber,
    errorState,
    errorCity,
    errorGender,
    errorProfilePic,
    errorCdUid,
    errorInterestedCategories,
    errorInterestedCourses,
    errorSubjectOrStream,

    errorHighestEducation,
    errorBoardOrUniversity,
    errorYearOfCompletion,
    errorCgpaOrPercentile,
    errorMsg,
    cgpaOrPercentileType,
    yearArray,
  } = iState;
  const dateDropdown =[] 
  let currentYear = new Date().getFullYear();   
  let earliestYear = 1950;     
  while (currentYear >= earliestYear) {   
    dateDropdown.push(currentYear)   
    currentYear -= 1;    
  }



  let handleValidation = () => {
    let emailError = "";
    let errorSubjectOrStream = "";

    let errorFullName = "";
    let errorPhoneNumber = "";
    let errorEmail = "";
    let errorGender = "";
    let errorProfilePc = "";
    let errorCdUid = "";
    let stateError = "";
    let errorInterestedCategories = "";
    let errorInterestedCourses = "";
    let errorHighestEducation = "";
    let errorBoardOrUniversity = "";
    let errorYearOfCompletion = "";
    let errorCgpaOrPercentile = "";
    let errorState = "";
    let errorCity = "";
    let genderError = "";
   let errorCgpaOrPercentileType = '';

    let formIsValid = true;

    function validatePhoneNumber(input_str) {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      return re.test(input_str);
    }

    if (!email) {
      errorEmail = "* Please enter email ID.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorEmail = "* Email format is not valid";
      formIsValid = false;
    }

    if (!fullName) {
      errorFullName = "* Please enter Name.";
      formIsValid = false;
    }

    if (!phoneNumber) {
      errorPhoneNumber = "* Please enter phone number.";
      formIsValid = false;
    } else if (!validatePhoneNumber) {
      errorPhoneNumber = "* Please enter valid phone number.";
      formIsValid = false;
    }

    // if (!cdUid) {
    //   errorCdUid = "* Please enter CD u-ID.";
    //   formIsValid = false;
    // }

    if (!interestedCategories) {
      errorInterestedCategories = "* Please select interested category.";
      formIsValid = false;
    }
    if (!interestedCourses) {
      errorInterestedCourses = "* Please select interested coursce .";
      formIsValid = false;
    }
    if (!highestEducation) {
      errorHighestEducation = "* Please enter highesht education.";
      formIsValid = false;
    }
    if (!boardOrUniversity) {
      errorBoardOrUniversity = "* Please enter board university.";
      formIsValid = false;
    }

    if (!cgpaOrPercentile) {
      errorCgpaOrPercentile = "* Please select cgpa or percentage.";
      formIsValid = false;
    }
    if (!cgpaOrPercentileType) {
      errorCgpaOrPercentileType = "* Please enter cgpa or percentage value.";
      formIsValid = false;
    }
    else if  (cgpaOrPercentileType === "Percentile" && cgpaOrPercentile > 100 ) {
      errorCgpaOrPercentile = "* Please enter valid percentage.";
      formIsValid = false;
    }
    if  (cgpaOrPercentileType === "CGPA" && cgpaOrPercentile > 10) {
      errorCgpaOrPercentile = "* Please enter valid CGPA.";
      formIsValid = false;
    }



    if (!yearOfCompletion) {
      errorYearOfCompletion = "* Please select year of completion.";
      formIsValid = false;
    }
    if (!subjectOrStream) {
      errorSubjectOrStream = "* Please select subject or stream.";
      formIsValid = false;
    }

    if (!city) {
      errorCity = "* Please select your city.";
      formIsValid = false;
    }

    if (!gender) {
      genderError = "* Please select your gender.";
      formIsValid = false;
    }
    if (!stateList?.name) {
      errorState = "* Please select your state";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errorFullName,
      errorPhoneNumber,
      stateError,
      errorEmail,
      errorGender,
      errorProfilePc,
      errorCdUid,
      errorInterestedCategories,
      errorInterestedCourses,
      errorHighestEducation,
      errorBoardOrUniversity,
      errorYearOfCompletion,
      errorCgpaOrPercentile,
      errorSubjectOrStream,
      errorCity,
      errorState,
      genderError,
      errorCgpaOrPercentileType,
    });
    return formIsValid;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });

    // if (name === "state") {
    //   // debugger
    //   console.log((stateData?.data?.find(x =>x.name)),value)

    //   dispatch(getCityList({stateIsoCode:"IN", isoCode: stateData?.data?.find(x=>x.name==value)?.isoCode }));
    //   updateState({
    //     ...iState,
    //     [name]: value,
    //   });
    // } else{
    //   updateState({
    //     ...iState,
    //     [name]: value,
    //   });
    // }
  };
console.log("years",yearArray)
  
  useEffect(() => {
    dispatch(
      getCityList({
        isoCode: stateData?.data?.find((x) => x?.name == state)?.isoCode,
        stateIsoCode: "IN",
      })
    );
  }, [stateData]);
  console.log("city", state, cityData);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    console.log(formIsValid);

    if (formIsValid) {
      let data = {
        _id: id,
        email,
        fullName,
        phoneNumber,
        gender,
        profilePic,
        cdUid,
        city,
        state: stateList.name,
        otherHighestEducation: otherHighestEducation,
        cgpaOrPercentileType,

        interestedCategories,
        interestedCourses,
        highestEducation,
        boardOrUniversity,
        yearOfCompletion,
        cgpaOrPercentile,
        subjectOrStream,
      };

      dispatch(studentUpdateAction(data))
        .then((res) => {
          if (res.status === 200) {
            console.log("res-------------------> res", res)
            navigate("/my-profile");
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const config = {
    bucketName: "careerdekhoo",
    dirName: "website",
    region: "ap-south-1",
    accessKeyId: "AKIAVOWC6CG6JOZ2Q32M",
    secretAccessKey: "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",
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  // console.log("-->", otherHighestEducation);
  useEffect(() => {
    dispatch(getStateList({ isoCode: "IN" }));

    dispatch(studentDetailsAction({ _id: id }))
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          const {
            email,
            fullName,
            phoneNumber,
            gender,
            profilePic,
            cdUid,
            state,
            city,
            subjectOrStream,
            interestedCategories,
            interestedCourses,
            highestEducation,
            boardOrUniversity,
            yearOfCompletion,
            cgpaOrPercentile,
            otherHighestEducation,
            cgpaOrPercentileType,
          } = res?.data?.result;

          updateState({
            ...iState,
            email,
            fullName,
            phoneNumber,
            gender,
            profilePic,
            cdUid,
            subjectOrStream,
            highestEducation,
            boardOrUniversity,
            yearOfCompletion,
            cgpaOrPercentile,
            state,
            otherHighestEducation,
            city,
            interestedCategories: interestedCategories[0],
            interestedCourses: interestedCourses[0],
            cgpaOrPercentileType
          });
          setCity(city);
          // setIntersetedCategories(interestedCategories);
          // setIntersetedCourses(interestedCourses)
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            errorMsg: res.message,
            emailError: "",
            passwordError: "",
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(() => {
    if (stateList) {
      dispatch(
        getCityList({
          stateIsoCode: "IN",
          isoCode: stateList.isoCode,
        })
      );
    }
  }, [stateList]);

  useEffect(() => {
    dispatch(
      getStateList({
        isoCode: "IN",
      })
    )
      .then((res) => {
        if (res.status === 200) {
          const stt = res?.data?.filter((item) => item.name == state);
          setStateList(stt[0]);
          console.log(stt);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, [state]);

  useEffect(() => {
    dispatch(courseDropdownAction());
  }, []);

  // console.log(courseDropdownD/ata, "@@@@@@@@@@@");
  const handleRadio = (e,valueName) => {
    const {name,value,checked} = e.target;
    if(checked){
      // console.log(value)
      updateState({...iState, cgpaOrPercentileType:value})
    }

  }
  return (
    <div>
      <Header />
      <section>
        <div class="DashboardArea">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <Sidenav />
              </div>
              <div class="col-sm-9">
                <div class="DashboardBox">
                  <div class="TitleBox">
                    <h4>My Profile</h4>
                  </div>

                  <div class="ProfileBox">
                    <figure>
                      <img
                        src={
                          profilePic
                            ? profilePic
                            : require("../../assets/images/demo-profile.png")
                        }
                      />
                    </figure>
                    <span className="CameraIcon">
                      <i className="fa fa-camera"></i>
                      <input
                        type="file"
                        name="profilePic"
                        onClick={(e) => e.target.value == null}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          console.log("fileee", file);
                          if (file) {
                            console.log("fileee", file);
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = function(e) {
                              //Initiate the JavaScript Image object.
                              var image = new Image();
                      
                              //Set the Base64 string return from FileReader as source.
                              image.src = e.target.result;
                      
                              //Validate the File Height and Width.
                              image.onload = function() {
                                var height = this.height;
                                var width = this.width;
                                if (height === 250 && width === 250) {
                                  if (
                                    file.type === "image/jpeg" ||
                                    file.type === "image/jpg" ||
                                    file.type === "image/png"
                                  ) {
                                    S3FileUpload.uploadFile(file, config)
                                      .then((data) => {
                                        console.log("data===============================>", data);
                                        updateState({ ...iState, profilePic: data.location });
                                      })
                      
                                      .catch((err) => console.log(err));
                                  } else {
                                    toast.error("Only jpeg, jpg and png are allowed ", {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                  }
                                } else {
                                  toast.error("Images dimension should be  250 X 250 ", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                }
                              };
                            };
                          }}
                      }
                      />
                    </span>
                  </div>

                  <div class="OpeningArea ProfileEdit">
                    <div class="OpeningBody">
                      <div class="OpeningFilter">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Student Name</label>
                              <input
                                name="fullName"
                                value={fullName}
                                onChange={handleChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                              {errorFullName && (
                                <p style={{ color: "red" }}>{errorFullName}</p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Phone Number</label>
                              <input
                                value={phoneNumber}
                                name="phoneNumber"
                                type="text"
                                class="form-control"
                                placeholder=""
                                onChange={handleChange}
                              />
                              {errorPhoneNumber && (
                                <p style={{ color: "red" }}>
                                  {errorPhoneNumber}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Email Address</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={email}
                                name="email"
                              />
                              {emailError && (
                                <p style={{ color: "red" }}>{emailError}</p>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Gender</label>
                              <select
                                name="gender"
                                value={gender}
                                onChange={handleChange}
                                class="form-control"
                              >
                                <option value={""}>Select Gender</option>
                                <option value={"Female"}>Female</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Others"}>Others</option>
                              </select>
                              {genderError && (
                                <p style={{ color: "red" }}>{genderError}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Select State</label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setStateList({
                                    isoCode: e.target.value.split(",")[0],
                                    name: e.target.value.split(",")[1],
                                  })
                                }
                              >
                                <option>Select</option>

                                {stateData?.data?.map((st) =>
                                  st.name == state ? (
                                    <option
                                      selected="selected"
                                      value={[st?.isoCode, st?.name]}
                                    >
                                      {st?.name}
                                    </option>
                                  ) : (
                                    <option value={[st?.isoCode, st?.name]}>
                                      {st?.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Select City</label>

                              <select
                                onChange={(e) => setCity(e.target.value)}
                                className="form-control"
                              >
                                <option>Select</option>
                                {cityData?.data?.map((ct) =>
                                  ct.name == city ? (
                                    <option selected value={ct?.isoCode}>
                                      {ct?.name}
                                    </option>
                                  ) : (
                                    <option value={ct?.isoCode}>
                                      {ct?.name}
                                    </option>
                                  )
                                )}
                              </select>
                              {/* {cityError && <p style={{ color: "red" }}>{cityError}</p>} */}
                            </div>
                          </div>

                          {/* <div class="col-sm-6">
                            <div class="form-group">
                              <label>CD-UID (to be filled by Admin)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={cdUid}
                                name="cdUid"
                                onChange={handleChange}
                              />
                            </div>
                          </div> */}

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Select Interested Courses</label>
                            
                              <select
                                name="interestedCourses"
                                value={interestedCourses}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option>--Select--</option>
                                {courseDropdownData?.data?.result?.map(
                                  (item) => (
                                    <option value={item?.courseName}>
                                      {item?.courseName}
                                    </option>
                                  )
                                )}
                              </select>
                              {errorInterestedCourses && (
                                <p style={{ color: "red" }}>
                                  {errorInterestedCourses}
                                </p>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Select Interested Categories</label>

                              {/* <ul>
                                  <li>
                                    <input
                                      type="checkbox"
                                      checked={(interestedCategories?.includes("Engineering"))}
                                      onChange={(e) =>
                                        e.target.checked
                                          ? setIntersetedCategories((prev) => [
                                              ...prev,
                                              "Engineering",
                                            ])
                                          : setIntersetedCategories((prev) =>
                                              prev.filter(
                                                (gender) =>
                                                  gender !== "Engineering"
                                              )
                                            )
                                      }
                                      name="range"
                                    />
                                    <span>Engineering</span>
                                  </li>
                                  <li>
                                    <input
                                       checked={(interestedCategories?.includes("Doctor"))}

                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked
                                          ? setIntersetedCategories((prev) => [
                                              ...prev,
                                              "Doctor",
                                            ])
                                          : setIntersetedCategories((prev) =>
                                              prev.filter(
                                                (gender) => gender !== "Doctor"
                                              )
                                            )
                                      }
                                      name="range"
                                    />
                                    <span>Doctor</span>
                                  </li>
                                  <li>
                                    <input
                                         checked={(interestedCategories?.includes("Marketing"))}

                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked
                                          ? setIntersetedCategories((prev) => [
                                              ...prev,
                                              "Marketing",
                                            ])
                                          : setIntersetedCategories((prev) =>
                                              prev.filter(
                                                (gender) =>
                                                  gender !== "Marketing"
                                              )
                                            )
                                      }
                                      name="range"
                                    />
                                    <span>Marketing </span>
                                  </li>
                                  <li>
                                    <input
                                           checked={(interestedCategories?.includes("Accounting"))}

                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked
                                          ? setIntersetedCategories((prev) => [
                                              ...prev,
                                              "Accounting",
                                            ])
                                          : setIntersetedCategories((prev) =>
                                              prev.filter(
                                                (gender) =>
                                                  gender !== "Accounting"
                                              )
                                            )
                                      }
                                      name="range"
                                    />
                                    <span>Accounting </span>
                                  </li>
                                  <li>
                                    <input
                                         checked={(interestedCategories?.includes("Finance"))}

                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked
                                          ? setIntersetedCategories((prev) => [
                                              ...prev,
                                              "Finance",
                                            ])
                                          : setIntersetedCategories((prev) =>
                                              prev.filter(
                                                (gender) => gender !== "Finance"
                                              )
                                            )
                                      }
                                      name="range"
                                    />
                                    <span>Finance </span>
                                  </li>
                                </ul> */}

                              <select
                                name="interestedCategories"
                                value={interestedCategories}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option>--Select--</option>
                                <option value="University">University</option>
                                <option value="College">College</option>
                                <option value="School">School</option>
                                <option value="Pre-school">Pre-school</option>
                                <option value="Certificates & Coaching">
                                  Certificates & Coaching{" "}
                                </option>
                              </select>
                              {errorInterestedCategories && (
                                <p style={{ color: "red" }}>
                                  {errorInterestedCategories}
                                </p>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <h4>Education Details</h4>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div className="form-group">
                              <label>Select Highest Education</label>
                              <select
                                name="highestEducation"
                                value={highestEducation}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option>--Select--</option>

                                <option value="High School">High School</option>

                                <option value="12">12th</option>
                                <option value="Bachelor Degree">
                                  Bachelor Degree
                                </option>
                                <option value="Professional Diploma / Degree">
                                  Professional Diploma / Degree
                                </option>
                                <option value="Others">Others</option>
                              </select>
                              {errorHighestEducation && (
                                <p style={{ color: "red" }}>
                                  {errorHighestEducation}
                                </p>
                              )}
                            </div>
                          </div>


                          {highestEducation === "Others" && <div class="col-sm-6">
                            <div class="form-group">
                              <label>Highest Education</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={otherHighestEducation}
                                name="otherHighestEducation"
                                onChange={handleChange}
                              />
                            </div>
                          </div>}

                          <div class="col-sm-6">
                            <div className="form-group">
                              <label>Board / University</label>
                              <input
                                name="boardOrUniversity"
                                value={boardOrUniversity}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter University"
                              />
                              {errorBoardOrUniversity && (
                                <p style={{ color: "red" }}>
                                  {errorBoardOrUniversity}
                                </p>
                              )}
                            </div>
                          </div>

                        
                          <div class="col-sm-6">
                            <div className="form-group">
                            <article> 
                                <aside>
                                  <label className="Checkbox"> Percentile
                                      <input type="radio" name="radioData" value="Percentile"
                                       onChange={(e) =>handleRadio(e,"Percentile")}
                                      checked={cgpaOrPercentileType === "Percentile"}
                                      />
                                      <span className="checkmark"></span>
                                      
                                    </label>
                                    <label className="Checkbox"> CGPA
                                      <input type="radio" name="radioData" value="CGPA"
                                       onChange={(e) =>handleRadio(e,"CGPA")}
                                       checked={cgpaOrPercentileType === "CGPA"}
                                      //  checked={selectedOption === "Percentile"}
                                       />
                                      <span className="checkmark"></span>
                                    </label>
                                    {errorCgpaOrPercentileType && (
                                <p style={{ color: "red" }}>
                                  {errorCgpaOrPercentileType}
                                </p>
                              )}
                                    </aside>
                                    </article>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div className="form-group">
                               
                                  <label>Add {cgpaOrPercentileType === "Percentile" ? "Percentile" : "CGPA"} </label>
                                  <input
                                    name="cgpaOrPercentile"
                                    value={cgpaOrPercentile}
                                    onChange={handleChange}
                                    type="number"
                                    className="form-control"
                                    placeholder={`Enter ${cgpaOrPercentileType === "Percentile" ? "Percentile" : "CGPA"}`}
                                    disabled={!cgpaOrPercentileType}
                                    // "Enter {cgpaOrPercentile === "Percentile" ? "Percentile" : "CGPA"}"
                                  />
                                 
                              
                              {errorCgpaOrPercentile && (
                                <p style={{ color: "red" }}>
                                  {errorCgpaOrPercentile}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div className="form-group">
                              <label>Select Year of completion</label>
                              <select
                                name="yearOfCompletion"
                                value={yearOfCompletion}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option>--Select--</option>
                                {dateDropdown?.map((ele,i) => (
                                   <option value={ele}>{ele}</option>
                                )
                                 
                                   
                                 
                                )}
                                {/* <option value="1950">1950</option>
                                <option value="1951">1951</option>
                                <option value="1952">1952</option>
                                <option value="1953">1953</option>
                                <option value="1954">1954</option>
                                <option value="1955">1955</option>
                                <option value="1956">1956</option>
                                <option value="1957">1957</option>
                                <option value="1958">1958</option>
                                <option value="1959">1959</option>
                                <option value="1960">1960</option>
                                <option value="1961">1961</option>
                                <option value="1962">1962</option>
                                <option value="1963">1963</option>
                                <option value="1964">1964</option>
                                <option value="1965">1965</option>
                                <option value="1966">1966</option>
                                <option value="1967">1967</option>
                                <option value="1968">1968</option>
                                <option value="1969">1969</option>
                                <option value="1970">1970</option>
                                <option value="1971">1971</option>
                                <option value="1972">1972</option>
                                <option value="1973">1973</option>
                                <option value="1974">1974</option>
                                <option value="1975">1975</option>
                                <option value="1976">1976</option>
                                <option value="1977">1977</option>
                                <option value="1978">1978</option>
                                <option value="1979">1979</option>
                                <option value="1980">1980</option>
                                <option value="1981">1981</option>
                                <option value="1982">1982</option>
                                <option value="1983">1983</option>
                                <option value="1984">1984</option>
                                <option value="1985">1985</option>
                                <option value="1986">1986</option>
                                <option value="1987">1987</option>
                                <option value="1988">1988</option>
                                <option value="1989">1989</option>
                                <option value="1990">1990</option>
                                <option value="1991">1991</option>
                                <option value="1992">1992</option>
                                <option value="1993">1993</option>
                                <option value="1994">1994</option>
                                <option value="1995">1995</option>
                                <option value="1996">1996</option>
                                <option value="1997">1997</option>
                                <option value="1998">1998</option>
                                <option value="1999">1999</option>
                                <option value="2000">2000</option>
                                <option value="2001">2001</option>
                                <option value="2002">2002</option>
                                <option value="2003">2003</option>
                                <option value="2004">2004</option>
                                <option value="2005">2005</option>
                                <option value="2006">2006</option>
                                <option value="2007">2007</option>
                                <option value="2008">2008</option>
                                <option value="2009">2009</option>
                                <option value="2010">2010</option>
                                <option value="2011">2011</option>
                                <option value="2012">2012</option>
                                <option value="2013">2013</option>
                                <option value="2014">2014</option>
                                <option value="2015">2015</option>
                                <option value="2016">2016</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option> */}
                              </select>
                              {errorYearOfCompletion && (
                                <p style={{ color: "red" }}>
                                  {errorYearOfCompletion}
                                </p>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div className="form-group">
                              <label>Add Subject / Stream</label>
                              <input
                                name="subjectOrStream"
                                value={subjectOrStream}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Subject / Stream"
                              />
                              {errorSubjectOrStream && (
                                <p style={{ color: "red" }}>
                                  {errorSubjectOrStream}
                                </p>
                              )}
                            </div>{" "}
                          </div>

                          <div class="col-sm-6">
                                 

                          </div>

                          <div class="col-sm-5">
                            <div class="form-group">
                              <button onClick={handleSubmit}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MyProfileEdit;
