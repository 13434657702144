import { studentListConstant } from "../actionType";
import { studentDetailsConstant } from "../actionType";
import { studentUpdateConstant } from "../actionType";
import { studentApplication } from "../actionType";


const initialState ={
    studentList:[],
    studentData:{},
    applicationList:[],
    loader:false,
    error:{}
}



export default function studentReducer(state=initialState,{type,payload}){
    switch(type){
        case studentListConstant.STUDENT_LIST_INITIATE:
            return{...state,loader:true,  studentList:[]}
        case studentListConstant.STUDENT_LIST_SUCCESS:
            return{...state,loader:false,  studentList :payload.data}

        case studentListConstant.STUDENT_LIST_FAILURE:
            return{...state,  error:payload}

            
                case studentDetailsConstant.STUDENT_DETAILS_INITIATE:
                    return{...state,loader:true,  studentDate:{}}
                case studentDetailsConstant.STUDENT_DETAILS_SUCCESS:
                    return{...state,loader:false,  studentData :payload.data}
        
                case studentDetailsConstant.STUDENT_DETAILS_FAILURE:
                    return{...state,  error:payload}


                    case studentUpdateConstant.STUDENT_UPDATE_INITIATE:
                        return{...state,loader:true,  studentDate:{}}
                    case studentUpdateConstant.STUDENT_UPDATE_SUCCESS:
                        return{...state,loader:false,  studentData :payload}
            
                    case studentUpdateConstant.STUDENT_UPDATE_FAILURE:
                        return{...state,  error:payload}

                        case studentApplication.APPLICATION_LIST_INITIATE:
                            return{...state,loader:true,  applicationList:[]}
                        case studentApplication.APPLICATION_LIST_SUCCESS:
                            return{...state,loader:false,  applicationList :payload}
                
                        case studentApplication.APPLICATION_LIST_FAILURE:
                            return{...state,  error:payload}
    
    



                
     
    

            default : return state;
    }

    }

 