import axios from "axios";
import { sendOtpConstant } from "../actionType";


import { baseUrl } from "../../config/Config";


export const loginOtpInitiate=() =>({
    type:sendOtpConstant.LOGIN_OTP_INITIATE

})

export const loginOtpSuccess=(data) =>({
    type:sendOtpConstant.LOGIN_OTP_SUCCESS,
    payload:data

})

export const loginOtpFailure=(data) =>({
    type:sendOtpConstant.LOGIN_OTP_FAILURE,
    payload : data

})



export const  LoginOtpAction=(payload)=>{
    
        
    return dispatch => {
        dispatch(loginOtpInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl +'/website/sendOtpOnEmail', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(loginOtpSuccess(data))

                    }
                    else {
                        dispatch(loginOtpFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(loginOtpFailure(err))
                    reject(err);
                })

        );
    }

}


export const newOtpInitiate=() =>({
    type:sendOtpConstant.LOGIN_OTP_INITIATE

})

export const newOtpSuccess=(data) =>({
    type:sendOtpConstant.LOGIN_OTP_SUCCESS,
    payload:data

})

export const newOtpFailure=(data) =>({
    type:sendOtpConstant.LOGIN_OTP_FAILURE,
    payload : data

})



export const  SendNewOtpAction=(payload)=>{
    
        
    return dispatch => {
        dispatch(newOtpInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl +'/website/sendOtpOnEmailNew', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(newOtpSuccess(data))

                    }
                    else {
                        dispatch(newOtpFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(newOtpFailure(err))
                    reject(err);
                })

        );
    }

}

