import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";
import { getAddsActionTypes } from "../actionType";



export const getAddsListInitiate=() =>({
    type: getAddsActionTypes.GET_ADDS_INITIATE

})

export const getAddsListSuccess=(data) =>({
    type: getAddsActionTypes.GET_ADDS_SUCCESS,
    payload:data

})

export const getAddsListFailure=(data) =>({
    type: getAddsActionTypes.GET_ADDS_FAILURE,
    payload : data

})


//*************************************** */


export const  getAddsListAction=(payload)=>{
    const token = isLoggedIn('websiteData')        
    return dispatch => {
        dispatch(getAddsListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getAdds', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getAddsListSuccess(data))

                    }
                    else {
                        dispatch(getAddsListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getAddsListFailure(err))
                    reject(err);
                })

        );
    }

}