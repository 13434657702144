import React, { useEffect,lazy, Suspense, } from 'react';
import './App.css';
import { Route, HashRouter, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import { ToastContainer, toast, Slide } from 'react-toastify';
import ReactLottie from "react-lottie";


import CollegeList from './components/list-college';
import UniversityList from './components/list-university';
import PreschoolList from './components/list-preschool';
import SchoolList from './components/list-school';
// import Home from './components/home';
import CertificateList from './components/list-certificate';
// import DescriptionCeritificate from './components/description-ceritificate';
// import DescriptionCollege from './components/description-college';
// import DescriptionSchool from './components/description-school';
// import DescriptionUniversity from './components/description-university';
// import DescriptionPreSchool from './components/description-preSchool';
import CertificateCourse from './components/certificate-course';
import ChooseCareer from './components/choose-career';
import LatestNews from './components/latest-news';
import LatestNewsDetails from './components/latest-news-details';
import Exam from './components/exam';
import EducationLoan from './components/education-loan';
import Faq from './components/faq';
import AboutUs from './components/about-us';
import JoinUs from './components/join-us';
import JoinApply from './components/join-apply';
import ContactUs from './components/contact-us';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import Compare from './components/compare';
import CompareSelection from './components/compare-selection';
import FindJob from './components/find-job';
import FindJobApply from './components/find-job-apply';
import CourseDetails from './components/course-details';
import MyProfile from './components/user/my-profile';
import MyProfileEdit from './components/user/my-profile-edit';
import Wishlist from './components/user/wishlist';
import Notification from './components/user/notification';
import Settings from './components/user/settings';
import SettingsChangePassword from './components/user/settings-change-password';

import CollegeListCompare from './components/list-college-compare';
import UniversityListCompare from './components/list-univerisity-compare';
import SchoolListCompare from './components/list-school-compare';
import PreschoolListCompare from './components/list-preSchool-compare';
import CertificateListCompare from './components/list-certificate-compare';
import Search from './components/search';
import PrivacyDetails from './components/privacyDetails';
import TermsAndConditon from './components/termsAndConditon';
import { requestForToken } from './firebase';
import NotificationFirebase from './components/Notifcation';
import RouteMiddleware from './components/RouteMidlleware';
import animationData from "../src/assets/loader.json";


const Home = lazy(() => import('../src/components/home'))
// import CertificateList from './components/list-certificate';
// import DescriptionCeritificate from './components/description-ceritificate';
const DescriptionCeritificate = lazy(() => import('./components/description-ceritificate'))
const DescriptionCollege = lazy(() => import('./components/description-college'))
const DescriptionSchool = lazy(() => import('./components/description-school'))
const DescriptionUniversity = lazy(() => import('./components/description-university'))
const DescriptionPreSchool = lazy(() => import('./components/description-preSchool'))
// import DescriptionPreSchool from './components/description-preSchool';

// import DescriptionCollege from './components/description-college';
// import DescriptionSchool from './components/description-school';
// import DescriptionUniversity from './components/description-university'
// const Home = lazy(() => import('../src/components/home'))
// const Home = lazy(() => import('../src/components/home'))




function App() {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

    useEffect(() => {
      if (window.location.protocol == 'http:') {
      
        console.log("you are accessing us via "
            + "an insecure protocol (HTTP). "
            + "Redirecting you to HTTPS.");
              
        window.location.href =
            window.location.href.replace(
                    'http:', 'https:');
    }
    //  else {
    //  { window.location.href ="http"}
    // }
    else if
        (window.location.protocol == "https:") {
            console.log("you are accessing us via"
                + " our secure HTTPS protocol.");
        }
    },[])

  // requestForToken()



  return (
    <>
    
    {/* <Suspense fallback={
        <div style={{height:'100vh',background:'red',width:'100vw'}}>
          <h1>loresaldjlaksdjl</h1>
        </div>
      }> */}
<Suspense  fallback={
       
        <div style={{height:400,width:400, margin:"auto"}} >

        <ReactLottie options={animationOptions} width={'100%'} height={'100%'} className='bg-red-400' />
       </div>
      }> 
<React.Fragment>

     
<ToastContainer transition={Slide} />

<HashRouter>
  {/* {window.location.protocol == 'http:'? 
  <></> : <>
  <NotificationFirebase/>
  </>
} */}

  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/list-college" element={<CollegeList />} />        
    <Route path="/list-university" element={<UniversityList />} />
    <Route path="/list-preschool" element={<PreschoolList />} />
    <Route path="/list-school" element={<SchoolList />} />
    <Route path="/list-certificate" element={<CertificateList />} />
    <Route path="/list-college-compare" element={<CollegeListCompare />} /> 
    <Route path="/list-university-compare" element={<UniversityListCompare />} /> 
    <Route path="/list-school-compare" element={<SchoolListCompare />} /> 
    <Route path="/list-preschool-compare" element={<PreschoolListCompare />} />  
    <Route path="/list-certificate-compare" element={<CertificateListCompare />} />        
  
    <Route path="/description-certificate/:paramsId" element={<DescriptionCeritificate />} />
    <Route path="/description-college/:paramsId" element={<DescriptionCollege />} />
    <Route path="/description-school/:paramsId" element={<DescriptionSchool />} />
    <Route path="/description-university/:paramsId" element={<DescriptionUniversity />} />
    <Route path="/description-preSchool/:paramsId" element={<DescriptionPreSchool />} />
    <Route path="/certificate-course" element={<CertificateCourse />} />
    <Route path="/choose-career" element={<ChooseCareer />} />
    <Route path="/lastest-news" element={<LatestNews />} />
    <Route path="/lastest-news-details" element={<LatestNewsDetails />} />
    <Route path="/exam" element={<Exam />} />
    <Route path="/education-loan" element={<EducationLoan />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/join-us" element={<JoinUs />} />
    <Route path="/join-apply" element={<JoinApply />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog-details" element={<BlogDetails />} />
    <Route path="/compare/:paramsId/:name" element={<Compare />} />
    <Route path="/compare-selection" element={<CompareSelection />} />
    <Route path="/find-job" element={<FindJob />} />
    <Route path="/find-job-apply" element={<FindJobApply />} />
    <Route path="/course-details/:paramsId" element={<CourseDetails />} />
    <Route path="/my-profile" element={<MyProfile />} />
    <Route path="/my-profile-edit" element={<MyProfileEdit />} />
    <Route path="/wishlist" element={<Wishlist />} />
    <Route path="/notification" element={<Notification />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/settings-password" element={<SettingsChangePassword />} />
    <Route path="/privacyDetails" element={<PrivacyDetails/>} />
    <Route path="/termsAndConditon" element={<TermsAndConditon/>} />
    <Route path="/search" element={<Search />} />

  </Routes>
</HashRouter>

{/* <Switch> */}
   {/* {
     routes.map((route, index) => (
       <RouteMiddleware
         key={index}
         path={route.path}
         title={route.title}
         component={route.component}
         exact={route.exact}
       />
     ))
   } */}
 {/* </Switch> */}
</React.Fragment>
</Suspense>
    </>
  
  
  );
}

export default App;