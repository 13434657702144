import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { toast } from "react-toastify";
import ReactStars from "react-stars";

import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";

import Twitter from "../assets/images/Twitter.png";
import Dribbble from "../assets/images/Dribbble.png";
import Facebook from "../assets/images/Facebook.png";
import Instagram from "../assets/images/Instagram.png";
import Linkedin from "../assets/images/Linkedin.png";
import FooterEmail from "../assets/images/footer-email.png";
import { $CombinedState } from "redux";
import {
  advertiseEnquiryAction,
  instituteEnquiryAction,
} from "../redux/actions/enquiryAction";
import { coachingListAction } from "../redux/actions/coachingAndCertificatesAction";
import { streamDropdownAction } from "../redux/actions/universityAction";
import {
  getHomePageDataAction,
  subscribeNewsLetterAction,
} from "../redux/actions/home-pageAction";
import { AddRatitingAction } from "../redux/actions/ratingAction";

import { getCityList, getStateList } from "../redux/actions/jobAction";
import { getRatingStaticAction } from "../redux/actions/staticManagementAction";

const init = {
  advertiseModal: false,
  thankuModal: false,
  thankuRatingModal:false,
  name: "",
  email: "",
  requirements: "",
  // phoneNumber:"",
  bussinessName: "",
  errors: {},
};

const initialState = {
  nameNews:"",
  emailDk: "",
  mobile: "",
  stream: "",
  emailError: "",
  mobileError: "",
  streamError: "",
  nameNewsError:""
};
const initialInstituteState = {
  instituteName: "",
  instituteType: "",
  state: "",
  instuteState: "",
  intituteCity: "",
  instituteEmail: "",
  institutePhoneNumber: "",
  instituteRequirements: "",

  instituteNameError: "",
  instituteTypeError: "",
  instituteStateError: "",
  instituteCityError: "",
  instituteEmailError: "",
  institutePhoneNumberError: "",
  instituteRequirementsError: "",
};

const Footer = ({
  showRatingModal,
  showInstituteModal,
  setShowInstituteModal,
  handleCloseRatingModal,
  handleShowRatingModal,
  itemName,
  itemCategory,
  itemId,
  itemType,
}) => {

  const [iState, updateState] = useState(init);
  const [dkState, updateDkState] = useState(initialState);
  const [advertisementImage, updateAdvertisementImage] = useState("");
  const { stateData, cityData } = useSelector((state) => state.listReducer);
   const {ratingStaticData} = useSelector((state) => state.staticManagementReducer)
  const {courseDropdownData } = useSelector(
      (state) => state?.listReducer
    );
  const [stateList, setStateList] = useState({ name: "", isoCode: "" });
  const [city, setCity] = useState("");

  const {
    advertiseModal,
    thankuModal,
    name,
    email,
    requirements,
    bussinessName,
    errors,
    thankuRatingModal,
  } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentId = JSON.parse(localStorage.getItem("websiteData"))?._id;
  const [password1Shown, setPassword1Shown] = useState(false);
  const [signVerificationModal, setSignVerification] = useState(false);
  const handleSignVerficationClose = () => setSignVerification(false);
  const handleSignVerficationShow = () => {
    setSignVerification(true);
  };
  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };
  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleCloseModal = () => {
    updateState(init);
  };
  const handleShowModal = () => {
    updateState({ ...iState, advertiseModal: true });
  };
  const handleCloseThankuModal = () => {
    updateState({ ...iState, thankuModal: false, errors: {} });
  };
  const handleShowThankuModal = () => {
    updateState({ ...iState, thankuModal: true });
  };
  const handleCloseRatingThankuModal = () => {
    updateState({ ...iState, thankuRatingModal: false, errors: {} });
  };
  const handleShowratingThankuModal = () => {
    updateState({ ...iState, thankuRatingModal: true,errors:{} });
  };
  const [phoneNumber, setAllPhoneNumber] = useState();

  const handleChangePersonalPhone = (event) => {
    const result = event.target.value.replace(/\D/s, "");
    setAllPhoneNumber(result);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!name) {
      error.NameError = "Name can't be empty";
      formIsValid = false;
    }
    if (!email) {
      error.emailErrorrr = "Email can't be empty";
      formIsValid = false;
    }
    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailErrorrr = "Please enter a valid email";
        formIsValid = false;
      }
    }
    if (!requirements) {
      error.requirementsError = "Requirements can't be empty";
      formIsValid = false;
    }
    if (!bussinessName) {
      error.bussinessNameError = "Bussiness Name can't be empty";
      formIsValid = false;
    }

    if (!phoneNumber) {
      error.allPhoneNumberError = "* Mobile number can't be empty";
      formIsValid = false;
    }
    if (phoneNumber) {
      if (!/^[1-9][0-9]{9}$/.test(phoneNumber)) {
        error.allPhoneNumberError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }
    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const createAdvertise = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      let data = {
        name,
        email,
        requirements,
        bussinessName,
        phoneNumber,
      };
      dispatch(advertiseEnquiryAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              advertiseModal: false,
              thankuModal: true,
              name: "",
              email: "",
              phoneNumber: "",
              requirements: "",
              bussinessName: "",
              errors: {},
            });
            setAllPhoneNumber("");
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          // // console.log("err", err);
        });
    }
  };
  const initialRatingState = {
    Id: "",
    type: "",
    rating: "",
    review: "",
    images: [],
    category: "",
    relation: "",
    name: "",

    reviewError: "",
    relation: "",
  };

  const [ratingState, updateRatingState] = useState(initialRatingState);
  const [instituteEnquiryState, updateInstituteEnquiryState] = useState(
    initialInstituteState
  );

  const [ratingModal, setRatingModal] = useState(false);
  const [instituteModal, setInstituteModale] = useState(false);
  const handleInstituteShow = () => {
    setInstituteModale(true);
  };
  const handleInstituteClose = () => {
    setShowInstituteModal(false);
    updateInstituteEnquiryState({
      ...instituteEnquiryState,
      instituteName: "",
      instituteType: "",
      instituteEmail: "",
      institutePhoneNumber: "",
      instituteState: "",
      instituteCity: "",
      instituteRequirements: "",

      instituteNameError: "",
      instituteTypeError: "",
      instituteEmailError: "",
      institutePhoneNumberError: "",
      instituteStateError: "",
      instituteCityError: "",
      instituteRequirementsError: "",
    });
    setInstituteModale(false);
  };

  const handleRatingShow = () => {
    setRatingModal(true);
  };
  const handleRatingClose = () => {
    updateRatingState({
      ...ratingState,
      rating: "",
      review: "",
      images: "",
      category: "",
      relation: "",  
      reviewError: "", relationError: "" 
    });
    setRatingModal(false);
    handleCloseRatingModal()
    //  showRatingModal
   
  };

  const { coursesAndCertificatesListData } = useSelector(
    (state) => state.listReducer
  );

  const { streamData } = useSelector((state) => state?.listReducer);
  const { trendingCityList, trendingStreamList } = useSelector(
    (state) => state.trendingReducer
  );
  const { topCollegeUniversityList, topSchoolPreSchoolList } = useSelector(
    (state) => state.topReducer
  );

  const topCollegeList = topCollegeUniversityList?.filter(
    (item) => item.type === "COLLEGES"
  );
  const topUniversityList = topCollegeUniversityList?.filter(
    (item) => item.type === "UNIVERSITIES"
  );
  const topSchoolList = topSchoolPreSchoolList?.filter(
    (item) => item.type === "SCHOOL"
  );

  const { emailDk, emailError, mobile, mobileError, stream, streamError,nameNews, nameNewsError } =
    dkState;

  const {
    rating,
    review,
    images,
    category,
    relation,
    nameDk,
    Id,
    type,
    reviewError,
    relationError,
  } = ratingState;

  let handleValidationDK = () => {
    let emailErrorDk = "";
    let mobileError = "";
    let streamError = "";
    let nameNewsError="";
    let formIsValid = true;
    if (!emailDk) {
      emailErrorDk = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(emailDk)) {
      emailErrorDk = "* Email format is not valid";
      formIsValid = false;
    }
    if(!nameNews){
      nameNewsError ="* Please enter name"
    }
    if (!mobile) {
      mobileError = "* Please enter mobile number.";
      formIsValid = false;
    } else if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(mobile)) {
      mobileError = "* Please enter valid phone number.";
      formIsValid = false;
    }
    if (!stream) {
      streamError = "* Please select stream.";
      formIsValid = false;
    }

    updateDkState({
      ...dkState,
      nameNewsError,
      emailError: emailErrorDk,
      mobileError,
      streamError,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateDkState({
      ...dkState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidationDK();

    if (formIsValid) {
      var data = { email: emailDk, mobile_number: mobile, stream,name:nameNews };

      dispatch(subscribeNewsLetterAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateDkState({ ...dkState, emailDk: "", mobile: "", stream: "", nameNews:"",  nameNewsError:"",
            emailError: "",
            mobileError:"",
            streamError:"", });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateDkState({
              ...dkState,
              errorMsg: res.message,
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };



  let handleValidation2 = () => {
    let relationError = "";
    let reviewError = "";
    let formIsValid2 = true;
    if (!relation) {
      relationError = "* Please select how to relate this .";
      formIsValid2 = false;
    }
    if (!review) {
      reviewError = "* Please enter review.";
      formIsValid2 = false;
    }
    updateRatingState({
      ...ratingState,
      relationError,
      reviewError,
    });
    return formIsValid2;
  };

  const handleRatingChange = (e) => {
    const { name, value } = e.target;
    updateRatingState({
      ...ratingState,
      [name]: value,
    });
  };


  const handleRatingSubmit = (e) => {
    e.preventDefault();

    let formIsValid2 = handleValidation2();

    if (formIsValid2) {
      if (type === "university") {
        var data = {
          universityId: Id,
          name: nameDk,
          rating,
          category,
          review,
          images,
          relation,
        };
      }
      if (type === "college") {
        var data = {
          collegeId: Id,
          nameDk,
          rating,
          review,
          category,
          images,
          relation,
        };
      }
      if (type === "school") {
        var data = {
          schoolId: Id,
          nameDk,
          rating,
          review,
          category,
          images,
          relation,
        };
      }
      if (type === "preSchool") {
        var data = {
          preSchoolId: Id,
          name: nameDk,
          rating,
          review,

          category,
          images,
          relation,
        };
      }
      if (type === "coaching") {
        var data = {
          coachingCertificationId: Id,
          name: nameDk,
          rating,
          review,

          category,
          images,
          relation,
        };
      }

      

      dispatch(AddRatitingAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setRatingModal(false);
            updateState({ ...iState, thankuRatingModal: true,errors:{} });
            // handleCloseRatingModal()
            handleCloseRatingModal()
            // updateState({ ...iState, thankuModal: true });
            updateRatingState({
              ...ratingState,
              rating: "",
              review: "",
              images: "",
              category: "",
              relation: "",
              // nameDk: "",
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateRatingState({
              ...ratingState,
              relationError: "",
              reviewError: "",
            });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  const handleStar = (rate) => {
    updateRatingState({ ...ratingState, rating: rate });
  };

  useEffect(() => {
    setRatingModal(showRatingModal);
    dispatch(getRatingStaticAction())
    
  }, [showRatingModal]);

  useEffect(() => {
    setInstituteModale(showInstituteModal);
  }, [showInstituteModal]);

  useEffect(() => {
    dispatch(streamDropdownAction());
    dispatch(coachingListAction());
    dispatch(getHomePageDataAction()).then((res) => {
      updateAdvertisementImage(res?.data?.advertisementImage);
    });
    dispatch(getRatingStaticAction())
    
  }, []);

  useEffect(() => {
    updateRatingState({
      ...ratingState,
      nameDk: itemName,
      category: itemCategory,
      Id: itemId,
      type: itemType,
    });
  }, [itemName, itemCategory, itemId, itemType]);

  const {
    instituteName,
    instituteType,
    instituteState,
    instituteCity,
    instituteEmail,
    institutePhoneNumber,
    instituteRequirements,
    state,
    instituteNameError,
    instituteTypeError,
    instituteStateError,
    instituteCityError,
    instituteEmailError,
    institutePhoneNumberError,
    instituteRequirementsError,
  } = instituteEnquiryState;

  let handleValidationInstitute = () => {
    let instituteNameError = "";
    let instituteTypeError = "";
    let instituteStateError = "";
    let instituteCityError = "";
    let institutePhoneNumberError = "";
    let instituteEmailError = "";
    let instituteRequirementsError = "";
    let instituteFormValid = true;
    if (!instituteName) {
      instituteNameError = "* Please enter institute name.";
      instituteFormValid = false;
    }
    if (!instituteType) {
      instituteTypeError = "* Please select instute type.";
      instituteFormValid = false;
    }

    if (!instituteEmail) {
      instituteEmailError = "* Please enter email.";
      instituteFormValid = false;
    } else if (!/^.+?@.+?\..+$/.test(instituteEmail)) {
      instituteEmailError = "* Email format is not valid";
      instituteFormValid = false;
    }
    if (!city) {
      instituteCityError = "* Please select city .";
      instituteFormValid = false;
    }

    if (stateList?.name === undefined) {
      instituteStateError = "* Please select state .";
      instituteFormValid = false;
    }

    if (!instituteRequirements) {
      instituteRequirementsError = "* Please enter description.";
      instituteFormValid = false;
    }

    if (!instituteEmail) {
      instituteEmailError = "* Please enter email.";
      instituteFormValid = false;
    } else if (!/^.+?@.+?\..+$/.test(instituteEmail)) {
      instituteEmailError = "* Email format is not valid";
      instituteFormValid = false;
    }
    if (!institutePhoneNumber) {
      institutePhoneNumberError = "* Please enter mobile number.";
      instituteFormValid = false;
    } else if (
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(institutePhoneNumber)
    ) {
      institutePhoneNumberError = "* Please enter valid phone number.";
      instituteFormValid = false;
    }

    updateInstituteEnquiryState({
      ...instituteEnquiryState,
      instituteNameError,
      instituteTypeError,
      instituteEmailError,
      institutePhoneNumberError,
      instituteStateError,
      instituteCityError,
      instituteRequirementsError,
    });
    return instituteFormValid;
  };

  const handleInstituteChange = (e) => {
    const { name, value } = e.target;
    // console.log(name);
    updateInstituteEnquiryState({
      ...instituteEnquiryState,
      [name]: value,
    });
  };

  const instituteHandle = (e) => {
    e.preventDefault();

    let formIsValid = handleValidationInstitute();

    if (formIsValid) {
      var data = {
        instituteName,
        instituteType,
        state: stateList?.name,
        city: city,
        email: instituteEmail,
        phoneNumber: institutePhoneNumber,
        requirements: instituteRequirements,
      };
     

      dispatch(instituteEnquiryAction(data))
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setInstituteModale(false);
            updateState({ ...iState, thankuModal: true });
            updateInstituteEnquiryState({
              ...instituteEnquiryState,
              instituteName: "",
              instituteType: "",
              instituteEmail: "",
              institutePhoneNumber: "",
              instituteState: "",
              instituteCity: "",
              instituteRequirements: "",

              instituteNameError: "",
              instituteTypeError: "",
              instituteEmailError: "",
              institutePhoneNumberError: "",
              instituteStateError: "",
              instituteCityError: "",
              instituteRequirementsError: "",
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  useEffect(() => {
    if (stateList) {
      dispatch(
        getCityList({
          stateIsoCode: "IN",
          isoCode: stateList.isoCode,
        })
      );
    }
  }, [stateList]);

  useEffect(() => {
    dispatch(
      getStateList({
        isoCode: "IN",
      })
    )
      .then((res) => {
        if (res.status === 200) {
          const stt = res?.data?.filter((item) => item.name == state);
          setStateList(stt[0]);
          // console.log(stt);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        // alert(err);
      });
  }, [state]);

  const goToCollegeStream = (filterName, filterID) => {
    navigate("/list-college", {state : {filterName,filterID}})
  };
  const goToCollege =(filterID,filterName) => {
    navigate("/list-college", {state : {filterName,filterID}})
  }


  
  return (
    <div>
      <footer>
        <div class="MainFooter">
          <div class="container">
            <div class="LargeFooter">
              <div class="FooterLeft">
                <div class="row">
                  <div class="col pr-1">
                    <div class="Foot">
                      <h2>
                        <span>Top Universities</span>
                      </h2>
                      <ul>
                        {topUniversityList?.slice(0, 10)?.map((item) => (
                          <li>
                              <Link to={`/description-university/${item?._id}`} state={item}>
                                
                            {/* <Link to="/description-university" state={item}> */}
                              {item?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="col pr-1">
                    <div class="Foot">
                      <h2>
                        <span>Top Colleges</span>
                      </h2>
                      <ul>
                        {topCollegeList?.slice(0, 10)?.map((item) => (
                          <li>
                            <Link to={`/description-college/${item?._id}`} state={item}>
                              {item?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="col pr-1">
                    <div class="Foot">
                      <h2>
                        <span>Top Schools</span>
                      </h2>
                      <ul>
                        {topSchoolList?.slice(0, 10)?.map((item) => (
                          <li>
                            <Link to={`/description-school/${item?._id}`} state={item}>
                              {item?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="col pr-1">
                    <div class="Foot">
                      <h2>
                        <span>Popular Cities</span>
                      </h2>
                      <ul>
                        {trendingCityList?.slice(0, 10)?.map((item) => (
                          <li>
                            <a onClick={() =>goToCollegeStream("City", item?.city)}  >{item?.city}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="col pr-1">
                    <div class="Foot">
                      <h2>
                        <span>Popular Courses</span>
                      </h2>
                      <ul>
                        {courseDropdownData?.data?.result
                          ?.slice(0, 10)
                          ?.map((ele) => (
                            <li>
                                <a onClick={() =>goToCollege(ele?.streamId, "STREAM")} href="javascript:void(0);">{ele?.courseName}</a>
                              {/* <Link 
                              // to={`/description-certificate/${item?._id}`}
                               state={item}>
                                {item?.name}
                              </Link> */}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="FooterBroadcom">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/join-us">Join Our Team</Link>
                    </li>
                    <li>
                      <Link to="/termsAndConditon">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacyDetails">Privacy Policy</Link>
                    </li>
                    <li>
                      <a href="javascript:void(0);" onClick={handleShowModal}>
                        Advertise with us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="FooterRight">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/careerdekhooindia/" target="_blank">
                      <img src={Instagram} />
                    </a>
                  </li>
                  <li>
                    <Link to="">
                      <img src={Twitter} />
                    </Link>
                  </li>
                  {/* <li><Link to=""><img src={Dribbble} /></Link></li> */}
                  <li>
                    <Link to="">
                      <img src={Linkedin} />
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/CareerDekhooIndia" target="_blank">
                      <img src={Facebook} />
                    </a>
                  </li>
                </ul>
                <h6>
                  <img src={FooterEmail} />
                  <span>Subscribe for newsletter</span>
                </h6>
                <p>
                  Get the Latest Courses Notifications. <br /> Admission
                  Notifications. EdTech Webinar Notifications and Edu News
                  Updates.
                </p>
                <div class="FooterForm">
                  <form>
                    <div class="form-group">
                    <div class="form-group">
                      <input
                        type="text"
                        value={nameNews}
                        name="nameNews"
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Name"
                      />
                      {nameNewsError && (
                        <p style={{ color: "red" }}>{nameNewsError}</p>
                      )}
                    </div>
                      <input
                        type="text"
                        value={emailDk}
                        name="emailDK"
                        onChange={(e) =>
                          updateDkState({...dkState, emailDk: e.target.value })
                        }
                        class="form-control"
                        placeholder="Email Address"
                      />
                      {emailError &&(
                        <p style={{ color: "red" }}>{emailError}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        value={mobile}
                        name="mobile"
                        onChange={(e) =>
                          updateDkState({...dkState, mobile: e.target.value })
                        }
                        class="form-control"
                        placeholder="Mobile"
                      />
                      {mobileError && (
                        <p style={{ color: "red" }}>{mobileError}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="stream"
                          value={stream}
                          onChange={(e) => {
                            let index = e.nativeEvent.target.selectedIndex;

                            updateDkState({
                              ...dkState,
                              stream: e.target.value,
                            });
                          }}
                        >
                          <option value="">--Select Stream--</option>
                          {streamData?.data?.result?.map((stream) => (
                            <option value={stream.streamName}>
                              {stream.streamName}
                            </option>
                          ))}
                        </select>
                        {streamError &&  (
                          <p style={{ color: "red" }}>{streamError}</p>
                        )}
                      </div>{" "}
                      <button onClick={handleSubmit} class="Button">
                        Subscribe{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="SmallFooter">
          <div class="container">
            {/* <p>@CopyRight All right reserved & designed by Mobulous</p> */}
            <p>© 2023 Careerdekho Web Pvt. Ltd. All Rights Reserved</p>
          </div>
        </div>
      </footer>

      <div class="scroll-top-wrapper">
        <span onClick={goToTop} class="scroll-top-inner">
          <i class="fa fa-long-arrow-up"></i>
          Back to Top
        </span>
      </div>

      <div class="ModalBox">
        <div class="modal fade" id="LoginModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body"></div>
            </div>
          </div>
        </div>

   

      </div>
      <Modal show={ratingModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={
           handleRatingClose
            }>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              <img src={require("../assets/images/LogoModal.png")} />{" "}
            </span>
            <div>
              <figure>
                <img src={require("../assets/images/Modal-1.png")} />
              </figure>
              <figcaption>
                <h3>Welcome back to CareerDekhoo</h3>
                <p>
                  Share your experience with us to help the other visitors of
                  the website to get precise information while choosing their
                  dream institute. Be honest and truthful while writing the
                  review as this will help others in deciding their career
                  paths.
                </p>
              </figcaption>
            </div>
          </div>
          <div class="LoginRight">
            <div class="LoginBox">
              <div className="LoginBoxTool">
                <h3>Write a Review</h3>

                <div className="CustomToolTip">
                  <h6>
                    <i className="fa fa-info"></i>
                  </h6>
                  <div className="ToolTipHover">
                  <div
                                          dangerouslySetInnerHTML={{
                                            __html: ratingStaticData?.data,
                                          }}
                                          className="editor"
                                        ></div>

                    {/* <p>
                      Students trust a review when it comes from a genuine
                      person based on actual experience. Therefore, the reviews
                      submitted by you will help prospective students in making
                      an informed decision about the right career path for
                      themselves.
                    </p>
                    <p>
                      Kindly submit some basic details given in the Review and
                      Rating form. Your personal details will be kept
                      confidential.{" "}
                    </p>

                    <p>Your review will get rejected if: </p>
                    <ul>
                      <li>
                        If it is too short and vague, not having any useful
                        information{" "}
                      </li>
                      <li>If you have used slang or abusive words </li>
                      <li>If using short letters / SMS language</li>
                      <li>Do not Copy and write your own experience.</li>
                      <li>
                        Once the review is submitted, it will go to admin for
                        approval. It will be approved only if it follows all the
                        terms and conditions mentioned above for writing a
                        review.
                      </li>
                      <li>Add Comment for review: Minimum 50 words </li>
                      <li>Submit</li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <form onSubmit ={handleRatingSubmit}
              // {showRatingModal?handleCloseRatingModal:handleRatingSubmit}
              >
                <div class="form-group">
                  {/* <input
                    type="text"
                    class="form-control"
                    placeholder="category"
                    onChange={handleRatingChange}
                    value={category}
                    name="category"
                    disabled
                  /> */}
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    onChange={handleRatingChange}
                    value={nameDk}
                    name="nameDk"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <select
                    class="form-control"
                    onChange={handleRatingChange}
                    name="relation"
                  >
                    <option>--select how to relate this ---</option>
                    <option value="Currently studying at this institute ">
                      Currently studying at this institute{" "}
                    </option>
                    <option value="Ex-Student of this Institute">
                      Ex-Student of this Institute
                    </option>
                    <option value="Current Employee of this Institute">
                      Current Employee of this Institute
                    </option>
                    <option value="Ex-Employee of this Institute">
                      Ex-Employee of this Institute
                    </option>
                    <option value="Part of Management">
                      Part of Management
                    </option>
                    <option value="Part of Faculty ">Part of Faculty </option>
                  </select>
                  {relationError && (
                    <p style={{ color: "red" }}> {relationError}</p>
                  )}
                </div>

                <div class="LoginRating">
                  <aside>
                    <h6 style={{ fontSize: "14px" }}>Give Ratings</h6>
                    {/* &nbsf;  &nbsf;  &nbsf; */}
                    <ReactStars
                      count={5}
                      onChange={(rate) => handleStar(rate)}
                      size={35}
                      value={rating}
                      half={false}
                      color2={"#ffd700"}
                    />
                  </aside>
                </div>

                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Please add the reason of rating"
                    value={review}
                    name="review"
                    onChange={handleRatingChange}
                  />
                  {reviewError && (
                    <p style={{ color: "red" }}> {reviewError}</p>
                  )}
                </div>

                <div class="ContinueButton">
                  <button>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={advertiseModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleCloseModal}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={require("../assets/images/Modal-1.png")} />
              </figure>
              <figcaption>
                <h3>Advertise with CareerDekhho.Com</h3>
                <p>
                India's most trusted educational and career-building platform. 

                </p>

                <ul>
                  <li>Plenty of space for third-party banners</li>
                  <li>
                  Micro portals for educational institutes
                  </li>
                  <li>
                  Rotation of ad banners for maximum visibility
                  </li>
                  <li>Targeted advertising options and Customized advertising solutions </li>
                <li>Expert guidance and support
 </li> 
 <li>Affordable pricing
</li>
                </ul>
                For more information submit an inquiry form or contact us at:


                <a href="advertise@careerdekhoo.com"  target="_blank">
                  advertise@careerdekhoo.com
                </a>
              </figcaption>
            </div>
          </div>

          <div class="LoginRight">
            <div class="LoginBox">
              <h3>Advertise with Us</h3>
              <form onSubmit={createAdvertise}>
                <div class="form-group">
                  <label>Your Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.NameError}
                  </span>
                </div>

                <div class="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.emailErrorrr}
                  </span>
                </div>

                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChangePersonalPhone}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.allPhoneNumberError}
                  </span>
                </div>

                <div class="form-group">
                  <label>Business Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Business Name"
                    name="bussinessName"
                    value={bussinessName}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.bussinessNameError}
                  </span>
                </div>

                <div class="form-group">
                  <label>Describe your requirements</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Describe your requirements"
                    name="requirements"
                    value={requirements}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.requirementsError}
                  </span>
                </div>

                <div class="ContinueButton">
                  <button data-dismiss="modal">Submit</button>
                </div>
              </form>
            </div>
          </div>

          {/* </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </Modal>

      <Modal show={instituteModal}>
        <div class="LoginArea">
          <a class="CloseModal" onClick={handleInstituteClose}>
            &times;
          </a>
          <div class="LoginLeft">
            <span class="Icon">
              {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
            </span>
            <div>
              <figure>
                <img src={require("../assets/images/Modal-1.png")} />
              </figure>
              <figcaption>
                <h3>
                  Welcome to CareerDekhoo.com - India's trusted education
                  consultancy.
                </h3>
                <p>List your institute and enjoy:</p>
                <ul>
                  <li>Increased visibility to education seekers.</li>
                  <li>
                    Showcase your facilities, courses, faculty, and
                    achievements.
                  </li>
                  <li>Connect with potential students and parents.</li>
                  <li>Expert guidance and support from our team.</li>
                  <li>Stay up-to-date with the latest trends for growth.</li>
                  <li>
                    Join us to promote your institute and connect with the right
                    audience.
                  </li>
                </ul>
                {/* Submit the inquiry form and we will contact you shortly. For
                more information, contact us at
                <a href="advertise@careerdekhoo.com">
                  advertise@careerdekhoo.com
                </a> */}
              </figcaption>
            </div>
          </div>

          <div class="LoginRight">
            <div class="LoginBox">
              <h3>List your institute</h3>
              <p>Please fill below detials and we will get back to you soon</p>
              <br />
              <form onSubmit={instituteHandle}>
                <div class="form-group">
                  <label>Insititute Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                    name="instituteName"
                    value={instituteName}
                    onChange={handleInstituteChange}
                  />
                  {instituteNameError && (
                    <p style={{ color: "red" }}>{instituteNameError}</p>
                  )}{" "}
                </div>

                <div class="form-group">
                  <label>Insititute Type</label>
                  <select
                    name="instituteType"
                    value={instituteType}
                    onChange={handleInstituteChange}
                    className="form-control"
                  >
                    <option>--Select--</option>
                    <option value="University">University</option>
                    <option value="College">College</option>
                    <option value="School">School</option>
                    <option value="Pre-school">Pre-school</option>
                    <option value="Certificates & Coaching">
                      Certificates & Coaching
                    </option>
                  </select>
                  {instituteTypeError && (
                    <p style={{ color: "red" }}>{instituteTypeError}</p>
                  )}
                </div>

                <div class="form-group">
                  <label>Select State</label>
                  <select
                    className="form-control"
                    onChange={(e) =>
                      setStateList({
                        isoCode: e.target.value.split(",")[0],
                        name: e.target.value.split(",")[1],
                      })
                    }
                  >
                    <option>Select</option>
                    {stateData?.data?.map((state) => (
                      <option value={[state?.isoCode, state?.name]}>
                        {state?.name}
                      </option>
                    ))}
                  </select>

                  {instituteStateError && (
                    <p style={{ color: "red" }}>{instituteStateError}</p>
                  )}
                </div>

                <div class="form-group">
                  <label>Select City</label>
                  <select
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                  >
                    <option>Select</option>
                    {cityData?.data?.map((state) => (
                      <option value={state?.isoCode}>{state?.name}</option>
                    ))}
                  </select>
                  {instituteCityError && (
                    <p style={{ color: "red" }}>{instituteCityError}</p>
                  )}
                </div>

                <div class="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    name="instituteEmail"
                    value={instituteEmail}
                    onChange={handleInstituteChange}
                  />
                  {instituteEmailError && (
                    <p style={{ color: "red" }}>{instituteEmailError}</p>
                  )}
                </div>

                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Phone Number"
                    name="institutePhoneNumber"
                    value={institutePhoneNumber}
                    onChange={handleInstituteChange}
                  />
                  {institutePhoneNumberError && (
                    <p style={{ color: "red" }}>{institutePhoneNumberError}</p>
                  )}
                </div>

                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Describe your requirements"
                    name="instituteRequirements"
                    value={instituteRequirements}
                    onChange={handleInstituteChange}
                  ></textarea>
                  {instituteRequirementsError && (
                    <p style={{ color: "red" }}>{instituteRequirementsError}</p>
                  )}
                  {/* <input
                    type="text"
                    class="form-control"
                    placeholder="Describe your requirements"
                    name="instituteRequirements"
                    value={instituteRequirements}
                    onChange={handleInstituteChange}
                  />
                  {instituteRequirementsError && <p style={{color: "red"}}>{instituteRequirementsError}</p>} */}
                </div>

                <div class="ContinueButton">
                  <button>Submit</button>
                </div>
              </form>
            </div>
          </div>

          {/* </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </Modal>



      <Modal show={thankuModal}>
        <div class="LoginArea">
          <a
            onClick={handleCloseThankuModal}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="ThankBox">
            <div>
              <figure>
                <img src={require("../assets/images/Apply-Thank.png")} />
              </figure>
              <h3>Thank you</h3>
              <p>
                {
                   //  Thank You for applying with ${(name)}!
                `
Your application has been submitted successfully. 
We will be in touch and contact you soon.`}
                
              </p>
              <a
                href="javascript:void(0);"
                onClick={handleCloseThankuModal}
                data-dismiss="modal"
              >
                Great
              </a>
            </div>
          </div>
        </div>
      </Modal>
      
      <Modal show={thankuRatingModal}>
        <div class="LoginArea">
          <a
            onClick={handleCloseRatingThankuModal}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="ThankBox">
            <div>
              <figure>
                <img src={require("../assets/images/Apply-Thank.png")} />
              </figure>
              <h3>Thank you</h3>
              <p>
              Your review has been submitted successfully for the approval.

              </p>
              <a
                href="javascript:void(0);"
                onClick={handleCloseRatingThankuModal}
                data-dismiss="modal"
              >
                Great
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Footer;
