import axios from "axios";
import { coursesAndCertificates, jobManagement } from "../actionType";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";





  export const internalJobListInitate = () => ({
    type: jobManagement.INTERNAL_JOB_INITATE,
  });
  
  export const internalJobListSuccess = (data) => ({
    type: jobManagement.INTERNAL_JOB_SUCCESS,
    payload: data,
  });
  
  export const internalJobListFailure = (data) => ({
    type: jobManagement.INTERNAL_JOB_FAILURE,
    payload: data,
  });
  
  export function internalJobListAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(internalJobListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/internalJobList`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(internalJobListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(internalJobListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(internalJobListFailure(err))
            reject(err);
          });
      });
    };
  }


  

  export const externalJobListInitate = () => ({
    type: jobManagement.EXTERNAL_JOB_INITIATE,
  });
  
  export const externalJobListSuccess = (data) => ({
    type: jobManagement.EXTERNAL_JOB_SUCCESS,
    payload: data,
  });
  
  export const externalJobListFailure = (data) => ({
    type: jobManagement.EXTERNAL_JOB_FAILURE,
    payload: data,
  });
  
  export function externalJobListAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(externalJobListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/externalJobList`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(externalJobListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(externalJobListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(externalJobListFailure(err))
            reject(err);
          });
      });
    };
  }


  


  export const applyJobInitate = () => ({
    type: jobManagement.JOB_APPLY_INITIATE,
  });
  
  export const  applyJobSuccess = (data) => ({
    type: jobManagement.JOB_APPLY_SUCCESS,
    payload: data,
  });
  
  export const  applyJobFailure = (data) => ({
    type: jobManagement.JOB_APPLY_FAILURE,
    payload: data,
  });
  
  export function  applyJobAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(applyJobInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/jobApply`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(applyJobSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(applyJobFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(applyJobFailure(err))
            reject(err);
          });
      });
    };
  }









  export const verifyUdidInitate = () => ({
    type: jobManagement.VERIFY_CDUID_INITIATE,
  });
  
  export const  verifyUdidSuccess = (data) => ({
    type: jobManagement.VERIFY_CDUID_SUCCESS,
    payload: data,
  });
  
  export const  verifyUdidFailure = (data) => ({
    type: jobManagement.VERIFY_CDUID_FAILURE,
    payload: data,
  });
  
  export function  verifyUdidAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch( verifyUdidInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/verifyCduid`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(verifyUdidSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(verifyUdidFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(verifyUdidFailure(err))
            reject(err);
          });
      });
    };
  }



  
  export const getStateListInitate = () => ({
    type: jobManagement.GET_STATE_LIST_INITIATE,
  });
  
  export const getStateListSuccess = (data) => ({
    type: jobManagement.GET_STATE_LIST_SUCCESS,
    payload: data,
  });
  
  export const getStateListFailure = (data) => ({
    type: jobManagement.GET_STATE_LIST_FAILURE,
    payload: data,
  });
  
  export function getStateList(payload) {
    const token = isLoggedIn("institueData")
    // // // // console.log("token-----" , token)
    return (dispatch) => {
      dispatch(getStateListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/institute/getStates`, payload , {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status === 200) {
              dispatch(getStateListSuccess(data));
              resolve(data);
            } else {
              dispatch(getStateListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(getStateListFailure(err));
            reject(err);
          });
      });
    };
  }



   
  export const getCityListInitate = () => ({
    type: jobManagement.GET_CITY_LIST_INITIATE,
  });
  
  export const getCityListSuccess = (data) => ({
    type: jobManagement.GET_CITY_LIST_SUCCESS,
    payload: data,
  });
  
  export const getCityListFailure = (data) => ({
    type: jobManagement.GET_CITY_LIST_FAILURE,
    payload: data,
  });
  
  export function getCityList(payload) {
    const token = isLoggedIn("institueData")
    // // // // console.log("token-----" , token)
    return (dispatch) => {
      dispatch(getCityListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/institute/getCities`, payload , {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status === 200) {
              dispatch(getCityListSuccess(data));
              resolve(data);
            } else {
              dispatch(getCityListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(getCityListFailure(err));
            reject(err);
          });
      });
    };
  }
