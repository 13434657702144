import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";

import University1 from "../assets/images/University-1.png";
import University2 from "../assets/images/University-2.png";
import University3 from "../assets/images/University-3.png";
import University4 from "../assets/images/University-4.png";
import University5 from "../assets/images/University-5.png";
import { useDispatch, useSelector } from "react-redux";
import {
  universeListAction,
  universityFilterAction,
} from "../redux/actions/universityAction";
import Pagination from "react-js-pagination";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/material";
import StarRatings from "react-star-ratings";

const init = {
  studyModeData: null,
  ExamAcceptedData: [],
  streamData: null,
  facilitiesData: [],
  cityData: [],
  stateData: [],

  studyMode: [],
  stream: [],
  acceptedExams: [],
  feesMin: "",
  feesMax: "",
  facilities: [],
  city: [],
  stateName: [],
  sort:'',
  minFeesArray: [
    { backenedFees: "0", frontEndFees: "Rs 0" },
    { backenedFees: "1000", frontEndFees: "Rs 1000" },
    { backenedFees: "5000", frontEndFees: "Rs 5000" },
    { backenedFees: "10000", frontEndFees: "Rs 10000" },
    { backenedFees: "20000", frontEndFees: "Rs 20000" },
    { backenedFees: "40000", frontEndFees: "Rs 40000" },
    { backenedFees: "70000", frontEndFees: "Rs 70000" },
    { backenedFees: "100000", frontEndFees: "Rs 1 Lakh" },
    { backenedFees: "200000", frontEndFees: "Rs 2 Lakh" },
    { backenedFees: "300000", frontEndFees: "Rs 3 Lakh" },
    { backenedFees: "500000", frontEndFees: "Rs 5 Lakh" },
    { backenedFees: "800000", frontEndFees: "Rs 8 Lakh" },
    { backenedFees: "1000000", frontEndFees: "Rs 10 Lakhs" },
    { backenedFees: "1500000", frontEndFees: "Rs 15 Lakhs" },
    { backenedFees: "2000000", frontEndFees: "Rs 20 Lakhs" },
    { backenedFees: "2500000", frontEndFees: "Rs 25 Lakhs" },
    { backenedFees: "3000000", frontEndFees: "Rs 30 Lakhs" },
    { backenedFees: "4000000", frontEndFees: "Rs 40 Lakhs" },
    { backenedFees: "5000000", frontEndFees: "Rs 50 Lakhs" },
    { backenedFees: "7000000", frontEndFees: "Rs 70 Lakhs" },
    { backenedFees: "9000000", frontEndFees: "Rs 90 Lakhs" },
   
  ],
  maxFeesArray: [
    { backenedFees: "1000", frontEndFees: "Rs 1000" },
    { backenedFees: "5000", frontEndFees: "Rs 5000" },
    { backenedFees: "10000", frontEndFees: "Rs 10000" },
    { backenedFees: "20000", frontEndFees: "Rs 20000" },
    { backenedFees: "40000", frontEndFees: "Rs 40000" },
    { backenedFees: "70000", frontEndFees: "Rs 70000" },
    { backenedFees: "100000", frontEndFees: "Rs 1 Lakh" },
    { backenedFees: "200000", frontEndFees: "Rs 2 Lakh" },
    { backenedFees: "300000", frontEndFees: "Rs 3 Lakh" },
    { backenedFees: "500000", frontEndFees: "Rs 5 Lakh" },
    { backenedFees: "800000", frontEndFees: "Rs 8 Lakh" },
    { backenedFees: "1000000", frontEndFees: "Rs 10 Lakhs" },
    { backenedFees: "1500000", frontEndFees: "Rs 15 Lakhs" },
    { backenedFees: "2000000", frontEndFees: "Rs 20 Lakhs" },
    { backenedFees: "2500000", frontEndFees: "Rs 25 Lakhs" },
    { backenedFees: "3000000", frontEndFees: "Rs 30 Lakhs" },
    { backenedFees: "4000000", frontEndFees: "Rs 40 Lakhs" },
    { backenedFees: "5000000", frontEndFees: "Rs 50 Lakhs" },
    { backenedFees: "7000000", frontEndFees: "Rs 70 Lakhs" },
    { backenedFees: "9000000", frontEndFees: "Rs 90 Lakhs" },
    { backenedFees: "10000000", frontEndFees: "Rs 1 Crore" },
  ],
  toggleExamState: false,
  toggleCityState: false,
  toggleFacilityState: false,
  toggleStateName: false,
  toggleStudyModeState: false,
  toggleStreamState: false,
  toggleCoursesState: false,
};

const UniversityListCompare = () => {
  const [iState, updateState] = useState(init);
  const flag = JSON.parse(localStorage.getItem("flag"));
  const[addClass, setAddClass] = useState(false)
  const {
    studyMode,
    stream,
    acceptedExams,
    studyModeData,
    ExamAcceptedData,
    streamData,
    feesMax,
    sort,
    feesMin,
    facilitiesData,
    stateData,
    cityData,
    facilities,
    stateName,
    city,
    minFeesArray,maxFeesArray,
    toggleExamState,
    toggleCityState,
    toggleFacilityState,
    toggleStateName,
    toggleStudyModeState,
    toggleStreamState,
    toggleCoursesState,
  } = iState;
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { universityData, universityFilteredData } = useSelector(
    (stateName) => stateName.listReducer
  );
  const compareOneId = JSON.parse(localStorage.getItem("compareOneId"))
  const compareTwoId = JSON.parse(localStorage.getItem("compareTwoId"))
  const compareThreeId = JSON.parse(localStorage.getItem("compareThreeId"));
  const compareFourId = JSON.parse(localStorage.getItem("compareFourId"));
  const {state} = useLocation();
  useEffect(() => {
    dispatch(universeListAction());
    dispatch(universityFilterAction({ state:[] }));
  }, [state]);
  
  useEffect(() => {
    updateState({
      ...iState,
      ExamAcceptedData: universityFilteredData?.data?.ExamAccepted,
      studyModeData: universityFilteredData?.data?.StudyMode,
      streamData: universityFilteredData?.data?.Stream,
      facilitiesData: universityFilteredData?.data?.Facilities,
      stateData: universityFilteredData?.data?.State,
      cityData: universityFilteredData?.data?.City,
    });
  }, [universityFilteredData]);

  useEffect(() => {
    universityFilteredData?.data?.StudyMode?.map((ele, i) => {
      ele.checked = false;
    });
    universityFilteredData?.data?.ExamAccepted?.map((ele, i) => {
      ele.checked = false;
    });
    universityFilteredData?.data?.Stream?.map((ele, i) => {
      ele.checked = false;
    });
    universityFilteredData?.data?.State?.map((ele, i) => {
        ele.checked = false;
      });
      universityFilteredData?.data?.City?.map((ele, i) => {
        ele.checked = false;
      });
      universityFilteredData?.data?.Facilities?.map((ele, i) => {
        ele.checked = false;
      });
  }, [universityFilteredData]);

  // NORMAL CODE OF ISTATE AND ALL
  // NORMAL CODE OF ISTATE AND ALL
  // NORMAL CODE OF ISTATE AND ALL

  // FILTER CODE

  // STUDY MODE CHECKBOX CODE

  const handleInputStudyMode = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    let tempStudy = studyMode;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.StudyMode;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, studyModeData: temp });

    if (checked) {
      tempStudy = [...tempStudy, value];
      updateState({ ...iState, studyMode: tempStudy });
    } else {
      tempStudy = tempStudy.filter((item) => item !== value);
      updateState({ ...iState, studyMode: tempStudy });
    }
  };

  const handleClearStudyMode = () => {
    let temp = studyModeData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, studyModeData: temp, studyMode: [] });
  };

  // EXAM ACCEPTED CHECKBOX CODE

  const handleInputExamAccepted = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    // let tempStudy = studyMode;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.ExamAccepted;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, ExamAcceptedData: temp });

    if (checked) {
      let tempEntry = [...acceptedExams, value];
      updateState({ ...iState, acceptedExams: tempEntry });
    } else {
      let tempExit = acceptedExams.filter((item) => item !== value);
      updateState({ ...iState, acceptedExams: tempExit });
    }
  };

  const handleClearExamAccepted = () => {
    let temp = ExamAcceptedData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, ExamAcceptedData: temp, acceptedExams: [] });
  };

  //   STREAM CHECKBOX CODE

  const handleInputStream = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    // let tempStudy = studyMode;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.Stream;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, streamData: temp });

    if (checked) {
      let tempEntry = [...stream, value];
      updateState({ ...iState, stream: tempEntry });
    } else {
      let tempExit = stream.filter((item) => item !== value);
      updateState({ ...iState, stream: tempExit });
    }
  };

  const handleClearStream = () => {
    let temp = streamData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, streamData: temp, stream: [] });
  };



//   =====================STATE ======================
const handleInputFacilities = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    let tempFacility = facilities;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.Facilities;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, facilitiesData: temp });

    if (checked) {
        tempFacility = [...tempFacility, value];
      updateState({ ...iState, facilities: tempFacility });
    } else {
        tempFacility = tempFacility.filter((item) => item !== value);
      updateState({ ...iState, studyMode: tempFacility });
    }
  };
  const handleClearFacilities = () => {
    let temp = facilitiesData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, facilitiesData: temp, facilities: [] });
  };

  const handleInputState = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    let tempState = stateName;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.State;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, stateData: temp });

    if (checked) {
        tempState = [...tempState, value];
      updateState({ ...iState, stateName: tempState });
    } else {
        tempState = tempState.filter((item) => item !== value);
         updateState({ ...iState, stateName: tempState });
    }
  };

  const handleClearState = () => {
    let temp = stateData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, stateData: temp, stateName: [] });
  };

  const handleInputCity = (e, checkedValue) => {
    const { name, checked, value } = e.target;
    let tempCity = city;
    let tempUni = universityFilteredData;
    let temp = tempUni?.data?.City;
    temp.forEach((element, index) => {
      if (element?._id == value) {
        temp[index].checked = checked;
      }
    });
    updateState({ ...iState, cityData: temp });

    if (checked) {
        tempCity = [...tempCity, value];
      updateState({ ...iState, city: tempCity });
    } else {
        tempCity = tempCity.filter((item) => item !== value);
         updateState({ ...iState, city: tempCity });
    }
  };

  const handleClearCity = () => {
    let temp = cityData;
    temp?.map((ele) => {
      ele.checked = false;
    });
    updateState({ ...iState, cityData: temp, city: [] });
  };
  const handleFees = (e) => {
    const {name,value} = e.target
    updateState({...iState, [name]:value})
  }

  const handleClearFees = () => {   
    updateState({ ...iState, feesMin: "", feesMax: "" });
  };


  const handleFilteredData = () => {
    
    dispatch(
      universeListAction({
        studyMode,
        acceptedExams,
        stream,
        feesMax,
        feesMin,
        facilities,
        city,state:stateName
      })
    );
    setAddClass(false)
    // updateState({ ...iState, ExamAccepted: [], studyMode: [], stream: [], facilities:[], stateName:[], city:[], feesMax:"", feesMin:"" });
  };
  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(universeListAction({ page: pageNumber }));
  };


   
  // const splitFees = () => {
  //   updateState({ ...iState, feesMin: value[0], feesMax: value[1] });
  // };

  const toggleFuncExam = () => {
    updateState({ ...iState, toggleExamState: !toggleExamState });
  };

  const toggleFuncStream = () => {
    updateState({ ...iState, toggleStreamState: !toggleStreamState });
  };

  const toggleFuncFacilities = () => {
    updateState({ ...iState, toggleFacilityState: !toggleFacilityState });
  };

  const toggleFuncState = () => {
    updateState({ ...iState, toggleStateName: !toggleStateName });
  };

  const toggleFuncCity = () => {
    updateState({ ...iState, toggleCityState: !toggleCityState });
  };

  const toggleFuncStudyMode = () => {
    updateState({ ...iState, toggleStudyModeState: !toggleStudyModeState });
  };

  const toggleFuncCoursesState = () => {
    updateState({ ...iState, toggleCoursesState: !toggleCoursesState });
  };


  const goToComparePage = (id, type,ratingCount,rating) => {
    const sendCompareData = {
      ratingCount,
      rating,id
    }
    if(compareTwoId == null && compareOneId === null && compareThreeId === null && compareFourId === null){
      localStorage.setItem("compareOneId", JSON.stringify(sendCompareData))
    } 
    else if(compareOneId == null){
      localStorage.setItem("compareOneId", JSON.stringify(sendCompareData))
    }
    else if(compareTwoId == null){
      localStorage.setItem("compareTwoId", JSON.stringify(sendCompareData))
    } else if (compareOneId === null){
      localStorage.setItem("compareOneId", JSON.stringify(sendCompareData))
    } else if (compareThreeId === null){
      localStorage.setItem("compareThreeId", JSON.stringify(sendCompareData))
    } 
    else if (compareFourId === null){
      localStorage.setItem("compareFourId", JSON.stringify(sendCompareData))
    } 
    navigate(`/compare/${id}/University`, {
      state:{
        id,type

      }
    })    
  }

  useEffect(() => {
    // 👇 add class to body element
    console.log(addClass)
    if(addClass){
      document.body.classList.add('filterAdd');
    } else {
      document.body.classList.remove('filterAdd');
    }
   
    if (document.body.classList.contains('bg-salmon')) {
      console.log('body tag contains class');
    }
  }, [addClass]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  
    dispatch(universeListAction({"sort":value}));
  }


  return (
    <div>
      <Header />

      <section>
        <div class="SearchArea">
         
        </div>
      </section>

      <section>
        <div class="UniversityArea">
          <div class="container">
            <div class="UniversitySort">
              <aside>
              <button className="FilterOpen" onClick={() => { setAddClass(true) }}><i class="fa fa-filter" aria-hidden="true"></i></button>
                <span>Sort -</span>
                <select name="sort" value={sort} onChange={handleInput} >
                  <option value="A-Z">A to Z</option>
                  <option value="Z-A">Z to A</option>
                  <option value="newFirst">New First</option>
                  <option value="oldestFirst">Old First</option>
                </select>
                <i class="fa fa-angle-down"></i>
              </aside>
            </div>

            <div class="row">
              <div class="col-sm-3 padding_none">
                <div class="FilterArea">
                  <div class="FilterHead">
                    <h3>Filters</h3>
                  </div>

                  <div class="FilterBox">
                    <article>
                      <h3>Study Mode</h3>
                      <a onClick={handleClearStudyMode}>Clear all</a>
                    </article>
                    <aside>
                      <ul  className={
                        "HeightFixed normalFixed"
                          // toggleStudyModeState ? "HeightAuto" : "HeightFixed"
                        }>
                        {universityFilteredData?.data?.StudyMode &&
                        universityFilteredData?.data?.StudyMode?.length > 0 ? (
                          <>
                            {studyModeData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {ele?._id} ({ele?.total} Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id}
                                        checked={ele?.checked}
                                        onChange={(e) =>
                                          handleInputStudyMode(e, ele?.checked)
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}
                      </ul>
                      {universityFilteredData?.data?.StudyMode?.length > 5 ? (
                        <>
                          {toggleStudyModeState ? (
                            <li>
                              <p onClick={toggleFuncStudyMode}>
                                <a href="javascript:void(0);">- View less</a>
                              </p>
                            </li>
                          ) : (
                            <li>
                              <p onClick={toggleFuncStudyMode}>
                                <a href="javascript:void(0);">+ View more</a>
                              </p>
                            </li>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </aside>
                  </div>

                  <div class="FilterBox">
                    <article>
                      <h3>Fees Range</h3>
                      <a href="javascript:void(0);" onClick={handleClearFees}>
                        Clear all
                      </a>
                    </article>
                    <article class="min-max-section">
                    <aside>
                      <div class="form-group">
                        <label>Minimum Fees</label>
                        <select class="form-control" name="feesMin" value={feesMin} onChange={handleFees}>
                          <option value="">--Select--</option>
                          {minFeesArray?.map((ele, i) => (
                        <option value={ele?.backenedFees}>
                          {ele?.frontEndFees}
                        </option>
                      ))}
                        </select>
                        {/* <span style="color: red; font-size: 14px;"> </span> */}
                      </div>
                    </aside>
                    <aside>
                      <div class="form-group">
                        <label>Maximum Fees</label>
                        <select class="form-control" name="feesMax" value={feesMax} onChange={handleFees}>
                          <option value="">--Select--</option>
                          {maxFeesArray?.map((ele, i) => (
                        <option value={ele?.backenedFees}>
                          {ele?.frontEndFees}
                        </option>
                      ))}
                        </select>
                        {/* <span style="color: red; font-size: 14px;"> </span> */}
                      </div>
                    </aside>
                    </article>
                  </div>
                  <div class="FilterBox">
                    <article>
                     
                      <h3>Facilities</h3>
                      <a onClick={handleClearFacilities} href="javascript:void(0);">Clear all</a>
                    </article>
                    <aside>
                    <ul className={toggleFacilityState ? "HeightAuto" : "HeightFixed" } >
                        {universityFilteredData?.data?.Facilities &&
                        universityFilteredData?.data?.Facilities?.length > 0 ? (
                          <>
                            {facilitiesData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {`${ele?._id}`} ({ele?.total} Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id}
                                        checked={ele?.checked}
                                        onChange={handleInputFacilities}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}
                        <li>
                          {/* <p><a href="javascript:void(0);">+ View more</a></p> */}
                        </li>
                      </ul>
                      {universityFilteredData?.data?.Facilities?.length > 5 ? (
                        <>
                          {toggleFacilityState ? (
                            <li>
                              <p onClick={toggleFuncFacilities}>
                                <a href="javascript:void(0);">- View less</a>
                              </p>
                            </li>
                          ) : (
                            <li>
                              <p onClick={toggleFuncFacilities}>
                                <a href="javascript:void(0);">+ View more</a>
                              </p>
                            </li>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </aside>
                  </div>

                  <div class="FilterBox">
                    <article>
                     
                      <h3>State</h3>
                      <a onClick={handleClearState} href="javascript:void(0);">Clear all</a>
                    </article>
                    <aside>
                    <ul
                        className={
                          toggleStateName ? "HeightAuto" : "HeightFixed"
                        } >
                        {universityFilteredData?.data?.State &&
                        universityFilteredData?.data?.State?.length > 0 ? (
                          <>
                            {stateData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {`${ele?._id}`} ({ele?.total} Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id}
                                        checked={ele?.checked}
                                        onChange={handleInputState}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}
                        <li>
                          {/* <p><a href="javascript:void(0);">+ View more</a></p> */}
                        </li>
                      </ul>
                      {universityFilteredData?.data?.State?.length > 5 ? (
                        <>
                          {toggleStateName ? (
                            <li>
                              <p onClick={toggleFuncState}>
                                <a href="javascript:void(0);">- View less</a>
                              </p>
                            </li>
                          ) : (
                            <li>
                              <p onClick={toggleFuncState}>
                                <a href="javascript:void(0);">+ View more</a>
                              </p>
                            </li>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </aside>
                  </div>

                  <div class="FilterBox">
                    <article>
                     
                      <h3>City</h3>
                      <a onClick={handleClearCity} href="javascript:void(0);">Clear all</a>
                    </article>
                    <aside>
                    <ul className={
                          toggleCityState ? "HeightAuto" : "HeightFixed"}>
                        {universityFilteredData?.data?.City &&
                        universityFilteredData?.data?.City?.length > 0 ? (
                          <>
                            {cityData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {`${ele?._id}`} ({ele?.total} Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id}
                                        checked={ele?.checked}
                                        onChange={handleInputCity}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}
                        <li>
                          {/* <p><a href="javascript:void(0);">+ View more</a></p> */}
                        </li>
                      </ul>
                      {universityFilteredData?.data?.City?.length > 5 ? (
                        <>
                          {toggleCityState ? (
                            <li>
                              <p onClick={toggleFuncCity}>
                                <a href="javascript:void(0);">- View less</a>
                              </p>
                            </li>
                          ) : (
                            <li>
                              <p onClick={toggleFuncCity}>
                                <a href="javascript:void(0);">+ View more</a>
                              </p>
                            </li>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </aside>
                  </div>

                  <div class="FilterBox">
                    <article>
                      <h3>Stream</h3>
                      <a onClick={handleClearStream}>Clear all</a>
                    </article>
                    <aside>
                      <ul>
                        {universityFilteredData?.data?.Stream &&
                        universityFilteredData?.data?.Stream?.length > 0 ? (
                          <>
                            {streamData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {`${ele?.streamInfo?.streamName}`} (
                                      {ele?.total} Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id}
                                        checked={ele?.checked}
                                        onChange={handleInputStream}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}
                        <li>
                          {/* <p><a href="javascript:void(0);">+ View more</a></p> */}
                        </li>
                      </ul>
                    </aside>
                  </div>

                  <div class="FilterBox">
                    <article>
                      <h3>Exam Accepted</h3>
                      <a onClick={handleClearExamAccepted}>Clear all</a>
                    </article>
                    <aside>
                    <ul
                        className={
                          toggleExamState ? "HeightAuto" : "HeightFixed"
                        }
                      >
                        {universityFilteredData?.data?.ExamAccepted &&
                        universityFilteredData?.data?.ExamAccepted?.length >
                          0 ? (
                          <>
                            {ExamAcceptedData?.map((ele, index) => {
                              return (
                                <>
                                  <li>
                                    <label class="Checkbox">
                                      {`${ele?._id.toString()}`} ({ele?.total}{" "}
                                      Results)
                                      <input
                                        type="checkbox"
                                        value={ele?._id.toString()}
                                        checked={ele?.checked}
                                        onChange={handleInputExamAccepted}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>NO DATA FOUND </h2>
                          </>
                        )}

                        {/* <li>
                                                    <label class="Checkbox">
                                                        Category 5 (20 Results)
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li> */}
                        <li>
                          {/* <p><a href="javascript:void(0);">+ View more</a></p> */}
                        </li>
                      </ul>
                      {universityFilteredData?.data?.ExamAccepted?.length > 5 ? (
                        <>
                          {toggleExamState ? (
                            <li>
                              <p onClick={toggleFuncExam}>
                                <a href="javascript:void(0);">- View less</a>
                              </p>
                            </li>
                          ) : (
                            <li>
                              <p onClick={toggleFuncExam}>
                                <a href="javascript:void(0);">+ View more</a>
                              </p>
                            </li>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                    
                    </aside>
                  </div>
                  <button onClick={handleFilteredData}>Apply</button>
                </div>
              </div>
              <div class="col-sm-9">
                {universityData?.data?.result &&
                universityData?.data?.result?.length > 0 ? (
                  <>
                    {universityData?.data?.result?.map((ele, index) => {
                      return (
                        <>
                          <div class="UniversityBox">
                            <Link to={`/description-university/${ele?._id}`}
                                      state={ele} class="UniversityLeft">
                            <img src={ele?.thumbnail} />
                            </Link>
                            <div class="UniversityRight">
                              <div class="UniversityName">
                                <Link 
                                to={`/description-university/${ele?._id}`}
                                
                                      state={ele}>
                                  <h3>
                                     
                                      {ele?.name}
                                    
                                  </h3>
                                  <ul>
                                    <li>
                                      <img
                                        src={require("../assets/images/Location.png")}
                                      />{" "}
                                      {ele?.city},{ele?.state}
                                    </li>
                                    <li>
                                    <StarRatings
                            rating={ele?.ratingCount}
                            starRatedColor="yellow"
                            // changeRating={this.changeRating}
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="2px"
                            name="rating"
                          />
                          <span>({ele?.rating})</span>
                                    </li>
                                  </ul>
                                </Link>
                                {/* <aside>
                                  <a
                                    class="WishIcon"
                                    href="javascript:void(0);"
                                  >
                                    <i class="fa fa-heart-o"></i> Add to
                                    Wishlist
                                  </a> 
                                </aside>*/}
                              </div>
                              {ele?.rankings && ele?.rankings?.length > 0 ? <>
                              <div class="UniversityRank">
                                <h6>Ranking :</h6>
                                <ul>
                                
                                    <li>
                                    <span class="Icon">
                                      {/* <img
                                        src={require("../assets/images/Ranking-1.png")}
                                      /> */}
                                    </span>
                                    <label>
                                    {(ele?.rankings?.[0]?.soureOfRanking)?ele?.rankings?.[0]?.soureOfRanking:""} : <strong>{(ele?.rankings?.[0]?.ranking)?ele?.rankings?.[0]?.ranking:""}/10</strong>
                                    </label>
                                  </li>
                                 
                                
                                  {/* <li>
                                    <span class="Icon">
                                      <img
                                        src={require("../assets/images/Ranking-2.png")}
                                      />
                                    </span>
                                    <label>
                                      Outlook :{" "}
                                      <strong>
                                        {(ele?.rankings?.[0]?.ranking)?ele?.rankings?.[0]?.ranking:0}/10
                                        {ele?.rankingOutlook}/10</strong>
                                    </label>
                                  </li> */}
                                  </ul>
                                </div>
                                </> : <></>}
                             
                             
                          
                              <div class="UniversityType">
                              <button disabled={ele?._id == state?.id2 || ele?._id == state?.id3 || ele?._id == state?.id4 }  onClick={() =>goToComparePage(ele?._id, "University",ele?.ratingCount,ele?.rating )} class="Select">Select</button>
                                <h6>
                                  Type : <span>{ele?.studyType}</span>
                                </h6>
                              </div>
                              <div class="UniversityFee">
                                <p>
                                  <span>
                                    <img
                                      src={require("../assets/images/Book.png")}
                                    />
                                  </span>{" "}
                                  {ele?.studyMode}
                                </p>
                                <p>
                                  <strong>Fees Range : </strong>{" "}
                                  {`${ele?.feesMin} to ${ele?.feesMax}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <h2>NO DATA FOUND</h2>
                  </>
                )}
              </div>
              <div class="Pagination">
                <p>
                  {" "}
                  Total records : <span> {universityData?.data?.total}</span>
                </p>

                <ul>
                  {universityData?.data?.total && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={universityData?.data?.total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default UniversityListCompare;




