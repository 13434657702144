import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {toast} from "react-toastify"
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";


import {LogoutUserAction} from "../../redux/actions/logoutAction"
import { studentDetailsAction } from "../../redux/actions/studentAction";
 
const Sidenav = () => {
    const Profie =JSON.parse(localStorage.getItem('websiteData'));

    let path = window.location.href.split('/');
    let pathLength = parseInt(path.length) - 1;
    let pathMatch = path[pathLength];


     const dispatch= useDispatch();
     const navigate = useNavigate()
    const handleLogout=(e)=>{
        e.preventDefault()
        const data = {_id :Profie._id}
        //console.log(data)

    

        dispatch(LogoutUserAction(data))
        .then((res) => {
            if (res.status === 200) {
              navigate("/");
            //   dispatch(studentDetailsAction())
              localStorage.removeItem("websiteData");
              window.location.reload();

              


            } else {
              toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
           })
        .catch((err) => {
        alert(err);
         });


    }



    return (
        <div class="Sidenav">
            <ul>
                <li className={path[pathLength] === "my-profile" || path[pathLength] === "my-profile-edit" ? "active" :''}>
                    <Link to="/my-profile">My Profile</Link>
                </li>
                <li className={path[pathLength] === "wishlist" ? "active" :''}>
                    <Link to="/wishlist">WishList</Link>
                </li>
                <li className={path[pathLength] === "notification" ? "active" :''}>
                    <Link to="/notification">Notifications</Link>
                </li>
                <li className={path[pathLength] === "settings" || path[4] === "settings-password" ? "active" :''}>
                    <Link to="/settings">Settings</Link>
                </li>
                <li>
                    <a onClick={handleLogout} >Logout</a>
                </li>
            </ul>
        </div>
    );
}

export default Sidenav