import React, { useEffect } from 'react'
import "../App.css";

import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacyPolicyAction } from '../redux/actions/staticManagementAction';

const PrivacyDetails = () => {
    const dispatch = useDispatch();
    const {privacyData} = useSelector((state)=> state.staticManagementReducer)
    useEffect(() =>{
        dispatch(getPrivacyPolicyAction())
        window.scrollTo(0, 0);
    },[])
    console.log("privacyData",privacyData)
  return (
  
    <div>
        <Header />
        <section>
            <div class="SearchArea NewsBreadcumb">
                <div class="container">
                    <h3>Privacy Policy</h3>
                    <p>Home / Privacy Policy</p>
                </div>
            </div>
        </section>
        <section>
            <div class="AboutArea"> 
                <div className='container'>
             
                   
                    <h1>Privacy Policy</h1>



                    <div
                                          dangerouslySetInnerHTML={{
                                            __html: privacyData?.data,
                                          }}
                                          className="editor"
                                        ></div>


{/* 
                    <h3>1. Introduction</h3>
                    <p>Welcome to CareerDekhoo Edtech Services.</p>
                    
                    <p>This privacy policy ("policy") will help to understand that the terms (“us”, “we”, or “our”) used in this document refers to CareerDekhoo Edtech Services which operates www.careerdekhoo.com (hereinafter referred to as “Service”) whereas "You" / "Your" / "Yourself" refer to the users, who visit / access or use (collectively "usage") this website.</p>
                    
                    <p>Our Privacy Policy governs your visit to www.careerdekhoo.com, and explains how we collect, safeguard and disclose information that results from your use of our Service.</p>

                    <p>Careerdekhoo.com search engine provides information about the courses/careers or colleges/universities/schools/pre-schools/institutions and assists students/parents by required and relevant information on the admission process details, colleges/institutions of their interest. We do not take applications/registrations on behalf of any college or institution irrespective of whether they are partner colleges or other listed colleges. By applying to any college/ educational institution on the website, the user/visitor is deemed to have applied for the assistance of the CareerDekhoo support team.</p>
                    
                    <p>Kindly read this policy carefully. By visiting our website/reading this privacy policy you acknowledged that you have understood, agreed, and given consent to the terms and conditions of this policy under relevant sections of India Information Technology Act,2000 for collection, use, storage, processing, sharing and transfer/discloser of your information. </p>            
                    
                    <p>We use your data to provide and improve Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                    <p>Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“agreement”).</p>

                    <h3>2. Definitions</h3>

                    <p>SERVICE means the www.careerdekhoo.com website operated by CareerDekhoo Edtech Services</p>

                    <p>PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                    </p>

                    <p>USAGE DATA is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                    </p>

                    <p>COOKIES are small files stored on your device (computer or mobile device).
                    </p>

                    <p>DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>

                    <p>DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>

                    <p>
                        DATA SUBJECT is any living individual who is the subject of Personal Data.
                    </p>
                    <p>
                        THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
                    </p>

                    <h3>3. Information Collection and Use</h3>

                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                    <h3>4. Types of Data Collected</h3>
                    <h4>Personal Data</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally, identifiable information may include, but is not limited to:</p>
                    <ol>
                        <li>Email address</li>
                        <li>First name and last name; gender, age; DOB; financial information (as per requirement) <br/>
</li>
<li>Phone number, Address, Country, State, Province, ZIP/Postal code, City <br/>
</li>
<li>Cookies and Usage Data</li>
                    </ol>
                  
                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or sending us to email at grivance@careerdekhoo.com with a short description. </p>

                    <h4>Usage Data</h4>
                    <p>We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).</p>
                    <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data</p>

                    <h4>Location Data</h4>
                    <p>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p>

                    <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>

                    <h3>Tracking Cookies Data</h3>

                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. <br/>
                        Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used to track information about your activity on the website and to improve as well as analyze our Service. <br/>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.
                    </p>
                    <p>Examples of Cookies we use:</p>
                    <ol>
                        <li>Session Cookies: We use Session Cookies to operate our Service.</li>
                        <li>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li>
                        <li>Security Cookies: We use Security Cookies for security purposes.</li>
                        <li>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                    </ol>

                    <h4>Other Data</h4>

                    <p>While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at the place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.</p>
                    
                    <h3>5. Use of Data and Retention of Data</h3>
                    <p>CareerDekhoo Edtech Services uses the collected data for various purposes:</p>

                    <ul>
                        <li>to provide and maintain our Service;</li>
                        <li>to notify you about changes to our Service;</li>
                        <li>to allow you to participate in interactive features of our Service when you choose to do so;</li>
                        <li>to provide customer support;</li>
                        <li>to gather analysis or valuable information so that we can improve our Service;</li>
                        <li>to monitor the usage of our Service; We might use your information to confirm your registration for an event or contest. </li>
                        <li>to detect, prevent and address technical issues; to fulfil any other purpose for which you provide it</li>
                        <li>to fulfil any other purpose for which you provide it;</li>
                        <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                        <li>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</li>
                        <li>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
                        <li>in any other way we may describe when you provide the information;</li>
                        <li>for any other purpose with your consent.</li>
                        <li>to keep you informed about news, events, and ideas regarding your preference and help you to gain more knowledge and information about the preferred content. </li>
                        <li>to provide access to us to display advertisements to our advertiser’s target audiences.</li>
                    </ul>

                    <h3>6. Retention of Data</h3>

                    <ul>
                        <li>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</li>
                        <li>Your personal information gathered by us is kept in a form that permits your identification for no longer than is necessary for the purposes for which the personal information is processed in line with legal, regulatory, contractual, or statutory obligations as applicable.</li>
                        <li>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</li>
                    </ul>

                    <h3>7. Transfer of Data</h3>
                    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. <br/>
                        If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there. <br/>
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. <br/>
                        CareerDekhoo Edtech Services will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. <br/>
                        We may share your personal information data with our partnered educational institutions or the listed institutes on our website based on your expression of interest/search/visited pages so that they may consider you for further marketing and recruitment campaigns. Institutions can use your personal data to contact you to share information about admission offers/admission details/enrolments, or any other promotional events. <br/>
                        We may share your personal data with third-party vendors / other companies for their promotional activities/selling of goods / or showing you ads of your interest or for companies using AI (Artificial intelligence) platforms. <br/>
                        We also reserve the right to share your information with any government agency (law enforcement agencies) for any legal purpose or if requested as per the law or for the prevention of cyber incidents, verification of identity, or any related criminal investigations. 
                    </p>

                    <h3>8. Disclosure of Data</h3>
                    <p>We may disclose personal information that we collect, or you provide:</p>
                    
                    <h5>0.1. Disclosure for Law Enforcement.</h5>
                    <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>
                    <h5>0.2. Business Transaction.</h5>
                    <p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>

                    <h3>9. Other cases: We may disclose your information also:</h3>

                    <ul>
                        <li>to our subsidiaries and affiliates;</li>
                        <li>to contractors, service providers, and other third parties we use to support our business;</li>
                        <li>to fulfill the purpose for which you provide it;</li>
                        <li>for the purpose of including your company’s logo on our website;</li>
                        <li>or any other purpose disclosed by us when you provide the information;</li>
                        <li>with your consent in any other cases;</li>
                        <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
                    </ul>

                    <h3>10. Security of Data</h3>
                    <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                    <p><b>To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through the de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.</b></p>
                    <p>If you submit a request to stop selling your personal information, we will stop making such transfers.</p>
                    <p>Please note, if you ask us to delete or stop selling your data, it may affect your experience with us, and you may not be able to participate in certain programs or membership services, which require the usage of your personal information to function. However, in no circumstances, we will discriminate against you for exercising your rights.</p>
                    <p>To exercise your data protection rights described above, please send your request(s) by email: grivance@careerdekhoo.com.</p>

                    <h3>11. Service Providers</h3>
                    <p>We may employ third parties and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used. <br/>
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </p>

                    <h3>12. Analytics</h3>
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

                    <h3>13. CI/CD tools</h3>
                    <p>We may use third-party Service Providers to automate the development process of our Service.</p>

                    <h3>14. Advertising</h3>
                    <p>We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.</p>

                    <h3>15. Behavioural Remarketing</h3>
                    <p>We may use remarketing services to advertise on third-party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>

                    <h3>16. Links to Other Sites</h3>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. <br/>
                        We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services, so we advise the user to verify such information before making any decision on the promotional content/advertisements published on the website. <br/>
                        This policy is only applicable for Careerdekhoo.com, not for other websites linked or advertisements/third-party promotional content published on the website. 
                    </p>

                    <h3>17. Children’s Privacy</h3>
                    <p>Our Services are not intended for use by children under the age of 13 (“Child” or “Children”). <br/>
                        By visiting the website and creating the account, the user agrees with the minimum age requirement terms and gives consent that the user fully fills in the mentioned requirements. <br/>
                        We do not knowingly collect personally identifiable information from children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.
                    </p>

                    <h3>18. Changes to This Privacy Policy</h3>
                    <p>We may update /modify /reserve the right to update our Privacy Policy at any time, as and when change is required. We will notify you of any changes by posting the new Privacy Policy on this page. <br/>
                        Your continued use of our website gives your unconditional acceptance of such updates/modifications/changes in the Privacy Policy. <br/>
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. 
                    </p>

                    <h3>19. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us by email: grivance@careerdekhoo.com.</p> */}
                    </div>
        
                </div>
            </section>
        <Footer/>
    </div>
   
  
  )
}

export default PrivacyDetails