import { blogListConstant } from "../actionType";


const initialState ={
    blogList:[],
    loader:false,
    error:{}
}



export default function blogListReducer(state=initialState,{type,payload}){
    switch(type){
        case blogListConstant.BLOG_LIST_INITIATE:
            return{...state,loader:true,  blogList:[]}
        case blogListConstant.BLOG_LIST_SUCCESS:
            return{...state,loader:false,  blogList :payload.data}

        case blogListConstant.BLOG_LIST_FAILURE:
            return{...state,  error:payload}

            default : return state;
    }

    }
