
import { isLoggedIn } from "../../utils";
import { signUPConstant } from "../actionType";
import  axios from 'axios';

import { baseUrl } from "../../config/Config";





//*************************              ADMIN LOGIN        ******************************* */

export const signUpInitiate=() =>({
    type:signUPConstant.SIGN_UP_INITIATE
})

export const signUpSuccess=(data) =>({
    type:signUPConstant.SIGN_UP_SUCCESS,
    payload:data

})

export const signUpFailure=(data) =>({
    type:signUPConstant.SIGN_UP_FAILURE,
    payload : data

})

// *******************    ADMIN LOGIN ACTION **************************************

export const  signUpAction=(payload)=>{
    return dispatch => {
        dispatch(signUpInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/studentSignUp', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(signUpSuccess(data))

                    }
                    else {
                        dispatch(signUpFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(signUpFailure(err))
                    reject(err);
                })

        );
    }

}




export const googleSignUpInitiate=() =>({
    type:signUPConstant.GOOGLE_SIGN_UP_INITIATE
})

export const googleSignUpSuccess=(data) =>({
    type:signUPConstant.GOOGLE_SIGN_UP_SUCCESS,
    payload:data

})

export const googleSignUpFailure=(data) =>({
    type:signUPConstant.GOOGLE_SIGN_UP_FAILURE,
    payload : data

})

// *******************    ADMIN LOGIN ACTION **************************************

export const  GoogleSignUpAction=(payload)=>{
    return dispatch => {
        dispatch(googleSignUpInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/socialLogin', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(googleSignUpSuccess(data))

                    }
                    else {
                        dispatch(googleSignUpFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(googleSignUpFailure(err))
                    reject(err);
                })

        );
    }

}










