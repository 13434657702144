import axios from "axios";
import { coursesAndCertificates } from "../actionType";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";





  export const CoursesListInitate = () => ({
    type: coursesAndCertificates.COURSES_LIST_INITIATE,
  });
  
  export const CoursesListSuccess = (data) => ({
    type: coursesAndCertificates.COURSES_LIST_SUCCESS,
    payload: data,
  });
  
  export const CoursesListFailure = (data) => ({
    type: coursesAndCertificates.COURSES_LIST_FAILURE,
    payload: data,
  });
  
  export function coachingListAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(CoursesListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/cerificateAndCoachingsList`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(CoursesListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(CoursesListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(CoursesListFailure(err))
            reject(err);
          });
      });
    };
  }

  export const CoursesDetailsInitate = () => ({
    type: coursesAndCertificates.COURSES_DETAILS_INITIATE,
  });
  
  export const CoursesDetailsSuccess = (data) => ({
    type: coursesAndCertificates.COURSES_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const CoursesDetailsFailure = (data) => ({
    type: coursesAndCertificates.COURSES_DETAILS_FAILURE,
    payload: data,
  });
  
  export function coachingDetailsAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(CoursesDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/cerificateAndCoachingsDetails`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                CoursesDetailsSuccess(data);
              //// console.log("storeDetails", data);
              resolve(data);
            } else {
                CoursesDetailsFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            CoursesDetailsFailure(err);
            reject(err);
          });
      });
    };
  }


  
  export const coachingFilterInitate = () => ({
    type: coursesAndCertificates.COURSES_FILTER_INITIATE,
  });
  
  export const coachingFilterSuccess = (data) => ({
    type: coursesAndCertificates.COURSES_FILTER_SUCCESS,
    payload: data,
  });
  
  export const coachingFilterFailure = (data) => ({
    type: coursesAndCertificates.COURSES_FILTER_FAILURE,
    payload: data,
  });
  
  export function coachingFilterAction(payload) {
    const token = isLoggedIn("adminData");
    return (dispatch) => {
      dispatch(coachingFilterInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/cerificateAndCoachingsFilters`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(coachingFilterSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(coachingFilterFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(coachingFilterFailure(err))
            reject(err);
          });
      });
    };
  }



    
  export const courseDropdownInitate = () => ({
    type: coursesAndCertificates.COURSE_DROPDOWN_INITIATE,
  });
  
  export const courseDropdownSuccess = (data) => ({
    type: coursesAndCertificates.COURSE_DROPDOWN_SUCCESS,
    payload: data,
  });
  
  export const courseDropdownFailure = (data) => ({
    type: coursesAndCertificates.COURSE_DROPDOWNN_FAILURE,
    payload: data,
  });
  
  export function courseDropdownAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(courseDropdownInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/courseDropDown`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(courseDropdownSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(courseDropdownFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(courseDropdownFailure(err))
            reject(err);
          });
      });
    };
  }

export const courseDetailsInitate = () => ({
  type: coursesAndCertificates.COURSE_DETAILS_INITIATE,
});

export const courseDetailsSuccess = (data) => ({
  type: coursesAndCertificates.COURSE_DETAILS_SUCCESS,
  payload: data,
});

export const courseDetailsFailure = (data) => ({
  type: coursesAndCertificates.COURSE_DETAILS_FAILURE,
  payload: data,
});

export function courseDetailsAction(payload) {
  const token = isLoggedIn("websiteData");
  console.log("token----->", token)
  return (dispatch) => {
    dispatch(courseDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/website/courseDetails`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status == 200) {
              courseDetailsSuccess(data);
            //// console.log("storeDetails", data);
            resolve(data);
          } else {
              courseDetailsFailure(data);
            reject(data);
          }
        })
        .catch((err) => {
          courseDetailsFailure(err);
          reject(err);
        });
    });
  };
}

   
export const courseListInitate = () => ({
  type: coursesAndCertificates.COURSES_WEBSITE_LIST_INITIATE,
});

export const courseListSuccess = (data) => ({
  type: coursesAndCertificates.COURSES_WEBSITE_LIST_SUCCESS,
  payload: data,
});

export const courseListFailure = (data) => ({
  type: coursesAndCertificates.COURSES_WEBSITE_LIST_FAILURE,
  payload: data,
});

export function courseListAction(payload) {
  const token = isLoggedIn("websiteData");
  return (dispatch) => {
    dispatch(courseListInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/website/courseListWebsite`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          // //// console.log("data", response)
          if (data && data.status == 200) {
             dispatch(courseListSuccess(data));
            //// console.log("storeList", data);
            resolve(data);
          }
         
           else {
            dispatch(courseListFailure(data))
            reject(data)
          }
        })
        .catch((err) => {
          dispatch(courseListFailure(err))
          reject(err);
        });
    });
  };
}
