import {getStateCityConstant} from "../actionType"

const initialState ={
    stateList:[],
    cityList:[],
    loader:false,
    error:{}
}



export default function stateCityReducer(state=initialState,{type,payload}){
    switch(type){
        case getStateCityConstant.GET_STATE_LIST_INITIATE:
            return{...state,loader:true,  blogList:[]}
        case getStateCityConstant.GET_STATE_LIST_SUCCESS:
            return{...state,loader:false,  stateList :payload.data}

        case getStateCityConstant.GET_STATE_LIST_FAILURE:
            return{...state,  error:payload}


            case getStateCityConstant.GET_CITY_LIST_INITIATE:
                return{...state,loader:true,  cityList:[]}
            case getStateCityConstant.GET_CITY_LIST_SUCCESS:
                return{...state,loader:false,  cityList :payload}
    
            case getStateCityConstant.GET_CITY_LIST_FAILURE:
                return{...state,  error:payload}
    

            default : return state;
    }

    }
