import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";
import {toast} from "react-toastify"

import {UpdatePasswordAction} from "../../redux/actions/updatePasswordAction"
import { useDispatch, } from "react-redux";


const initialState= {
    oldPassword:'',
    password :'',
    confirmPassword:'',
    oldPasswordError:'',
    passwordError:'',
    confirmPasswordError:'',
    errorMsg:''
}

const SettingsChangePassword = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();



    const [iState, updateState] = useState(initialState);
    const [password1Shown, setPassword1Shown] = useState(false);
    const [password2Shown, setPassword2Shown] = useState(false);
    const [password3Shown, setPassword3Shown] = useState(false);

    const toggle1PasswordVisiblity = () => {
      setPassword1Shown(password1Shown ? false : true);
    };
  
    const toggle2PasswordVisiblity = () => {
      setPassword2Shown(password2Shown ? false : true);
    };

    const toggle3PasswordVisiblity = () => {
        setPassword3Shown(password2Shown ? false : true);
      };
  

    const {oldPassword,password,confirmPassword,passwordError, oldPasswordError,confirmPasswordError,errorMsg} = iState;

    let handleValidation = () => {
        let passwordError = "";
        let confirmPasswordError = "";
        let oldPasswordError="";
        let formIsValid = true;

        if (!oldPassword) {
            oldPasswordError = "* Please enter Old Password.";
            formIsValid = false;
          } 

          if (!password) {
            passwordError = "* Please enter Password.";
            formIsValid = false;
          } 
          else if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password))
    {
        passwordError = "* Please enter strong password.";
        formIsValid = false;
    }

          if(!confirmPassword){
            confirmPasswordError = "* Please re- Enter Password.";
            formIsValid = false;  

          }
          else if(password !== confirmPassword){
            confirmPasswordError = "* Password Not Match !.";
            formIsValid = false  


          }

        
         
        

                updateState({
            ...iState,
            oldPasswordError,
            passwordError,
            confirmPasswordError,
            errorMsg,
            });
            return formIsValid;
        };

        const handleChange = (e) => {
            const { name, value } = e.target;
            updateState({
              ...iState,
              [name]: value,
            });
          };



          const  handleSubmit=(e)=>{
            e.preventDefault();
            let formIsValid = handleValidation();

            if(formIsValid){
                const userdata =JSON.parse(localStorage.getItem('websiteData'));

                    var data={newPassword:confirmPassword, oldPassword, _id:userdata._id}
        
    
    
                dispatch(UpdatePasswordAction(data))
                .then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                         navigate("/settings");
                        toast.success(res.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      } else {
                        toast.error(res.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        updateState({
                          ...iState,
                          errorMsg: res.message,
                          ChangePasswordError: "",
                          passwordError: "",
                        });
                      }
                     })
                     .catch((err) => {
                       alert(err);
                     });
    
  

    
                    }}
   



    return (
        <div>
            <Header />
            <section>
                <div class="DashboardArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-3">
                                <Sidenav />
                            </div>
                            <div class="col-sm-9">
                                <div class="OpeningArea ProfileEdit">
                                    <div class="OpeningBody">
                                        <div class="OpeningFilter">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <h4>Change Password</h4>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>Enter your old Password</label>
                                                        <input value={oldPassword} name="oldPassword"
                                                                            type={password1Shown?"text":"password"}

                                                         onChange={handleChange} class="form-control" placeholder="Enter your old Password" />
                                                                           <span onClick={toggle1PasswordVisiblity} className={password1Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                                                        {oldPasswordError && <p style={{color: "red"}}>{oldPasswordError}</p>}

                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>Create New Password</label>
                                                        <input value={password} name="password" onChange={handleChange} 
                    type={password2Shown?"text":"password"}
                    class="form-control" placeholder="Enter your new password" />
                                      <span onClick={toggle2PasswordVisiblity} className={password2Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                                                        {passwordError && <p style={{color: "red"}}>{passwordError}</p>}

                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>Confirm your password</label>
                                                        <input value={confirmPassword} name="confirmPassword" onChange={handleChange} 
                                                                            type={password3Shown?"text":"password"}

                                                        class="form-control" placeholder="Re enter your Password" />
                                                                          <span onClick={toggle3PasswordVisiblity} className={password3Shown?"Icon cross":"Icon"}><i   className="fa fa-eye" ></i></span>

                                                        {confirmPasswordError && <p style={{color: "red"}}>{confirmPasswordError}</p>}
                                                    </div>
                                                </div>
                                                <div class="col-sm-6"></div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <button onClick={handleSubmit}>Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default SettingsChangePassword