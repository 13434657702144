import { isLoggedIn } from "../../utils"
import { updatePasswordConstant , notification} from "../actionType";
import axios from "axios";

import { baseUrl } from "../../config/Config";

export const updatePasswordInitiate=() =>({
    type: updatePasswordConstant.UPDATE_PASSWORD_INITIATE

})

export const updatePasswordSuccess=(data) =>({
    type:updatePasswordConstant.UPDATE_PASSWORD_SUCCESS,
    payload:data

})

export const updatePasswordFailure=(data) =>({
    type:updatePasswordConstant.UPDATE_PASSWORD_FAILURE,
    payload : data

})




export const UpdatePasswordAction=(payload)=>{

    const token =isLoggedIn('websiteData');

    
        
    return dispatch => {
        dispatch(updatePasswordInitiate())

        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/changePassword',payload,{
                headers: { Authorization: `${token}`},})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(updatePasswordSuccess(data))

                    }
                    else {
                        dispatch(updatePasswordFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(updatePasswordFailure(err))
                    reject(err);
                })

        );
    }

}



export const notificationInitiate=() =>({
    type: notification.NOTIFICATION_INITIATE

})

export const notificationSuccess=(data) =>({
    type:notification.NOTIFICATION_SUCCESS,
    payload:data

})

export const notificationFailure=(data) =>({
    type:notification.NOTIFICATION_FAILURE,
    payload : data

})




export const NotificationAction=(payload)=>{

    const token =isLoggedIn('websiteData');

    
        
    return dispatch => {
        dispatch(notificationInitiate())

        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/updateNotificationSettings',payload,{
                headers: { Authorization: `${token}`},})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(notificationSuccess(data))

                    }
                    else {
                        dispatch(notificationFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(notificationFailure(err))
                    reject(err);
                })

        );
    }

}
