import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import OwlCarousel from 'react-owl-carousel2';


import {getAboutUsAction} from "../redux/actions/staticManagementAction"
import { useDispatch, useSelector } from "react-redux";

import { homePageListAction } from "../redux/actions/home-pageAction";


const AboutUs = () => {
    const navigate = useNavigate()


    const dispatch = useDispatch();

    const {aboutusData} = useSelector((state)=> state.staticManagementReducer)
    const { homePageData } = useSelector((state) => state.homePageReducer);


    useEffect(()=>{


        dispatch(getAboutUsAction())
        dispatch(homePageListAction())
    },[])

    console.log(aboutusData)



    const schoolOptions = {
        margin: 20,
        smartSpeed: 1000,
        autoplay: 5000,
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"],
        loop: true,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 2 },
        },

    };

    const goToSchools = (id) => {
        navigate("/list-school", {
          state: {
            stateNameee: "",
            type: "",
          },
        });
      };
      const goTopreSchools = (id) => {
        navigate("/list-preschool", {
          state: {
            stateNameee: "",
            type: "",
          },
        });
      };
    
      const goToCollege = (id) => {
        navigate("/list-college", {state : {filterName:"",filterID:""}})
      };
      
    
      const goToUniversitites = (id) => {
        navigate("/list-university", {
          state: "",
        });
      };
    
    return (
        <div>
            <Header />
            <section>
                <div class="SearchArea NewsBreadcumb">
                    <div class="container">
                        <h3>About Us</h3>
                        <p><Link to="/">Home</Link> / About Us</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="NewsDetailsArea">
                    <div class="container">
                        {/* <div class="row">
                            <div class="col-sm-6">
                                <div class="WelcomeBox">
                                    <h3>Welcome to Lasson global learning academy</h3>
                                    <p>Consequuntur atque bibendum sequi vivamus aliqua senectus hendrerit consectet tristique, consequatur laborum unde, aliquet laboris cillum sollicitudin pretium</p>
                                    <article>
                                        <figure>
                                            <img src={require('../assets/images/About-Icon.png')} />
                                        </figure>
                                        <figcaption>
                                            <h5>Semester Fall 2020</h5>
                                            <p>Commodo numquam occaecati ullamcorper, aliqua eget. Repelle ndus aute sem saepe.</p>
                                        </figcaption>
                                    </article>
                                    <Link to="/find-job">Find Jobs</Link>

                                    <h6><span>Have any question call us</span> 540-325-1523</h6>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="WelcomeBox">
                                    <h3>We help you get find better schoolarship</h3>
                                    <p>Consequuntur atque bibendum sequi vivamus aliqua senectus hendrerit consectet tristique, consequatur laborum unde, aliquet laboris cillum sollicitudin pretium</p>
                                    <article>
                                        <figure>
                                            <img src={require('../assets/images/About-Icon-2.png')} />
                                        </figure>
                                        <figcaption>
                                            <h5>Semester Fall 2020</h5>
                                            <p>Commodo numquam occaecati ullamcorper, aliqua eget. Repelle ndus aute sem saepe.</p>
                                        </figcaption>
                                    </article>
                                    <Link to="/find-job">Find Jobs</Link>

                                    <h6><span>Have any question call us</span> 540-325-1523</h6>
                                </div>
                            </div>
                        </div> */}

                        <div class="NewsDetailsRigth">
                            <figcaption>
                                {/* <blockquote>
                                    <p>Cillum morbi hac? Natoque, euismod veniam animi venenatis cubilia? Gravida harum nihil nemo, cillum diamlorem molestie habitant impedit hendrerit, sequi ut voluptas?</p>
                                    <h6>RT dbdio</h6>
                                </blockquote>

                                <ul>
                                    <li><strong>Fugiat suspendisse maxime excepteur cillum hic cum labore cenas. Invent</strong></li>
                                    <li><strong>Is terdum porta magna justo, ad netus neque tempor condimentum, rem montes inc?</strong></li>
                                    <li><strong>Fugiat suspendisse maxime excepteur cillum hic cum</strong></li>
                                </ul> */}


                                <p>
                                <div
                dangerouslySetInnerHTML={{
                  __html: aboutusData?.data,
                }}
                className="editor"
              ></div></p>


                            </figcaption>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="ProjectsArea">
                    <div class="container">
                        <div class="ProjectsHead">
                            <p>Find Perfect one</p>
                            <h6>Check our started projects</h6>
                        </div>
                        <div class="ProjectsBody">
                            <div id="demos">
                                <OwlCarousel options={schoolOptions}>
                                    <div class="item">
                                        <div class="ProjectsBox">
                                            <figure>
                                                <img src={require('../assets/images/Project-1.png')} />
                                            </figure>
                                            <figcaption>
                                                <h6>Status: Running Category: Art, Science, Web</h6>
                                                <h4>Deserunt placeat vel duis illum purus? Eu amet</h4>
                                                <p>Blandit in ac odit tempor exercitationem aliquet aenean eros convallis interdum felis interdum, massa labore asperiores accusantium ulpa</p>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ProjectsBox">
                                            <figure>
                                                <img src={require('../assets/images/Project-2.png')} />
                                            </figure>
                                            <figcaption>
                                                <h6>Status: Running Category: Art, Science, Web</h6>
                                                <h4>Deserunt placeat vel duis illum purus? Eu amet</h4>
                                                <p>Blandit in ac odit tempor exercitationem aliquet aenean eros convallis interdum felis interdum, massa labore asperiores accusantium ulpa</p>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ProjectsBox">
                                            <figure>
                                                <img src={require('../assets/images/Project-1.png')} />
                                            </figure>
                                            <figcaption>
                                                <h6>Status: Running Category: Art, Science, Web</h6>
                                                <h4>Deserunt placeat vel duis illum purus? Eu amet</h4>
                                                <p>Blandit in ac odit tempor exercitationem aliquet aenean eros convallis interdum felis interdum, massa labore asperiores accusantium ulpa</p>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ProjectsBox">
                                            <figure>
                                                <img src={require('../assets/images/Project-2.png')} />
                                            </figure>
                                            <figcaption>
                                                <h6>Status: Running Category: Art, Science, Web</h6>
                                                <h4>Deserunt placeat vel duis illum purus? Eu amet</h4>
                                                <p>Blandit in ac odit tempor exercitationem aliquet aenean eros convallis interdum felis interdum, massa labore asperiores accusantium ulpa</p>
                                            </figcaption>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
        <div class="ExploreType">
          <div class="container">
            <div class="row">
              <a class="col-sm-3">
                <div class="TypeInner">
                  <div class="TpeShape">
                    <span>
                      <p>{homePageData?.schools}+</p>
                    </span>
                  </div>
                  <h5>Schools</h5>
                  <p onClick={goToSchools}>Explore</p>
                </div>
              </a>
              <div class="col-sm-3">
                <div class="TypeInner">
                  <div class="TpeShape">
                    <span>
                      <p>{homePageData?.universities}+</p>
                    </span>
                  </div>
                  <h5>Universities</h5>
                  <p onClick={goToUniversitites}>Explore</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="TypeInner">
                  <div class="TpeShape">
                    <span>
                      <p>{homePageData?.colleges}+</p>
                    </span>
                  </div>
                  <h5>Colleges</h5>
                  <p onClick={goToCollege}>Explore</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="TypeInner">
                  <div class="TpeShape">
                    <span>
                      <p>{homePageData?.preSchools}+</p>
                    </span>
                  </div>
                  <h5>Pre-Schools</h5>
                  <p onClick={goTopreSchools}>Explore</p>
                </div>
              </div>
              {/* <div  class="col-sm-3">
                                <div class="TypeInner">
                                    <div class="TpeShape">
                                        <span>
                                            <p>{homePageData?.preSchools}+</p>
                                        </span>
                                    </div>
                                    <h5>Certificates</h5>
                                    <Link to="/list-certificate">Explore </Link>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>

            <section>
                <div class="JoinArea">
                    <div class="container">
                        <div class="JoinBox">
                            <aside>
                                <h3>Become a Partner</h3>
                                <p>Are you knowledge base tell just some words we will came back within 5 minutes</p>
                            </aside>
                            <form>
                                <input type="text" disabled="true" class="form-control" placeholder="Email address" />
                                <button disabled="true">Join Us</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
}

export default AboutUs