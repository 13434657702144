import axios from "axios";
import { collegeActionTypes } from "../actionType";
import {baseUrl} from "../../config/Config"
import { isLoggedIn } from "../../utils";


console.log(baseUrl)


export const collegeListInitate = () => ({
    type: collegeActionTypes.COLLEGE_LIST_INITIATE,
  });
  
  export const collegeListSuccess = (data) => ({
    type: collegeActionTypes.COLLEGE_LIST_SUCCESS,
    payload: data,
  });
  
  export const collegeListFailure = (data) => ({
    type: collegeActionTypes.COLLEGE_LIST_FAILURE,
    payload: data,
  });
  
  export function collegeListAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(collegeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl+'/website/collegeList', payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(collegeListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(collegeListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(collegeListFailure(err))
            reject(err);
          });
      });
    };
  }

  export const collegeDetailsInitate = () => ({
    type: collegeActionTypes.COLLEGE_DETAILS_INITIATE,
  });
  
  export const collegeDetailsSuccess = (data) => ({
    type: collegeActionTypes.COLLEGE_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const collegeDetailsFailure = (data) => ({
    type: collegeActionTypes.COLLEGE_DETAILS_FAILURE,
    payload: data,
  });
  
  export function collegeDetailsAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(collegeDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl+'/website/collegeDetails', payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                collegeDetailsSuccess(data);
              //// console.log("storeDetails", data);
              resolve(data);
            } else {
                collegeDetailsFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            collegeDetailsFailure(err);
            reject(err);
          });
      });
    };
  }


  export const collegeFilterInitate = () => ({
    type: collegeActionTypes.COLLEGE_FILTER_INITIATE,
  });
  
  export const collegeFilterSuccess = (data) => ({
    type: collegeActionTypes.COLLEGE_FILTER_SUCCESS,
    payload: data,
  });
  
  export const collegeFilterFailure = (data) => ({
    type: collegeActionTypes.COLLEGE_FILTER_SUCCESS,
    payload: data,
  });

  
  export function collegeFilterAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(collegeFilterInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl+'/website/collegeFilters', payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(collegeFilterSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(collegeFilterFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(collegeFilterFailure(err))
            reject(err);
          });
      });
    };
  }