import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";


const Notification = () => {

    return (
        <div>
            <Header />
            <section>
                <div class="DashboardArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-3">
                                <Sidenav />
                            </div>
                            <div class="col-sm-9">
                                <div class="NotificationsBox">
                                    <h4>NO DATA FOUND</h4>
                                    {/* <ul>
                                        <li>
                                            <h4>ABC International <span>24 Feb '21 , 2:00 PM</span> </h4>
                                            <p>We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </p>
                                        </li>
                                        <li>
                                            <h4>ABC International <span>24 Feb '21 , 2:00 PM</span> </h4>
                                            <p>We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </p>
                                        </li>
                                        <li>
                                            <h4>ABC International <span>24 Feb '21 , 2:00 PM</span> </h4>
                                            <p>We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </p>
                                        </li>
                                        <li>
                                            <h4>ABC International <span>24 Feb '21 , 2:00 PM</span> </h4>
                                            <p>We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </p>
                                        </li>
                                        <li>
                                            <h4>ABC International <span>24 Feb '21 , 2:00 PM</span> </h4>
                                            <p>We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </p>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Notification