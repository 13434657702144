import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";

import { HomepageSearchAction } from "../redux/actions/searchAction";
import StarRatings from "react-star-ratings";
import {
  AddFavouriteAction,
  RemoveFavouriteAction,
} from "../redux/actions/favouriteAction";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { getAddsListAction } from "../redux/actions/getAddsActions";
import NoDataFound from "./noDateFound";
import InputAuto from "./InputAuto";


const Search = () => {
  const { keywords } = useParams();
  const [seachItem, setSearchItem]= useState([])

  const [search, setSearch] = useState("");
  const [ShowSignInModal, setShowSignInModal] = useState(false);
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const { searchList } = useSelector((state) => state.searchReducer);
  const [addsMappingData, setAddsMappingData] = useState([]);
  const { getAddsListData } = useSelector((stateName) => stateName.listReducer);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddsListAction({ category: "Search Listing Page" }));
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(HomepageSearchAction({ search }))
    .then((res) => {
      if (res.status === 200) {
        setSearchItem(res?.data)
      }
    })
  };

  const handleReset = (e) => {
    e.preventDefault();
    dispatch(HomepageSearchAction({ search: "" }))
        .then((res) => {
      if (res.status === 200) {
        setSearchItem(res?.data)
      }
    })
    setSearchItem([]);
  };

  // const handleWishList = (e, Id, isFav) => {
  //     const userdata = JSON.parse(localStorage.getItem("websiteData"));

  //     e.preventDefault();
  //     if (!userdata) {
  //       toast.error("Please login to perform this action !", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });

  //       setShowSignInModal(true);
  //     } else {
  //       if (isFav === true) {
  //         dispatch(RemoveFavouriteAction({ schoolId: Id }))
  //           .then((res) => {
  //             if (res.status === 200) {
  //               dispatch(HomepageSearchAction());
  //             } else {
  //               toast.error(res.message, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //             }
  //           })
  //           .catch((err) => {
  //             alert(err);
  //           });
  //       } else {
  //         dispatch(AddFavouriteAction({ schoolId: Id }))
  //           .then((res) => {
  //             if (res.status === 200) {
  //               dispatch(HomepageSearchAction());
  //             } else {
  //               toast.error(res.message, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //             }
  //           })
  //           .catch((err) => {
  //             alert(err);
  //           });
  //       }
  //     }
  //   };

  let [searchListData, setSearchList] = useState([]);
  const handleWishList = (e, Id, isFav, index, type) => {
    const userdata = JSON.parse(localStorage.getItem("websiteData"));

    // console.log(Id, type, isFav);
    e.preventDefault();
    if (!userdata) {
      // toast.error("Please login to perform this action !", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      setShowSignInModal(true);
    } else {
      if (type === "university") {
        // console.log("university");
        if (isFav === true) {
          searchListData[index].isFav = false;
          let value = [...searchListData];
          setSearchList(value);

          dispatch(RemoveFavouriteAction({ universityId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          // console.log("inside");
          searchListData[index].isFav = true;
          let value = [...searchListData];


          setSearchList(value);

          dispatch(AddFavouriteAction({ universityId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      } else if (type === "school") {
        if (isFav === true) {
          searchListData[index].isFav = false;
          let value = [...searchListData];
          setSearchList(value);

          dispatch(RemoveFavouriteAction({ school: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          searchListData[index].isFav = true;
          let value = [...searchListData];

          // console.log(value, "++++++++++++++++++");

          setSearchList(value);

          dispatch(AddFavouriteAction({ school: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      } else if (type === "pre_school") {
        if (isFav === true) {
          searchListData[index].isFav = false;
          let value = [...searchListData];
          setSearchList(value);

          dispatch(RemoveFavouriteAction({ preSchoolId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          searchListData[index].isFav = true;
          let value = [...searchListData];

          // console.log(value, "++++++++++++++++++");



          setSearchList(value);

          dispatch(AddFavouriteAction({ preSchoolId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      } else if (type === "certificate") {
        if (isFav === true) {
          searchListData[index].isFav = false;
          let value = [...searchListData];
          setSearchList(value);

          dispatch(RemoveFavouriteAction({ certificateId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          searchListData[index].isFav = true;
          let value = [...searchListData];

          // console.log(value, "++++++++++++++++++");

          setSearchList(value);

          dispatch(AddFavouriteAction({ preSchoolId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // (coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      } else if (type === "college") {
        if (isFav === true) {
          searchListData[index].isFav = false;
          let value = [...searchListData];
          setSearchList(value);

          dispatch(RemoveFavouriteAction({ collegeId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          searchListData[index].isFav = true;
          let value = [...searchListData];

          // console.log(value, "++++++++++++++++++");

          setSearchList(value);

          (AddFavouriteAction({ preSchoolId: Id }))
            .then((res) => {
              if (res.status === 200) {
                // dispatch(coachingListAction())
              } else {
                // toast.error(res.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      }
    }
  };

  useEffect(() => {
    setSearchList(searchList);
  }, [searchList]);
  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
  };

  useEffect(() => {
    if (getAddsListData?.length > 0) {
      if (getAddsListData?.[0]?.image?.length > 0) {
        // console.log("insde");
        if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
          // console.log("insde",getAddsListData?.data?.[0]?.image)
          setAddsMappingData(getAddsListData?.[0]?.image);
          // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
        } else {
          setAddsMappingData([
            "https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg",
          ]);
          // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
        }
      }
    }
  }, [getAddsListData]);


  const getSelectedVal = value => {
    // console.log(value);
    setSearch(value)
  };
  
  const getChanges = value => {
 let a = setSearch(value)
 console.log("--------->", search)

    // dispatch(HomepageSearchAction({ search }))
    // .then((res) => {
    //   if (res.status === 200) {
    //     setSearchItem(res?.data)
    //   }
    // })


  };
  
  useEffect(() => {
    console.log("search------>", search)
    dispatch(HomepageSearchAction({ search }))
    .then((res) => {
      console.log("res---->", res)
      if (res.status === 200) {
        setSearchItem(res?.data)
      } else if (res.status === 404){
        setSearchItem(res?.data)
      }
    })

  }, [search])
  // console.log("seachItem",seachItem,'***********************')

  return (
    <div>
      <Header />
      <section>
        <div class="SearchArea">
          <div class="container">
            <div class="ResultSearch">
              <div class="form-group">
                <InputAuto
                  value={search}
                  type="text"
                  data={seachItem}
                  onSelected={getSelectedVal}
                  preValue={search}

                  onChange={getChanges}

                  class="form-control"
                  placeholder="Search"
                />
              </div>
              <button onClick={handleSearch} class="Search">
                Search
              </button>
              <button onClick={handleReset} class="Reset">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="SearchResultArea SliderTwo">
          <div class="VerticalBannerBox VerticalBannerLeft">
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Top Left") {
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </div>

          <div class="VerticalBannerBox VerticalBannerRight">
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Listing row 1  right") {
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Listing row 2 right") {
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Listing row 3 right") {
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8 SearchMinHeight">
                

               
                <br />

                <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? (
                    <>
                      {addsMappingData?.map((ele, i) => {
                        if (ele?.position == "Footer top") {
                          return (
                            <a href={ele?.link} target="_blank">
                              {" "}
                              <img src={ele?.image} alt="Footer top" />
                            </a>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Search;
