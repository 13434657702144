import { topConstant } from "../actionType";




import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";




export const topCollegeUniversityListInitiate=() =>({
    type: topConstant.TOP_COLLEGE_UNIVERSITY_LIST_INITIATE,

})

export const topCollegeUniversityListSuccess=(data) =>({
    type: topConstant.TOP_COLLEGE_UNIVERSITY_LIST_SUCCESS,
    payload:data

})

export const topCollegeUniversityListFailure=(data) =>({
    type: topConstant.TOP_COLLEGE_UNIVERSITY_LIST_FAILURE,

})


export const  topCollegeUniversityListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(topCollegeUniversityListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/topCollegesAndUniversities', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(topCollegeUniversityListSuccess(data))

                    }
                    else {
                        dispatch(topCollegeUniversityListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(topCollegeUniversityListFailure(err))
                    reject(err);
                })

        );
    }

}

// **********************************







export const topSchoolPreSchoolListInitiate=() =>({

    type: topConstant.TOP_SCHOOL_PRESCHOOL_LIST_INITIATE,

})

export const topSchoolPreSchoolListSuccess=(data) =>({
    type: topConstant.TOP_SCHOOL_PRESCHOOL_LIST_SUCCESS,
    payload:data

})

export const topSchoolPreSchoolListFailure=(data) =>({
    type: topConstant.TOP_SCHOOL_PRESCHOOL_LIST_FAILURE,

})


export const  topSchoolPreSchoolListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(topSchoolPreSchoolListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/topSchoolsAndPreSchools', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(topSchoolPreSchoolListSuccess(data))

                    }
                    else {
                        dispatch(topSchoolPreSchoolListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(topSchoolPreSchoolListFailure(err))
                    reject(err);
                })

        );
    }

}
