import { homePageConstant, subscribeNewsLetter } from "../actionType";




import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";




export const homePageListInitiate=() =>({
    type: homePageConstant.HOME_PAGE_DATA_INITIATE,

})

export const homePageListSuccess=(data) =>({
    type: homePageConstant.HOME_PAGE_DATA_SUCCESS,
    payload:data

})

export const homePageListFailure=(data) =>({
    type: homePageListAction.HOME_PAGE_DATA_FAILURE,

})


export const  homePageListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(homePageListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getHomePageData', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(homePageListSuccess(data))

                    }
                    else {
                        dispatch(homePageListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(homePageListFailure(err))
                    reject(err);
                })

        );
    }

}

// **********************************



export const subscribeNewsLetterInitiate=() =>({
    type: subscribeNewsLetter.SUBSCRIBE_NEWSLETTER_INITIATE,

})

export const subscribeNewsLetterSuccess=(data) =>({
    type: homePageConstant.HOME_PAGE_DATA_SUCCESS,
    payload:data

})

export const subscribeNewsLetterFailure=(data) =>({
    type: subscribeNewsLetter.SUBSCRIBE_NEWSLETTER_FAILURE,

})


export const  subscribeNewsLetterAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(subscribeNewsLetterInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/subscribeNewsletter', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(subscribeNewsLetterSuccess(data))

                    }
                    else {
                        dispatch(subscribeNewsLetterFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(subscribeNewsLetterFailure(err))
                    reject(err);
                })

        );
    }

}




// **********************************


export const getHomePageDataInitiate=() =>({
    type: homePageConstant.GET_HOME_PAGE_DATA_INITIATE,

})

export const getHomePageDataSuccess=(data) =>({
    type: homePageConstant.GET_HOME_PAGE_DATA_SUCCESS,
    payload:data

})

export const getHomePageDataFailure=(data) =>({
    type: homePageConstant.GET_HOME_PAGE_DATA_FAILURE,
})


export const getHomePageDataAction=(payload)=>{
    const token = isLoggedIn('websiteData')
   
    return dispatch => {
        dispatch(getHomePageDataInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getHomePageData', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getHomePageDataSuccess(data))

                    }
                    else {
                        dispatch(getHomePageDataFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getHomePageDataFailure(err))
                    reject(err);
                })

        );
    }

}







