import axios from "axios"

import { verifyOtpConstant } from "../actionType"
import { baseUrl } from "../../config/Config";


export const verifyOtpInitiate=() =>({
    type:verifyOtpConstant.VERIFY_OTP_INITIATE

})

export const verifyOtpSuccess=(data) =>({
    type:verifyOtpConstant.VERIFY_OTP_SUCCESS,
    payload:data

})

export const verifyOtpFailure=(data) =>({
    type:verifyOtpConstant.VERIFY_OTP_FAILURE,
    payload : data

})



export const  veriFyOtpAction=(payload)=>{
    
        
    return dispatch => {
        dispatch(verifyOtpInitiate())
        return new Promise((resolve, reject) =>
            axios.post( baseUrl+'/website/emailVerifyOtp', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(verifyOtpSuccess(data))

                    }
                    else {
                        dispatch(verifyOtpFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(verifyOtpFailure(err))
                    reject(err);
                })

        );
    }

}
