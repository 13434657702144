import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";

import {getStaticContactUsAction} from "../redux/actions/staticManagementAction"
import { useDispatch, useSelector } from "react-redux";

const ContactUs = () => {
    
    const dispatch = useDispatch();

    const {contactusData} = useSelector((state)=> state.staticManagementReducer)

    useEffect(()=>{


        dispatch(getStaticContactUsAction())
    },[])
console.log(contactusData)


    return (
        <div>
            <Header />
            <section>
                <div class="NewsBreadcumb ContactBreadcumb" >
                    <div class="container">
                        <h3>Contact us</h3>
                        <p>Home / Contact us</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="ContactInfoArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="ContactInfoBox">
                                    <figure>
                                        <img src={require('../assets/images/Info-1.png')} />
                                    </figure>
                                    <figcaption>
                                        <h3>Head office</h3>
                                        <p>{contactusData?.address}</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="ContactInfoBox">
                                    <figure>
                                        <img src={require('../assets/images/Info-2.png')} />
                                    </figure>
                                    <figcaption>
                                        <h3>Call Us Direct</h3>
                                        <p>{contactusData?.contactNo}</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="ContactInfoBox">
                                    <figure>
                                        <img src={require('../assets/images/Info-3.png')} />
                                    </figure>
                                    <figcaption>
                                        <h3>Mail Address</h3>
                                        <p>{contactusData?.email}</p>
                                    </figcaption>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="ContactArea">
                    <div class="container">
                        <div class="ContactHead">
                            {/* <Link to="/find-job">Find Jobs</Link> */}
                            <h6><span></span> What's update now</h6>
                            <h3>Have any questions? Get in touch-</h3>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="ContactBox">
                                    <figure>
                                        <img src={require('../assets/images/Contact-1.png')} />
                                    </figure>
                                    <figcaption>
                                        <h5>Head office</h5>
                                        <p>{contactusData?.address}</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="ContactBox">
                                    <figure>
                                        <img src={require('../assets/images/Contact-2.png')} />
                                    </figure>
                                    <figcaption>
                                        <h5>  Mail</h5>
                                        <p>{contactusData?.email}</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="ContactBox">
                                    <figure>
                                        <img src={require('../assets/images/Contact-3.png')} />
                                    </figure>
                                    <figcaption>
                                        <h5>Call Us</h5>
                                        <p>{contactusData?.contactNo}</p>
                                    </figcaption>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <img src={require('../assets/images/Map.png')} style={{width:"100%"}}></img>
            <Footer />

        </div>
    );
}

export default ContactUs