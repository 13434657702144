
import { loginConstant } from "../actionType";
import { sendOtpConstant } from "../actionType";
import { reSendOtpConstant } from "../actionType";
import {confirmPasswordConstant} from "../actionType";
import { verifyOtpConstant } from "../actionType";
import { logOutConstant } from "../actionType";
import { updatePasswordConstant } from "../actionType";
import { adminDetailsConstant } from "../actionType";
import { signUPConstant } from "../actionType";

const initialState ={
    adminData:{},
    adminDetails:{},
    loader:false,
    loginStatus:false,
    error:{}
}


// ********************   ADMIN LOGIN  ********************************


export default function adminLoginReducer(state=initialState,{type,payload}){
    switch(type){
        case loginConstant.LOGIN_INITIATE:
            return{...state,loader:true, loginStatus:false, adminData:{}}

        case loginConstant.LOGIN_SUCCESS:
            return{...state, loader:false, loginStatus:true, adminData:payload}

        case  loginConstant.LOGIN_FAILURE:
            return{...state, loader:false, error:payload}



            case loginConstant.OTP_LOGIN_INITIATE:
                return{...state,loader:true, loginStatus:false, }
    
            case loginConstant.OTP_LOGIN_SUCCESS:
                return{...state, loader:false, loginStatus:true, }
    
            case  loginConstant.OTP_LOGIN_FAILURE:
                return{...state, loader:false, error:payload}
    
    



//******************          GET ADMIN DETAIL  **********************************/



        case adminDetailsConstant.ADMIN_DETAILS_INITIATE:
            return{...state,loader:true, loginStatus:false, adminDetails:{}}

        case adminDetailsConstant.ADMIN_DETAILS_SUCCESS:
            return{...state, loader:false, loginStatus:true, adminDetails:payload}

        case  adminDetailsConstant.ADMIN_DETAILS_FAILURE:
            return{...state, loader:false, error:payload}







//********************    FORGET PASSWORD   REDUCER           ****************************** */


        
        case sendOtpConstant.LOGIN_OTP_INITIATE:
            return{...state,loader:true}

        case sendOtpConstant.LOGIN_OTP_SUCCESS:
            return{...state, loader:false}

        case  sendOtpConstant.LOGIN_OTP_FAILURE:
            return{...state, loader:false}



//**************************       RESEND OTP REDUCER                *********************************** */


        case reSendOtpConstant.RESEND_OTP_INITIATE:
            return{...state,loader:true}

        case reSendOtpConstant.RESEND_OTP_SUCCESS:
            return{...state, loader:false}

        case  reSendOtpConstant.RESEND_OTP_FAILURE:
            return{...state, loader:false}



// ******************    VERIFY OTP REDUCER              *****************************************



        case  verifyOtpConstant.VERIFY_OTP_INITIATE:
            return{...state,loader:true}

        case verifyOtpConstant.VERIFY_OTP_SUCCESS:
            return{...state, loader:false}

        case  verifyOtpConstant.VERIFY_OTP_FAILURE:
            return{...state, loader:false}



    //***********************  CONFIRM PASSWOR REDUCER  ***************************** */


    case confirmPasswordConstant.CONFIRM_PASSWORD_INITIATE:
        return{...state,loader:true}

    case confirmPasswordConstant.CONFIRM_PASSWORD_SUCCESS:
        return{...state, loader:false}

    case  confirmPasswordConstant.CONFIRM_PASSWORD_FAILURE:
        return{...state, loader:false}


// ******************    LOGOUT REDUCER ************************



    case logOutConstant.LOGOUT_INITIATE:
        return{...state,loader:true}

    case logOutConstant.LOGOUT_SUCCESS:
        return{...state, loader:false}

    case  logOutConstant.LOGOUT_FAILURE:
        return{...state, loader:false}


// ***************  UPADETE PASSWORD REDUCER  *************************

    case updatePasswordConstant.UPDATE_PASSWORD_INITIATE:
         return{...state,loader:true}

    case updatePasswordConstant.UPDATE_PASSWORD_SUCCESS:
        return{...state, loader:false}

    case  updatePasswordConstant.UPDATE_PASSWORD_FAILURE:
        return{...state, loader:false}




        case signUPConstant.SIGN_UP_INITIATE:
         return{...state,loader:true}

    case signUPConstant.SIGN_UP_SUCCESS:
        return{...state, loader:false}

    case  signUPConstant.SIGN_UP_FAILURE:
        return{...state, loader:false}



        default : return state;
    }




}