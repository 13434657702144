import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";

const CertificateCourse = () => {

    return (
        <div>
            <Header />
            <section>
                <div class="CollegeSlider">
                    <div id="demo" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src={require('../assets/images/SearchBanner.png')} alt="" />
                            </div>
                            <div class="carousel-item">
                                <img src={require('../assets/images/SearchBanner.png')} alt="" />
                            </div>
                            <div class="carousel-item">
                                <img src={require('../assets/images/SearchBanner.png')} alt="" />
                            </div>
                        </div>
                        <ul class="carousel-indicators">
                            <li data-target="#demo" data-slide-to="0" class="active"></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                        </ul>
                    </div>
                    <div class="CollegeContent">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-9">
                                    <div class="CollegeContentLeft">
                                        <h3>IIM Bangalore: Indian Institute Of Management Bangalore (IIMB)</h3>
                                        <p>
                                            <span><img src={require('../assets/images/Location.png')} /></span> Dwarka, Sec-4, Delhi
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="CollegeContentRight">
                                        <aside>
                                            <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                            <label>CD : <strong>8/10</strong></label>
                                        </aside>
                                        <a href="javascript:void(0);" class="Wish">
                                            <i class="fa fa-heart-o"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="OverviewArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="OverviewLeft">
                                    <div class="OverviewBox">
                                        <div class="FeeRange">
                                            <aside>
                                                <p>Course : <span>MBA</span></p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    Associated with : <span><a href="javascript:void(0);">B&C Institution Groups</a></span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>Reviews :</p>
                                                <h5>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <span>(12k)</span>
                                                </h5>
                                            </aside>
                                            <aside>
                                                <h6>Fee Range : <strong>1 lakh to 2 Lakh</strong></h6>
                                            </aside>
                                        </div>
                                    </div>

                                    <div class="OverviewBox">
                                        <h3>Description</h3>
                                        <p>
                                            Perferendis lacinia non, est distinctio ut eveniet, posuere mus nostrum eget itaque, irure illo leo. Est! Numquam autem ipsa! Dolores eiusmod, impedit bibendum porro! Error! Magna quia. Quia officia non?
                                            Lectus corporis laudantium cursus voluptas eveniet
                                        </p>
                                        <p>
                                            Perferendis, voluptatum. Exercitation justo aliquip? Convallis ligula aptent aute ab? Sit necessitatibus error, quaerat curae tristique tempore velit, nascetur ullam metus molestie, etiam sapien cupiditate
                                            magni do ut, consequuntur doloribus ea fusce recusandae eros, minim dolore magnis
                                        </p>
                                    </div>

                                    <div class="RankingTable">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <th>Ranking</th>
                                                    <td>
                                                        <div class="RankingBox">
                                                            <p>
                                                                <span class="Icon"><img src={require('../assets/images/Ranking-1.png')} /></span>
                                                                <label>CD : <strong>8/10</strong></label>
                                                            </p>

                                                            <p>
                                                                <span class="Icon"><img src={require('../assets/images/Ranking-2.png')} /></span>
                                                                <label>Outlook : <strong>8/10</strong></label>
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Eligiblity</th>
                                                    <td>Graduated with Maths</td>
                                                </tr>
                                                <tr>
                                                    <th>Study Mode</th>
                                                    <td>Regular | Online</td>
                                                </tr>
                                                <tr>
                                                    <th>College Type</th>
                                                    <td>Private</td>
                                                </tr>
                                                <tr>
                                                    <th>Allowed Gender</th>
                                                    <td>Co-ed</td>
                                                </tr>
                                                <tr>
                                                    <th>Accepted Exams</th>
                                                    <td>CAT, JEE</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="OverviewBox">
                                        <h3>Career Options</h3>
                                        <p>
                                            Perferendis lacinia non, est distinctio ut eveniet, posuere mus nostrum eget itaque, irure illo leo. Est! Numquam autem ipsa! Dolores eiusmod, impedit bibendum porro! Error! Magna quia. Quia officia non?
                                            Lectus corporis laudantium cursus voluptas eveniet
                                        </p>

                                        <p>
                                            Perferendis, voluptatum. Exercitation justo aliquip? Convallis ligula aptent aute ab? Sit necessitatibus error, quaerat curae tristique tempore velit, nascetur ullam metus molestie, etiam sapien cupiditate
                                            magni do ut, consequuntur doloribus ea fusce recusandae eros, minim dolore magnis
                                        </p>
                                    </div>

                                    <div class="OverviewBox">
                                        <h3>How to prepare?</h3>
                                        <p>
                                            Perferendis lacinia non, est distinctio ut eveniet, posuere mus nostrum eget itaque, irure illo leo. Est! Numquam autem ipsa! Dolores eiusmod, impedit bibendum porro! Error! Magna quia. Quia officia non?
                                            Lectus corporis laudantium cursus voluptas eveniet
                                        </p>

                                        <p>
                                            Perferendis, voluptatum. Exercitation justo aliquip? Convallis ligula aptent aute ab? Sit necessitatibus error, quaerat curae tristique tempore velit, nascetur ullam metus molestie, etiam sapien cupiditate
                                            magni do ut, consequuntur doloribus ea fusce recusandae eros, minim dolore magnis
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="OverviewRight">
                                    <div class="BrochureBox">
                                        <button class="Apply" data-toggle="modal" data-target="#ApplyCourseModal">Apply</button>
                                    </div>

                                    <div class="FacultiesBox CoursesBox">
                                        <h3>Similar Courses</h3>
                                        <ul>
                                            <li>
                                                <figure>
                                                    <img src={require('../assets/images/Courses-1.png')} />
                                                </figure>
                                                <figcaption>
                                                    <h5>Computer Science</h5>
                                                    <p>Perferendis lacinia non, est distinctio ut eveniet, posuere</p>
                                                </figcaption>
                                            </li>
                                            <li>
                                                <figure>
                                                    <img src={require('../assets/images/Courses-1.png')} />
                                                </figure>
                                                <figcaption>
                                                    <h5>Computer Science</h5>
                                                    <p>Perferendis lacinia non, est distinctio ut eveniet, posuere</p>
                                                </figcaption>
                                            </li>
                                            <li>
                                                <figure>
                                                    <img src={require('../assets/images/Courses-1.png')} />
                                                </figure>
                                                <figcaption>
                                                    <h5>Computer Science</h5>
                                                    <p>Perferendis lacinia non, est distinctio ut eveniet, posuere</p>
                                                </figcaption>
                                            </li>
                                            <li>
                                                <figure>
                                                    <img src={require('../assets/images/Courses-1.png')} />
                                                </figure>
                                                <figcaption>
                                                    <h5>Computer Science</h5>
                                                    <p>Perferendis lacinia non, est distinctio ut eveniet, posuere</p>
                                                </figcaption>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

export default CertificateCourse