import { ratingConstant } from "../actionType";

const initialState = {
  ratingData: [],
  ratingList: [],
  loader: false,
  error: {},
};

export default function ratingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ratingConstant.ADD_RATING_INITIATE:
      return { ...state, loader: true, ratingData: {} };
    case ratingConstant.ADD_RATING_SUCCESS:
      return { ...state, loader: false, ratingData: payload.data };

    case ratingConstant.ADD_RATING_FAILURE:
      return { ...state, error: payload };

    case ratingConstant.RATING_LIST_INITIATE:
      return { ...state, loader: true, ratingList: {} };
    case ratingConstant.RATING_LIST_SUCCESS:
      return { ...state, loader: false, ratingList: payload.data };

    case ratingConstant.RATING_LIST_FAILURE:
      return { ...state, error: payload };

    default:
      return state;
  }
}
