// module.exports = {
//     baseUrl:'https://careerdekhoo.com:4000/api/v1'
//     // "http://3.130.15.227:7021/api/v1"
//     // http://13.59.90.211:7021/api/v1/institute/
//     // http://3.130.15.227/
//     // 'https://careerdekhoo.com:4000/api/v1',  
    
//   }

  module.exports = {
    baseUrl:
    window.location.protocol == "http" || "https"
      ?'https://careerdekhoo.com:4000/api/v1'
      :'https://careerdekhoo.com:4000/api/v1',
};


