import { collegeActionTypes,schoolActionTypes,universityActionTypes ,coursesAndCertificates, courseActionTypes, jobManagement, ratingManagement, getAddsActionTypes} from "../actionType";

const initialState = {
  collegeData:[],
  collegeFilteredData:[],

  universityData:[],
  universityFilteredData:[],

  schoolData:[],
  schoolFilteredData:[],

  preSchoolData:[],
  preSchoolFilteredData:[],

  coursesAndCertificatesFilteredData:[],
  coursesAndCertificatesListData:[],
  subStreamData:[],
  streamData:[],
 

  courseDropdownData:[],
  courseListData:[],

  internalJobListData:[],
  externalJobListData:[],
  stateData:[],
  cityData:[],


  unviersityRatingData:[],
  collegeRatingData:[],
  schoolRatingData:[],
  preSchoolRatingData:[],
  coachingRatingData:[],

  getAddsListData:[]

};

export default function listReducer(state = initialState, { type, payload }) {
//   console.log("payloadAtReducerPage--------->", payload);
  switch (type) {


    case coursesAndCertificates.COURSES_WEBSITE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        courseListData: [],
      };
    }
    case coursesAndCertificates.COURSES_WEBSITE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        courseListData: payload,
      };
    }
    case coursesAndCertificates.COURSES_WEBSITE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        courseListData: payload.data,
      };
    }

    case coursesAndCertificates.COURSES_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        coursesAndCertificatesListData: [],
      };
    }
    case coursesAndCertificates.COURSES_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        coursesAndCertificatesListData: payload,
      };
    }
    case coursesAndCertificates.COURSES_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        coursesAndCertificatesListData: payload.data,
      };
    }

    case coursesAndCertificates.COURSES_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        coursesAndCertificatesListData: [],
      };
    }
    case coursesAndCertificates.COURSES_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        coursesAndCertificatesListData: payload,
      };
    }
    case coursesAndCertificates.COURSES_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        coursesAndCertificatesListData: payload.data,
      };
    }


   
    case collegeActionTypes.COLLEGE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        collegeData: [],
      };
    }
    case collegeActionTypes.COLLEGE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        collegeData: payload,
      };
    }
    case collegeActionTypes.COLLEGE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        collegeData: payload.data,
      };
    }



    case collegeActionTypes.COLLEGE_FILTER_INITIATE: {
      return {
        ...state,
        collegeFilteredData: [],
      };
    }
    case collegeActionTypes.COLLEGE_FILTER_SUCCESS: {
      return {
        ...state,
        //   loader: false,
        collegeFilteredData: payload,
      };
    }
    case collegeActionTypes.COLLEGE_FILTER_FAILURE: {
      return {
        ...state,
        collegeFilteredData: payload.data,
      };
    }






    case universityActionTypes.UNIVERSITY_LIST_INITIATE: {
        return {
          ...state,
          universityData: [],
        };
      }
      case universityActionTypes.UNIVERSITY_LIST_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          universityData: payload,
        };
      }
      case universityActionTypes.UNIVERSITY_LIST_FAILURE: {
        return {
          ...state,
          universityData: payload.data,
        };
      }


      case universityActionTypes.UNIVERSITY_FILTER_INITIATE: {
        return {
          ...state,
          universityFilteredData: [],
        };
      }
      case universityActionTypes.UNIVERSITY_FILTER_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          universityFilteredData: payload,
        };
      }
      case universityActionTypes.UNIVERSITY_FILTER_FAILURE: {
        return {
          ...state,
          universityFilteredData: payload.data,
        };
      }

   










// -------------------------------------------------------- //


      case schoolActionTypes.SCHOOL_LIST_INITIATE: {
        return {
          ...state,
          //   loader: false,
          schoolData: [],
        };
      }
      case schoolActionTypes.SCHOOL_LIST_SUCCESS: {
        return {
          ...state,
          schoolData: payload,
        };
      }

      case schoolActionTypes.SCHOOL_LIST_FAILURE: {
        return {
          ...state,
          schoolData: payload.data,
        };
      }

      case schoolActionTypes.SCHOOL_FILTER_INITIATE: {
        return {
          ...state,
          schoolFilteredData: [],
        };
      }
      case schoolActionTypes.SCHOOL_FILTER_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          schoolFilteredData: payload,
        };
      }
      case schoolActionTypes.SCHOOL_FILTER_FAILURE: {
        return {
          ...state,
          schoolFilteredData: payload.data,
        };
      }

      case schoolActionTypes.PRE_SCHOOL_LIST_INITIATE: {
        return {
          ...state,
          //   loader: false,
          preSchoolData: [],
        };
      }
      case schoolActionTypes.PRE_SCHOOL_LIST_SUCCESS: {
        return {
          ...state,
          preSchoolData: payload,
        };
      }

      case schoolActionTypes.PRE_SCHOOL_LIST_FAILURE: {
        return {
          ...state,
          preSchoolData: payload.data,
        };
      }

      case schoolActionTypes.PRE_SCHOOL_FILTER_INITIATE: {
        return {
          ...state,
          preSchoolFilteredData: [],
        };
      }
      case schoolActionTypes.PRE_SCHOOL_FILTER_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          preSchoolFilteredData: payload,
        };
      }
      case schoolActionTypes.PRE_SCHOOL_FILTER_FAILURE: {
        return {
          ...state,
          preSchoolFilteredData: payload.data,
        };
      }
    
   











      case coursesAndCertificates.COURSES_LIST_INITIATE: {
        return {
          ...state,
          coursesAndCertificatesListData: [],
        };
      }
      case coursesAndCertificates.COURSES_LIST_SUCCESS: {
        return {
          ...state,
          coursesAndCertificatesListData: payload,
        };
      }
      case coursesAndCertificates.COURSES_LIST_FAILURE: {
        return {
          ...state,
          coursesAndCertificatesListData: payload.data,
        };
      }


      


      case coursesAndCertificates.COURSE_DROPDOWN_INITIATE: {
        return {
          ...state,
          courseDropdownData: [],
        };
      }
      case coursesAndCertificates.COURSE_DROPDOWN_SUCCESS: {
        return {
          ...state,
          courseDropdownData: payload,
        };
      }
      case coursesAndCertificates.COURSE_DROPDOWNN_FAILURE: {
        return {
          ...state,
          courseDropdownData: payload.data,
        };
      }




      case coursesAndCertificates.COURSES_FILTER_INITIATE: {
        return {
          ...state,
          coursesAndCertificatesFilteredData: [],
        };
      }
      case coursesAndCertificates.COURSES_FILTER_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          coursesAndCertificatesFilteredData: payload,
        };
      }
      case coursesAndCertificates.COURSES_FILTER_FAILURE: {
        return {
          ...state,
          coursesAndCertificatesFilteredData: payload.data,
        };
      }


      
      

      case universityActionTypes.STREAM_DROPDOWN_INITIATE: {
        return {
          ...state,
          streamData: [],
        };
      }
      case universityActionTypes.STREAM_DROPDOWN_SUCCESS: {
        return {
          ...state,
          streamData: payload,
        };
      }
      case universityActionTypes.STREAM_DROPDOWN_FAILURE: {
        return {
          ...state,
          streamData: payload.data,
        };
      }

      case universityActionTypes.SUB_STREAM_DROPDOWN_INITIATE: {
        return {
          ...state,
          subStreamData: [],
        };
      }
      case universityActionTypes.SUB_STREAM_DROPDOWN_SUCCESS: {
        return {
          ...state,
          subStreamData: payload,
        };
      }
      case universityActionTypes.SUB_STREAM_DROPDOWN_FAILURE: {
        return {
          ...state,
          subStreamData: payload.data,
        };
      }





      // JOB 


      case jobManagement.INTERNAL_JOB_INITATE: {
        return {
          ...state,
          internalJobListData: [],
        };
      }
      case jobManagement.INTERNAL_JOB_SUCCESS: {
        return {
          ...state,
          internalJobListData: payload,
        };
      }
      case jobManagement.INTERNAL_JOB_FAILURE: {
        return {
          ...state,
          internalJobListData: payload.data,
        };
      }

      case jobManagement.EXTERNAL_JOB_INITIATE: {
        return {
          ...state,
          externalJobListData : [],
        };
      }
      case jobManagement.EXTERNAL_JOB_SUCCESS: {
        return {
          ...state,
          externalJobListData: payload,
        };
      }
      case jobManagement.EXTERNAL_JOB_FAILURE: {
        return {
          ...state,
          externalJobListData: payload.data,
        };
      }
      case jobManagement.GET_STATE_LIST_INITIATE: {
        return {
          ...state,
          stateData: [],
        };
      }
      case jobManagement.GET_STATE_LIST_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          stateData: payload,
        };
      }
      case jobManagement.GET_STATE_LIST_FAILURE: {
        return {
          ...state,
          stateData: payload.data,
        };
      }

      case jobManagement.GET_CITY_LIST_INITIATE: {
        return {
          ...state,
          cityData: [],
        };
      }
      case jobManagement.GET_CITY_LIST_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          cityData: payload,
        };
      }
      case jobManagement.GET_CITY_LIST_FAILURE: {
        return {
          ...state,
          cityData: payload.data,
        };
      }




      // RATING
      
      
      case ratingManagement.COLLEGE_RATING_INITIATE: {
        return {
          ...state,
          collegeRatingData: [],
        };
      }
      case ratingManagement.COLLEGE_RATING_SUCCESS: {
        return {
          ...state,
          collegeRatingData: payload,
        };
      }
      case ratingManagement.COLLEGE_RATING_FAILURE: {
        return {
          ...state,
          collegeRatingData: payload.data,
        };
      }

      case ratingManagement.UNIVERSITY_RATING_INITIATE: {
        return {
          ...state,
          unviersityRatingData : [],
        };
      }
      case ratingManagement.UNIVERSITY_RATING_SUCCESS: {
        return {
          ...state,
          unviersityRatingData: payload,
        };
      }
      case ratingManagement.UNIVERSITY_RATING_FAILURE: {
        return {
          ...state,
          unviersityRatingData: payload.data,
        };
      }
      case ratingManagement.SCHOOL_RATING_INITIATE: {
        return {
          ...state,
          schoolRatingData: [],
        };
      }
      case ratingManagement.SCHOOL_RATING_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          schoolRatingData: payload,
        };
      }
      case ratingManagement.SCHOOL_RATING_FAILURE: {
        return {
          ...state,
          schoolRatingData: payload.data,
        };
      }

      case ratingManagement.PRE_SCHOOL_RATING_INITIATE: {
        return {
          ...state,
          preSchoolRatingData: [],
        };
      }
      case ratingManagement.PRE_SCHOOL_RATING_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          preSchoolRatingData: payload,
        };
      }
      case ratingManagement.PRE_SCHOOL_RATING_FAILURE: {
        return {
          ...state,
          preSchoolRatingData: payload.data,
        };
      }

      case ratingManagement.COACHING_RATING_INITIATE: {
        return {
          ...state,
          coachingRatingData : [],
        };
      }
      case ratingManagement.COACHING_RATING_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          coachingRatingData: payload,
        };
      }
      case ratingManagement.COACHING_RATING_FAILURE: {
        return {
          ...state,
          coachingRatingData: payload.data,
        };
      }



      case getAddsActionTypes.GET_ADDS_INITIATE: {
        return {
          ...state,
          getAddsListData : [],
        };
      }
      case getAddsActionTypes.GET_ADDS_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          getAddsListData: payload.data,
        };
      }
      case getAddsActionTypes.GET_ADDS_FAILURE: {
        return {
          ...state,
          getAddsListData: payload.data,
        };
      }



    default:
      return state;
  }
}
