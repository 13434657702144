import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";

import { useDispatch, useSelector } from "react-redux";

import {BlogDetailsAction, BlogListAction} from "../redux/actions/blogAction";
import { useEffect } from "react";
import moment from "moment";

const init = {
    blogDetailsData:[]
}

const BlogDetails = () => {
    const[iState, updateState] = useState(init);
    const {blogDetailsData} = iState;

    const { state } = useLocation();
    const dispatch = useDispatch()


    const {blogList} = useSelector((state)=> state.blogReducer)

    useEffect(()=>{
     dispatch(BlogDetailsAction({_id:state?._id})).then(((res) =>{
        console.log(res)
        updateState({...iState, blogDetailsData:res?.data})
     })).catch((err) =>{
        console.log(err)
     })
    },[])


    useEffect(()=>
    {
        dispatch(BlogListAction())
        window.scrollTo(0, 0);
    },[])

    const getDetailsFun = (id) =>{
        dispatch(BlogDetailsAction({_id:id})).then((res) =>{
            console.log("res--------->", res)
            updateState({...iState, blogDetailsData:res?.data})
        }).catch((err) =>{
            console.log("errr", err)
        })
    }



    return (
        <div>
            <Header />
            <section>
                <div class="SearchArea NewsBreadcumb">
                    <div class="container">
                        <h3>Blog Details</h3>
                        <p>Home / Blog Details</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="NewsDetailsArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="FacultiesBox CoursesBox">
                                    <h3>Recent Blog</h3>
                                    <ul>
                                    {blogList?.result?.map((item)=>
                                        <li onClick={() =>getDetailsFun(item?._id)}>
                                        <figure>
                                            <img src={item?.image} />
                                        </figure>
                                        <figcaption>
                                            <h5>{item?.title}</h5>
                                            <h6>{moment(item?.createdAt).format('ll')}</h6>
                                        </figcaption>
                                    </li>
                                        )}


                                    </ul>
                                </div>
                            </div>

                            <div class="col-sm-8">
                                <div class="NewsDetailsRigth">
                                    <figure >
                                        <img src={blogDetailsData?.image} />
                                    </figure>
                                    <figcaption>
                                    <h5>{moment(blogDetailsData?.createdAt).format('ll')}</h5>

                                        <h4>{blogDetailsData?.title}</h4>

                                        <div dangerouslySetInnerHTML={{__html: blogDetailsData?.description}} className='editor'></div>

                                    </figcaption>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
}

export default BlogDetails