
import { isLoggedIn } from "../../utils";
import { loginConstant } from "../actionType";
import { sendOtpConstant } from "../actionType";
import { reSendOtpConstant } from "../actionType";
import  axios from 'axios';

import { baseUrl } from "../../config/Config";





//*************************              ADMIN LOGIN        ******************************* */

export const adminLoginInitiate=() =>({
    type:loginConstant.LOGIN_INITIATE

})

export const adminLoginSuccess=(data) =>({
    type:loginConstant.LOGIN_SUCCESS,
    payload:data

})

export const adminLoginFailure=(data) =>({
    type:loginConstant.LOGIN_FAILURE,
    payload : data

})

// *******************    ADMIN LOGIN ACTION **************************************

export const  AdminLoginAction=(payload)=>{
    return dispatch => {
        dispatch(adminLoginInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/studentLoginPassword', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(adminLoginSuccess(data))

                    }
                    else {
                        dispatch(adminLoginFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(adminLoginFailure(err))
                    reject(err);
                })

        );
    }

}







//*************************              ADMIN LOGIN        ******************************* */

export const otpLoginInitiate=() =>({
    type:loginConstant.OTP_LOGIN_INITIATE

})

export const otpLoginSuccess=(data) =>({
    type:loginConstant.OTP_LOGIN_SUCCESS,
    payload:data

})

export const otpLoginFailure=(data) =>({
    type:loginConstant.OTP_LOGIN_FAILURE,
    payload : data

})

// *******************    OTP LOGIN ACTION **************************************

export const  OtpLoginAction=(payload)=>{
    return dispatch => {
        dispatch(otpLoginInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/studentLoginOtp', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(otpLoginSuccess(data))

                    }
                    else {
                        dispatch(otpLoginFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(otpLoginFailure(err))
                    reject(err);
                })

        );
    }

}




