import { examCareerConstant } from "../actionType";

const initialState = {
  examList: [],
  careerList: [],
  examData: {},
  careerData: {},
  loader: false,
  error: {},
};

export default function examCareerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case examCareerConstant.EXAM_LIST_INITIATE:
      return { ...state, loader: true, examList: [] };
    case examCareerConstant.EXAM_LIST_SUCCESS:
      return { ...state, loader: false, examList: payload.data };

    case examCareerConstant.EXAM_LIST_FAILURE:
      return { ...state, error: payload };

    case examCareerConstant.CAREER_LIST_INITIATE:
      return { ...state, loader: true, careerList: [] };
    case examCareerConstant.CAREER_LIST_SUCCESS:
      return { ...state, loader: false, careerList: payload.data };

    case examCareerConstant.CAREER_LIST_FAILURE:
      return { ...state, error: payload };

    case examCareerConstant.ADD_EXAM_ENQUIRY_INITIATE:
      return { ...state, loader: true, examData: {} };
    case examCareerConstant.ADD_EXAM_ENQUIRY_SUCCESS:
      return { ...state, loader: false, examData: payload.data };

    case examCareerConstant.ADD_CAREER_ENQUIRY_INITIATE:
      return { ...state, error: payload };
    case examCareerConstant.ADD_CAREER_ENQUIRY_SUCCESS:
      return { ...state, loader: true, careerData: {} };
    case examCareerConstant.ADD_CAREER_ENQUIRY_INITIATE:
      return { ...state, loader: false, careerData: payload.data };

    case examCareerConstant.ADD_CAREER_ENQUIRY_FAILURE:
      return { ...state, error: payload };

    default:
      return state;
  }
}
