import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import {
  StateListAction,
  CityListAction,
} from "../redux/actions/getStateCityAction";

import Logo from "../assets/images/logo.png";

import {
  streamDropdownAction,
  subStreamDropdownAction,
} from "../redux/actions/universityAction";
import {
  CareerListAction,
  AddCareerEnquiryAction,
} from "../redux/actions/examCareerAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { getAddsListAction } from "../redux/actions/getAddsActions";
import NoDataFound from "./noDateFound";

const initialState = {
  stream: { streamName: "", streamId: "" },
  subStream: { substreamName: "", subStreamId: "" },
};

const addInitState = {
  instituteName: "",
  instituteType: "",
  state: "",
  mobileNumber: "",
  remarks: "",
  studentId: "",
  examModalName: "",

  instituteNameError: "",
  instituteTypeError: "",
  stateError: "",
  cityError: "",
  mobileNumberError: "",
  thankuModal: false,
};

const Choosecareer = () => {
  const [iState, updateState] = useState(initialState);
  const [ShowSignInModal, setShowSignInModal] = useState(false);
  const [addState, updateAddState] = useState(addInitState);
  const [enquiryModal, setEnquiry] = useState(false);
  const { cityList, stateList } = useSelector(
    (state) => state.stateCityReducer
  );
 

  const [stateData, setStateData] = useState({ name: "", isoCode: "" });
  const [city, setCity] = useState("");

  const { careerList } = useSelector((state) => state.examCareerReducer);

  const [streamList, setStreamList] = useState([]);
  const [substreamList, setSubstreamList] = useState([]);

  const dispatch = useDispatch();
  const [mobileNumber, setmobileNumber] = useState("");

  const handleChangeMobile = (event) => {
    const result = event.target.value.replace(/\D/g, "");

    setmobileNumber(result);
  };

  const [addsMappingData, setAddsMappingData] = useState([]);

  const { getAddsListData } = useSelector((state) => state?.listReducer);
  console.log("getAddsListData===============>", getAddsListData);

  const { stream, subStream } = iState;

  const handleEnquiryClose = () => {
    updateAddState({
      ...addState,
      instituteNameError: "",
      instituteTypeError: "",
      mobileNumberError: "",
      stateError: "",
      cityError: "",
      city:"", mobileNumber:"",remarks:"",instituteType:"", state:''
    });
    setmobileNumber("")
    setEnquiry(false);

  
  };
  const handleEnquiryShow = (examName) => {
    const userData = JSON.parse(localStorage.getItem("websiteData"))?._id;
    if (userData) {
      setEnquiry(true);
      updateAddState({ ...iState, examModalName: examName });
    } else {
      // toast.info("Please login to perform this action !");
      setShowSignInModal(true);
    }
    // setEnquiry(true);
  };
  const handleCloseThankuModal = () => {
    updateAddState({
      ...addState,
      thankuModal: false,
      instituteNameError: "",
      instituteTypeError: "",
      mobileNumberError: "",
      stateError: "",
      cityError: "",
      city:"", mobileNumber:"",remarks:"",instituteType:"", state:''
    });
  };
  const handleShowThankuModal = () => {
    updateAddState({  ...addState,
      thankuModal: false, });
  };


  const {
    instituteName,
    instituteType,
    thankuModal,
    // mobileNumber,
    examModalName,
    state,
    remarks,
    studentId,

    instituteNameError,
    instituteTypeError,
    mobileNumberError,
    stateError,
    cityError,
  } = addState;

  let handleValidation = () => {
    let instituteNameError = "";
    let instituteTypeError = "";
    let mobileNumberError = "";
    let stateError = "";
    let cityError = "";
    let formIsValid = true;
    if (!examModalName) {
      instituteNameError = "* Please enter Exam Name.";
      formIsValid = false;
    }

    if (!instituteType) {
      instituteTypeError = "* Please select institute type.";
      formIsValid = false;
    }

    if (!mobileNumber) {
      mobileNumberError = "* Please enter mobile number.";
      formIsValid = false;
    } else if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(mobileNumber)) {
      mobileNumberError = "* Please enter valid phone number.";
      formIsValid = false;
    }
    if (!city) {
      cityError = "* Please select city.";
      formIsValid = false;
    }
    if (!stateData.name) {
      stateError = "* Please select state.";
      formIsValid = false;
    }

    updateAddState({
      ...addState,
      instituteNameError,
      instituteTypeError,
      mobileNumberError,
      cityError,
      stateError,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateAddState({
      ...addState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    let stdId = JSON.parse(localStorage.getItem("websiteData"))?._id;

    if (formIsValid) {
      console.log(studentId, "@@@@@@@@@@@@@@");
      var data = {
        studentId: stdId,
        instituteName:examModalName,
        instituteType,
        state: stateData.name,
        city,
        mobileNumber,
        remarks,
      };

      dispatch(AddCareerEnquiryAction(data))
        .then((res) => {
          if (res.status === 200) {
            // toast.success(res.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });

            setEnquiry(false);
            updateAddState({ ...addState, thankuModal: true, city:"", mobileNumber:"",remarks:"",instituteType:"" });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          //alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(
      StateListAction({
        isoCode: "IN",
      })
    );

    if (stateData) {
      dispatch(
        CityListAction({
          stateIsoCode: "IN",
          isoCode: stateData.isoCode,
        })
      );
    }
  }, [stateData]);

  useEffect(() => {


    dispatch(CareerListAction());
    dispatch(getAddsListAction({ category: "Career Details Page" }));

    dispatch(streamDropdownAction())
      .then((res) => {
        if (res.status === 200) {
          setStreamList(res?.data?.result);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        //alert(err);
      });
  }, []);

  console.log(stream);

  useEffect(() => {
    // if(!stream.streamName ===""){
    dispatch(subStreamDropdownAction({ streamId: stream?.streamId }))
      .then((res) => {
        if (res.status === 200) {
          setSubstreamList(res?.data?.result);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        //alert(err);
      });
    // }
  }, [stream]);

  useEffect(() => {
    if (getAddsListData?.[0]?.image?.length > 0) {
      console.log("insde");
      if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
        // console.log("insde",getAddsListData?.data?.[0]?.image)
        setAddsMappingData(getAddsListData?.[0]?.image);
        // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
      } else {
        setAddsMappingData([
          "https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg",
        ]);
        // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
      }
    }
  }, [getAddsListData]);

  const handleSearch = () => {
    dispatch(
      CareerListAction({
        stream: stream.streamId,
        subStreamId: subStream.subStreamId,
      })
    );
  };

  const handleCloseModal = () => {
    setShowSignInModal(false);
  };
  return (
    <div>
      <Header
        ShowSignInModal={ShowSignInModal}
        handleCloseModal={handleCloseModal}
      />

      <section>
        <div class="SearchArea">
          <div class="container">
            <div class="SearchBox SearchStreamBox">
              <div class="form-group">
                <select
                  className="form-control"
                  name="stream"
                  onChange={(e) => {
                    let index = e.nativeEvent.target.selectedIndex;
                    updateState({
                      ...iState,
                      stream: {
                        streamName: e.nativeEvent.target[index].text,
                        streamId: e.nativeEvent.target[index].value,
                      },
                    });
                  }}
                >
                  <option>--Select Stream--</option>
                  {streamList.map((stream) => (
                    <option value={stream._id}>{stream.streamName}</option>
                  ))}
                </select>{" "}
              </div>
              <div class="form-group">
                <select
                  className="form-control"
                  name="subStream"
                  onChange={(e) => {
                    let index = e.nativeEvent.target.selectedIndex;
                    updateState({
                      ...iState,
                      subStream: {
                        substreamName: e.nativeEvent.target[index].text,
                        subStreamId: e.nativeEvent.target[index].value,
                      },
                    });
                  }}
                  disabled={!stream?.streamId}
                >
                  <option>--Select Sub-Stream--</option>
                  {/* {substreamList?.data?.result && substreamList?.data?.result?.length > 0 ? <> */}
                  {substreamList?.map((item) => (
                    <option value={item._id}>{item.subStreamName}</option>
                  ))}
                  {/* </> : <></>} */}
                </select>
              </div>
              <button onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="EnquireArea">
          <div class="VerticalBannerBox VerticalBannerLeft">
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Left") {
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </div>
          <div class="VerticalBannerBox VerticalBannerRight">
            {addsMappingData && addsMappingData?.length > 0 ? (
              <>
                {addsMappingData?.map((ele, i) => {
                  if (ele?.position == "Right") {
                    console.log(ele);
                    return (
                      <a href={ele?.link} target="_blank">
                        {" "}
                        <img src={ele?.image} alt=" faq top" />
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </div>
          <div class="container">
            <div class="AdvertisementBox">
              {addsMappingData && addsMappingData?.length > 0 ? (
                <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Top") {
                      return (
                        <a href={ele?.link} target="_blank">
                          {" "}
                          <img src={ele?.image} alt="faq top" />
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
            <div class="EnquireAccordian">
              <div id="accordion">
                {careerList?.result?.map((item,i) => (
                  <div class="card">
                    <div
                      class="card-header collapsed"
                      data-toggle="collapse"
                      href={`#collapse${i}`}
                      aria-expanded="true"
                    >
                      <figure>
                        <img src={item?.image} />
                      </figure>
                      <figcaption>
                        <h3>{item?.name}</h3>
                        <article>
                          <div>
                            <h6>Career Stream</h6>
                            <p>{item?.streamId?.streamName}</p>
                          </div>
                          <div>
                            <h6>Payscale</h6>
                            <p>{item?.payScale}</p>
                          </div>
                          <div>
                            {/* <h6>Conducting Body</h6>
                                                    <p>{item?.conductingBody}</p> */}
                          </div>
                          <div>
                            {/* <h6>Payscale</h6>
                                                    <p>{item?.payScale}</p> */}
                          </div>
                          <div>
                            {/* <h6>Application Fees</h6>
                                                    <p>{item?.fees}</p> */}
                          </div>
                          {/* <div>
                                                    <h6>Exam Date</h6>
                                                    <p>{moment(item?.data).format('ll')}</p>
                                                </div>
                                                 <div >
                                                    <h6>Payscale</h6>
                                                    <p>{item?.payScale}</p>
                                                </div> */}
                        </article>
                      </figcaption>
                      <aside>
                        <a onClick={() => handleEnquiryShow(item?.name)}>
                          Enquire More
                        </a>
                        <span>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </aside>
                    </div>
                    <div
                      id={`collapse${i}`}
                      class="collapse"
                      // data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="EnquireContent">
                          <h4>Eligibility</h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.eligibility,
                            }}
                            className="editor"
                          ></div>

                          {/* <h4>Syllabus</h4>
                                                <div dangerouslySetInnerHTML={{__html: item?.syllabus}} className='editor'></div> */}

                          <h4>How To Prepare</h4>
                          {item?.prepare?.map((item) => (
                            <>
                              <p>
                                Pratice papers:{" "}
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item?.prepare,
                                    }}
                                    className="editor"
                                  ></div>
                                </p>
                              </p>
                            </>
                          ))}
                          <h6>Related Web Links</h6>
                          {item?.prepare?.map((item) => (
                            <>
                              <p>
                                Links:{" "}
                                <a
                                  href={item?.webLinks}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {item?.webLinks}
                                </a>
                              </p>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {careerList?.result?.length === 0 && <NoDataFound />}

                <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? (
                    <>
                      {addsMappingData?.map((ele, i) => {
                        if (ele?.position == "Bottom") {
                          return (
                            <a href={ele?.link} target="_blank">
                              {" "}
                              <img src={ele?.image} alt="faq top" />
                            </a>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal show={enquiryModal}>
            <div class="LoginArea">
              <a class="CloseModal" onClick={handleEnquiryClose}>
                ×
              </a>
              <div class="LoginLeft">
                <span class="Icon">
                  {/* <img src={require("../assets/images/LogoModal.png")} />{" "} */}
                </span>
                <div>
                  <figure>
                    <img src={Logo} />
                  </figure>
                  <figcaption>
                    <h3>Career Dekho</h3>
                    <p>
                      Welcome to India’s most trusted and largest career
                      guidance portal
                    </p>
                  </figcaption>
                </div>
              </div>
              <div class="LoginRight">
                <div class="LoginBox">
                  <h3>
                    Please fill below details and we <br />
                    will get back to you soon
                  </h3>
                  <h3>
                    {/* <strong>List your Institute</strong> */}
                  </h3>
                  <form>
                    <div class="form-group">
                      <label>Exam Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Exam Name"
                        value={examModalName}
                        name="instituteName"
                        onChange={handleChange}
                      />
                      {instituteNameError && (
                        <p style={{ color: "red" }}>{instituteNameError}</p>
                      )}
                    </div>

                    <div class="form-group">
                      <label>Select Insititute Type</label>
                      <select
                        name="instituteType"
                        class="form-control"
                        onChange={handleChange}
                      >
                        <option>--select institute type --</option>
                        <option value="School Only">School Only</option>
                        <option value="School and Pre-School">
                          School and Pre-School
                        </option>
                        <option value="Pre-school Only">Pre-school Only</option>
                        <option value="College">College</option>
                        <option value="University">University</option>
                        <option value="Other">Other</option>
                      </select>
                      {instituteTypeError && (
                        <p style={{ color: "red" }}>{instituteTypeError}</p>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Select State</label>
                      <select
                        className="form-control"
                        value={state?.name}
                        onChange={(e) =>
                          setStateData({
                            isoCode: e.target.value.split(",")[0],
                            name: e.target.value.split(",")[1],
                          })
                        }
                      >
                        <option>Select</option>
                        {stateList?.map((state) => (
                          <option value={[state?.isoCode, state?.name]}>
                            {state?.name}
                          </option>
                        ))}
                      </select>

                      {stateError && (
                        <p style={{ color: "red" }}>{stateError}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Select City</label>
                      <select
                        onChange={(e) => setCity(e.target.value)}
                        className="form-control"
                      >
                        <option>Select</option>
                        {cityList?.data?.map((state) => (
                          <option value={state?.isoCode}>{state?.name}</option>
                        ))}
                      </select>
                      {cityError && <p style={{ color: "red" }}>{cityError}</p>}
                    </div>

                    <div class="form-group">
                      <label>Mobile Number</label>
                      <input
                        class="form-control"
                        placeholder="Mobile number"
                        value={mobileNumber}
                        onChange={handleChangeMobile}
                        maxLength={13}
                        name="mobileNumber"
                      />
                      {mobileNumberError && (
                        <p style={{ color: "red" }}>{mobileNumberError}</p>
                      )}
                    </div>

                    <div class="form-group">
                      <label>Remarks (If Any)</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Write here"
                        value={remarks}
                        onChange={handleChange}
                        name="remarks"
                      />
                    </div>

                    <div class="ContinueButton">
                      <button onClick={handleSubmit}>Continue</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={thankuModal}>
        <div class="LoginArea">
          <a
            onClick={handleCloseThankuModal}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="ThankBox">
            <div>
              <figure>
                <img src={require("../assets/images/Apply-Thank.png")} />
              </figure>
              <h3>Thank you</h3>
              <p>
                {
                   //  Thank You for applying with ${(name)}!
                `
Your application has been submitted successfully. 
We will be in touch and contact you soon.`}
                
              </p>
              <a
                href="javascript:void(0);"
                onClick={handleCloseThankuModal}
                data-dismiss="modal"
              >
                Great
              </a>
            </div>
          </div>
        </div>
      </Modal>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Choosecareer;
