import { trendingConstant } from "../actionType";


const initialState = {
  
  trendingCollegeUniversityList: [],
  trendingCollegeUniversityData: {},


  trendingSchoolPreSchoolList: [],
  trendingSchoolPreSchoolData: {},

  trendingStreamList: [],
  trendingStreamData: {},

  trendingCityList: [],
  trendingCityData: {},

  loader: false,
  error: {},
};

export default function universityReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
   

      case trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_INITIATE:
        return { ...state, loader: true, trendingCollegeUniversityList: [] };
      case trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_SUCCESS:
        return { ...state, loader: false, trendingCollegeUniversityList: payload.data };
  
      case trendingConstant.TRENDING_COLLEGE_UNIVERSITY_LIST_FAILURE:
        return { ...state, error: payload };
  
    

        case trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_INITIATE:
            return { ...state, loader: true, trendingSchoolPreSchoolList: [] };
          case trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_SUCCESS:
            return { ...state, loader: false, trendingSchoolPreSchoolList: payload.data };
      
          case trendingConstant.TRENDING_SCHOOL_PRESCHOOL_LIST_FAILURE:
            return { ...state, error: payload };
      


            
    

        case trendingConstant.TRENDING_STREAM_LIST_INITIATE:
            return { ...state, loader: true, trendingStreamList: [] };
          case trendingConstant.TRENDING_STREAM_LIST_SUCCESS:
            return { ...state, loader: false, trendingStreamList: payload.data };
      
          case trendingConstant.TRENDING_STREAM_LIST_FAILURE:
            return { ...state, error: payload };
      


        

            case trendingConstant.TRENDING_CITY_LIST_INITIATE:
                return { ...state, loader: true, trendingCityList: [] };
              case trendingConstant.TRENDING_CITY_LIST_SUCCESS:
                return { ...state, loader: false, trendingCityList: payload.data };
          
              case trendingConstant.TRENDING_CITY_LIST_FAILURE:
                return { ...state, error: payload };
          
    

    default:
      return state;
  }
}
