import { staticManagementConstant } from "../actionType";






import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const getContactUsInitiate=() =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_INITIATE

})

export const getContactUsSuccess=(data) =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_SUCCESS,
    payload:data

})

export const getContactUsFailure=(data) =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_FAILURE,

})

// **********************************
export const getEducationLoanInitiate=() =>({
    type: staticManagementConstant.GET_STATIC_EDUCATION_LOAN_INITIATE

})

export const getEducationLoanSuccess=(data) =>({
    type: staticManagementConstant.GET_STATIC_EDUCATION_LOAN_SUCCESS,
    payload:data

})

export const getEducationLoanFailure=(data) =>({
    type: staticManagementConstant.GET_STATIC_EDUCATION_LOAN_FAILURE ,

})


// ***********************************


// ***********************************



export const getTermInitiate=() =>({
    type: staticManagementConstant.GET_STATIC_TERM_CONDITION_INITIATE

})

export const getTermSuccess=(data) =>({
    type: staticManagementConstant.GET_STATIC_TERM_CONDITION_SUCCESS,
    payload:data

})

export const getTermFailure=(data) =>({
    type : staticManagementConstant.GET_STATIC_TERM_CONDITION_FAILURE,

})

//*************************************** */

//*************************************** */




export const getPrivacyinitiate=() =>({
    type: staticManagementConstant.GET_STATIC_EDUCATION_LOAN_INITIATE

})

export const getPrivacySuccess=(data) =>({
    type:  staticManagementConstant.GET_STATIC_PRIVACY_POLICY_SUCCESS,
    payload:data

})

export const getPrivacyFailure=(data) =>({
    type: staticManagementConstant.GET_STATIC_PRIVACY_POLICY_FAILURE,

})

//*************************************** */



//*************************************** */


export const getAboutUsInitiate=() =>({
    type: staticManagementConstant.GET_STATIC_ABOUT_US_INITIATE

})

export const getAboutUsSuccess=(data) =>({
    type: staticManagementConstant.GET_STATIC_ABOUT_US_SUCCESS,
    payload:data

})

export const getAboutUsFailure=(data) =>({
    type: staticManagementConstant.GET_STATIC_ABOUT_US_FAILURE,

})

//*************************************** */


//*************************************** */

// //*************************************** */


export const  getStaticContactUsAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(getContactUsInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getContactUs', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getContactUsSuccess(data))

                    }
                    else {
                        dispatch(getContactUsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getContactUsFailure(err))
                    reject(err);
                })

        );
    }

}





export const  getEducationLoanAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(getEducationLoanInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getEducationLoan', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getEducationLoanSuccess(data))

                    }
                    else {
                        dispatch(getEducationLoanFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getEducationLoanFailure(err))
                    reject(err);
                })

        );
    }

}



export const  getTermsConditionAction=(payload)=>{
    const token = isLoggedIn('adminData')

        
    return dispatch => {
        dispatch(getTermInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getTermsAndCond', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getTermSuccess(data))

                    }
                    else {
                        dispatch(getTermFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getTermInitiate(err))
                    reject(err);
                })

        );
    }

}





export const  getPrivacyPolicyAction=(payload)=>{
    
    const token = isLoggedIn('adminData')

    return dispatch => {
        dispatch(getPrivacyinitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getPrivacyAndPolicy', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getPrivacySuccess(data))

                    }
                    else {
                        dispatch(getPrivacyFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getPrivacyFailure(err))
                    reject(err);
                })

        );
    }

}



export const  getAboutUsAction=(payload)=>{
    
    const token = isLoggedIn('adminData')

    return dispatch => {
        dispatch(getAboutUsInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getAboutUs', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getAboutUsSuccess(data))

                    }
                    else {
                        dispatch(getContactUsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getContactUsFailure(err))
                    reject(err);
                })

        );
    }

}




export const addLoanEnquiryInitiate=() =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_INITIATE

})

export const addLoanEnquirySuccess=(data) =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_SUCCESS,
    payload:data

})

export const addLoanEnquiryFailure=(data) =>({
    type: staticManagementConstant.GET_STATIC_CONTACT_US_FAILURE,

})






export const  AddLoanEnquiryAction=(payload)=>{
    
    const token = isLoggedIn('adminData')

    return dispatch => {
        dispatch(addLoanEnquiryInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/createLoanEnquiry', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(addLoanEnquirySuccess(data))

                    }
                    else {
                        dispatch(addLoanEnquiryFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(addLoanEnquiryFailure(err))
                    reject(err);
                })

        );
    }

}


export const getRatingStaticInitiate=() =>({
    type: staticManagementConstant.GET_RATING_STATIC_INITIATE

})

export const getRatingStaticSuccess=(data) =>({
    type: staticManagementConstant.GET_RATING_STATIC_SUCCESS,
    payload:data

})

export const getRatingStaticFailure=(data) =>({
    type : staticManagementConstant.GET_RATING_STATIC_FAILURE,

})





export const  getRatingStaticAction=(payload)=>{
    
    const token = isLoggedIn('adminData')

    return dispatch => {
        dispatch(getRatingStaticInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/getRatingStaticData', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(getRatingStaticSuccess(data))

                    }
                    else {
                        dispatch(getRatingStaticFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(getRatingStaticFailure(err))
                    reject(err);
                })

        );
    }
}