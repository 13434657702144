import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import "../../App.css";
import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";

import { FavouriteListAction } from "../../redux/actions/favouriteAction";
import { RemoveFavouriteAction } from "../../redux/actions/favouriteAction";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import LoaderDots from "../loaderDots";

const Wishlist = () => {
  const { favouriteList } = useSelector((state) => state.favouriteReducer);
  const [keyName, setKeyName] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(FavouriteListAction());
    setLoader(false);
  }, []);

  console.log(favouriteList, "****************");

  const handleRemoveWishlist = (e, id, type) => {
    e.preventDefault();
    if (type === "university") {
      dispatch(
        RemoveFavouriteAction({
          universityId: id,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(FavouriteListAction());
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else if (type === "college") {
      dispatch(
        RemoveFavouriteAction({
          collegeId: id,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(FavouriteListAction());
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else if (type === "school") {
      dispatch(
        RemoveFavouriteAction({
          schoolId: id,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(FavouriteListAction());
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else if (type === "preSchool") {
      dispatch(
        RemoveFavouriteAction({
          preSchoolId: id,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(FavouriteListAction());
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else if (type === "coaching") {
      dispatch(
        RemoveFavouriteAction({
          coachingCertificationId: id,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(FavouriteListAction());
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  console.log("favouriteListfavouriteList",favouriteList);

  return (
    <div>
      <Header />
      <section>
        <div class="DashboardArea">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <Sidenav />
              </div>

              {favouriteList?.total == 0  ? (
                <>
                  <p>No data on Wishlist!</p>
                </>
              ) : (
                <>
                 <div class="col-sm-9">
                    {favouriteList && favouriteList?.result?.length > 0 ? (
                      <>
                        {favouriteList?.result?.map((item) => (
                          <div class="UniversityBox">
                            <div class="UniversityLeft">
                              <img
                                src={
                                  item?.universityId?.thumbnail ||
                                  item?.schoolId?.thumbnail ||
                                  item?.collegeId?.thumbnail ||
                                  item?.preSchoolId?.thumbnail ||
                                  item?.coachingCertificationId?.thumbnail
                                }
                              />
                            </div>
                            <div class="UniversityRight">
                              <div class="UniversityName">
                                <aside>
                                  <h3>
                                    {item?.universityId?.name ||
                                      item?.schoolId?.name ||
                                      item?.collegeId?.name ||
                                      item?.preSchoolId?.name ||
                                      item?.coachingCertificationId?.name}
                                  </h3>
                                  <ul>
                                    <li>
                                      <img
                                        src={require("../../assets/images/Location.png")}
                                      />{" "}
                                      {item?.universityId?.address ||
                                        item?.collegeId?.address ||
                                        item?.schoolId?.address ||
                                        item?.preSchoolId?.address ||
                                        item?.coachingCertificationId?.address}
                                    </li>
                                    <li>
                                      <StarRatings
                                        rating={
                                          item?.collegeId?.rating ||
                                          item?.universityId?.rating ||
                                          item?.schoolId?.rating ||
                                          item?.preSchoolId?.rating ||
                                          item?.coachingCertificationId?.rating
                                        }
                                        starRatedColor="yellow"
                                        // changeRating={this.changeRating}
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="2px"
                                        name="rating"
                                      />
                                      {/* <span>
                                (
                                {item?.universityId?.rating?.toString() ||
                                  item?.schoolId?.rating?.toString() || item?.collegeId?.rating?.toString()}
                                )
                              </span> */}
                                    </li>
                                  </ul>
                                </aside>
                                <aside>
                                  <a
                                    class="WishIcon"
                                    onClick={(e) =>
                                      handleRemoveWishlist(
                                        e,
                                        item?.universityId?._id ||
                                          item?.schoolId?._id ||
                                          item?.collegeId?._id ||
                                          item?.preSchoolId?._id ||
                                          item?.coachingCertificationId?._id,
                                        item?.type
                                      )
                                    }
                                  >
                                    Remove to Wishlist
                                  </a>
                                </aside>
                              </div>
                              <div class="UniversityRank">
                                <h6>Ranking :</h6>
                                <ul>
                                  <li>
                                    {/* <span class="Icon">
                              <img
                                src={require("../../assets/images/Ranking-1.png")}
                              />
                            </span> */}
                                    <label>
                                      {item?.collegeId?.rankings?.[0]
                                        ?.soureOfRanking ||
                                        item?.coachingCertificationId
                                          ?.rankings[0]?.soureOfRanking ||
                                        item?.universityId?.rankings[0]
                                          ?.soureOfRanking ||
                                        item?.schoolId?.rankings[0]
                                          ?.soureOfRanking ||
                                        item?.preSchoolId?.rankings[0]
                                          ?.soureOfRanking}{" "}
                                      :
                                      <strong>
                                        {item?.collegeId?.rankings?.[0]?.ranking
                                          ? item?.collegeId?.rankings?.[0]
                                              ?.ranking
                                          : 0 ||
                                            item?.universityId?.rankings?.[0]
                                              ?.ranking
                                          ? item?.universityId?.rankings?.[0]
                                              ?.ranking
                                          : 0 ||
                                            item?.schoolId?.rankings?.[0]
                                              ?.ranking
                                          ? item?.schoolId?.rankings?.[0]
                                              ?.ranking
                                          : 0 ||
                                            item?.preSchoolId?.rankings?.[0]
                                              ?.ranking
                                          ? item?.preSchoolId?.rankings?.[0]
                                              ?.ranking
                                          : 0 ||
                                            item?.coachingCertificationId
                                              ?.rankings?.[0]?.ranking
                                          ? item?.coachingCertificationId
                                              ?.rankings?.[0]?.ranking
                                          : 0}
                                        /10
                                      </strong>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                              <div class="UniversityType">
                                <h6>Type :</h6>
                                <p>
                                  {item?.universityId?.studyType ||
                                    item?.schoolId?.studyType ||
                                    item?.collegeId?.collegeType ||
                                    item?.preSchoolId?.studyType ||
                                    item?.coachingCertificationId?.studyType}
                                </p>
                              </div>
                              <div class="UniversityFee">
                                <p>
                                  <span>
                                    <img
                                      src={require("../../assets/images/Book.png")}
                                    />
                                  </span>{" "}
                                  {item?.universityId?.studyMode ||
                                    item?.schoolId?.studyMode ||
                                    item?.collegeId?.studyMode ||
                                    item?.preSchoolId?.studyMode ||
                                    item?.coachingCertificationId?.studyMode}
                                </p>
                                <p>
                                  <strong>Fees Range : </strong>{" "}
                                  {item?.universityId?.feesMin ||
                                    item?.schoolId?.feesMin ||
                                    item?.collegeId?.feesMin ||
                                    item?.preSchoolId?.feesMin ||
                                    item?.coachingCertificationId?.feesMin}{" "}
                                  to{" "}
                                  {item?.universityId?.feesMax ||
                                    item?.schoolId?.feesMax ||
                                    item?.collegeId?.feesMax ||
                                    item?.preSchoolId?.feesMax ||
                                    item?.coachingCertificationId?.feesMax}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                         <LoaderDots />
                      </>
                    )}

                    {/*  */}
                  </div>
                  {/* <ThreeDots /> */}
                
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Wishlist;
