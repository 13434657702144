import { studentDetailsConstant } from "../actionType";
import { studentUpdateConstant } from "../actionType";
import { studentApplication } from "../actionType";







import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";


// ***********************************


export const studentDetialsInitiate=() =>({
    type: studentDetailsConstant.STUDENT_DETAILS_INITIATE

})

export const studentDetailsSuccess=(data) =>({
    type: studentDetailsConstant.STUDENT_DETAILS_SUCCESS,
    payload:data

})

export const studentDetailsFailure=(data) =>({
    type : studentDetailsConstant.STUDENT_DETAILS_FAILURE,

})

//*************************************** */




export const studentUpdateInitiate=() =>({
    type: studentUpdateConstant.STUDENT_UPDATE_INITIATE

})

export const studentUpdateSuccess=(data) =>({
    type:  studentUpdateConstant.STUDENT_UPDATE_SUCCESS,
    payload:data

})

export const studentUpdateFailure=(data) =>({
    type: studentUpdateConstant.STUDENT_UPDATE_FAILURE,

})






export const  studentDetailsAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(studentDetialsInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/studentDetails', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(studentDetailsSuccess(data))

                    }
                    else {
                        dispatch(studentDetailsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(studentDetailsFailure(err))
                    reject(err);
                })

        );
    }

}



export const  studentUpdateAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(studentUpdateInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/updateStudent', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(studentUpdateSuccess(data))

                    }
                    else {
                        dispatch(studentUpdateFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(studentUpdateFailure(err))
                    reject(err);
                })

        );
    }

}





//*************************************** */




export const studentApplicationInitiate=() =>({
    type: studentApplication.APPLICATION_LIST_INITIATE

})

export const studentApplicationSuccess=(data) =>({
    type:  studentApplication.APPLICATION_LIST_SUCCESS,
    payload:data

})

export const studentApplicationFailure=(data) =>({
    type:  studentApplication.APPLICATION_LIST_FAILURE,

})






export const  StudentApplicationAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(studentApplicationInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/myApplications', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(studentApplicationSuccess(data))

                    }
                    else {
                        dispatch(studentApplicationFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(studentApplicationFailure(err))
                    reject(err);
                })

        );
    }

}
