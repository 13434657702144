import { examCareerConstant } from "../actionType";






import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const examListInitiate=() =>({
    type: examCareerConstant.EXAM_LIST_INITIATE

})

export const examListSuccess=(data) =>({
    type: examCareerConstant.EXAM_LIST_SUCCESS,
    payload:data

})

export const examListFailure=(data) =>({
    type: examCareerConstant.EXAM_LIST_FAILURE,
    payload : data

})



//*************************************** */


export const  ExamListAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(examListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/examList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(examListSuccess(data))

                    }
                    else {
                        dispatch(examListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(examListFailure(err))
                    reject(err);
                })

        );
    }

}





export const careerListInitiate=() =>({
    type: examCareerConstant.CAREER_LIST_INITIATE

})

export const careerListSuccess=(data) =>({
    type: examCareerConstant.CAREER_LIST_SUCCESS,
    payload:data

})

export const careerListFailure=(data) =>({
    type: examCareerConstant.CAREER_LIST_SUCCESS,
    payload : data

})



//*************************************** */


export const  CareerListAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(careerListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/careerList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(careerListSuccess(data))

                    }
                    else {
                        dispatch(careerListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(careerListFailure(err))
                    reject(err);
                })

        );
    }

}


export const addCareerEnquiryInitiate=() =>({
    type: examCareerConstant.ADD_CAREER_ENQUIRY_INITIATE

})

export const addCareerEnquirySuccess=(data) =>({
    type: examCareerConstant.ADD_CAREER_ENQUIRY_SUCCESS,
    payload:data

})

export const addCareerEnquiryFailure=(data) =>({
    type: examCareerConstant.ADD_CAREER_ENQUIRY_FAILURE,
    payload : data

})



//*************************************** */


export const  AddCareerEnquiryAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(addCareerEnquiryInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/createCarrerEnquiry', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(addCareerEnquirySuccess(data))

                    }
                    else {
                        dispatch(addCareerEnquiryFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(addCareerEnquiryFailure(err))
                    reject(err);
                })

        );
    }

}



export const addExamEnquiryInitiate=() =>({
    type: examCareerConstant.ADD_EXAM_ENQUIRY_INITIATE

})

export const addExamEnquirySuccess=(data) =>({
    type: examCareerConstant.ADD_EXAM_ENQUIRY_SUCCESS,
    payload:data

})

export const addExamEnquiryFailure=(data) =>({
    type: examCareerConstant.ADD_EXAM_ENQUIRY_FAILURE,
    payload : data

})



//*************************************** */


export const  AddExamEnquiryAction=(payload)=>{
    const token = isLoggedIn('websiteData')

    
        
    return dispatch => {
        dispatch(addExamEnquiryInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/createExamEnquiry', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(addExamEnquirySuccess(data))

                    }
                    else {
                        dispatch(addExamEnquiryFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(addExamEnquiryFailure(err))
                    reject(err);
                })

        );
    }

}





