import { faqListConstant } from "../actionType";

const initialState ={
    faqList:[],
    loader:false,
    error:{}
}



export default function faqReducer(state=initialState,{type,payload}){
    switch(type){
        case faqListConstant.FAQ_LIST_INITIATE:
            return{...state,loader:true,  faqList:[]}
        case faqListConstant.FAQ_LIST_SUCCESS:
            return{...state,loader:false,  faqList :payload.data}

        case faqListConstant.FAQ_LIST_FAILURE:
            return{...state,  error:payload}

            default : return state;
    }

    }

 