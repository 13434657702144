import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { universeDetailsAction } from "../redux/actions/universityAction";
import { collegeDetailsAction } from "../redux/actions/collegeAction";
import { preSchoolDetailsAction, schoolDetailsAction } from "../redux/actions/schoolAction";
import { coachingDetailsAction } from "../redux/actions/coachingAndCertificatesAction";
import { getAddsListAction } from "../redux/actions/getAddsActions";
import StarRatings from "react-star-ratings";

const init ={
universityDetailsDataFirst:[],
collegeDetailsDataFirst:[],
schoolDetailsDataFirst:[],
preSchoolDetailsDataFirst:[],
certificateDetailsDataFirst:[],
universityDetailsDataSecond:[],
collegeDetailsDataSecond:[],
schoolDetailsDataSecond:[],
preSchoolDetailsDataSecond:[],
certificateDetailsDataSecond:[],
}

const Compare = () => {
    const [iState, updateState] = useState(init);
    const{universityDetailsDataFirst, collegeDetailsDataFirst,schoolDetailsDataFirst} = iState;
    const [detailsDataSecond, updateDetailsDataSecond] = useState([])
    const [detailsDataThird, updateDetailsDataThird] = useState([])
    const [detailsDataFour, updateDetailsDataFour] = useState([])
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {state} = useLocation(); 
    const[addsMappingData, setAddsMappingData] = useState([]);
    const {paramsId, name} = useParams()
  
  const {getAddsListData } = useSelector(
    (state) => state?.listReducer
  );

    
    const compareOneId = JSON?.parse(localStorage?.getItem("compareOneId"))
    const compareTwoId = JSON?.parse(localStorage?.getItem("compareTwoId"))
    const compareThreeId = JSON?.parse(localStorage?.getItem("compareThreeId"));
    const compareFourId = JSON?.parse(localStorage?.getItem("compareFourId"));
    // console.log(compareOneId)
    useEffect(() =>{
      window.scrollTo(0,0)
        dispatch(getAddsListAction({category:"Compare Page"}))
    },[])
    useEffect(() => {
       
        if(state.type === "University"){
            dispatch(universeDetailsAction({_id:compareOneId?.id})).then((res) => {
                updateState({...iState, universityDetailsDataFirst:res?.data})
   
           })
        } else if (state.type === "College"){
            dispatch(collegeDetailsAction({_id:compareOneId?.id})).then((res) => {              
                updateState({...iState, universityDetailsDataFirst:res?.data})
   
           })
        }
        else if (state.type === "School"){
            dispatch(schoolDetailsAction({_id:compareOneId?.id})).then((res) => {
                updateState({...iState, universityDetailsDataFirst:res?.data})
   
           })
        }
        else if (state.type === "preSchool"){
            dispatch(preSchoolDetailsAction({_id:compareOneId?.id})).then((res) => {
                updateState({...iState, universityDetailsDataFirst:res?.data})
   
           })
        } else if (state.type === "Certificate"){
            dispatch(coachingDetailsAction({_id:compareOneId?.id})).then((res) => {
                updateState({...iState, universityDetailsDataFirst:res?.data})
   
           })
        }
        
       
    },[compareOneId?.id])

    useEffect(() => {
      
        if(state.type === "University"){
            dispatch(universeDetailsAction({_id:compareTwoId?.id})).then((res) => {
                updateDetailsDataSecond(res?.data)
   
           })
        } else if (state.type === "College"){
            dispatch(collegeDetailsAction({_id:compareTwoId?.id})).then((res) => {
                updateDetailsDataSecond(res?.data)
   
           })
        } else if (state.type === "School"){
            dispatch(schoolDetailsAction({_id:compareTwoId?.id})).then((res) => {
                updateDetailsDataSecond(res?.data)
   
           })
        }
        else if (state.type === "preSchool"){
            dispatch(preSchoolDetailsAction({_id:compareTwoId?.id})).then((res) => {
                updateDetailsDataSecond(res?.data)
   
           })
        }
        else if (state.type === "Certificate"){
            dispatch(coachingDetailsAction({_id:compareTwoId?.id})).then((res) => {
                updateDetailsDataSecond(res?.data)
   
           })
        }
       
    },[compareTwoId?.id])

    useEffect(() => {
      // console.log("==========>", compareThreeId)
      if(state.type === "University"){
          dispatch(universeDetailsAction({_id:compareThreeId?.id})).then((res) => {
              updateDetailsDataThird(res?.data)
 
         })
      } else if (state.type === "College"){
          dispatch(collegeDetailsAction({_id:compareThreeId?.id})).then((res) => {
              updateDetailsDataThird(res?.data)
 
         })
      } else if (state.type === "School"){
          dispatch(schoolDetailsAction({_id:compareThreeId?.id})).then((res) => {
              updateDetailsDataThird(res?.data)
 
         })
      }
      else if (state.type === "preSchool"){
          dispatch(preSchoolDetailsAction({_id:compareThreeId?.id})).then((res) => {
              updateDetailsDataThird(res?.data)
 
         })
      }
      else if (state.type === "Certificate"){
          dispatch(coachingDetailsAction({_id:compareThreeId?.id})).then((res) => {
              updateDetailsDataThird(res?.data)
 
         })
      }
     
  },[compareThreeId?.id])

  useEffect(() => {
    // console.log("==========>", compareFourId)
    if(state.type === "University"){
        dispatch(universeDetailsAction({_id:compareFourId?.id})).then((res) => {
            updateDetailsDataFour(res?.data)

       })
    } else if (state.type === "College"){
        dispatch(collegeDetailsAction({_id:compareFourId?.id})).then((res) => {
            updateDetailsDataFour(res?.data)

       })
    } else if (state.type === "School"){
        dispatch(schoolDetailsAction({_id:compareFourId?.id})).then((res) => {
            updateDetailsDataFour(res?.data)

       })
    }
    else if (state.type === "preSchool"){
        dispatch(preSchoolDetailsAction({_id:compareFourId?.id})).then((res) => {
            updateDetailsDataFour(res?.data)

       })
    }
    else if (state.type === "Certificate"){
        dispatch(coachingDetailsAction({_id:compareFourId?.id})).then((res) => {
            updateDetailsDataFour(res?.data)

       })
    }
   
},[compareFourId?.id])
    useEffect(() =>{
        if(getAddsListData && getAddsListData?.length>0){
            if(getAddsListData?.length > 0 ){
                if(getAddsListData?.[0]?.image?.length > 0){
                    // console.log("insde")
                    if(getAddsListData?.[0]?.googleAddShow == "Inactive"){
                      // console.log("insde",getAddsListData?.data?.[0]?.image)
                      setAddsMappingData(getAddsListData?.[0]?.image)
                      // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
                    } else{
                      setAddsMappingData("https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg")
                      // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
                    }
                  }
            }
        }
     
       
        
      },[getAddsListData])
    const goToSelectComparePage = (id2, id3,id4)=>{
        // alert(id)
        if(state.type === "University"){
            navigate("/list-university-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
        }
        if(state.type === "College"){
            navigate("/list-college-compare", {
                state:{
                id2, id3, id4
                  
                }
              })
          
        }
        if(state.type === "School"){
            navigate("/list-school-compare", {
                state:{
                id2, id3, id4
                  
                }
              })
        }
        if(state.type === "preSchool"){
            navigate("/list-preSchool-compare", {
                state:{
                id2, id3, id4
                  
                }
              })
        }
        if(state.type === "Certificate"){
            navigate("/list-certificate-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
          
        }
    }


    const goToCompareSelectionFirst = (id2, id3,id4) => {
        // alert(id)
        localStorage.removeItem("compareOneId")
        if(state.type === "University"){
            navigate("/list-university-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
           
        }
        if(state.type === "College"){
            navigate("/list-college-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
        }
        if(state.type === "School"){
            navigate("/list-school-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
         
        }
        if(state.type === "preSchool"){
            navigate("/list-preSchool-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
        }
        if(state.type === "Certificate"){
            navigate("/list-certificate-compare", {
                state:{
                  id2, id3, id4
                  
                }
              })
        }
    }
    // goToCompareSelectionSecond
    const goToCompareSelectionSecond = (id2, id3,id4) => {
        // alert(id2, id3,id4)
        localStorage.removeItem("compareTwoId")
        if(state.type === "University"){
            navigate("/list-university-compare", {
                state:{
                  id2, id3,id4
                  
                }
              })
            navigate("/list-university-compare",state={id2, id3,id4})
        }
        if(state.type === "College"){
            navigate("/list-college-compare", {
                state:{
                  id2, id3,id4
                  
                }
              })
            navigate("/list-college-compare",state={id2, id3,id4})
        }
        if(state.type === "School"){
            navigate("/list-school-compare", {
                state:{
                  id2, id3,id4
                  
                }
              })
            navigate("/list-school-compare",state={id2, id3,id4})
        }
        if(state.type === "preSchool"){
            navigate("/list-preSchool-compare", {
                state:{
                  id2, id3,id4
                  
                }
              })
        }
        if(state.type === "Certificate"){
            // navigate("/list-certificate-compare",state={id2, id3,id4})
            navigate("/list-certificate-compare", {
                state:{
                  id2, id3,id4
                  
                }
              })
        }
    }

    const goToCompareSelectionThird = (id2, id3,id4) => {
      // alert(id2, id3,id4)
      localStorage.removeItem("compareThreeId")
      if(state.type === "University"){
          navigate("/list-university-compare", {
              state:{
                id2, id3,id4
                
              }
            })
          navigate("/list-university-compare",state={id2, id3,id4})
      }
      if(state.type === "College"){
          navigate("/list-college-compare", {
              state:{
                id2, id3,id4
                
              }
            })
          navigate("/list-college-compare",state={id2, id3,id4})
      }
      if(state.type === "School"){
          navigate("/list-school-compare", {
              state:{
                id2, id3,id4
                
              }
            })
          navigate("/list-school-compare",state={id2, id3,id4})
      }
      if(state.type === "preSchool"){
          navigate("/list-preSchool-compare", {
              state:{
                id2, id3,id4
                
              }
            })
      }
      if(state.type === "Certificate"){
          // navigate("/list-certificate-compare",state={id2, id3,id4})
          navigate("/list-certificate-compare", {
              state:{
                id2, id3,id4
                
              }
            })
      }
  }

  const goToCompareSelectionFour = (id2, id3,id4) => {
    // alert(id2, id3,id4)
    localStorage.removeItem("compareThreeId")
    if(state.type === "University"){
        navigate("/list-university-compare", {
            state:{
              id2, id3,id4
              
            }
          })
        navigate("/list-university-compare",state={id2, id3,id4})
    }
    if(state.type === "College"){
        navigate("/list-college-compare", {
            state:{
              id2, id3,id4
              
            }
          })
        navigate("/list-college-compare",state={id2, id3,id4})
    }
    if(state.type === "School"){
        navigate("/list-school-compare", {
            state:{
              id2, id3,id4
              
            }
          })
        navigate("/list-school-compare",state={id2, id3,id4})
    }
    if(state.type === "preSchool"){
        navigate("/list-preSchool-compare", {
            state:{
              id2, id3,id4
              
            }
          })
    }
    if(state.type === "Certificate"){
        // navigate("/list-certificate-compare",state={id2, id3,id4})
        navigate("/list-certificate-compare", {
            state:{
              id2, id3,id4
              
            }
          })
    }
}

  
// console.log("newsSlider",universityDetailsDataFirst)

    return (
        <div>
            <Header />
            <div class="SearchArea NewsBreadcumb">
                <div class="container">
                    <h3>Compare {name} </h3>
                </div>
            </div>
           

            <section>
           
                <div class="CompareArea">
                {/* <div class="VerticalBannerBox VerticalBannerLeft">
     
</div> */}
<div class="VerticalBannerBox VerticalBannerRight CompareVerticalBanner">
      {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Top right"){
                // console.log(ele)
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              )
            }
      })}
         </> : <></> }
         {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Top right 2"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              )
            }
      })}
         </> : <></> }
</div>
                    <div class="container">
                    <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position ==  "Top"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              
              )
            }
      })}
         </> : <></> }
                               
                            </div>
                        <div class="CompareTable">
                       

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Compare 1</th>
                                        <th>Compare 2</th>
                                        <th>Compare 3</th>
                                        <th>Compare 4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <strong>Name</strong></td>
                                        <td>
                                            <div class="CollegeBox">
                                                <figure>
                                                    <img src={universityDetailsDataFirst?.thumbnail} />
                                                </figure>
                                                <figcaption>
                                                    <h3><a>{universityDetailsDataFirst?.name}</a></h3>
                                                    <ul>
                                                        <li><img src={require('../assets/images/Location.png')} /> {universityDetailsDataFirst?.city}, {universityDetailsDataFirst?.state}</li>
                                                        <li>
                                      <StarRatings
                                        rating={compareOneId?.ratingCount}
                                        starRatedColor="yellow"
                                        // changeRating={this.changeRating}
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="2px"
                                        name="rating"
                                      />
                                      <span>({compareOneId?.rating})</span>
                                    </li>
                                                    </ul>
                                                    <a onClick={() =>goToCompareSelectionFirst(detailsDataSecond?._id, detailsDataThird?._id, detailsDataFour?._id)} class="Change">Change</a>
                                                </figcaption>
                                            </div>
                                        </td>


                                        <td>
                                            {compareTwoId? <>
                                                <div class="CollegeBox">
                                                <figure>
                                                    <img src={detailsDataSecond?.thumbnail} />
                                                </figure>
                                                <figcaption>
                                                    <h3><a>{detailsDataSecond?.name}</a></h3>
                                                    <ul>
                                                        <li><img src={require('../assets/images/Location.png')} /> {detailsDataSecond?.city}, {detailsDataSecond?.state}</li>
                                                        <li>
                                      <StarRatings
                                        rating={compareTwoId?.ratingCount}
                                        starRatedColor="yellow"
                                        // changeRating={this.changeRating}
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="2px"
                                        name="rating"
                                      />
                                      <span>({compareTwoId?.rating})</span>
                                    </li>
                                                    </ul>
                                                    <a onClick={() =>goToCompareSelectionSecond(universityDetailsDataFirst?._id, detailsDataFour?._id, detailsDataThird?._id)} class="Change">Change</a>
                                                </figcaption>
                                            </div>
                                            </> : <>
                                            <a onClick={() =>goToSelectComparePage(universityDetailsDataFirst?._id,detailsDataFour?._id, detailsDataThird?._id)} class="Select" >Select Compare</a>
                                            </>}
                                           
                                        </td>

                                        <td>
                                            {compareThreeId? <>
                                                <div class="CollegeBox">
                                                <figure>
                                                    <img src={detailsDataThird?.thumbnail} />
                                                </figure>
                                                <figcaption>
                                                    <h3><a>{detailsDataThird?.name}</a></h3>
                                                    <ul>
                                                        <li><img src={require('../assets/images/Location.png')} /> {detailsDataThird?.city}, {detailsDataThird?.state}</li>
                                                        <li>
                                      <StarRatings
                                        rating={compareThreeId?.ratingCount}
                                        starRatedColor="yellow"
                                        // changeRating={this.changeRating}
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="2px"
                                        name="rating"
                                      />
                                      <span>({compareThreeId?.rating})</span>
                                    </li>
                                                    </ul>
                                                    <a onClick={() =>goToCompareSelectionThird(universityDetailsDataFirst?._id, detailsDataSecond?._id, detailsDataFour?._id)} class="Change">Change</a>
                                                </figcaption>
                                            </div>
                                            </> : <>
                                            <a onClick={() =>goToSelectComparePage(universityDetailsDataFirst?._id,detailsDataSecond?._id, detailsDataFour?._id)} class="Select" >Select Compare</a>
                                            </>}
                                           
                                        </td>


                                        <td>
                                            {compareFourId? <>
                                                <div class="CollegeBox">
                                                <figure>
                                                    <img src={detailsDataFour?.thumbnail} />
                                                </figure>
                                                <figcaption>
                                                    <h3><a>{detailsDataFour?.name}</a></h3>
                                                    <ul>
                                                        <li><img src={require('../assets/images/Location.png')} /> {detailsDataFour?.city}, {detailsDataFour?.state}</li>
                                                        <li>
                                      <StarRatings
                                        rating={compareFourId?.ratingCount}
                                        starRatedColor="yellow"
                                        // changeRating={this.changeRating}
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="2px"
                                        name="rating"
                                      />
                                      <span>({compareFourId?.rating})</span>
                                    </li>
                                                    </ul>
                                                    <a onClick={() =>goToCompareSelectionFour(universityDetailsDataFirst?._id,detailsDataSecond?._id, detailsDataThird?._id)} class="Change">Change</a>
                                                </figcaption>
                                            </div>
                                            </> : <>
                                            <a onClick={() =>goToSelectComparePage(universityDetailsDataFirst?._id,detailsDataSecond?._id, detailsDataThird?._id)} class="Select" >Select Compare</a>
                                            </>}
                                           
                                        </td>



                                    </tr>
                                    <tr>
                                    <td> <strong>Ranking</strong></td>
                                        
                                
                                      {universityDetailsDataFirst?.rankings?.length > 0 ? <>
                                            <td>
                                                <div className="RankingBox">
                                                    <p>
                                                    {(universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking)?universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking:""} : <strong>{(universityDetailsDataFirst?.rankings?.[0]?.ranking)?universityDetailsDataFirst?.rankings?.[0]?.ranking:""}/10</strong>
                                                    </p>
                                                    <div class="RankingDetails">
                                                    {universityDetailsDataFirst?.rankings &&universityDetailsDataFirst?.rankings?.length > 0 ? <>
                              {universityDetailsDataFirst?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> }
                                                    </div>
                                                </div>


                                            <ul>
                                            {/* {(universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking)?universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking:""} : <strong>{(universityDetailsDataFirst?.rankings?.[0]?.ranking)?universityDetailsDataFirst?.rankings?.[0]?.ranking:""}/10</strong> */}
                                            {/* <div className="RankingDetails">
                              {universityDetailsDataFirst?.rankings &&universityDetailsDataFirst?.rankings?.length > 0 ? <>
                              {universityDetailsDataFirst?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> } 
                            
                            </div>*/}
                                            </ul>
                                           
                                        </td>
                                        </> : <> <><td>N/A</td></></>}
                      


                                     
                                      
                                          {compareTwoId ? <>
                                            {detailsDataSecond?.rankings?.length > 0 ? <>
                                            <td>
                                                <div className="RankingBox">
                                                    <p>
                                                    {(detailsDataSecond?.rankings?.[0]?.soureOfRanking)?detailsDataSecond?.rankings?.[0]?.soureOfRanking:""} : <strong>{(detailsDataSecond?.rankings?.[0]?.ranking)?detailsDataSecond?.rankings?.[0]?.ranking:""}/10</strong>
                                                    </p>
                                                    <div class="RankingDetails">
                                                    {detailsDataSecond?.rankings &&detailsDataSecond?.rankings?.length > 0 ? <>
                              {detailsDataSecond?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> }
                                                    </div>
                                                </div>


                                            <ul>
                                            {/* {(universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking)?universityDetailsDataFirst?.rankings?.[0]?.soureOfRanking:""} : <strong>{(universityDetailsDataFirst?.rankings?.[0]?.ranking)?universityDetailsDataFirst?.rankings?.[0]?.ranking:""}/10</strong> */}
                                            {/* <div className="RankingDetails">
                              {universityDetailsDataFirst?.rankings &&universityDetailsDataFirst?.rankings?.length > 0 ? <>
                              {universityDetailsDataFirst?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> } 
                            
                            </div>*/}
                                            </ul>
                                           
                                        </td>
                                        </> : <><td>N/A</td></>}
                                          </> : <></>}
                                        
                                          {compareThreeId ? <>
                                            {detailsDataThird?.rankings?.length > 0 ? <>
                                            <td>
                                                <div className="RankingBox">
                                                    <p>
                                                    {(detailsDataThird?.rankings?.[0]?.soureOfRanking)?detailsDataThird?.rankings?.[0]?.soureOfRanking:""} : <strong>{(detailsDataThird?.rankings?.[0]?.ranking)?detailsDataThird?.rankings?.[0]?.ranking:""}/10</strong>
                                                    </p>
                                                    <div class="RankingDetails">
                                                    {detailsDataThird?.rankings &&detailsDataThird?.rankings?.length > 0 ? <>
                              {detailsDataThird?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> }
                                                    </div>
                                                </div>


                                            <ul>
                                           
                                            </ul>
                                           
                                        </td>
                                        </> :  <><td>N/A</td></>}
                                          </> : <></>}

                                          {compareFourId ? <>
                                            {detailsDataFour?.rankings?.length > 0 ? <>
                                            <td>
                                                <div className="RankingBox">
                                                    <p>
                                                    {(detailsDataThird?.rankings?.[0]?.soureOfRanking)?detailsDataThird?.rankings?.[0]?.soureOfRanking:""} : <strong>{(detailsDataThird?.rankings?.[0]?.ranking)?detailsDataThird?.rankings?.[0]?.ranking:""}/10</strong>
                                                    </p>
                                                    <div class="RankingDetails">
                                                    {detailsDataThird?.rankings &&detailsDataThird?.rankings?.length > 0 ? <>
                              {detailsDataThird?.rankings?.map((ele,i) =>{
                                return(
                                  <>
                                   <p>
                                <label>{ele?.soureOfRanking} : </label>
                                <span>{ele?.ranking}/10</span>
                              </p> 
                             
                                  </>
                                )
                              })}
                              </> : <></> }
                                                    </div>
                                                </div>


                                            <ul>
                                           
                                            </ul>
                                           
                                        </td>
                                        </> : <><td>N/A</td></>}
                                          </> : <></>}
                                        
                                      
                                    </tr>
                                    <tr>
                                    <td> <strong>Fee</strong></td>
                                       
                                        <td><strong>{universityDetailsDataFirst?.feesMin} {(universityDetailsDataFirst?.feesMin)?"to":""} {universityDetailsDataFirst?.feesMin}</strong></td>
                                        {detailsDataSecond ? <>
                                            <td><strong>{detailsDataSecond?.feesMin} {(detailsDataSecond?.feesMin)?"to":""} {detailsDataSecond?.feesMin}</strong></td>
                                        </> : <></>}
                                        {detailsDataThird ? <>
                                            <td><strong>{detailsDataThird?.feesMin} {(detailsDataThird?.feesMin)?"to":""} {detailsDataThird?.feesMin}</strong></td>
                                        </> : <></>}
                                        {detailsDataFour ? <>
                                            <td><strong>{detailsDataFour?.feesMin} {(detailsDataFour?.feesMin)?"to":""} {detailsDataFour?.feesMin}</strong></td>
                                        </> : <></>}
                                    </tr>

                                    <tr>
                                    <td> <strong>Study Mode</strong></td>
                                      
                                
                                        <td><strong>{universityDetailsDataFirst?.studyMode}</strong></td>
                                        {detailsDataSecond ? <>
                                            <td><strong>{detailsDataSecond?.studyMode} </strong></td>
                                        </> : <></>}
                                        {detailsDataThird ? <>
                                            <td><strong>{detailsDataThird?.studyMode} </strong></td>
                                        </> : <></>}
                                        {detailsDataFour ? <>
                                            <td><strong>{detailsDataFour?.studyMode} </strong></td>
                                        </> : <></>}
                                    </tr>
                                    <tr>
                                    <td> <strong>Study Type</strong></td>
                                        
                                        <td><strong>{state.type == "Certificate" || state.type == "College" ? universityDetailsDataFirst?.collegeType : universityDetailsDataFirst?.studyType  }</strong></td>
                                        {detailsDataSecond ? <>
                                            <td><strong>{state.type == "Certificate" || state.type == "College" ? detailsDataSecond?.collegeType : detailsDataSecond?.studyType} </strong></td>
                                        </> : <></>}
                                        {detailsDataThird ? <>
                                            <td><strong>{state.type == "Certificate" || state.type == "College" ? detailsDataThird?.collegeType : detailsDataThird?.studyType} </strong></td>
                                        </> : <></>}
                                        {detailsDataFour ? <>
                                            <td><strong>{state.type == "Certificate" || state.type == "College" ? detailsDataFour?.collegeType : detailsDataFour?.studyType} </strong></td>
                                        </> : <></>}
                                    </tr>
                                    <tr>
                                    <td> <strong>ESTD</strong></td>
                                       
                                        <td><strong>{universityDetailsDataFirst?.estd}</strong></td>
                                        {detailsDataSecond ? <>
                                            <td><strong>{detailsDataSecond?.estd} </strong></td>
                                        </> : <></>}
                                        {detailsDataThird ? <>
                                            <td><strong>{detailsDataThird?.estd} </strong></td>
                                        </> : <></>}
                                        {detailsDataFour ? <>
                                            <td><strong>{detailsDataFour?.estd} </strong></td>
                                        </> : <></>}

                                    </tr>
                                    <tr>
                                    <td> <strong>Facilities</strong></td>
                                       

                                        <td><strong>{universityDetailsDataFirst?.facilities?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                        {compareTwoId? <>
                                            <td><strong>{detailsDataSecond?.facilities?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                        </> : <></>}
                                        {compareThreeId? <>
                                            <td><strong>{detailsDataThird?.facilities?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                        </> : <></>}
                                        {compareFourId? <>
                                            <td><strong>{detailsDataFour?.facilities?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                        </> : <></>}
                                       
                                    </tr>
                                    <tr>
                                        {state.type == "School" || state.type == "preSchool" || state.type == "Certificate"  ? <></> : <>
                                        
                                        <td> <strong>Courses Provided</strong></td>
                                        <td><strong>{universityDetailsDataFirst?.courseInfo?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                       {compareTwoId ? <>
                                        <td><strong>{detailsDataSecond?.courseInfo?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                       </> : <></>}
                                       {compareThreeId ? <>
                                        <td><strong>{detailsDataThird?.courseInfo?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                       </> : <></>}
                                       {compareFourId ? <>
                                        <td><strong>{detailsDataFour?.courseInfo?.map((ele) => {
                                            return(
                                                <>
                                                {ele?.name}, {" "}
                                                </>
                                            )
                                        })}</strong></td>
                                       </> : <></>}
                                        </>}
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="AdvertisementBox">
                  {addsMappingData && addsMappingData?.length > 0 ? <>
      {addsMappingData?.map((ele,i) => {
            if(ele?.position == "Footer top"){
              return(
                <a href={ele?.link} target = "_blank">   <img src={ele?.image} alt=" faq top"/></a>
              
              )
            }
      })}
         </> : <></> }
                               
                            </div>
                    </div>
                   
                </div>
            </section>

            <Footer />

        </div>
    );
}

export default Compare


{/* <a onClick={() =>goToSelectComparePage()} class="Select" >Select Compare</a> */}