import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import {
  externalJobListAction,
  getStateList,
  internalJobListAction,
} from "../redux/actions/jobAction";
import { streamDropdownAction } from "../redux/actions/universityAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAddsListAction } from "../redux/actions/getAddsActions";
const init = {
  workType: "",
  jobType: "",
  salary: "",
  experience: "",
  search: "",
  category: "",
  location: "",
};

const FindJob = () => {
  const [ShowSignInModal, setShowSignInModal] = useState(false);
  const [iState, updateState] = useState(init);
  const [addsMappingData, setAddsMappingData] = useState([]);
  const { search, category, location, jobType, workType, experience, salary } =
    iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { externalJobListData, stateData, streamData, getAddsListData } =
    useSelector((state) => state.listReducer);
  console.log("getAddsListData===============>", getAddsListData);
  useEffect(() => {
    dispatch(getStateList({ isoCode: "IN" }));
    dispatch(externalJobListAction());
    dispatch(streamDropdownAction());
    dispatch(getAddsListAction({ category: "Find Job Page" }));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getAddsListData && getAddsListData?.length > 0) {
      if (getAddsListData?.[0]?.image?.length > 0) {
        console.log("insde");
        if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
          // console.log("insde",getAddsListData?.data?.[0]?.image)
          setAddsMappingData(getAddsListData?.[0]?.image);
          // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
        } else {
          setAddsMappingData([
            "https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg",
          ]);
          // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
        }
      }
    }
  }, [getAddsListData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const filteredData = (e) => {
    e.preventDefault();
    const data = {
      search,
      category,
      location,
      jobType,
      workType,
      experience,
      salary,
    };
    dispatch(externalJobListAction(data));
  };
  const goToJobApply = (ele) => {
    const userData = JSON.parse(localStorage.getItem("websiteData"))?._id;
    if (userData) {
      navigate("/find-job-apply", {
        state: {
          ele,
        },
      });
    } else {
      // toast.info("// please login to perform this action!");
      setShowSignInModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowSignInModal(false);
  };

  return (
    <div>
      <Header
        ShowSignInModal={ShowSignInModal}
        handleCloseModal={handleCloseModal}
      />
      <section>
        <div
          class="SearchArea NewsBreadcumb"
          style={{
            backgroundImage:
              "url(https://mobulous.co.in/CareerDekhoo/Website/images/JobBanner.png)",
          }}
        >
          <div class="container">
            <h3>Discover the path, where passion follows</h3>
            <p>Home / find jobs</p>
          </div>
        </div>
      </section>

      <section>
        <div class="VerticalBannerBox VerticalBannerLeft">
          {addsMappingData && addsMappingData?.length > 0 ? (
            <>
              {addsMappingData?.map((ele, i) => {
                if (ele?.position == "Filter left") {
                  return (
                    <a href={ele?.link} target="_blank">
                      {" "}
                      <img src={ele?.image} alt=" faq top" />
                    </a>
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div class="VerticalBannerBox VerticalBannerRight">
          {addsMappingData && addsMappingData?.length > 0 ? (
            <>
              {addsMappingData?.map((ele, i) => {
                if (ele?.position == "Filter right") {
                  console.log(ele);
                  return (
                    <a href={ele?.link} target="_blank">
                      {" "}
                      <img src={ele?.image} alt=" faq top" />
                    </a>
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div class="OpeningArea">
          <div class="container">
            <div class="AdvertisementBox">
              {addsMappingData && addsMappingData?.length > 0 ? (
                <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Top left") {
                      return (
                        <a href={ele?.link} target="_blank">
                          {" "}
                          <img src={ele?.image} alt="faq top" />
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
            <div class="OpeningHead">
              <h3>Find Jobs</h3>
              <p>
                CareerDekhoo.com offers an exclusive service for prime members
                who have a unique CD-UID to access the "Find Jobs" service. We
                understand the struggle of candidates after their studies in
                pursuing further career opportunities. Hence, we list the latest
                industry jobs on our portal to help candidates build their
                careers. We collaborate with different companies from various
                domains as per industry requirements. Eligible candidates can
                apply for this service and our internal recruitment team
                scrutinizes each candidate's resume. Here are the benefits of
                our "Find Jobs" service:
              </p>
              <br />
              <ul>
                <li>• Access to the listing of the latest industry jobs</li>
                <li>• Job applications preference through our portal</li>
                <li>• Regular career guidance news and updates</li>
                <li>• Participation in job events</li>
                <li>• Assistance with job interview preparation</li>
                <li>• Personalized career counseling</li>
              </ul>
              <br />

              <p>
                To apply for this service, candidates with a CD-UID can read the
                instructions provided on our website. The CD-UID is generated
                when any candidate gets admission/enrolled in any of our listed
                institutes through us and becomes a prime member. Our
                recruitment process involves a first-round screening by our team
                and then direct interaction with the company, either online or
                offline. It is important to note that the selection of the
                candidate completely depends on the interview and the final
                decision lies with the interviewer or the recruiting company,
                according to their norms.
              </p>
              <br />
              <p>For more information, contact us at <a href="mailto:info@careerdekhoo.com">info@careerdekhoo.com</a></p>
            </div>

            <div class="OpeningBody">
              <h3>Current Openings</h3>

              <div class="OpeningFilter">
                <form>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Search</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          name="search"
                          value={search}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Job Category</label>
                        <select
                          class="form-control"
                          name="category"
                          value={category}
                          onChange={handleChange}
                        >
                          <option>Select Type</option>
                          {streamData?.data?.result &&
                          streamData?.data?.result?.length > 0 ? (
                            <>
                              {streamData?.data?.result?.map((ele, index) => (
                                <option value={ele?.streamName}>
                                  {ele?.streamName}
                                </option>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Job Type</label>
                        <select
                          value={jobType}
                          name="jobType"
                          onChange={handleChange}
                          class="form-control"
                        >
                          <option>Select Type</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Work Type</label>
                        <select
                          value={workType}
                          name="workType"
                          onChange={handleChange}
                          class="form-control"
                        >
                          <option>Select Type</option>
                          <option value="Remote">Remote</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="On-Site">On-Site</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Experience</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter your experience... "
                          name="experience"
                          value={experience}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Salary Range</label>
                        <select
                          value={salary}
                          name="salary"
                          onChange={handleChange}
                          class="form-control"
                        >
                          <option>-- Select --</option>
                          <option value="1 LPA to 3 LPA">1 LPA to 3 LPA</option>
                          <option value="3 LPA to 6 LPA">3 LPA to 6 LPA</option>
                          <option value="6 LPA to 9 LPA">6 LPA to 9 LPA</option>
                          <option value="9 LPA to 12 LPA">
                            9 LPA to 12 LPA
                          </option>
                          <option value="12 LPA to 15 LPA">
                            12 LPA to 15 LPA
                          </option>
                          <option value="15 LPA to 20 LPA">
                            15 LPA to 20 LPA
                          </option>
                          <option value="20 LPA Above ">20 LPA Above </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Job Location</label>
                        <select
                          class="form-control"
                          name="location"
                          value={location}
                          onChange={handleChange}
                        >
                          <option>Select</option>
                          {stateData?.data && stateData?.data?.length > 0 ? (
                            <>
                              {stateData?.data?.map((ele, index) => (
                                <option value={ele?.isoCode}>
                                  {ele?.name}
                                </option>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <button onClick={filteredData}>Search</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {externalJobListData?.data?.result &&
              externalJobListData?.data?.result?.length > 0 ? (
                <>
                  {externalJobListData?.data?.result?.map((ele, index) => {
                    // console.log(ele)
                    return (
                      <>
                        <div class="OpeningBox">
                          <div class="OpeningBoxHead">
                            <aside>
                              <h6>
                                Job Id:{"  "}
                                {ele?.jobId}
                              </h6>
                              <h5>{ele?.title}</h5>
                              <p>
                                <img
                                  src={require("../assets/images/LocationBlue.png")}
                                />
                                {ele?.location}
                              </p>
                            </aside>
                            <aside class="text-right">
                              {/* <Link to="/find-job-apply" state={ele} class="Apply">Apply</Link> */}
                              <button
                                class="Apply"
                                onClick={() => goToJobApply(ele)}
                              >
                                Apply
                              </button>
                              <a
                                class="Download"
                                href={ele?.description}
                                target="_blank"
                              >
                                Download Job Description
                              </a>
                            </aside>
                          </div>
                          <div class="OpeningBoxBody">
                            <article>
                              <aside>
                                <span class="Interest">
                                  Salary:{"  "}
                                  {ele?.salary}
                                </span>
                              </aside>
                            </article>
                            <article>
                              <aside>
                                <h6>Category{"  "}</h6>
                                <p>{ele?.stream}</p>
                              </aside>
                              <aside>
                                <h6>Work Type{"  "}</h6>
                                <p>{ele?.workType}</p>
                              </aside>
                              <aside>
                                <h6>Job Type{"  "}</h6>
                                <p>{ele?.jobType}</p>
                              </aside>
                              <aside>
                                <h6>Experience Required{"  "}</h6>
                                <p>{ele?.experience} years</p>
                              </aside>
                            </article>
                            {/* <h6>Job Description</h6>
                                    <p>
                                    {ele?.jobId}
                                    </p> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {" "}
                  <h4> No data found</h4>
                </>
              )}
              {/* footer top */}
              <div class="AdvertisementBox">
                {addsMappingData && addsMappingData?.length > 0 ? (
                  <>
                    {addsMappingData?.map((ele, i) => {
                      if (ele?.position == "Footer top") {
                        return (
                          <a href={ele?.link} target="_blank">
                            {" "}
                            <img src={ele?.image} alt="footer top" />
                          </a>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FindJob;
