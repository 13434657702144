import { homePageConstant, subscribeNewsLetter } from "../actionType";


const initialState = {
  
  homePageData: [],
  subscribeNewsData:{},

  loader: false,
  error: {},
};

export default function homePageReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
   

      case homePageConstant.HOME_PAGE_DATA_INITIATE:
        return { ...state, loader: true, homePageData: [] };
      case homePageConstant.HOME_PAGE_DATA_SUCCESS:
        return { ...state, loader: false, homePageData: payload.data };
  
      case homePageConstant.HOME_PAGE_DATA_FAILURE:
        return { ...state, error: payload };

        case subscribeNewsLetter.SUBSCRIBE_NEWSLETTER_INITIATE:
          return { ...state, loader: true, subscribeNewsData:{} };
        case subscribeNewsLetter.SUBSCRIBE_NEWSLETTER_SUCCESS:
          return { ...state, loader: false, subscribeNewsData: payload.data };
    
        case subscribeNewsLetter.SUBSCRIBE_NEWSLETTER_FAILURE:
          return { ...state, error: payload };
  
  
    

                   
    

       

    default:
      return state;
  }
}
