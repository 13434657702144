import { faqListConstant } from "../actionType";


import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const faqListInitiate=() =>({
    type: faqListConstant.FAQ_LIST_INITIATE

})

export const faqListSuccess=(data) =>({
    type: faqListConstant.FAQ_LIST_SUCCESS,
    payload:data

})

export const faqListFailure=(data) =>({
    type: faqListConstant.FAQ_LIST_FAILURE,
    payload : data

})


//*************************************** */


export const  FaqListAction=(payload)=>{
    const token = isLoggedIn('adminData')

        
    return dispatch => {
        dispatch(faqListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/faqList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(faqListSuccess(data))

                    }
                    else {
                        dispatch(faqListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(faqListFailure(err))
                    reject(err);
                })

        );
    }

}

