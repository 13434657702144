import { newsListConstant } from "../actionType";

const initialState ={
    newsList:[],
    loader:false,
    error:{}
}



export default  function  newsListReducer(state=initialState,{type,payload}){
    switch(type){
        case newsListConstant.NEWS_LIST_INITIATE:
            return{...state,loader:true,  newsList:[]}
        case newsListConstant.NEWS_LIST_SUCCESS:
            return{...state,loader:false,  newsList :payload.data}

        case newsListConstant.NEWS_LIST_FAILURE:
            return{...state,  error:payload}

            default : return state;
    }

    }

