import { homepageSearch } from "../actionType";


const initialState ={
    searchList:[],
    searchSuggestionList:[],
    loader:false,
    error:{}
}



export default function searchReducer(state=initialState,{type,payload}){
    switch(type){
        case homepageSearch.HOMEPAGE_SEARCH_INITIATE:
            return{...state,loader:true,  searchList:[]}
        case homepageSearch.HOMEPAGE_SEARCH_SUCCESS:
            return{...state,loader:false,  searchList :payload.data}

        case homepageSearch.HOMEPAGE_SEARCH_FAILURE:
            return{...state,  error:payload}
            case homepageSearch.SEARCH_SUGGESTION_INITIATE:
                return{...state,loader:true,  searchSuggestionList:[]}
            case homepageSearch.SEARCH_SUGGESTION_SUCCESS:
                return{...state,loader:false,  searchSuggestionList :payload.data}
    
            case homepageSearch.SEARCH_SUGGESTION_FAILURE:
                return{...state,  error:payload}
    

            default : return state;
    }

    }
