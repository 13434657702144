import {getStateCityConstant} from "../actionType"

import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const stateListInitiate=() =>({
    type: getStateCityConstant.GET_STATE_LIST_INITIATE

})

export const stateListSuccess=(data) =>({
    type: getStateCityConstant.GET_STATE_LIST_SUCCESS,
    payload:data

})

export const stateListFailure=(data) =>({
    type: getStateCityConstant.GET_STATE_LIST_FAILURE,
    payload : data

})

// **********************************


export const cityListInitiate=() =>({
    type: getStateCityConstant.GET_CITY_LIST_INITIATE

})

export const cityListSuccess=(data) =>({
    type: getStateCityConstant.GET_CITY_LIST_SUCCESS,
    payload:data

})

export const cityListFailure=(data) =>({
    type: getStateCityConstant.GET_CITY_LIST_FAILURE,
    payload : data

})


//*************************************** */


export const  StateListAction=(payload)=>{
    const token = isLoggedIn('webisteData')

    
        
    return dispatch => {
        dispatch(stateListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/institute/getStates', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(stateListSuccess(data))

                    }
                    else {
                        dispatch(stateListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(stateListFailure(err))
                    reject(err);
                })

        );
    }

}




export const  CityListAction=(payload)=>{
    const token = isLoggedIn('werbisteData')

    
        
    return dispatch => {
        dispatch(cityListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/institute/getCities', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(cityListSuccess(data))

                    }
                    else {
                        dispatch(cityListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(cityListFailure(err))
                    reject(err);
                })

        );
    }

}


