import React from 'react'
import { ThreeDots } from "react-loader-spinner";

const LoaderDots = () => {
  return (
<div className="LoaderBox">
                <ThreeDots
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                color="orange"

              />  
              </div>)
}

export default LoaderDots