import axios from "axios";
import { universityActionTypes } from "../actionType";
import { isLoggedIn } from "../../utils";
import {baseUrl} from "../../config/Config"





  export const universeListInitate = () => ({
    type: universityActionTypes.UNIVERSITY_LIST_INITIATE,
  });
  
  export const universeListSuccess = (data) => ({
    type: universityActionTypes.UNIVERSITY_LIST_SUCCESS,
    payload: data,
  });
  
  export const universeListFailure = (data) => ({
    type: universityActionTypes.UNIVERSITY_LIST_FAILURE,
    payload: data,
  });
  
  export function universeListAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(universeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl+'/website/universityList', payload, {
            headers: { authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(universeListSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(universeListFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(universeListFailure(err))
            reject(err);
          });
      });
    };
  }

  export const universeDetailsInitate = () => ({
    type: universityActionTypes.UNIVERSITY_DETAILS_INITIATE,
  });
  
  export const universeDetailsSuccess = (data) => ({
    type: universityActionTypes.UNIVERSITY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const universeDetailsFailure = (data) => ({
    type: universityActionTypes.UNIVERSITY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export function universeDetailsAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(universeDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/universityDetails`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                universeDetailsSuccess(data);
              //// console.log("storeDetails", data);
              resolve(data);
            } else {
                universeDetailsFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            universeDetailsFailure(err);
            reject(err);
          });
      });
    };
  }

  export const universityFilterInitate = () => ({
    type: universityActionTypes.UNIVERSITY_FILTER_INITIATE,
  });
  
  export const universityFilterSuccess = (data) => ({
    type: universityActionTypes.UNIVERSITY_FILTER_SUCCESS,
    payload: data,
  });
  
  export const universityFilterFailure = (data) => ({
    type: universityActionTypes.UNIVERSITY_FILTER_FAILURE,
    payload: data,
  });
  
  export function universityFilterAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(universityFilterInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/universityFilters`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(universityFilterSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(universityFilterFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(universityFilterFailure(err))
            reject(err);
          });
      });
    };
  }

  export const changeUniverseStatusInitate = () => ({
    type: universityActionTypes.CHANGE_UNIVERSITY_STATUS_INITIATE,
  });
  
  export const changeUniverseStatusSuccess = (data) => ({
    type: universityActionTypes.CHANGE_UNIVERSITY_STATUS_SUCCESS,
    payload: data,
  });
  
  export const changeUniverseStatusFailure = (data) => ({
    type: universityActionTypes.CHANGE_UNIVERSITY_STATUS_FAILURE,
    payload: data,
  });
  
  export function changeUniverseStatus(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(changeUniverseStatusInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${baseUrl}changeUniversityStatus`,
            payload,
            {
              headers: { Authorization: `${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                changeUniverseStatusSuccess(data);
              //// console.log("changeCategoryStatus", data);
              resolve(data);
            } else {
                changeUniverseStatusFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            changeUniverseStatusFailure(err);
            reject(err);
          });
      });
    };
  }

  export const updateUniverseInitate = () => ({
    type: universityActionTypes.UPDATE_UNIVERSITY_INITIATE,
  });
  
  export const updateUniverseSuccess = (data) => ({
    type: universityActionTypes.UPDATE_UNIVERSITY_SUCCESS,
    payload: data,
  });
  
  export const updateUniverseFailure = (data) => ({
    type: universityActionTypes.UPDATE_UNIVERSITY_FAILURE,
    payload: data,
  });
  
  export function updateUniverseAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(updateUniverseInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}updateUniversity`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                updateUniverseSuccess(data);
              //// console.log("updateCategory", data);
              resolve(data);
            } else {
                updateUniverseFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            updateUniverseFailure(err);
            reject(err);
          });
      });
    };
  }
  
  export const removeUniverseInitate = () => ({
    type: universityActionTypes.REMOVE_UNIVERSITY_INITIATE,
  });
  
  export const removeUniverseSuccess = (data) => ({
    type: universityActionTypes.REMOVE_UNIVERSITY_SUCCESS,
    payload: data,
  });
  
  export const removeUniverseFailure = (data) => ({
    type: universityActionTypes.REMOVE_UNIVERSITY_FAILURE,
    payload: data,
  });
  
  export function removeUniversity(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(removeUniverseInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/removeUniversity`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            if (data && data.status == 200) {
                removeUniverseSuccess(data);
              //// console.log("removeCustomer", data);
              resolve(data);
            } else {
                removeUniverseFailure(data);
              reject(data);
            }
          })
          .catch((err) => {
            removeUniverseFailure(err);
            reject(err);
          });
      });
    };
  }


  export const streamDropdownInitate = () => ({
    type: universityActionTypes.STREAM_DROPDOWN_INITIATE,
  });
  
  export const streamDropdownSuccess = (data) => ({
    type: universityActionTypes.STREAM_DROPDOWN_SUCCESS,
    payload: data,
  });
  
  export const streamDropdownFailure = (data) => ({
    type: universityActionTypes.STREAM_DROPDOWN_FAILURE,
    payload: data,
  });
  
  export function streamDropdownAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(streamDropdownInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/streamDropDown`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(streamDropdownSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(streamDropdownFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(streamDropdownFailure(err))
            reject(err);
          });
      });
    };
  }

  export const subStreamDropdownInitate = () => ({
    type: universityActionTypes.SUB_STREAM_DROPDOWN_INITIATE,
  });
  
  export const subStreamDropdownSuccess = (data) => ({
    type: universityActionTypes.SUB_STREAM_DROPDOWN_SUCCESS,
    payload: data,
  });
  
  export const subStreamDropdownFailure = (data) => ({
    type: universityActionTypes.SUB_STREAM_DROPDOWN_FAILURE,
    payload: data,
  });
  
  export function subStreamDropdownAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(subStreamDropdownInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/subStreamDropDown`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(subStreamDropdownSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
           
             else {
              dispatch(subStreamDropdownFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(subStreamDropdownFailure(err))
            reject(err);
          });
      });
    };
  }

  // export const courseDropdownInitate = () => ({
  //   type: universityActionTypes.COURSES_DROPDOWN_INITIATE,
  // });
  
  // export const courseDropdownSuccess = (data) => ({
  //   type: universityActionTypes.COURSES_DROPDOWN_SUCCESS,
  //   payload: data,
  // });
  
  // export const courseDropdownFailure = (data) => ({
  //   type: universityActionTypes.COURSES_DROPDOWN_FAILURE,
  //   payload: data,
  // });
  
  // export function courseDropdownAction(payload) {
  //   const token = isLoggedIn("websiteData");
  //   return (dispatch) => {
  //     dispatch(courseDropdownInitate());
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .post(`${baseUrl}/website/courseDropDown`, payload, {
  //           headers: { Authorization: `${token}` },
  //         })
  //         .then((response) => {
  //           const data = response.data;
  //           // //// console.log("data", response)
  //           if (data && data.status == 200) {
  //              dispatch(courseDropdownSuccess(data));
  //             //// console.log("storeList", data);
  //             resolve(data);
  //           }
           
  //            else {
  //             dispatch(courseDropdownFailure(data))
  //             reject(data)
  //           }
  //         })
  //         .catch((err) => {
  //           dispatch(courseDropdownFailure(err))
  //           reject(err);
  //         });
  //     });
  //   };
  // }