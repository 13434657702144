import { ratingConstant } from "../actionType";


import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const addRatitingInitiate=() =>({
    type: ratingConstant.ADD_RATING_INITIATE

})

export const addRatitingSuccess=(data) =>({
    type: ratingConstant.ADD_RATING_SUCCESS,
    payload:data

})

export const addRatitingFailure=(data) =>({
    type: ratingConstant.ADD_RATING_FAILURE,
    payload : data

})


//*************************************** */


export const  AddRatitingAction=(payload)=>{
    const token = isLoggedIn('websiteData')

        
    return dispatch => {
        dispatch(addRatitingInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/saveRatings', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(addRatitingSuccess(data))

                    }
                    else {
                        dispatch(addRatitingFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(addRatitingFailure(err))
                    reject(err);
                })

        );
    }

}




export const RatitingListInitiate=() =>({
    type: ratingConstant.RATING_LIST_INITIATE

})

export const RatitingListSuccess=(data) =>({
    type: ratingConstant.RATING_LIST_SUCCESS,
    payload:data

})

export const RatitingListFailure=(data) =>({
    type: ratingConstant.RATING_LIST_FAILURE,
    payload : data

})


//*************************************** */


export const  RatitingListAction=(payload)=>{
    const token = isLoggedIn('websiteData')

        
    return dispatch => {
        dispatch(RatitingListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/ratingList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(RatitingListSuccess(data))

                    }
                    else {
                        dispatch(RatitingListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(RatitingListFailure(err))
                    reject(err);
                })

        );
    }

}


