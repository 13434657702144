import { newsListConstant } from "../actionType";






import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const newsListInitiate=() =>({
    type: newsListConstant.NEWS_LIST_INITIATE

})

export const newsListSuccess=(data) =>({
    type: newsListConstant.NEWS_LIST_SUCCESS,
    payload:data

})

export const newsListFailure=(data) =>({
    type: newsListConstant.NEWS_LIST_FAILURE,
    payload : data

})


//*************************************** */


export const  NewsListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(newsListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/newsUpdateList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        // console.log(data,"33333333333333")
                        dispatch(newsListSuccess(data))

                    }
                    else {
                        dispatch(newsListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(newsListFailure(err))
                    reject(err);
                })

        );
    }

}





export const newsDetailsInitiate=() =>({
    type: newsListConstant.NEWS_DETAILS_INITIATE

})

export const newsDetailsSuccess=(data) =>({
    type: newsListConstant.NEWS_DETAILS_SUCCESS,
    payload:data

})

export const newsDetailsFailure=(data) =>({
    type: newsListConstant.NEWS_DETAILS_FAILURE,
    payload : data

})


//*************************************** */


export const  NewsDetailsAction=(payload)=>{
    const token = isLoggedIn('adminData') 
    return dispatch => {
        dispatch(newsDetailsInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/newsUpdateDetails', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        // console.log(data,"33333333333333")
                        dispatch(newsDetailsSuccess(data))

                    }
                    else {
                        dispatch(newsDetailsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(newsDetailsFailure(err))
                    reject(err);
                })

        );
    }

}



