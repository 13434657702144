import axios from "axios";
import { homepageSearch } from "../actionType";


import { baseUrl } from "../../config/Config";


export const searchInitiate=() =>({
    type:homepageSearch.HOMEPAGE_SEARCH_INITIATE

})

export const searchSuccess=(data) =>({
    type:homepageSearch.HOMEPAGE_SEARCH_SUCCESS,
    payload:data

})

export const searchFailure=(data) =>({
    type:homepageSearch.HOMEPAGE_SEARCH_FAILURE,
    payload : data

})



export const  HomepageSearchAction=(payload)=>{
    
        
    return dispatch => {
        dispatch(searchInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl +'/website/homeScreenSearch', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(searchSuccess(data))

                    }
                    else {
                        dispatch(searchFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(searchFailure(err))
                    reject(err);
                })

        );
    }

}

export const searchSuggestionInitiate=() =>({
    type:homepageSearch.SEARCH_SUGGESTION_INITIATE

})

export const searchSuggestionSuccess=(data) =>({
    type:homepageSearch.SEARCH_SUGGESTION_SUCCESS,
    payload:data

})

export const searchSuggestionFailure=(data) =>({
    type:homepageSearch.SEARCH_SUGGESTION_FAILURE,
    payload : data

})



export const  SearchSuggestionAction=(payload)=>{
    



    return dispatch => {
        dispatch(searchSuggestionInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl +'/website/searchSuggestions', payload)
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(searchSuggestionSuccess(data))

                    }
                    else {
                        dispatch(searchSuggestionFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(searchSuggestionFailure(err))
                    reject(err);
                })

        );
    }

}

