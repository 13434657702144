import axios from "axios";
import { coursesAndCertificates, enquiryManagement } from "../actionType";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";





  export const advertiseEnquiryInitate = () => ({
    type: enquiryManagement.ADVERTISE_ENQUIRY_INITIATE,
  });
  
  export const advertiseEnquirySuccess = (data) => ({
    type: enquiryManagement.ADVERTISE_ENQUIRY_SUCCESS,
    payload: data,
  });
  
  export const advertiseEnquiryFailure = (data) => ({
    type: enquiryManagement.ADVERTISE_ENQUIRY_FAILURE,
    payload: data,
  });
  
  export function advertiseEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(advertiseEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createAdvertiseEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(advertiseEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(advertiseEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(advertiseEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }


  export const carrerEnquiryInitate = () => ({
    type: enquiryManagement.CAREER_ENQUIRY_INITIATE,
  });
  
  export const carrerEnquirySuccess = (data) => ({
    type: enquiryManagement.CAREER_ENQUIRY_SUCCESS,
    payload: data,
  });
  
  export const carrerEnquiryFailure = (data) => ({
    type: enquiryManagement.CAREER_ENQUIRY_FAILURE,
    payload: data,
  });
  
  export function carrerEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(carrerEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createCarrerEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(carrerEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(carrerEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(carrerEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }

  export const createEntitiesInitate = () => ({
    type: enquiryManagement.CREATE_ENTITIES_INITIATE,
  });
  
  export const createEntitiesSuccess = (data) => ({
    type: enquiryManagement.CREATE_ENTITIES_SUCCESS,
    payload: data,
  });
  
  export const createEntitiesFailure = (data) => ({
    type: enquiryManagement.CREATE_ENTITIES_FAILURE,
    payload: data,
  });
  
  export function createEntitiesAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(createEntitiesInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createEntitiesEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(createEntitiesSuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(createEntitiesFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(createEntitiesFailure(err))
            reject(err);
          });
      });
    };
  }



  
  export const createLoanEnquiryInitate = () => ({
    type: enquiryManagement.CREATE_ENTITIES_INITIATE,
  });
  
  export const createLoanEnquirySuccess = (data) => ({
    type: enquiryManagement.CREATE_ENTITIES_SUCCESS,
    payload: data,
  });
  
  export const createLoanEnquiryFailure = (data) => ({
    type: enquiryManagement.CREATE_ENTITIES_FAILURE,
    payload: data,
  });
  
  export function createLoanEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(createLoanEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createLoanEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(createLoanEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(createLoanEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(createLoanEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }




   
  export const createExamEnquiryInitate = () => ({
    type: enquiryManagement.CREATE_EXAM_ENQUIRY_INITIATE,
  });
  
  export const createExamEnquirySuccess = (data) => ({
    type: enquiryManagement.CREATE_EXAM_ENQUIRY_SUCCESS,
    payload: data,
  });
  
  export const createExamEnquiryFailure = (data) => ({
    type: enquiryManagement.CREATE_EXAM_ENQUIRY_FAILURE,
    payload: data,
  });
  
  export function createExamEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(createExamEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createExamEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(createExamEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }
            //  else if (data && data.status == 400) {
            //   dispatch(collegeListSuccess(data));
            //  //// console.log("storeList", data);
            //  resolve(data);
            // }
             else {
              dispatch(createExamEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(createExamEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }


  export const instituteEnquiryInitate = () => ({
    type: enquiryManagement.CREATE_INSTITUTE_ENQUIRY_INITIATE,
  });
  
  export const instituteEnquirySuccess = (data) => ({
    type: enquiryManagement.CREATE_INSTITUTE_ENQUIRY_SUCCESS,
    payload: data,
  });
  
  export const instituteEnquiryFailure = (data) => ({
    type: enquiryManagement.CREATE_INSTITUTE_ENQUIRY_FAILURE,
    payload: data,
  });
  
  export function instituteEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(instituteEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createInstituteEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(instituteEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }

             else {
              dispatch(instituteEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(instituteEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }



  
  export const admissionEnquiryInitate = () => ({
    type: enquiryManagement.ADMISSION_ENQUIRY_INITIATE,
  });
  
  export const admissionEnquirySuccess = (data) => ({
    type: enquiryManagement.ADMISSION_ENQUIRY_SUCCESS,
    payload: data,
  });
  
  export const admissionEnquiryFailure = (data) => ({
    type: enquiryManagement.ADMISSION_ENQUIRY_FAIURE,
    payload: data,
  });
  
  export function admissionEnquiryAction(payload) {
    const token = isLoggedIn("websiteData");
    return (dispatch) => {
      dispatch(admissionEnquiryInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseUrl}/website/createAddmissionEnquiry`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            const data = response.data;
            // //// console.log("data", response)
            if (data && data.status == 200) {
               dispatch(admissionEnquirySuccess(data));
              //// console.log("storeList", data);
              resolve(data);
            }

             else {
              dispatch(admissionEnquiryFailure(data))
              reject(data)
            }
          })
          .catch((err) => {
            dispatch(admissionEnquiryFailure(err))
            reject(err);
          });
      });
    };
  }