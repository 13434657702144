import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { NewsListAction } from "../redux/actions/newsAction"
import moment from "moment";
import { getAddsListAction } from "../redux/actions/getAddsActions";


const LatestNews = () => {
  const dispatch = useDispatch()
  const { newsList } = useSelector((state) => state.newsListReducer)
  const [addsMappingData, setAddsMappingData] = useState([])

  const { getAddsListData } = useSelector(
    (state) => state?.listReducer
  );
  // console.log("getAddsListData===============>",getAddsListData)

  useEffect(() => {
    dispatch(NewsListAction())
    dispatch(getAddsListAction({ category: "Latest News Page" }))
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (getAddsListData?.[0]?.image?.length > 0) {
      // console.log("insde")
      if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
        // console.log("insde",getAddsListData?.data?.[0]?.image)
        setAddsMappingData(getAddsListData?.[0]?.image)
        // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
      } else {
        setAddsMappingData(["https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"])
        // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
      }
    }

  }, [getAddsListData])

  return (
    <div>
      <Header />
      <section>
        <div class="SearchArea NewsBreadcumb">
          <div class="container">
            <h3>Latest News</h3>
            <p>Home / Latest News</p>
          </div>
        </div>
      </section>

      <section>
        <div class="VerticalBannerBox VerticalBannerLeft">
          {addsMappingData && addsMappingData?.length > 0 ? <>
            {addsMappingData?.map((ele, i) => {
              if (ele?.position == "Page heading left") {
                return (
                  <a href={ele?.link} target="_blank">   <img src={ele?.image} alt=" faq top" /></a>

                )
              }
            })}
          </> : <></>}
        </div>
        <div class="VerticalBannerBox VerticalBannerRight">
          {addsMappingData && addsMappingData?.length > 0 ? <>
            {addsMappingData?.map((ele, i) => {
              if (ele?.position == "Listing right") {
                console.log(ele)
                return (
                  <a href={ele?.link} target="_blank">   <img src={ele?.image} alt=" faq top" /></a>

                )
              }
            })}
          </> : <></>}
        </div>
        <div class="NewsArea">
          <div class="container">
            <div class="MainHeading">
              <figure><img src={require('../assets/images/blogIcon.png')} /></figure>
              <p>What's News</p>
              <h2>Get Update From Our News</h2>
            </div>

            <div class="row">
              <div class="AdvertisementBox">
                {addsMappingData && addsMappingData?.length > 0 ? <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Top center") {
                      return (
                        <a href={ele?.link} target="_blank">   <img src={ele?.image} alt="faq top" /></a>

                      )
                    }
                  })}
                </> : <></>}
              </div>
              {newsList?.result?.map((item, index) =>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="NewsBox">
                    <figure>
                      <img src={item?.image} />
                    </figure>
                    <figcaption>
                      <h6><span>{moment(item?.createdAt).format('ll')}</span></h6>
                      <h3><Link to="/lastest-news-details" state={item}>{item?.title}</Link></h3>
                      <div dangerouslySetInnerHTML={{ __html: newsList?.result?.[index]?.description?.slice(0, 350) }} className='editor'></div>
                      <p class="readMore"><Link to="/lastest-news-details" state={item}>Read more</Link></p>
                    </figcaption>
                  </div>
                </div>
              )}
              <div class="AdvertisementBox">
                {addsMappingData && addsMappingData?.length > 0 ? <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Footer top") {
                      return (
                        <a href={ele?.link} target="_blank">   <img src={ele?.image} alt="faq top" /></a>

                      )
                    }
                  })}
                </> : <></>}
              </div>

            </div>

          </div>
        </div>
      </section>
      <Footer />

    </div>
  );
}

export default LatestNews