import { favouriteConstant } from "../actionType";


import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const favouriteListInitiate=() =>({
    type: favouriteConstant.FAVOURITE_LIST_INITIATE

})

export const favouriteListSuccess=(data) =>({
    type: favouriteConstant.FAVOURITE_LIST_SUCCESS,
    payload:data

})

export const favouriteListFailure=(data) =>({
    type: favouriteConstant.FAVOURITE_LIST_INITIATE,
    payload : data

})


//*************************************** */


export const  FavouriteListAction=(payload)=>{
    const token = isLoggedIn('websiteData')

        
    return dispatch => {
        dispatch(favouriteListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/favouriteList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(favouriteListSuccess(data))

                    }
                   else if (data && data.status === 201) {
                        dispatch(favouriteListSuccess(data))

                    }
                    else {
                        dispatch(favouriteListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(favouriteListFailure(err))
                    reject(err);
                })

        );
    }

}


export const addFavouriteInitiate=() =>({
    type: favouriteConstant.ADD_FAVOURITE_INITIATE

})

export const addFavouriteSuccess=(data) =>({
    type: favouriteConstant.ADD_FAVOURITE_SUCCESS,
    payload:data

})

export const addFavouriteFailure=(data) =>({
    type: favouriteConstant.ADD_FAVOURITE_FAILURE,
    payload : data

})


//*************************************** */


export const  AddFavouriteAction=(payload)=>{
    const token = isLoggedIn('websiteData')

        
    return dispatch => {
        dispatch(addFavouriteInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/addFavourite', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(addFavouriteSuccess(data))

                    }
                    else {
                        dispatch(addFavouriteFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(addFavouriteFailure(err))
                    reject(err);
                })

        );
    }

}



export const removeFavouriteInitiate=() =>({
    type: favouriteConstant.REMOVE_FAVOURITE_INITIATE

})

export const removeFavouriteSuccess=(data) =>({
    type: favouriteConstant.REMOVE_FAVOURITE_SUCCESS,
    payload:data

})

export const removeFavouriteFailure=(data) =>({
    type: favouriteConstant.REMOVE_FAVOURITE_FAILURE,
    payload : data

})


//*************************************** */


export const  RemoveFavouriteAction=(payload)=>{
    const token = isLoggedIn('websiteData')

        
    return dispatch => {
        dispatch(removeFavouriteInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/removeFavourite', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(removeFavouriteSuccess(data))

                    }
                    else {
                        dispatch(removeFavouriteFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(removeFavouriteFailure(err))
                    reject(err);
                })

        );
    }

}



