import { favouriteConstant } from "../actionType";

const initialState ={
    favouriteList:[],
    favouriteData:{},
}



export default function favouriteReducer(state=initialState,{type,payload}){
    switch(type){
        case favouriteConstant.FAVOURITE_LIST_INITIATE:
            return{...state,loader:true,  favouriteList:[]}
        case favouriteConstant.FAVOURITE_LIST_SUCCESS:
            return{...state,loader:false,  favouriteList :payload.data}

        case favouriteConstant.FAVOURITE_LIST_FAILURE:
            return{...state,  error:payload}


            case favouriteConstant.ADD_FAVOURITE_INITIATE:
                return{...state,loader:true,  favouriteData:{}}
            case favouriteConstant.ADD_FAVOURITE_SUCCESS:
                return{...state,loader:false,  favouriteData :payload.data}
    
            case favouriteConstant.ADD_FAVOURITE_INITIATE:
                return{...state,  error:payload}

        
                case favouriteConstant.REMOVE_FAVOURITE_INITIATE:
            return{...state,loader:true,  favouriteData:[]}
        case favouriteConstant.REMOVE_FAVOURITE_SUCCESS:
            return{...state,loader:false,  favouriteData :payload.data}

        case favouriteConstant.REMOVE_FAVOURITE_FAILURE:
            return{...state,  error:payload}

            default : return state;
    }

    }

 