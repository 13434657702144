import { combineReducers } from "redux";

import adminLoginReducer from './reducers/loginReducer';
import trendingReducer from './reducers/trendingReducer';
import topReducer from './reducers/topReducer';
import homePageReducer from './reducers/home-pageReducer';
import faqReducer from './reducers/faqReducer';
import staticManagementReducer from './reducers/staticManagementReducer';
import blogReducer from './reducers/blogReducer';
import newsReducer from './reducers/newsReducer';
import newsLetterReducer from './reducers/newsLetterReducer';
import studentReducer from './reducers/studentReducer';
import listReducer  from './reducers/listReducer'
import favouriteReducer  from './reducers/favouriteReducer';
import searchReducer  from './reducers/searchReducer';
import newsListReducer from "./reducers/newsReducer";
import examCareerReducer from "./reducers/examCareerReducer";
import stateCityReducer from "./reducers/stateCityReducer";
import ratingReducer from "./reducers/ratingReducer";











const rootReducer=combineReducers({
    adminLoginReducer,
    trendingReducer,
    topReducer,
    homePageReducer,
    faqReducer,
    staticManagementReducer,
    blogReducer,
    newsReducer,
    newsLetterReducer,
    studentReducer,
    listReducer,
    favouriteReducer,
    searchReducer,
    newsListReducer,
    examCareerReducer,
    stateCityReducer,
    ratingReducer,


})
    


export default rootReducer;