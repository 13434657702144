import { blogListConstant } from "../actionType";






import axios from "axios";
import { baseUrl } from "../../config/Config";
import { isLoggedIn } from "../../utils";



export const blogListInitiate=() =>({
    type: blogListConstant.BLOG_LIST_INITIATE

})

export const blogListSuccess=(data) =>({
    type: blogListConstant.BLOG_LIST_SUCCESS,
    payload:data

})

export const blogListFailure=(data) =>({
    type: blogListConstant.BLOG_LIST_FAILURE,
    payload : data

})



//*************************************** */


export const  BlogListAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(blogListInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/blogList', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(blogListSuccess(data))

                    }
                    else {
                        dispatch(blogListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(blogListFailure(err))
                    reject(err);
                })

        );
    }

}



export const blogDetailsInitiate=() =>({
    type: blogListConstant.BLOG_DETAILS_INITIATE

})

export const blogDetailsSuccess=(data) =>({
    type: blogListConstant.BLOG_DETAILS_SUCCESS,
    payload:data

})

export const blogDetailsFailure=(data) =>({
    type: blogListConstant.BLOG_DETAILS_FAILURE,
    payload : data

})



//*************************************** */


export const  BlogDetailsAction=(payload)=>{
    const token = isLoggedIn('adminData')

    
        
    return dispatch => {
        dispatch(blogDetailsInitiate())
        return new Promise((resolve, reject) =>
            axios.post(baseUrl+'/website/blogDetails', payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(blogDetailsSuccess(data))

                    }
                    else {
                        dispatch(blogDetailsFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(blogListFailure(err))
                    reject(err);
                })

        );
    }

}


