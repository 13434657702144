import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";

import {FaqListAction} from "../redux/actions/faqAction";



const Faq = () => {


    const dispatch= useDispatch();
    const {faqList} = useSelector((state)=> state.faqReducer)


    useEffect(()=>
    {
        dispatch(FaqListAction())
 
    },[])

    console.log(faqList)
    return (
        <div>
            <Header />
            <section>
                <div class="SearchArea NewsBreadcumb">
                    <div class="container">
                        <h3>Frequently Asked Question & Answers</h3>
                        <p>Home / Frequently Asked Question & Answers</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="OverviewArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="OverviewLeft">
                                    <div class="OverviewBox">
                                        <h3>FAQ's</h3>
                                        <div class="FAQ">
                                            <div id="accordion">
                                                {faqList?.result?.map((item,key)=> 
                                                <div class="card">
                                                <div class="card-header collapsed" data-toggle="collapse" href={`#collapse${key}`} aria-expanded="true">
                                                    <p>{item?.question}</p>
                                                </div>
                                                <div id={`collapse${key}`} class="collapse" 
                                                // data-parent="#accordion"
                                                >
                                                    <div class="card-body">
                                                        <p>{item?.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                                
                                                )}
                                                

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
}

export default Faq