import { logOutConstant } from "../actionType";

import axios from "axios";
import { isLoggedIn } from "../../utils";

import { baseUrl } from "../../config/Config";

export const logoutUserInitiate=() =>({
    type: logOutConstant.LOGOUT_INITIATE

})

export const logoutUserSuccess=(data) =>({
    type:logOutConstant.LOGOUT_SUCCESS,
    payload:data

})

export const logoutUserFailure=(data) =>({
    type:logOutConstant.LOGOUT_FAILURE,
    payload : data

})




export const LogoutUserAction=(payload)=>{

    const token =isLoggedIn('websiteData');
    console.log(token)
 
    
        
    return dispatch => {
        dispatch(logoutUserInitiate())

        return new Promise((resolve, reject) =>
            axios.post(baseUrl +'/website/studentLogout',payload,{
                headers: {authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {
                        dispatch(logoutUserSuccess(data))

                    }
                    else {
                        dispatch(logoutUserFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(logoutUserFailure(err))
                    reject(err);
                })

        );
    }

}
