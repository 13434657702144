import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";
import {NotificationAction} from "../../redux/actions/updatePasswordAction"
import { useDispatch, useSelector } from "react-redux";


const Settings = () => {
    const dispatch = useDispatch()
    const[notifications, setNotifications] = useState(true)
    const handleChange=(e)=>{
        dispatch(NotificationAction(e))
        setNotifications(e)
    }


    const { studentData, applicationList } = useSelector(
        (state) => state?.studentReducer
      );
      console.log("studentdata", studentData?.result?.platform)

    

    return (
        <div>
            <Header />
            <section>
                <div class="DashboardArea">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-3">
                                <Sidenav />
                            </div>
                            <div class="col-sm-9">
                                <div class="SettingsBox">
                                    <ul>
                                        <li>
                                            <aside>
                                                <div>
                                                    <h4>Notifications</h4>
                                                    <p> You are currently receiving notifications. To disable notifications, use the Toggle. By default, it is enabled for all users to keep themselves updated with the latest industry news, course news, college news, offers, etc.</p>
                                                </div>
                                                <label class="switch">
                                                    <input type="checkbox" checked={notifications} onChange={(e)=>handleChange(e.target.checked)}/>
                                                        <span class="slider"></span>
                                                </label>
                                            </aside>
                                        </li>
                                        {studentData?.result?.platform === "" ? <>
                                        <li>
                                            <Link to="/settings-password">
                                                <aside>
                                                    <div>
                                                        <h4>Change Password</h4>
                                                        <p>To change or update your login password, click here.</p>
                                                    </div>
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </aside>
                                            </Link>
                                        </li>
                                        
                                        </> : <>
                                        
                                        </>}
                                      
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Settings