import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HomepageSearchAction } from "../redux/actions/searchAction";


export default function InputAuto({
  label,
  pholder,
  data,
  onSelected,
  onChange,
  preValue,
}) {

    const dispatch = useDispatch()
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");


  const handler = e => {
    setSugesstions(data?.filter(i => i.startsWith(e.target.value)));
  };

  const handleChange = e => {
    const input = e.target.value;
    setIsHideSuggs(false);
    setSelectedVal(input);
    onChange(input);
  };

  const hideSuggs = value => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
    
  };

  const handleEnter = (e) =>{
    if(e.keyCode == 13 ){
      dispatch(HomepageSearchAction({ search:selectedVal }));


    }}
   


    
    console.log('data', data)
  return (
    <div className="sugesstion-auto">
      <div className="form-control-auto SearchForm">
        <input
        className="form-control"
          placeholder="Search for colleges, universities, schools , preschools and more.."
          type="search"
          value={preValue?preValue:selectedVal}
          onChange={handleChange}
          onKeyUp={handler}
          onKeyDown={handleEnter}

        />
      </div>

      <div
        className="suggestions"
        style={{ display: isHideSuggs ? "none" : "block" }}
      >
        {/* ={`/description-certificate/${ele?._id}`} */}
        {data?.map((item, idx) => (
          <Link to={`/description-${
            item?.type === "pre_school"
              ? "preschool"
              : item?.type === "university"
              ? "university"
              : item?.type === "school"
              ? "school"
              : item?.type === "certificate"
              ? "certificate"
              : "college"
          }/${item?._id}`} state={item}>
          <div
            key={"" + item + idx}
            // onClick={() => {
            //   hideSuggs(item);
            // }}
          >
            <span className="Icon">
            <img src ={item?.thumbnail} />
            </span>
      {item?.name}
          </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
