import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import StarRatings from "react-star-ratings";
import Header from "../header";
import Footer from "../footer";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";

import {
  studentDetailsAction,
  StudentApplicationAction,
} from "../../redux/actions/studentAction";
import { RatitingListAction } from "../../redux/actions/ratingAction";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { FadeLoader } from "react-spinners";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { studentData, applicationList } = useSelector(
    (state) => state?.studentReducer
  );
  const { ratingList } = useSelector((state) => state?.ratingReducer);
  const [isLoading, setLoader] = useState(false);
  const Profie = JSON.parse(localStorage.getItem("websiteData"));
  useEffect(() => {
    setLoader(true);
    dispatch(studentDetailsAction()).then((res) => {
      if (res.status === 503) {
        // toast?.error("User is already login somewhere, Please login again")
        // localStorage.clear();
        // handleSignInShow()
        // window.location.reload()
      }
    });

    dispatch(RatitingListAction());
    dispatch(StudentApplicationAction());
    setLoader(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (studentData?.result) {
      window.localStorage.setItem(
        "websiteData",
        JSON.stringify(studentData?.result)
      );
    }
  }, []);


  const override = {
    margin: "200px 350px",
    radius: "200px",
    height: "1500px",
    width: "500px",
  };

  console.log("ratingList", ratingList)

  return (
    <div>
      <Header />
      <section>
        <div class="DashboardArea">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <Sidenav />
              </div>
              {isLoading ? (
                <>
                  <FadeLoader
                    loading={isLoading}
                    color="#47d636"
                    cssOverride={override}
                  />
                </>
              ) : (
                <>
                  <div class="col-sm-9">
                    <div class="DashboardBox">
                      <div class="TitleBox">
                        <h4>My Profile</h4>
                        <Link to="/my-profile-edit" class="Edit">
                          Edit Profile
                        </Link>
                      </div>

                      <div class="ProfileBox">
                        <figure>
                          {studentData?.result?.profilePic ? (
                            <img src={studentData?.result?.profilePic} />
                          ) : (
                            <img
                              src={require("../../assets/images/demo-profile.png")}
                            />
                          )}
                        </figure>
                        <figcaption>
                          <article>
                            <p>
                              CD-UID: {studentData?.result?.cdUid}{" "}
                              <span>
                                <i class="fa fa-info-circle"></i> How to get
                                CD-UID?
                                <div className="ToopTipProfile">
                                  How to get CD-UID?
                                  <p>
                                    <strong>1. What is CD-UID? </strong>
                                  </p>
                                  <p>
                                    {" "}
                                    CD-UID is only available for premium users
                                    to avail facility of the exclusive and
                                    customized “Find Jobs” platform on the
                                    CareerDekhoo website free of cost.{" "}
                                  </p>
                                  <p>
                                    <strong>
                                      2. Who are premium users and who can get
                                      CD-UID?
                                    </strong>
                                  </p>
                                  <p>
                                    {" "}
                                    Premium users are the students who get
                                    enrolled/admitted in any academic course to
                                    any of the listed institutes on our website
                                    using our “Get Admission” services.{" "}
                                  </p>
                                  <p>
                                    <strong>
                                      3. Eligibility for the “Find Job” portal
                                      or CD-UID?
                                    </strong>
                                  </p>
                                  <p>
                                    {" "}
                                    This service is only available for students
                                    who are pursuing higher education in
                                    Colleges or Universities. If you are in the
                                    final year of your degree or diploma course
                                    then can apply or be eligible for CD-UID.{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    For details and eligibility criteria for
                                    posted jobs kindly read, Job Description
                                    Details under “Find Jobs”.{" "}
                                  </p>
                                  <p>
                                    <strong>4. How to get CD-UID?</strong>
                                  </p>
                                  <p>
                                    {" "}
                                    If you have taken admission through
                                    CareerDekhoo “Get Admission” service and are
                                    still not able to see your CD-UID, then
                                    contact or speak with our academic consular
                                    at getmycduid@careerdekhoo.com
                                  </p>
                                  <p>
                                    {" "}
                                    If your college/university/institute is one
                                    of our partner institutes and listed on our
                                    website then contact your institute
                                    management to get CD-UID.{" "}
                                  </p>
                                  <p>
                                    For any other query or concern about CD-UID
                                    or Find Job portal, contact us at
                                    query@careerdekhoo.com{" "}
                                  </p>
                                </div>
                              </span>
                            </p>
                            <h3>{studentData?.result?.fullName}</h3>
                            <ul>
                              <li>
                                <span>
                                  <i class="fa fa-envelope"></i>
                                </span>{" "}
                                {studentData?.result?.email}
                              </li>
                              <li>
                                <span>
                                  <i class="fa fa-phone"></i>
                                </span>{" "}
                                {studentData?.result?.phoneNumber}
                              </li>
                            </ul>
                          </article>
                          <article style={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                              value={studentData?.percentage}
                              text={`${
                                studentData?.percentage
                                  ? studentData?.percentage
                                  : 0
                              }% `}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: `rgba(62, 152, 199, ${"50" / 100})`,
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(0.20turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.25turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#f88",
                                  // Text size
                                  fontSize: "16px",
                                  textAnchor: "middle",
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#3e98c7",
                                },
                              }}
                            />
                          </article>{" "}
                        </figcaption>
                      </div>

                      <div class="CommonTabs">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#Personal"
                            >
                              Personal Info
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#Education"
                            >
                              Education
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#Applications"
                            >
                              My Applications
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#Reviews"
                            >
                              My Reviews
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          <div class="tab-pane active" id="Personal">
                            <div class="PersonalInfomation">
                              <article>
                                <aside>
                                  <p>
                                    Gender{" "}
                                    <span>{studentData?.result?.gender}</span>
                                  </p>
                                </aside>
                                <aside>
                                  <p>
                                    City{" "}
                                    <span>{studentData?.result?.city}</span>
                                  </p>
                                </aside>
                              </article>
                              <article>
                                <aside>
                                  <p>
                                    Interested Categories{" "}
                                    <span>
                                      {studentData?.result?.interestedCategories?.toString()}
                                    </span>
                                  </p>
                                </aside>
                                <aside>
                                  <p>
                                    Courses Interests{" "}
                                    <span>
                                      {studentData?.result?.interestedCourses?.toString()}
                                    </span>
                                  </p>
                                </aside>
                              </article>
                            </div>
                          </div>

                          <div class="tab-pane fade" id="Education">
                            <div class="PersonalInfomation">
                              <article>
                                <aside>
                                  <p>
                                    Highest Education{" "}
                                    <span>
                                      {studentData?.result?.highestEducation}
                                    </span>
                                  </p>
                                </aside>
                                <aside>
                                  <p>
                                    Board / University{" "}
                                    <span>
                                      {studentData?.result?.boardOrUniversity}
                                    </span>
                                  </p>
                                </aside>
                                <aside>
                                  <p>
                                    Year of Completion{" "}
                                    <span>
                                      {studentData?.result?.yearOfCompletion}
                                    </span>
                                  </p>
                                </aside>
                              </article>
                              <article>
                                <aside>
                                  <p>
                                    {studentData?.result
                                      ?.cgpaOrPercentileType ===
                                    "Percentile" ? (
                                      <>Percentile</>
                                    ) : (
                                      <>CGPA</>
                                    )}

                                    <span>
                                      {studentData?.result?.cgpaOrPercentile}
                                    </span>
                                  </p>
                                </aside>
                                <aside>
                                  <p>
                                    Stream / Subject{" "}
                                    <span>
                                      {studentData?.result?.subjectOrStream}
                                    </span>
                                  </p>
                                </aside>
                              </article>
                            </div>
                          </div>

                          <div class="tab-pane fade" id="Applications">
                            {applicationList?.data?.ratings?.map((item) => (
                              <div className="LoanArea">
                                <div class="LoanBox">
                                  {/* <div class="LoanBoxHead">
                        {item?.type ==="university" &&  <span><img src={item?.universityId?.thumbnail} /></span>}
                        {item?.type ==="school" &&  <span><img src={item?.schoolId?.thumbnail} /></span>}
                        {item?.type ==="preSchool" &&  <span><img src={item?.preSchoolId?.thumbnail} /></span>}
                        {item?.type ==="coaching" &&  <span><img src={item?.coachingCertificationId?.thumbnail} /></span>}

 
                        </div> */}
                                  <article>
                                    <aside>
                                      <h6>Enquiry for</h6>
                                      <p>{item?.type}</p>
                                    </aside>
                                    <aside>
                                      {item?.type === "loan" && (
                                        <>
                                          <h6>Bank Name</h6>
                                          <p>{item?.loanData?.bankName}</p>
                                        </>
                                      )}

                                      {item?.type === "university" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.universityId?.name}</p>
                                        </>
                                      )}

                                      {item?.type === "school" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.schoolId?.name}</p>
                                        </>
                                      )}

                                      {item?.type === "preSchool" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.preSchoolId?.name}</p>
                                        </>
                                      )}

                                      {item?.type === "coaching" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>
                                            {item?.coachingCertificationId
                                              ?.name ||
                                              item?.certiCoachId?.name}
                                          </p>
                                        </>
                                      )}

                                      {item?.type === "college" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.collegeId?.name}</p>
                                        </>
                                      )}
                                      {item?.type === "carrer" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.instituteName}</p>
                                        </>
                                      )}
                                      {item?.type === "exam" && (
                                        <>
                                          <h6>Name</h6>
                                          <p>{item?.instituteName}</p>
                                        </>
                                      )}
                                    </aside>
                                    <aside>
                                      {item?.type === "loan" && (
                                        <>
                                          <h6>Bank Type</h6>
                                          <p>{item?.loanData?.bankType}</p>
                                        </>
                                      )}
                                      {item?.type === "university" && (
                                        <>
                                          <h6>Courses</h6>
                                          <p>{item?.courses}</p>
                                        </>
                                      )}
                                      {item?.type === "school" && (
                                        <>
                                          <h6>Board</h6>
                                          <p>{item?.board}</p>
                                        </>
                                      )}
                                      {item?.type === "preSchool" && (
                                        <>
                                          <h6>Board</h6>
                                          <p>{item?.board}</p>
                                        </>
                                      )}
                                      {item?.type === "coaching" && (
                                        <>
                                          <h6>Courses</h6>
                                          <p>{item?.courses}</p>
                                        </>
                                      )}
                                      {item?.type === "college" && (
                                        <>
                                          <h6>Courses</h6>
                                          <p>{item?.courses}</p>
                                        </>
                                      )}

                                      {item?.type === "carrer" && (
                                        <>
                                          <h6>Institute Type</h6>
                                          <p>{item?.instituteType}</p>
                                        </>
                                      )}
                                      {item?.type === "exam" && (
                                        <>
                                          <h6>Institute Type</h6>
                                          <p>{item?.instituteType}</p>
                                        </>
                                      )}
                                    </aside>
                                    <aside>
                                      {item?.type === "loan" && (
                                        <>
                                          {" "}
                                          <h6>Loan Term (Max)</h6>
                                          <p>
                                            {item?.loanData?.loanTerm} Years
                                          </p>
                                        </>
                                      )}

                                      {item?.type === "university" && (
                                        <>
                                          {" "}
                                          <h6>Stream</h6>
                                          <p>{item?.stream} </p>
                                        </>
                                      )}
                                      {/* {item?.type ==="school" &&<> <h6>Stream</h6>
                            <p>{item?.stream} </p></>} */}
                                      {item?.type === "college" && (
                                        <>
                                          {" "}
                                          <h6>Stream</h6>
                                          <p>{item?.stream} </p>
                                        </>
                                      )}
                                      {/* {item?.type ==="preSchool" &&<> <h6>Stream</h6>
                            <p>{item?.stream} </p></>} */}
                                      {item?.type === "coaching" && (
                                        <>
                                          {" "}
                                          <h6>Stream</h6>
                                          <p>{item?.stream} </p>
                                        </>
                                      )}

                                      {item?.type === "exam" && (
                                        <>
                                          <h6>State</h6>
                                          <p>{item?.state}</p>
                                        </>
                                      )}
                                      {item?.type === "carrer" && (
                                        <>
                                          <h6>State</h6>
                                          <p>{item?.state}</p>
                                        </>
                                      )}
                                    </aside>
                                    <aside>
                                      {item?.type === "loan" && (
                                        <span class="Interest">
                                          Rate of Interest{" "}
                                          <strong>
                                            {item?.loanData?.interestRate}%
                                          </strong>
                                        </span>
                                      )}
                                      {item?.type === "exam" && (
                                        <>
                                          <h6>city</h6>
                                          <p>{item?.city}</p>
                                        </>
                                      )}
                                      {item?.type === "carrer" && (
                                        <>
                                          <h6>city</h6>
                                          <p>{item?.city}</p>
                                        </>
                                      )}
                                    </aside>
                                  </article>
                                  {/* <h6>Loan Scheme</h6>
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra just</p> */}
                                </div>
                              </div>
                            ))}
                          </div>

                          <div class="tab-pane fade" id="Reviews">
                            {ratingList?.ratings?.map((item) => (
                              <di class="ReviewUniversityBox">
                                <div class="UniversityBox">
                                  <div class="UniversityLeft">
                                    <img
                                      src={
                                        item?.universityId?.thumbnail ||
                                        item?.schoolId?.thumbnail ||
                                        item?.collegeId?.thumbnail ||
                                        item?.preSchoolId?.thumbnail ||
                                        item?.coachingCertificationId
                                          ?.thumbnail ||
                                        item?.certiCoachId?.thumbnail
                                      }
                                    />
                                  </div>
                                  <div class="UniversityRight">
                                    <div class="UniversityName">
                                      <aside>
                                        <h3>
                                          <Link
                                            to={
                                              (item?.type === "coaching" &&
                                                `/description-certificate/${item?.coachingCertificationId?._id}`) ||
                                              (item?.type === "preSchool" &&
                                                `/description-preSchool/${item?.preSchoolId?._id}`) ||
                                              (item?.type === "school" &&
                                                `/description-school/${item?.schoolId?._id}`) ||
                                              (item?.type === "college" &&
                                                `/description-college/${item?.collegeId?._id}`) ||
                                              (item?.type === "university" &&
                                                `/description-university/${item?.universityId?._id}`)
                                            }
                                            state={
                                              (item?.type === "coaching" &&
                                                item?.coachingCertificationId) ||
                                              (item?.type === "preSchool" &&
                                                item?.preSchoolId) ||
                                              (item?.type === "university" &&
                                                item?.universityId) ||
                                              (item?.type === "school" &&
                                                item?.schoolId) ||
                                              (item?.type === "college" &&
                                                item?.collegeId)
                                            }
                                          >
                                            {item?.universityId?.name ||
                                              item?.schoolId?.name ||
                                              item?.collegeId?.name ||
                                              item?.preSchoolId?.name ||
                                              item?.coachingCertificationId
                                                ?.name ||
                                              item?.certiCoachId?.name}
                                          </Link>
                                        </h3>
                                        <ul>
                                          <li>
                                            <img
                                              src={require("../../assets/images/Location.png")}
                                            />{" "}
                                            {item?.universityId?.address ||
                                              item?.collegeId?.address ||
                                              item?.schoolId?.address ||
                                              item?.preSchoolId?.address ||
                                              item?.coachingCertificationId
                                                ?.address ||
                                              item?.certiCoachId?.address}
                                          </li>
                                          <li>
                                            <StarRatings
                                              rating={
                                                item?.collegeId?.rating ||
                                                item?.universityId
                                                  ?.rating ||
                                                item?.schoolId?.rating ||
                                                item?.preSchoolId
                                                  ?.rating ||
                                                item?.coachingCertificationId
                                                  ?.rating
                                              }
                                              starRatedColor="yellow"
                                              // changeRating={this.changeRating}
                                              numberOfStars={5}
                                              starDimension="20px"
                                              starSpacing="2px"
                                              name="rating"
                                            />
                                          </li>
                                        </ul>
                                      </aside>
                                      <aside>
                                        <p>
                                          Applied on{" "}
                                          {moment(item?.createdAt).format("ll")}
                                        </p>
                                      </aside>
                                    </div>
                                    <div class="UniversityRank">
                                      <h6>Ranking :</h6>
                                      <ul>
                                        <li>
                                          {/* <span class="Icon">
                                            <img
                                      src={require("../../assets/images/Ranking-1.png")}
                                    />
                                          </span> */}
                                          {item?.collegeId?.rankings?.length > 0 || item?.universityId?.rankings?.length > 0  || item?.schoolId?.rankings?.length > 0  || item?.preSchoolId?.rankings?.length > 0 || item?.coachingCertificationId?.rankings?.length > 0  ?
                                          <>
  <label>
                                            {item?.collegeId?.rankings?.[0]
                                              ?.soureOfRanking
                                              ? item?.collegeId?.rankings?.[0]?.soureOfRanking?.toString() 
                                              : "" ||
                                                item?.universityId
                                                  ?.rankings?.[0]
                                                  ?.soureOfRanking
                                              ? item?.universityId?.rankings?.[0]?.soureOfRanking?.toString()
                                              : "" ||
                                                item?.schoolId?.rankings?.[0]
                                                  ?.soureOfRanking
                                              ? item?.schoolId?.rankings?.[0]?.soureOfRanking?.toString()
                                              : "" ||
                                                item?.preSchoolId?.rankings?.[0]
                                                  ?.soureOfRanking
                                              ? item?.preSchoolId?.rankings?.[0]?.soureOfRanking?.toString()
                                              : "" ||
                                                item?.coachingCertificationId
                                                  ?.rankings?.[0]
                                                  ?.soureOfRanking
                                              ? item?.coachingCertificationId?.rankings?.[0]?.soureOfRanking?.toString()
                                              : ""}
                                            {" "}:{" "}
                                            <strong>
                                              {item?.collegeId?.rankings?.[0]
                                                ?.ranking
                                                ?  item?.collegeId?.rankings?.[0]?.ranking?.toString()
                                                : "" ||
                                                  item?.universityId
                                                    ?.rankings?.[0]?.ranking
                                                ? item?.universityId?.rankings?.[0]?.ranking?.toString()
                                                : "" ||
                                                  item?.schoolId?.rankings?.[0]
                                                    ?.ranking
                                                ? item?.schoolId?.rankings?.[0]?.ranking?.toString()
                                                : "" ||
                                                  item?.preSchoolId
                                                    ?.rankings?.[0]?.ranking
                                                ? item?.preSchoolId?.rankings?.[0]?.ranking?.toString()
                                                : "" ||
                                                  item?.coachingCertificationId
                                                    ?.rankings?.[0]?.ranking
                                                ? item?.coachingCertificationId?.rankings?.[0]?.ranking?.toString()
                                                : ""}
                                                 /10
                                              
                                            </strong>
                                          </label>
                                          </>
                                           : 
                                           <>
                                           <strong>N/A</strong>
                                        
                                           </>
                                           }
                                        
                                        </li>
                                        <li>
                                          <span class="Icon">
                                            {/* <img
                                      src={require("../../assets/images/Ranking-2.png")}
                                    /> */}
                                          </span>
                                          <label>
                                            {/* Outlook : <strong>8/10</strong> */}
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="UniversityType">
                                      <h6>Type :</h6>
                                      <p>
                                        {" "}
                                        {item?.universityId?.studyType ||
                                          item?.schoolId?.studyType ||
                                          item?.collegeId?.collegeType ||
                                          item?.preSchoolId?.studyType ||
                                          item?.coachingCertificationId
                                            ?.studyType}
                                      </p>
                                    </div>
                                    <div class="UniversityFee">
                                      <p>
                                        <span>
                                          <img
                                            src={require("../../assets/images/Book.png")}
                                          />
                                        </span>{" "}
                                        {item?.universityId?.studyMode ||
                                          item?.schoolId?.studyMode ||
                                          item?.collegeId?.studyMode ||
                                          item?.preSchoolId?.studyMode ||
                                          item?.coachingCertificationId
                                            ?.studyMode}{" "}
                                      </p>
                                      <p>
                                        <strong>Fees Range : </strong>
                                        {item?.universityId?.feesMin ||
                                          item?.schoolId?.feesMin ||
                                          item?.collegeId?.feesMin ||
                                          item?.preSchoolId?.feesMin ||
                                          item?.coachingCertificationId
                                            ?.feesMin}{" "}
                                        to{" "}
                                        {item?.universityId?.feesMax ||
                                          item?.schoolId?.feesMax ||
                                          item?.collegeId?.feesMax ||
                                          item?.preSchoolId?.feesMax ||
                                          item?.coachingCertificationId
                                            ?.feesMax}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="ReviewUniversity">
                                  <h3>Your Review</h3>
                                  <ul>
                                    <li>
                                      <h6>
                                        <div>
                                          <StarRatings
                                            rating={item?.rating}
                                            starRatedColor="yellow"
                                            // changeRating={this.changeRating}
                                            numberOfStars={5}
                                            starDimension="20px"
                                            starSpacing="2px"
                                            name="rating"
                                          />
                                        </div>
                                        <span>
                                          {moment(item?.updatedAt).format("ll")}
                                        </span>
                                      </h6>
                                      <p>{item?.review}</p>
                                    </li>
                                  </ul>
                                </div>
                              </di>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default MyProfile;
