import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import Header from "./header";
import Footer from "./footer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { BlogListAction } from "../redux/actions/blogAction";
import { useEffect } from "react";
import { getAddsListAction } from "../redux/actions/getAddsActions";
import { NewsDetailsAction, NewsListAction } from "../redux/actions/newsAction";

const init = {
  newsDetailsData: [],
};

const LatestNewsDetails = () => {
  const [iState, updateState] = useState(init);
  const { newsDetailsData } = iState;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { newsList } = useSelector((state) => state.newsListReducer);
  const [addsMappingData, setAddsMappingData] = useState([]);

  const { getAddsListData } = useSelector((state) => state?.listReducer);

  const { blogList } = useSelector((state) => state.blogReducer);

  useEffect(() => {
    dispatch(NewsDetailsAction({ _id: state?._id }))
      .then((res) => {
        console.log(res);
        updateState({ ...iState, newsDetailsData: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(NewsDetailsAction({ _id: state?._id }));
    dispatch(getAddsListAction({ category: "News Details Page" }));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getAddsListData?.length > 0) {
      if (getAddsListData?.[0]?.image?.length > 0) {
        console.log("insde");
        if (getAddsListData?.[0]?.googleAddShow == "Inactive") {
          // console.log("insde",getAddsListData?.data?.[0]?.image)
          setAddsMappingData(getAddsListData?.[0]?.image);
          // updateState({...iState, addsMappingData:getAddsListData?.data?.[0]?.image})
        } else {
          setAddsMappingData([
            "https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg",
          ]);
          // updateState({...iState, addsMapptngData:"https://storage.googleapis.com/twg-content/images/1294-Thumbnail.width-1200.jpg"})
        }
      }
    }
  }, [getAddsListData]);

  const getDetailsFun = (id) => {
    dispatch(NewsDetailsAction({ _id: id }))
      .then((res) => {
        console.log("res--------->", res);
        updateState({ ...iState, newsDetailsData: res?.data });
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };
  console.log("newsDetailsData", newsDetailsData);

  return (
    <div>
      <Header />
      <section>
        <div class="SearchArea NewsBreadcumb">
          <div class="container">
            <h3>Lastest News Details</h3>
            <p>Home / lastest News Details</p>
          </div>
        </div>
      </section>

      <section>
       

        <div class="NewsDetailsArea">
          <div class="container">
            <div class="AdvertisementBox">
              {addsMappingData && addsMappingData?.length > 0 ? (
                <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "Top left") {
                      return (
                        <a href={ele?.link} target="_blank">
                          {" "}
                          <img src={ele?.image} alt="faq top" />
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="FacultiesBox CoursesBox">
                  <h3>Latest News</h3>
                  <ul>
                    {newsList?.result?.map((item) => (
                      <li>
                        <figure onClick={() => getDetailsFun(item?._id)}>
                          <img src={item?.image} />
                        </figure>
                        <figcaption>
                          <h5>{item?.title}</h5>
                          <h6>{moment(item?.createdAt).format("ll")}</h6>
                        </figcaption>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="VideoBox">
          {addsMappingData && addsMappingData?.length > 0 ? (
            <>
              {addsMappingData?.map((ele, i) => {
                if (ele?.position == "Left 1") {
                  return (
                    <a href={ele?.link} target="_blank">
                      {" "}
                      <img src={ele?.image} alt=" faq top" />
                    </a>
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
          {addsMappingData && addsMappingData?.length > 0 ? (
            <>
              {addsMappingData?.map((ele, i) => {
                if (ele?.position == "Left 2") {
                  console.log(ele);
                  return (
                    <a href={ele?.link} target="_blank">
                      {" "}
                      <img src={ele?.image} alt=" faq top" />
                    </a>
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
        </div>
              </div>

              <div class="col-sm-8">
                <div class="NewsDetailsRigth">
                  <figure>
                    <img src={newsDetailsData?.image} />
                  </figure>
                  <figcaption>
                    <h5>{moment(newsDetailsData?.createdAt).format("ll")}</h5>

                    <h4>{newsDetailsData?.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: newsDetailsData?.description,
                      }}
                      className="editor"
                    ></div>
                  </figcaption>
                </div>
              </div>
            </div>
            <div class="AdvertisementBox">
              {addsMappingData && addsMappingData?.length > 0 ? (
                <>
                  {addsMappingData?.map((ele, i) => {
                    if (ele?.position == "footer top") {
                      return (
                        <a href={ele?.link} target="_blank">
                          {" "}
                          <img src={ele?.image} alt="faq top" />
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LatestNewsDetails;
