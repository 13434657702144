export function logOut(userType) {
    return new Promise((res, rej) => {
      window.localStorage.removeItem(userType);
      res(true);
    });
  }
  
  export function isLoggedIn(userType) {
    let session = getObject("websiteData") || {};
    // console.log(session);
    session = Object.keys(session).length && JSON.parse(session);
    // console.log(session);
    let accessToken = (session && session["jwtToken"]) || "";
    // console.log("accessToken", accessToken);
    return accessToken;
  }
  
  export function getObject(key) {
    if (window && window.localStorage) {
      // console.log(window.localStorage);
      return window.localStorage.getItem(key);
    }
  }
  
  export function multiPartData(data, type = "") {
    let multiPart = new FormData();
    for (let key in data) {
      if (type === "multiple" && key === "blogImage") {
        data[key].forEach((file) => {
          multiPart.append(key, file);
        });
      } else if (key !== "blogImage") {
        multiPart.append(key, data[key]);
      }
    }
  
    return multiPart;
  }
  
  export function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
  